import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import i18n from 'i18next';
import server from '@services/api';
import { jobTypesRoute, jobTypeRoute } from './ts/routes';
import {
  DataJobTypes,
  JobTypesParams,
  JobTypeResources,
  FindJobType,
  DeleteJobType,
  GetJobTypesForSelectPayload,
} from './ts/jobTypes.interface';

export const jobTypesAdapter = createEntityAdapter();

export const initialState = jobTypesAdapter.getInitialState({
  jobTypes: {},
  jobType: {},
  components: {
    jobTypesSelect: {
      docs: [],
      loading: false,
      defaultValue: undefined,
    },
  },
  message: undefined,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
});

export const getJobTypes = createAsyncThunk(
  'jobTypes/getAll',
  async (params: JobTypesParams, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().jobTypes;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response: DataJobTypes = await server({
      method: 'get',
      url: jobTypesRoute(),
      params,
    });

    return response.data;
  }
);

export const createJobTypes = createAsyncThunk(
  'jobTypes/create',
  async (data: JobTypeResources, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().jobTypes;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response: FindJobType = await server({
      method: 'post',
      url: jobTypesRoute(),
      data,
    });

    return response;
  }
);

export const getJobType = createAsyncThunk(
  'jobTypes/getOne',
  async (id: string, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().jobTypes;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response: FindJobType = await server({
      method: 'get',
      url: jobTypeRoute(id),
    });

    return response.data;
  }
);

export const updateJobTypes = createAsyncThunk(
  'jobTypes/update',
  async (data: JobTypeResources, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().jobTypes;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response: FindJobType = await server({
      method: 'put',
      url: jobTypeRoute(data._id),
      data,
    });

    return response;
  }
);

export const deleteJobTypes = createAsyncThunk(
  'jobTypes/delete',
  async (id: string, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().jobTypes;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response: DeleteJobType = await server({
      method: 'delete',
      url: jobTypeRoute(id),
    });

    return response;
  }
);

export const getJobTypesForSelect = createAsyncThunk(
  'jobTypes/getJobTypesForSelect',
  async (params: GetJobTypesForSelectPayload) => {
    const response = await server.get(jobTypesRoute(), { params });
    return response.data;
  }
);

const jobTypesSlice = createSlice({
  name: 'jobTypes',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload;
    },
  },
  extraReducers: {
    [String(getJobTypes.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(getJobTypes.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.jobTypes = action.payload?.data;
        state.currentRequestId = undefined;
      }
    },
    [String(getJobTypes.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(createJobTypes.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(createJobTypes.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.message = i18n.t(`tasks.jobTypes.alerts.newSuccess`);
      }
    },
    [String(createJobTypes.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action?.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getJobType.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(getJobType.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.jobType = action.payload?.data;
        state.currentRequestId = undefined;
      }
    },
    [String(getJobType.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(updateJobTypes.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(updateJobTypes.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.jobType = action.payload?.data;
        state.currentRequestId = undefined;
        state.message = i18n.t(`tasks.jobTypes.alerts.editSuccess`);
      }
    },
    [String(updateJobTypes.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(deleteJobTypes.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(deleteJobTypes.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.message = i18n.t(`tasks.jobTypes.alerts.deleteSuccess`);
      }
    },
    [String(deleteJobTypes.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getJobTypesForSelect.pending)]: (state) => {
      state.components.jobTypesSelect.loading = true;
    },
    [String(getJobTypesForSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;
      const newDocs =
        next || firstLoad
          ? [
              ...state.components.jobTypesSelect?.docs,
              ...action.payload.data?.docs?.filter(
                (obj) =>
                  obj._id !== state.components.jobTypesSelect?.defaultValue &&
                  !state.components.jobTypesSelect?.defaultValue?.includes(obj._id)
              ),
            ]
          : action.payload.data?.docs;

      state.components.jobTypesSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue: next || firstLoad ? state.components.jobTypesSelect?.defaultValue : id,
      };
    },
    [String(getJobTypesForSelect.rejected)]: (state, action) => {
      state.components.jobTypesSelect.loading = false;
      state.error = action.error;
    },
  },
});

export const { setMessage } = jobTypesSlice.actions;

export default jobTypesSlice.reducer;
