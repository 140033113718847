import i18n from 'i18next';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  MailFilled,
  MessageOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  mdiAccountOutline,
  mdiSchoolOutline,
  mdiBriefcaseOutline,
  mdiFileDocumentOutline,
  mdiStoreOutline,
  mdiCogOutline,
  mdiCarOutline,
  mdiChartBoxOutline,
  mdiBellOutline,
  mdiCalendarOutline,
  mdiClipboardTextOutline,
  mdiInformationOutline,
  mdiClose,
} from '@mdi/js';

import ErrorOutlined from '@components/ui/atoms/icons/ErrorOutlined';

import { JobApplicationStatusListsEnum } from '@features/tasks/ts/jobs.enum';
import {
  CountryEnum,
  currenciesEnum,
  DocumentKeyCodeEnum,
  SpecialFilesEnum,
  ExtraDocsEnum,
  VehicleDocumentKeyCodeMatchEnum,
  VerticalsEnum,
  SpecialFilesStateEnum,
  OnSitesStatusEnum,
} from './enum';
import { RolesEnum } from '@features/auth/ts/enums';
import Icon from '@mdi/react';

export const otherDocs = {
  identityCardFront: { name: 'Cédula de identidad frontal' },
  identityCardReverse: { name: 'Cédula de identidad reverso' },
  pensionCertificate: { name: 'Certificado de pensión' },
  epsCertificate: { name: 'Certificado de EPS' },
  rucCertificate: { name: 'Certificado de RUC' },
  suspensionCertificate: { name: 'Exoneración de retención de 4ta categoría' },
};

export const otherDocsCodes = {
  identityCardFront: 'id_card_front',
  identityCardReverse: 'id_card_reverse',
  pensionCertificate: 'pension_certificate',
  epsCertificate: 'eps_certificate',
  rucCertificate: 'ruc_certificate',
  suspensionCertificate: 'suspension_certificate',
};

export const otherDocsCodesInverse = Object.fromEntries(
  Object.entries(otherDocs).map(([key, value]) => [value, key])
);

export const STATUS_USER = {
  staff: {
    title: 'Pendiente Staff',
    color: 'geekblue',
  },
  jobber: {
    title: 'Pendiente usuario',
    color: 'orange',
  },
};

export const STATUS_REQUIREMENT = {
  scheduled: {
    title: i18n.t('requirements.requirementDetails.status.options.scheduled'),
    color: 'orange',
  },
  rejected: {
    title: i18n.t('requirements.requirementDetails.status.options.rejected'),
    color: 'red',
  },
  in_assignment: {
    title: i18n.t('requirements.requirementDetails.status.options.in_assignment'),
    color: 'geekblue',
  },
  ongoing: {
    title: i18n.t('requirements.requirementDetails.status.options.ongoing'),
    color: 'green',
  },
  finished: {
    title: i18n.t('requirements.requirementDetails.status.options.finished'),
    color: 'purple',
  },
  cancelled: {
    title: i18n.t('requirements.requirementDetails.status.options.cancelled'),
    color: 'grey',
  },
};

export const FEASIBILITY_REQUERIMENT = {
  feasibility: {
    title: i18n.t('requirements.requirementDetails.gap.feasibility'),
    color: 'geekblue',
    icon: mdiInformationOutline,
  },
  notFeasible: {
    title: i18n.t('requirements.requirementDetails.gap.notFeasible'),
    color: 'red',
    icon: mdiClose,
  },
};

export const OS = {
  ios: 'IOS',
  android: 'Android',
};

export const STATUS = {
  completed: {
    title: 'Completado',
    color: 'green',
    icon: <CheckOutlined />,
  },
  in_review: {
    title: 'Revisar',
    color: '#025DC1',
    icon: <InfoCircleOutlined />,
  },
  objected: {
    title: 'Objetado',
    color: 'red',
    icon: <ErrorOutlined style={{ height: '16px', marginRight: '7px' }} />,
  },
  blocked: {
    title: 'bloqueado',
    color: 'magenta',
    icon: false,
  },
  in_process: {
    title: 'en proceso',
    color: 'cyan',
    icon: false,
  },
  rejected: {
    title: 'Rechazado',
    color: 'red',
    icon: <CloseOutlined style={{ color: '#D70903' }} />,
  },
  pending: {
    title: 'Pendiente usuario',
    color: '',
    icon: false,
  },
};

export const alertExpiredDocuments = (
  type: keyof typeof subStatus,
  status:
    | 'toExpire'
    | 'expired'
    | 'multipleCases'
    | 'withOutDate'
    | 'inReview'
    | 'expiredInReview'
    | 'toExpiredInReview',
  count: number,
  countTwo?: number
): any => {
  const subStatus = {
    table: {
      toExpire: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.toExpire`, {
          count,
          countTwo,
        }),
        icon: <Icon path={mdiCalendarOutline} size={'20px'} color="#AB5301" className="mdi-icon" />,
        color: 'yellow',
      },
      expired: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.expired`, {
          count,
          countTwo,
        }),
        icon: <Icon path={mdiCalendarOutline} size={'20px'} color="#D70903" className="mdi-icon" />,
        color: 'red',
      },
      multipleCases: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.multipleCase`, {
          count,
          countTwo,
        }),
        icon: <Icon path={mdiCalendarOutline} size={'20px'} color="#D70903" className="mdi-icon" />,
        color: 'red',
      },
      withOutDate: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.withOutDate`, {
          count,
          countTwo,
        }),
        icon: <Icon path={mdiCalendarOutline} size={'20px'} color="#5E5082" className="mdi-icon" />,
        color: 'purple',
      },
      inReview: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.inReview`, {
          count,
          countTwo,
        }),
        icon: (
          <Icon path={mdiFileDocumentOutline} size={'20px'} color="#025DC1" className="mdi-icon" />
        ),
        color: 'geekblue',
      },
      expiredInReview: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.expiredInReview`, {
          count,
          countTwo,
        }),
        icon: (
          <Icon path={mdiFileDocumentOutline} size={'20px'} color="#5E5082" className="mdi-icon" />
        ),
        color: 'purple',
      },
      toExpiredInReview: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.toExpiredInReview`, {
          count,
          countTwo,
        }),
        icon: (
          <Icon path={mdiFileDocumentOutline} size={'20px'} color="#5E5082" className="mdi-icon" />
        ),
        color: 'purple',
      },
    },
    applied: {
      toExpire: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.toExpire`, {
          count,
          countTwo,
        }),
        icon: <Icon path={mdiCalendarOutline} size={'20px'} color="#025DC1" className="mdi-icon" />,
        color: 'geekblue',
      },
      expired: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.expired`, {
          count,
          countTwo,
        }),
        icon: <Icon path={mdiCalendarOutline} size={'20px'} color="#AB5301" className="mdi-icon" />,
        color: 'yellow',
      },
      multipleCases: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.table.multipleCase`, {
          count,
          countTwo,
        }),
        icon: <Icon path={mdiCalendarOutline} size={'20px'} color="#AB5301" className="mdi-icon" />,
        color: 'yellow',
      },
    },
    message: {
      toExpire: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.summary.toExpire`, {
          count,
          countTwo,
        }),
        color: '#AB5301',
      },
      expired: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.summary.expired`, {
          count,
          countTwo,
        }),
        color: '#D70903',
      },
      multipleCases: {
        text: i18n.t(`applicants.review.visualizer.subStatus.tags.summary.multipleCase`, {
          count,
          countTwo,
        }),
        color: '#D70903',
      },
    },
  };

  return subStatus[type][status];
};

export const documentIsBlocking = [
  'visa_application',
  'id',
  'foreign_ID',
  'records',
  'vaccinationCard',
  'suspensionCertificate',
  'ptp',
  'ce',
];

export const fileStatus = {
  approved: <CheckOutlined style={{ fontSize: '20px', color: '#00810D' }} />,
  in_review: <InfoCircleOutlined style={{ fontSize: '20px', color: '#025DC1' }} />,
  toExpire: <InfoCircleOutlined style={{ fontSize: '20px', color: '#025DC1' }} />,
  expired: <InfoCircleOutlined style={{ fontSize: '20px', color: '#D70903' }} />,
  pending: <ExclamationCircleOutlined style={{ fontSize: '20px', color: '#AB5301' }} />,
  rejected: <CloseOutlined style={{ fontSize: '20px', color: '#D70903' }} />,
  objected: <ErrorOutlined />,
  undefined: <ExclamationCircleOutlined style={{ fontSize: '20px', color: '#AB5301' }} />,
};

export const alertSubStatus = {
  expired: <InfoCircleOutlined style={{ fontSize: '20px', color: '#D70903' }} />,
  toExpire: <InfoCircleOutlined style={{ fontSize: '20px', color: '#AB5301' }} />,
};

export const fileStatusColor = {
  approved: 'green',
  in_review: '#025DC1',
  pending: '',
  rejected: 'red',
  objected: 'red',
  undefined: '',
};

export const logbookActions = {
  call: {
    icon: <PhoneOutlined />,
  },
  email: {
    icon: <MailFilled />,
  },
  message: {
    icon: <MessageOutlined />,
  },
  assignation: {
    icon: <UserOutlined />,
  },
  update: {
    icon: <EditOutlined />,
  },
};

export const logbookCategoryType = {
  noAnswer: 'no-answer',
  interested: 'interested',
  notInterested: 'not-interested',
  futureInterest: 'future-interest',
};

export const alertIcons = {
  success: <CheckOutlined />,
  error: <CloseOutlined />,
  info: <InfoCircleOutlined />,
  warning: <ExclamationCircleOutlined />,
};

export const roleOptions = ['staff', 'staff_onboarding'];

export const enterpriseRoleOptions = [RolesEnum.ENTERPRISE, RolesEnum.ENTERPRISE_ADMIN];

const defaultKeysCountries = {
  phoneLengthWithoutPrefix: 10,
  accountNumberLength: 32,
  accountType: ['cc', 'ca'],
  docs: [''],
};

export const countries = {
  CO: {
    ...defaultKeysCountries,
    docs: [
      'cedula_de_ciudadania',
      'cedula_de_extranjeria',
      'pep',
      'numero_de_identificacion_tributaria',
      'ppt',
    ],
    phonePrefix: '+57',
    phoneLengthWithoutPrefix: 10,
    uploadDocuments: [
      DocumentKeyCodeEnum.ID_CARD_FRONT,
      DocumentKeyCodeEnum.ID_CARD_REVERSE,
      DocumentKeyCodeEnum.EPS_CERTIFICATE,
      DocumentKeyCodeEnum.PENSION_CERTIFICATE,
    ],
    name: 'Colombia',
    vehicles: ['moto'],
    vehiclesDocs: {
      moto: [
        VehicleDocumentKeyCodeMatchEnum.motorcycle_ownership_card,
        VehicleDocumentKeyCodeMatchEnum.motorcycle_road_worthiness_test,
        VehicleDocumentKeyCodeMatchEnum.motorcycle_driver_license,
        VehicleDocumentKeyCodeMatchEnum.motorcycle_soat,
      ],
    },
    extraDocuments: [
      ExtraDocsEnum.vaccination_card,
      ExtraDocsEnum.food_handling_certificate,
      ExtraDocsEnum.eps_certificate,
      ExtraDocsEnum.pension_certificate,
    ],
    paymentSchemaLimits: {
      hour: {
        min: 1,
        max: 1000000,
      },
      units: {
        min: 0,
        max: 500,
      },
    },
    mapCenter: { lat: 4.626233, lng: -74.080653 },
  },
  PE: {
    ...defaultKeysCountries,
    docs: ['dni', 'ce', 'ptp', 'cpp', 'ruc'],
    phonePrefix: '+51',
    name: 'Peru',
    interbankCodeLength: 20,
    accountNumberLength: 16,
    phoneLengthWithoutPrefix: 9,
    vehicles: ['auto', 'moto', 'van'],
    uploadDocuments: [
      DocumentKeyCodeEnum.ID_CARD_FRONT,
      DocumentKeyCodeEnum.ID_CARD_REVERSE,
      DocumentKeyCodeEnum.SUSPENSION_CERTIFICATE,
    ],
    vehiclesDocs: {
      auto: [
        VehicleDocumentKeyCodeMatchEnum.car_driver_license,
        VehicleDocumentKeyCodeMatchEnum.car_ownership_card,
        VehicleDocumentKeyCodeMatchEnum.car_soat,
        VehicleDocumentKeyCodeMatchEnum.car_road_worthiness_test,
      ],
      moto: [
        VehicleDocumentKeyCodeMatchEnum.motorcycle_driver_license,
        VehicleDocumentKeyCodeMatchEnum.motorcycle_ownership_card,
        VehicleDocumentKeyCodeMatchEnum.motorcycle_soat,
      ],
      van: [
        VehicleDocumentKeyCodeMatchEnum.van_driver_license,
        VehicleDocumentKeyCodeMatchEnum.van_ownership_card,
        VehicleDocumentKeyCodeMatchEnum.van_soat,
        VehicleDocumentKeyCodeMatchEnum.van_road_worthiness_test,
      ],
    },
    extraDocuments: [ExtraDocsEnum.vaccination_card, ExtraDocsEnum.health_card_with_food_handling],
    paymentSchemaLimits: {
      lap: {
        min: 0,
        max: 2,
      },
      kms: {
        min: 0,
        max: 50,
      },
      order: {
        min: 0,
        max: 50,
      },
      hour: {
        min: 0,
        max: 990,
      },
      units: {
        min: 0,
        max: 500,
      },
      tip: {
        min: 0,
        max: 400,
      },
      completeness: {
        min: 0,
        max: 100,
      },
      nps: {
        min: 0,
        max: 100,
      },
      canceled: {
        min: 0,
        max: 100,
      },
      insurable: {
        min: 0,
        max: 100,
      },
      additional: {
        min: 0,
        max: 100,
      },
      ontime: {
        min: 0,
        max: 999,
      },
      specialadditional: {
        min: 0,
        max: 999,
      },
      specialinsurable: {
        min: 0,
        max: 999,
      },
      specialcancellation: {
        min: 0,
        max: 999,
      },
      specialorder: {
        min: 0,
        max: 999,
      },
      kmsspecials: {
        min: 0,
        max: 999,
      },
      canceledunauthorized: {
        min: 0,
        max: 999,
      },
      transfer: {
        min: 0,
        max: 999,
      },
    },
    mapCenter: { lat: -12.044062386030685, lng: -77.04334164909426 },
  },
  MX: {
    ...defaultKeysCountries,
    docs: ['curp'],
    phonePrefix: '+55',
    phoneLengthWithoutPrefix: 10,
    name: 'Mexico',
    accountType: [''],
    interbankCodeLength: 18,
    accountNumberLength: 10,
    vehicles: ['auto', 'moto'],
    vehiclesDocs: {},
    extraDocuments: [],
    mapCenter: { lat: 19.42428, lng: -99.162819 },
    uploadDocuments: [DocumentKeyCodeEnum.ID_CARD_FRONT, DocumentKeyCodeEnum.ID_CARD_REVERSE],
  },
  CL: {
    ...defaultKeysCountries,
    docs: ['rut'],
    phonePrefix: '+56',
    name: 'Chile',
    accountType: ['cc', 'cv', 'ca'],
    phoneLengthWithoutPrefix: 9,
    vehicles: ['auto', 'moto', 'bicimoto', 'furgon', 'van'],
    uploadDocuments: [DocumentKeyCodeEnum.ID_CARD_FRONT, DocumentKeyCodeEnum.ID_CARD_REVERSE],
    vehiclesDocs: {
      auto: [
        VehicleDocumentKeyCodeMatchEnum.car_registration_certificate,
        VehicleDocumentKeyCodeMatchEnum.car_driver_license,
        VehicleDocumentKeyCodeMatchEnum.car_insurance,
      ],
      moto: [
        VehicleDocumentKeyCodeMatchEnum.motorcycle_registration_certificate,
        VehicleDocumentKeyCodeMatchEnum.motorcycle_driver_license,
      ],
      bicimoto: [
        VehicleDocumentKeyCodeMatchEnum.moped_registration_certificate,
        VehicleDocumentKeyCodeMatchEnum.moped_driver_license,
      ],
      furgon: [
        VehicleDocumentKeyCodeMatchEnum.furgon_registration_certificate,
        VehicleDocumentKeyCodeMatchEnum.furgon_driver_license,
      ],
    },
    extraDocuments: [ExtraDocsEnum.vaccination_card],
    paymentSchemaLimits: {
      order: {
        min: 0,
        max: 10000,
      },
      lap: {
        min: 0,
        max: 400,
      },
      sku: {
        min: 0,
        max: 500,
      },
      units: {
        min: 0,
        max: 500,
      },
      kms: {
        min: 0,
        max: 500,
      },
      hour: {
        min: 1,
        max: 250000,
      },
    },
    mapCenter: { lat: -33.458389, lng: -70.645116 },
  },
};

export const AttendanceRatio = [50, 80, 100, 300, 500, 800, 1000];
export const AttendanceRatioZoom = { 50: 17, 80: 17, 100: 17, 300: 16, 500: 15, 800: 14, 1000: 14 };

export const documentType = {
  cepe: 'ce',
  rut: 'rut',
  ruc: 'ruc',
  dni: 'dni',
  ptp: 'ptp',
  ppt: 'ppt',
  cpp: 'cpp',
  curp: 'curp',
  passport: 'pasaporte',
  ci: 'cedula_de_identidad',
  cc: 'cedula_de_ciudadania',
  ceco: 'cedula_de_extranjeria',
  pep: 'pep',
};

export const lunchHoursLimit = {
  MIN: 10,
  MAX: 60,
};

export const lunchHoursLimitByCountry = {
  [CountryEnum.PE]: {
    MIN: 10,
    MAX: 180,
  },
};

export const links = {
  dashboard: {
    title: i18n.t('home.links.dashboard.title'),
    links: [
      {
        dir: 'dashboard/',
        name: i18n.t('home.links.dashboard.indicators'),
      },
    ],
    path: mdiChartBoxOutline,
    color: '#7E6AAD',
    style: { backgroundColor: '#EBE7F7' },
    classCss: 'card-top',
  },

  jobbers: {
    title: i18n.t('home.links.jobbers.title'),
    links: [
      {
        dir: 'applicants/',
        name: i18n.t('home.links.jobbers.pendingApplicants'),
      },
      {
        dir: 'jobbers/',
        name: i18n.t('home.links.jobbers.activeJobbers'),
      },
      {
        dir: 'blocklist-safelist/',
        name: i18n.t('home.links.jobbers.blocklist_safelist'),
      },
    ],
    path: mdiAccountOutline,
    color: '#EC0091',
    style: { backgroundColor: '#FDE6F4' },
    classCss: 'card-top',
  },

  notifications: {
    title: i18n.t('home.links.notifications.title'),
    links: [
      {
        dir: 'notifications/',
        name: i18n.t('home.links.notifications.main'),
      },
    ],
    path: mdiBellOutline,
    color: '#282F39',
    style: { backgroundColor: '#F3F3F3' },
    classCss: 'card-top',
  },

  inductions: {
    title: i18n.t('home.links.inductions.title'),
    links: [
      {
        dir: 'courses-skills-shifts/',
        name: i18n.t('home.links.inductions.inductionManager'),
      },
    ],
    path: mdiSchoolOutline,
    color: '#324B5E',
    style: { backgroundColor: '#E5EFFA' },
    classCss: 'card-top',
  },

  tasks: {
    title: i18n.t('home.links.tasks.title'),
    links: [
      {
        dir: 'tasks/',
        name: i18n.t('home.links.tasks.tasks'),
      },
    ],
    path: mdiBriefcaseOutline,
    color: '#0088D',
    style: { backgroundColor: '#EEFFED' },
    classCss: 'card-top',
  },

  delivery: {
    title: i18n.t('home.links.delivery.title'),
    links: [
      {
        dir: 'delivery/',
        name: i18n.t('home.links.delivery.delivery'),
      },
    ],
    path: mdiCarOutline,
    color: '#6b1959',
    style: { backgroundColor: '#F8F4F7' },
    classCss: 'card-top',
  },

  payments: {
    title: i18n.t('home.links.payments.title'),
    links: [
      {
        dir: 'payments-labours/',
        name: i18n.t('home.links.payments.paymentPage'),
      },
    ],
    path: mdiFileDocumentOutline,
    color: '#6B1959',
    style: { backgroundColor: '#F8F4F7' },
    classCss: 'card-top',
  },

  paymentsLaboursShopper: {
    title: i18n.t('home.links.payments.title'),
    links: [
      {
        dir: 'payments-labours/',
        name: i18n.t('sidebar.menu.payments.submenu.0'),
      },
      {
        dir: 'payments-shoppers/',
        name: i18n.t('sidebar.menu.payments.submenu.1'),
      },
    ],
    path: mdiAccountOutline,
    color: '#EC0091',
    style: { backgroundColor: '#FDE6F4' },
    classCss: 'card-top',
  },

  reports: {
    title: i18n.t('home.links.reports.title'),
    links: [
      {
        dir: 'finance/report/',
        name: i18n.t('home.links.reports.paymentReport'),
      },
    ],
    path: mdiFileDocumentOutline,
    color: '#AB5301',
    style: { backgroundColor: '#FEF7EA' },
    classCss: 'card-top',
  },

  requirements: {
    title: i18n.t('home.links.requirements.title'),
    links: [
      {
        dir: 'requirements/',
        name: i18n.t('home.links.requirements.requirementsPage'),
      },
    ],
    color: '#6E5D97 ',
    path: mdiClipboardTextOutline,
    style: { backgroundColor: '#EBE7F7' },
    classCss: 'card-top',
  },

  commercialCustomer: {
    title: i18n.t('home.links.commercialCustomer.title'),
    links: [
      {
        dir: 'enterprises/',
        name: i18n.t('home.links.commercialCustomer.customers'),
      },
    ],
    path: mdiStoreOutline,
    color: '#D70903',
    style: { backgroundColor: '#FEF2F1' },
    classCss: 'card-top',
  },

  config: {
    title: i18n.t('home.links.configuration.title'),
    links: [
      {
        dir: 'staffs/',
        name: i18n.t('home.links.configuration.staffs'),
        roles: [RolesEnum.STAFF, RolesEnum.ADMIN],
      },
      {
        dir: 'localities/',
        name: i18n.t('home.links.configuration.localitiesManager'),
        roles: [RolesEnum.STAFF, RolesEnum.ADMIN],
      },
      {
        dir: 'maintainers/job-types/',
        name: i18n.t('home.links.configuration.taskTypeMaintainer'),
      },
      {
        dir: 'maintainers/coordinators/',
        name: i18n.t('home.links.configuration.maintainerCoordinatorsHeadquarters'),
      },
    ],
    path: mdiCogOutline,
    color: '#324B5E',
    style: { backgroundColor: '#F5F6FA' },
    classCss: 'card-bottom',
  },
};

export const fileTypesExtensions = {
  jpg: ['image/jpeg'],
  jpeg: ['image/jpeg'],
  pdf: ['application/pdf'],
  csv: ['application/vnd.ms-excel', 'text/csv'],
};

export const statusToStats = {
  [JobApplicationStatusListsEnum.FINISHED]: 'hasApplicationsCompletedLastMonth',
  [JobApplicationStatusListsEnum.ABSENT]: 'hasApplicationsAbsentLastMonth',
  [JobApplicationStatusListsEnum.APPLIED]: 'hasApplicationsAppliedNextWeek',
  [JobApplicationStatusListsEnum.ASSIGNED]: 'hasApplicationsAssignedNextWeek',
  [JobApplicationStatusListsEnum.CANCELLED_POST]: 'hasApplicationsCancelledAfterAssignedLastMonth',
};

export const maxKilometers = 100;

export const countryCurrencies = {
  [CountryEnum.CL]: [currenciesEnum.CLP, currenciesEnum.USD, currenciesEnum.UF],
  [CountryEnum.CO]: [currenciesEnum.COP, currenciesEnum.USD],
  [CountryEnum.MX]: [currenciesEnum.MXN, currenciesEnum.USD],
  [CountryEnum.PE]: [currenciesEnum.PEN, currenciesEnum.USD],
};

export const currenciesSymbol = {
  [currenciesEnum.USD]: '$',
  [currenciesEnum.CLP]: '$',
  [currenciesEnum.COP]: '$',
  [currenciesEnum.PEN]: 'S/',
  [currenciesEnum.MXN]: '$',
  [currenciesEnum.UF]: '$',
};

export const enabledVerticals = [
  VerticalsEnum.CUSTOMER_SERVICE,
  VerticalsEnum.LAST_MILLE,
  VerticalsEnum.STORAGE,
  VerticalsEnum.RESTAURANT,
  VerticalsEnum.RETAIL,
  VerticalsEnum.INTERN,
  VerticalsEnum.SUPERVISOR,
  VerticalsEnum.EST,
];

export const pendingDocuments = [
  SpecialFilesEnum.RECORDS,
  SpecialFilesEnum.ID_CARD_FRONT,
  SpecialFilesEnum.ID_CARD_REVERSE,
  SpecialFilesEnum.REGISTRATION_CERTIFICATE,
  SpecialFilesEnum.DRIVER_LICENSE,
  SpecialFilesEnum.VISA_APPLICATION,
];

export const pendingDocumentsStatus = [
  SpecialFilesStateEnum.OBJECTED,
  SpecialFilesStateEnum.NOT_OBJECTED,
];

export const onSitesStatus = [
  OnSitesStatusEnum.SCHEDULED,
  OnSitesStatusEnum.CANCELLED,
  OnSitesStatusEnum.ABSENT,
  OnSitesStatusEnum.PRESENT,
  OnSitesStatusEnum.FINISHED,
];

export const dateTimeFormat = `HH:mm [hrs.] - DD/MM/YYYY`;
export const pickerPlaceholderFormat = 'DD/MM/YYYY';

export enum enterpriseType {
  SMU = 'unimarc',
  WALMART = 'walmart',
}

export enum enterpriseCodeNumber {
  SMU = '61e052a06f4c8f00241e5ecb',
  WALMART = '61e049816f4c8f00241e5eba',
  RAPPI = '61e043dbec355b00230419db',
}

export enum jobTypeCodeNumber {
  PERSONAL_SHOPPER = '61e03fce1eef800023e74c09',
  HOME_DELIVERY = '61e04030fe2865002366a2ba',
}

export enum jobTypeName {
  'personal shopper' = '61e03fce1eef800023e74c09',
  'home delivery' = '61e04030fe2865002366a2ba',
}

export enum BlockDocument {
  NON_BLOCKING = 'nonBlocking',
  IS_BLOCKED = 'isBlocked',
}

export const DOCUMENT_CODES = {
  CL: {
    records: ['records'],
    otherDocuments: [
      'id_front',
      'id_reverse',
      'visa_application_front',
      'visa_application_reverse',
    ],
    additionalInformation: [
      'carRegistrationCertificate',
      'motorcycleRegistrationCertificate',
      'mopedRegistrationCertificate',
      'furgonRegistrationCertificate',
      'carDriverLicense',
      'motorcycleDriverLicense',
      'mopedDriverLicense',
      'furgonDriverLicense',
      'carInsurance',
    ],
  },
  PE: {
    otherDocuments: [
      'id_front',
      'id_reverse',
      'foreign_ID_front',
      'foreign_ID_reverse',
      'PTP_front',
      'PTP_reverse',
    ],
    additionalInformation: [
      'suspensionCertificate',
      'healthCardWithFoodHandling',
      'carDriverLicense',
      'furgonRegistrationCertificate',
      'foodHandlingCertificate',
      'motorcycleDriverLicense',
      'furgonDriverLicense',
      'carSoat',
      'vanSoat',
      'motorcycleSoat',
    ],
  },
  CO: {
    otherDocuments: [
      'id_front',
      'id_reverse',
      'PEP_front',
      'PEP_reverse',
      'foreign_ID_front',
      'foreign_ID_reverse',
      'PTP_front',
      'PTP_reverse',
    ],
    additionalInformation: [
      'furgonRegistrationCertificate',
      'motorcycleDriverLicense',
      'furgonDriverLicense',
      'motorcycleSoat',
      'foodHandlingCertificate',
      'motorcycleRoadWorthinessTest',
      'vanRoadWorthinessTest',
      'carRoadWorthinessTest',
    ],
  },
};

export const SPECIAL_CODE_MAP = {
  id_front: 'identityCardFront',
  id_reverse: 'identityCardReverse',
  visa_application_front: 'identityCardFront',
  visa_application_reverse: 'identityCardReverse',
  foreign_ID_front: 'identityCardFront',
  foreign_ID_reverse: 'identityCardReverse',
  PTP_front: 'identityCardFront',
  PTP_reverse: 'identityCardReverse',
  PEP_front: 'identityCardFront',
  PEP_reverse: 'identityCardReverse',
};
