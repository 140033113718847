import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import server from '@services/api';
import i18n from 'i18next';

import {
  courseRoute,
  coursesRoute,
  getCoursesCountSummaryRoute,
  getThinkificCoursesRoute,
  getThinkificCategoriesRoute,
} from '@features/courses/ts/routes';
import {
  GetCoursePayload,
  GetCoursesPayload,
  GetThinkificCoursesPayload,
  PostCoursePayload,
  PutCoursePayload,
} from './ts/courses.interfaces';
import { clearState as clearStateUtils } from '@utils/functions';
import { RequestStatusEnum } from '@utils/enum';
import { CoursesTypes } from './ts/enums';

export const coursesAdapter = createEntityAdapter();

export const initialState = coursesAdapter.getInitialState({
  courses: { docs: [], loading: false },
  coursesCounter: { loading: false, meets: 0, eLearning: 0, onSite: 0 },
  courseSummary: { loading: false, data: {} },
  editCourse: { loading: false, status: RequestStatusEnum.IDLE, data: {} },
  components: {
    coursesSelect: { docs: [], loading: false },
    thinkificCoursesSelect: { docs: [], loading: false, defaultValue: undefined },
    thinkificCategoriesSelect: { docs: [], loading: false, defaultValue: undefined },
  },
  alertMessages: { type: undefined, text: '' },
  createCourseStatus: RequestStatusEnum.IDLE,
  deleteCourseStatus: RequestStatusEnum.IDLE,
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
});

export const getCourses = createAsyncThunk(
  'courses/getCourses',
  async (payload: GetCoursesPayload) => {
    const response = await server.get(coursesRoute(), {
      params: { ...payload },
    });
    return response.data;
  }
);

export const getCoursesSummaryCounter = createAsyncThunk(
  'courses/getCoursesSummaryCounter',
  async () => {
    const response = await server.get(getCoursesCountSummaryRoute());
    return response.data;
  }
);

export const getCoursesForSelect = createAsyncThunk(
  'courses/getCoursesForSelect',
  async (payload: { limit: number; type?: CoursesTypes[] }) => {
    const response = await server.get(coursesRoute(), { params: payload });
    return response.data;
  }
);

export const getThinkificCategoriesForSelect = createAsyncThunk(
  'courses/getThinkificCategoriesForSelect',
  async (params: object) => {
    const response = await server.get(getThinkificCategoriesRoute(), { params });
    return response.data;
  }
);

export const getThinkificCoursesForSelect = createAsyncThunk(
  'courses/getThinkificCoursesForSelect',
  async (params: GetThinkificCoursesPayload) => {
    const response = await server.get(getThinkificCoursesRoute(params.categoryId), { params });
    return response.data;
  }
);

export const postCourse = createAsyncThunk(
  'courses/postCourse',
  async (payload: PostCoursePayload) => {
    const response = await server.post(coursesRoute(), payload);
    return response.data;
  }
);

export const getCourse = createAsyncThunk(
  'courses/getCourse',
  async (payload: GetCoursePayload) => {
    const response = await server.get(courseRoute(payload.id));
    return response.data;
  }
);

export const putCourse = createAsyncThunk(
  'courses/putCourse',
  async (payload: PutCoursePayload) => {
    const response = await server.put(courseRoute(payload._id), payload);
    return response.data;
  }
);

export const deleteCourse = createAsyncThunk('courses/deleteCourse', async (id: string) => {
  const response = await server.delete(courseRoute(id));
  return response.data;
});

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    clearAlertMsg: (state) => {
      state.alertMessages = { type: '', text: '' };
    },
    clearCourseSummary: (state) => {
      state.courseSummary = { loading: false, data: {} };
    },
    clearState: clearStateUtils,
  },
  extraReducers: {
    [String(getCourses.pending)]: (state) => {
      state.courses.loading = true;
    },
    [String(getCourses.fulfilled)]: (state, action) => {
      state.courses = { loading: false, ...action.payload.data };
    },
    [String(getCourses.rejected)]: (state, action) => {
      state.courses.loading = false;
      state.error = action.error;
    },

    [String(getCoursesSummaryCounter.pending)]: (state) => {
      state.coursesCounter.loading = true;
    },
    [String(getCoursesSummaryCounter.fulfilled)]: (state, action) => {
      state.coursesCounter = { loading: false, ...action.payload.data };
    },
    [String(getCoursesSummaryCounter.rejected)]: (state, action) => {
      state.coursesCounter.loading = false;
      state.error = action.error;
    },

    [String(getCoursesForSelect.pending)]: (state) => {
      state.components.coursesSelect.loading = true;
    },
    [String(getCoursesForSelect.fulfilled)]: (state, action) => {
      state.components.coursesSelect = { loading: false, ...action.payload.data };
    },
    [String(getCoursesForSelect.rejected)]: (state, action) => {
      state.components.coursesSelect.loading = false;
      state.error = action.error;
    },

    [String(getThinkificCategoriesForSelect.pending)]: (state) => {
      state.components.thinkificCategoriesSelect.loading = true;
    },
    [String(getThinkificCategoriesForSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;

      const newDocs =
        next || firstLoad
          ? [
              ...state.components.thinkificCategoriesSelect?.docs,
              ...action.payload.data?.filter(
                (obj) => obj._id !== state.components.thinkificCategoriesSelect?.defaultValue
              ),
            ]
          : action.payload.data;

      state.components.thinkificCategoriesSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue:
          next || firstLoad ? state.components.thinkificCategoriesSelect?.defaultValue : id,
      };
    },
    [String(getThinkificCategoriesForSelect.rejected)]: (state, action) => {
      state.components.thinkificCategoriesSelect.loading = false;
      state.error = action.error;
    },

    [String(getThinkificCoursesForSelect.pending)]: (state) => {
      state.components.thinkificCoursesSelect.loading = true;
    },
    [String(getThinkificCoursesForSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;

      const newDocs =
        next || firstLoad
          ? [
              ...state.components.thinkificCoursesSelect?.docs,
              ...action.payload.data?.filter(
                (obj) => obj._id !== state.components.thinkificCoursesSelect?.defaultValue
              ),
            ]
          : action.payload.data;

      state.components.thinkificCoursesSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue:
          next || firstLoad ? state.components.thinkificCoursesSelect?.defaultValue : id,
      };
    },
    [String(getThinkificCoursesForSelect.rejected)]: (state, action) => {
      state.components.thinkificCoursesSelect.loading = false;
      state.error = action.error;
    },

    [String(postCourse.pending)]: (state) => {
      state.createCourseStatus = RequestStatusEnum.PENDING;
    },
    [String(postCourse.fulfilled)]: (state) => {
      state.createCourseStatus = RequestStatusEnum.SUCCESS;
      state.alertMessages = {
        type: 'success',
        text: i18n.t('courses.main.actionsDrawer.new.successMessage'),
      };
    },
    [String(postCourse.rejected)]: (state, action) => {
      state.createCourseStatus = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(getCourse.pending)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'editCourse' : 'courseSummary';
      state[field].loading = true;
    },
    [String(getCourse.fulfilled)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'editCourse' : 'courseSummary';
      state[field].loading = false;
      state[field].data = action.payload.data;
    },
    [String(getCourse.rejected)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'editCourse' : 'courseSummary';
      state[field].loading = false;
      state.error = action.error;
    },

    [String(putCourse.pending)]: (state) => {
      state.editCourse.status = RequestStatusEnum.PENDING;
    },
    [String(putCourse.fulfilled)]: (state) => {
      state.editCourse.status = RequestStatusEnum.SUCCESS;
      state.alertMessages = {
        type: 'success',
        text: i18n.t('courses.main.actionsDrawer.edit.successMessage'),
      };
    },
    [String(putCourse.rejected)]: (state, action) => {
      state.editCourse.status = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(deleteCourse.pending)]: (state) => {
      state.deleteCourseStatus = RequestStatusEnum.PENDING;
    },
    [String(deleteCourse.fulfilled)]: (state) => {
      state.deleteCourseStatus = RequestStatusEnum.SUCCESS;
      state.alertMessages = {
        type: 'success',
        text: i18n.t('courses.main.deleteModal.successMessage'),
      };
    },
    [String(deleteCourse.rejected)]: (state, action) => {
      state.deleteCourseStatus = RequestStatusEnum.ERROR;
      state.error = action.error;
    },
  },
});

export const { clearAlertMsg, clearCourseSummary, clearState } = coursesSlice.actions;
export default coursesSlice.reducer;
