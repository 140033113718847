import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import server from '@services/api';

import {
  disableRoute,
  getSearchRoute,
  getStaffsRoute,
  createStaffRoute,
  editStaffRoute,
} from '@features/staffs/ts/routes';
import {
  GetStaffLists,
  GetStaffsFiltersInterfaces,
  PostDisable,
  StaffDocs,
  CreateStaffInfo,
  EditStaffInfo,
} from './ts/staffs.interfaces';

import { storeStateHandler, pendingStateHandler } from '@utils/functions';
import i18n from 'i18next';

export const staffsAdapter = createEntityAdapter();

export const initialState = staffsAdapter.getInitialState({
  staffs: {
    docs: [] as [] | StaffDocs[],
    loading: false,
    hasPrevious: false,
    payload: {
      limit: 10,
    },
    paginationAux: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  },
  staffsSelect: { docs: [], loading: false, defaultValue: undefined },
  staffsAltSelect: { docs: [], loading: false, defaultValue: undefined },
  createStaff: { status: 'idle' },
  editStaff: { status: 'idle' },
  successMsg: '',

  loading: 'idle',
  currentRequestId: undefined,
  error: null,
  search: {
    searchLoading: false,
    searchList: null,
  },
  disable: { bulkState: 'idle', individualState: 'idle' },
});

export const getStaffs = createAsyncThunk(
  'staffs/getStaffs',
  async (payload: GetStaffsFiltersInterfaces) => {
    const response = await server.get(getStaffsRoute(payload));
    return response.data;
  }
);

export const getSearch = createAsyncThunk(
  'staffs/getSearch',
  async (params: GetStaffsFiltersInterfaces, { getState, requestId }: any) => {
    const { currentRequestId } = getState().staffs;
    if (requestId !== currentRequestId) {
      return;
    }
    const response: GetStaffLists = await server({
      method: 'get',
      url: getSearchRoute(params),
    });
    return response?.data;
  }
);

export const getStaffsForSelect = createAsyncThunk(
  'stores/getStaffsForSelect',
  async (params: GetStaffsFiltersInterfaces) => {
    const response = await server.get(getStaffsRoute(params));
    return response.data;
  }
);

export const getStaffsForAltSelect = createAsyncThunk(
  'stores/getStaffsForAltSelect',
  async (params: GetStaffsFiltersInterfaces) => {
    const response = await server.get(getStaffsRoute(params));
    return response.data;
  }
);

export const createStaff = createAsyncThunk(
  'staffs/createStaff',
  async (params: CreateStaffInfo, thunkApi: any) => {
    try {
      const { currentRequestId } = thunkApi.getState().staffs;
      if (thunkApi.requestId !== currentRequestId) {
        return;
      }
      const response: GetStaffLists = await server.post(createStaffRoute(), params);
      return response?.data;
    } catch (error) {
      const { status } = error.request;
      return thunkApi.rejectWithValue({ status });
    }
  }
);

export const editStaff = createAsyncThunk(
  'staffs/editStaff',
  async (params: EditStaffInfo, thunkApi: any) => {
    try {
      const { currentRequestId } = thunkApi.getState().staffs;
      if (thunkApi.requestId !== currentRequestId) {
        return;
      }
      const response = await server.put(editStaffRoute(params.id), params);
      return response?.data;
    } catch (error) {
      const { status } = error.request;
      return thunkApi.rejectWithValue({ status });
    }
  }
);

export const postDisable = createAsyncThunk(
  'applicants/postAssignment',
  async (payload: PostDisable, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response = await server.put(disableRoute(), payload);
    response.data.individualDisable = payload.individualDisable;
    return response.data;
  }
);

const staffsSlice = createSlice({
  name: 'staffs',
  initialState,
  reducers: {
    clearState: (state, b: { payload: { key: string; subkey: string; base?: string } }) => {
      const originalState = !b.payload?.base ?? 'idle';
      if (state[b.payload?.key]?.[b.payload?.subkey])
        state[b.payload.key][b.payload?.subkey] = originalState;
    },
    clearSuccessMsg: (state) => {
      state.successMsg = '';
    },
    setPayload: (state, b: { payload: GetStaffsFiltersInterfaces }) => {
      state.staffs.payload = b.payload;
    },
    setPagination: (state, b: { payload: any }) => {
      state.staffs.paginationAux = b.payload;
    },
    setDisableLocalyToStaff: (state, b: { payload: { staffIds: Array<string> } }) => {
      const { staffIds } = b.payload ?? {};
      for (let i = 0; i < staffIds.length; i++) {
        const index = state.staffs.docs.findIndex(
          (item: Record<string, string>) => item._id === staffIds[i]
        );
        if (index >= 0) state.staffs.docs[index].isBlocked = true;
      }
    },
  },
  extraReducers: {
    [String(getStaffs.pending)]: (state, action) => {
      state.staffs.loading = true;
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getStaffs.fulfilled)]: (state, action) => {
      state.staffs.loading = false;
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.staffs = { ...state.staffs, loading: false, ...action.payload.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getStaffs.rejected)]: (state, action) => {
      state.staffs.loading = false;
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [String(getSearch.pending)]: (state, action) => {
      state.search.searchLoading = true;
      state.currentRequestId = action.meta.requestId;
      state.search.searchList = null;
    },
    [String(getSearch.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.search.searchLoading === true && state.currentRequestId === requestId) {
        state.search.searchList = action.payload.data.docs;
        state.search.searchLoading = false;
        state.currentRequestId = undefined;
      }
    },
    [String(getSearch.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.search.searchLoading === true && state.currentRequestId === requestId) {
        state.search.searchLoading = false;
        state.currentRequestId = undefined;
        state.search.searchList = null;
      }
    },

    [String(getStaffsForSelect.pending)]: (state) => {
      state.staffsSelect.loading = true;
    },
    [String(getStaffsForSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;

      const newDocs =
        next || firstLoad
          ? [
              ...state.staffsSelect?.docs,
              ...action.payload.data?.docs?.filter(
                (obj) => obj._id !== state.staffsSelect?.defaultValue
              ),
            ]
          : action.payload.data?.docs;
      state.staffsSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue: next || firstLoad ? state.staffsSelect?.defaultValue : id,
      };
    },
    [String(getStaffsForSelect.rejected)]: (state, action) => {
      state.staffsSelect.loading = false;
      state.error = action.error;
    },

    [String(getStaffsForAltSelect.pending)]: (state) => {
      state.staffsAltSelect.loading = true;
    },
    [String(getStaffsForAltSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;

      const newDocs =
        next || firstLoad
          ? [
              ...state.staffsAltSelect?.docs,
              ...action.payload.data?.docs?.filter(
                (obj) => obj._id !== state.staffsAltSelect?.defaultValue
              ),
            ]
          : action.payload.data?.docs;
      state.staffsAltSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue: next || firstLoad ? state.staffsAltSelect?.defaultValue : id,
      };
    },
    [String(getStaffsForAltSelect.rejected)]: (state, action) => {
      state.staffsAltSelect.loading = false;
      state.error = action.error;
    },

    [String(createStaff.pending)]: (state, action) => {
      state.createStaff.status = 'pending';
      pendingStateHandler(state, action);
    },
    [String(createStaff.fulfilled)]: (state, action) => {
      state.createStaff.status = 'success';
      storeStateHandler(state, action);
      state.successMsg = i18n.t('staffs.createStaff.successMsg');
    },
    [String(createStaff.rejected)]: (state, action) => {
      if (action.payload?.status === 409) state.createStaff.status = 'alreadyTaken';
      else state.createStaff.status = 'error';
      storeStateHandler(state, action);
    },

    [String(editStaff.pending)]: (state, action) => {
      state.editStaff.status = 'pending';
      pendingStateHandler(state, action);
    },
    [String(editStaff.fulfilled)]: (state, action) => {
      state.editStaff.status = 'success';
      storeStateHandler(state, action);
      const index = state.staffs.docs.findIndex((item: any) => item._id === action.meta?.arg?.id);
      if (index >= 0) state.staffs.docs[index].roles = action.meta?.arg?.roles;
      state.successMsg = i18n.t('staffs.editStaff.successMsg');
    },
    [String(editStaff.rejected)]: (state, action) => {
      state.editStaff.status = 'error';
      storeStateHandler(state, action);
    },

    [String(postDisable.pending)]: (state, action) => {
      const key = action.meta?.arg?.individualAssignation ? 'individualState' : 'bulkState';
      state.disable[key] = 'pending';
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(postDisable.fulfilled)]: (state, action) => {
      const key = action.meta?.arg?.individualAssignation ? 'individualState' : 'bulkState';
      state.disable[key] = 'success';
      state.successMsg = i18n.t('staffs.bulkDisable.success');
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.currentRequestId = undefined;
      }
    },
    [String(postDisable.rejected)]: (state, action) => {
      const key = action.meta?.arg?.individualAssignation ? 'individualState' : 'bulkState';
      state.disable[key] = 'error';
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const { clearState, clearSuccessMsg, setPayload, setPagination, setDisableLocalyToStaff } =
  staffsSlice.actions;
export default staffsSlice.reducer;
