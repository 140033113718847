import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import i18n from 'i18next';
import server from '@services/api';
import { RequestStatusEnum } from '@utils/enum';

import {
  getStoresRoute,
  getEnterprisesRoute,
  getStoreRoute,
  getStoresShopperRoute,
  createStoreIncentiveRoute,
  updateStoreIncentiveRoute,
  updateStoreIncentiveStatusRoute,
  blockStoreIncentiveRoute,
  existsRappiIdRoute,
} from './ts/routes';
import {
  GetStoresForSelectPayload,
  Data,
  Doc,
  StoresParams,
  Stores,
  Store,
  StoreDelete,
  StoresShopper,
  IncentiveRequestProperties,
  StoreIncentiveStatusRouteParams,
  BlockIncentiveRouteParams,
} from './ts/stores.interfaces';
import { EnterpriseParams } from './ts/enterprises.interfaces';

export const storesAdapter = createEntityAdapter();

export const initialState = storesAdapter.getInitialState({
  stores: { data: undefined as Data, loading: RequestStatusEnum.IDLE },
  store: { data: undefined as Doc, loading: RequestStatusEnum.IDLE },

  coordinators: { data: [], drawer: { visible: false, type: undefined } },
  components: {
    storesSelect: { docs: [], loading: false, defaultValue: undefined },
    enterprisesSelect: { docs: [], loading: false, defaultValue: undefined },
    storesShopper: { docs: [], loading: false },
  },
  loading: 'idle',
  currentRequestId: undefined,
  message: undefined,
  error: null,
  storeIncentiveState: {
    status: RequestStatusEnum.IDLE,
    type: null,
    message: null,
  },
  rappiIdExists: {
    status: RequestStatusEnum.IDLE,
    exists: false,
  },
});

export const getStoresForSelect = createAsyncThunk(
  'stores/getStoresForSelect',
  async (params: GetStoresForSelectPayload) => {
    const { enterpriseId } = params;
    const response = await server.get(getStoresRoute(), {
      params: { ...params, enterprise: enterpriseId },
    });
    return response.data;
  }
);

export const getEnterprisesForSelect = createAsyncThunk(
  'stores/getEnterprisesForSelect',
  async (params: EnterpriseParams) => {
    const response = await server.get(getEnterprisesRoute(), {
      params,
    });
    return response.data;
  }
);

export const getStores = createAsyncThunk<Data, StoresParams>(
  'stores/getStores',
  async (params, thunkApi: any) => {
    const { stores } = thunkApi.getState();
    const { loading } = stores?.stores;

    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }

    const response: Stores = await server({
      method: 'get',
      url: getStoresRoute(),
      params,
    });

    return response.data;
  }
);

export const getStore = createAsyncThunk<Data, string>(
  'stores/getStore',
  async (id, thunkApi: any) => {
    const { stores } = thunkApi.getState();
    const { loading } = stores.store;

    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }

    const response: Stores = await server({
      method: 'get',
      url: getStoreRoute(id),
    });

    return response.data;
  }
);

export const createStore = createAsyncThunk<Data, Doc>(
  'stores/createStore',
  async (data, thunkApi: any) => {
    try {
      const { stores } = thunkApi.getState();
      const { loading } = stores.store;

      if (loading !== RequestStatusEnum.PENDING) {
        return;
      }

      const response: Stores = await server({
        method: 'post',
        url: getStoresRoute(),
        data,
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response, null);
    }
  }
);

export const updateStore = createAsyncThunk<Data, Doc>(
  'stores/updateStore',
  async (data, thunkApi: any) => {
    try {
      const { stores } = thunkApi.getState();
      const { loading } = stores.store;

      if (loading !== RequestStatusEnum.PENDING) {
        return;
      }

      const response: Stores = await server({
        method: 'put',
        url: getStoreRoute(data._id),
        data,
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response, null);
    }
  }
);

export const deleteStore = createAsyncThunk<StoreDelete, string>(
  'stores/deleteStore',
  async (id, thunkApi: any) => {
    const { stores } = thunkApi.getState();
    const { loading } = stores.store;

    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }

    const response: StoreDelete = await server({
      method: 'delete',
      url: getStoreRoute(id),
    });

    return response;
  }
);

export const getStoresShopper = createAsyncThunk<Data, StoresShopper>(
  'stores/getStoresShopper',
  async (params: object, { rejectWithValue }: any) => {
    try {
      const response = await server({ method: 'get', url: getStoresShopperRoute(), params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const createStoreIncentive = createAsyncThunk(
  'stores/createStoreIncentive',
  async (params: IncentiveRequestProperties, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'post',
        url: createStoreIncentiveRoute(params.storeId),
        data: params.body,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const updateStoreIncentive = createAsyncThunk(
  'stores/updateStoreIncentive',
  async (params: IncentiveRequestProperties, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'put',
        url: updateStoreIncentiveRoute(params.storeId, params.incentiveId),
        data: params.body,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const deleteStoreIncentive = createAsyncThunk(
  'stores/deleteStoreIncentive',
  async (params: StoreIncentiveStatusRouteParams, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'delete',
        url: updateStoreIncentiveStatusRoute(params.storeId, params.incentiveId),
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const blockStoreIncentive = createAsyncThunk(
  'stores/blockStoreIncentive',
  async (params: BlockIncentiveRouteParams, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'put',
        url: blockStoreIncentiveRoute(params.storeId),
        data: params.body,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const existsRappiId = createAsyncThunk(
  'stores/existsRappiId',
  async (params: { rappiId: string; currentStoreId?: string }) => {
    const response = await server({
      method: 'get',
      url: existsRappiIdRoute(),
      params,
    });

    return response.data.data;
  }
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setCoordinatorsDrawer: (state, b: { payload: { visible: boolean; type: string } }) => {
      const { visible, type } = b.payload ?? {};
      state.coordinators.drawer = { visible, type };
    },
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload;
    },
    clearStoresSelect: (state) => {
      state.components.storesSelect = { loading: false, docs: [], defaultValue: undefined };
    },
    restoreIncentiveState: (state) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: null,
        message: null,
      };
    },
    clearStoreSelect: (state) => {
      state.store = { data: undefined as Doc, loading: RequestStatusEnum.IDLE };
    },
  },
  extraReducers: {
    [String(getStoresForSelect.pending)]: (state) => {
      state.components.storesSelect.loading = true;
    },
    [String(getStoresForSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;
      const newDocs =
        next || firstLoad
          ? [
              ...state.components.storesSelect?.docs,
              ...action.payload.data?.docs?.filter(
                (obj) => obj._id !== state.components.storesSelect?.defaultValue
              ),
            ]
          : action.payload.data?.docs;
      state.components.storesSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue: next || firstLoad ? state.components.storesSelect?.defaultValue : id,
      };
    },
    [String(getStoresForSelect.rejected)]: (state, action) => {
      state.components.storesSelect.loading = false;
      state.error = action.error;
    },

    [String(getEnterprisesForSelect.pending)]: (state) => {
      state.components.enterprisesSelect.loading = true;
    },
    [String(getEnterprisesForSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;
      // Tiene valor por defecto
      // 1 - id
      // 2 - firstLoad
      // 3 - next
      // No tiene
      // 1 - id
      // 2 - next
      const newDocs =
        next || firstLoad
          ? [
              ...state.components.enterprisesSelect?.docs,
              ...action.payload.data?.docs?.filter(
                (obj) => obj._id !== state.components.enterprisesSelect?.defaultValue
              ),
            ]
          : action.payload.data?.docs;
      state.components.enterprisesSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue: next || firstLoad ? state.components.enterprisesSelect?.defaultValue : id,
      };
    },
    [String(getEnterprisesForSelect.rejected)]: (state, action) => {
      state.components.enterprisesSelect.loading = false;
      state.error = action.error;
    },

    [String(getStores.pending)]: (state) => {
      if (state.stores.loading === RequestStatusEnum.IDLE) {
        state.stores.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(getStores.fulfilled)]: (state, action: PayloadAction<Stores>) => {
      if (state.stores.loading === RequestStatusEnum.PENDING) {
        state.stores = { loading: RequestStatusEnum.IDLE, data: action.payload.data };
      }
    },
    [String(getStores.rejected)]: (state, action) => {
      if (state.stores.loading === RequestStatusEnum.PENDING) {
        state.stores.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(getStore.pending)]: (state) => {
      if (state.store.loading === RequestStatusEnum.IDLE) {
        state.store.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(getStore.fulfilled)]: (state, action: PayloadAction<Store>) => {
      if (state.store.loading === RequestStatusEnum.PENDING) {
        state.store = { loading: RequestStatusEnum.IDLE, data: action.payload.data };
      }
    },
    [String(getStore.rejected)]: (state, action) => {
      if (state.store.loading === RequestStatusEnum.PENDING) {
        state.store.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(createStore.pending)]: (state) => {
      if (state.store.loading === RequestStatusEnum.IDLE) {
        state.store.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(createStore.fulfilled)]: (state, action: PayloadAction<Store>) => {
      if (state.store.loading === RequestStatusEnum.PENDING) {
        state.store.loading = RequestStatusEnum.IDLE;
        state.message = i18n.t('stores.stores.drawer.new.message', {
          input: action?.payload?.data?.name ?? null,
        });
      }
    },
    [String(createStore.rejected)]: (state, action) => {
      if (state.store.loading === RequestStatusEnum.PENDING) {
        state.store.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(updateStore.pending)]: (state) => {
      if (state.store.loading === RequestStatusEnum.IDLE) {
        state.store.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(updateStore.fulfilled)]: (state, action: PayloadAction<Store>) => {
      if (state.store.loading === RequestStatusEnum.PENDING) {
        state.store = { loading: RequestStatusEnum.IDLE, data: action.payload.data };
        state.message = i18n.t('stores.stores.drawer.edit.message', {
          input: action?.payload?.data?.name ?? null,
        });
      }
    },
    [String(updateStore.rejected)]: (state, action) => {
      if (state.store.loading === RequestStatusEnum.PENDING) {
        state.store.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(deleteStore.pending)]: (state) => {
      if (state.store.loading === RequestStatusEnum.IDLE) {
        state.store.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(deleteStore.fulfilled)]: (state) => {
      if (state.store.loading === RequestStatusEnum.PENDING) {
        state.store.loading = RequestStatusEnum.IDLE;
        state.message = i18n.t('stores.stores.drawer.delete.message', {
          input: state?.store?.data?.name ?? null,
        });
      }
    },
    [String(deleteStore.rejected)]: (state, action) => {
      if (state.store.loading === RequestStatusEnum.PENDING) {
        state.store.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(getStoresShopper.pending)]: (state) => {
      state.components.storesShopper.loading = true;
    },
    [String(getStoresShopper.fulfilled)]: (state, action) => {
      const filtered = [...(action.payload?.data || [])].filter(
        (store) => store._id !== action?.meta?.arg?.exclude
      );

      state.components.storesShopper.docs = filtered;
      state.components.storesShopper.loading = false;
    },
    [String(getStoresShopper.rejected)]: (state, action) => {
      state.components.storesShopper.loading = false;
      state.error = action.error;
    },

    [String(createStoreIncentive.pending)]: (state) => {
      state.storeIncentiveState = { status: RequestStatusEnum.PENDING, type: null, message: null };
    },
    [String(createStoreIncentive.fulfilled)]: (state, action) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: 'success',
        message: i18n.t('stores.stores.incentive.alert.create_success', {
          name: action?.meta?.arg?.name,
        }),
      };
    },
    [String(createStoreIncentive.rejected)]: (state) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: 'error',
        message: i18n.t('stores.stores.incentive.alert.create_error'),
      };
    },

    [String(updateStoreIncentive.pending)]: (state) => {
      state.storeIncentiveState = { status: RequestStatusEnum.PENDING, type: null, message: null };
    },
    [String(updateStoreIncentive.fulfilled)]: (state, action) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: 'success',
        message: i18n.t('stores.stores.incentive.alert.update_success', {
          name: action?.meta?.arg?.name,
        }),
      };
    },
    [String(updateStoreIncentive.rejected)]: (state) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: 'error',
        message: i18n.t('stores.stores.incentive.alert.update_error'),
      };
    },

    [String(deleteStoreIncentive.pending)]: (state) => {
      state.storeIncentiveState = { status: RequestStatusEnum.PENDING, type: null, message: null };
    },
    [String(deleteStoreIncentive.fulfilled)]: (state, action) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: 'success',
        message: i18n.t('stores.stores.incentive.alert.delete_success', {
          name: action?.meta?.arg?.name,
        }),
      };
    },
    [String(deleteStoreIncentive.rejected)]: (state) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: 'error',
        message: i18n.t('stores.stores.incentive.alert.delete_error'),
      };
    },

    [String(blockStoreIncentive.pending)]: (state) => {
      state.storeIncentiveState = { status: RequestStatusEnum.PENDING, type: null, message: null };
    },
    [String(blockStoreIncentive.fulfilled)]: (state, action) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: 'success',
        message: i18n.t(
          `stores.stores.incentive.alert.${
            action?.meta?.arg?.body?.isActive ? 'unblock' : 'block'
          }_success`,
          { name: action?.meta?.arg?.name }
        ),
      };
    },
    [String(blockStoreIncentive.rejected)]: (state, action) => {
      state.storeIncentiveState = {
        status: RequestStatusEnum.IDLE,
        type: 'error',
        message: i18n.t(
          `stores.stores.incentive.alert.${
            action?.meta?.arg?.body?.isActive ? 'unblock' : 'block'
          }_error`,
          { name: action?.meta?.arg?.name }
        ),
      };
    },

    [String(existsRappiId.pending)]: (state) => {
      if (state.rappiIdExists.status === RequestStatusEnum.IDLE) {
        state.rappiIdExists.status = RequestStatusEnum.PENDING;
      }
    },
    [String(existsRappiId.fulfilled)]: (state, action: PayloadAction<boolean>) => {
      if (state.rappiIdExists.status === RequestStatusEnum.PENDING) {
        state.rappiIdExists = { status: RequestStatusEnum.IDLE, exists: action.payload };
      }
    },
    [String(existsRappiId.rejected)]: (state, action) => {
      if (state.rappiIdExists.status === RequestStatusEnum.PENDING) {
        state.rappiIdExists.status = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
  },
});

export const {
  setCoordinatorsDrawer,
  setMessage,
  clearStoresSelect,
  restoreIncentiveState,
  clearStoreSelect,
} = tasksSlice.actions;
export default tasksSlice.reducer;
