import { OperationalValueParams } from '../../../components/templates/features/dashboard/OperationalDataTab/OperationalDataTab';

export const actionGetNpsIndicatorsRoute = (): string => '/account/jobbers/nps-dashboard';
export const getQualificationsTaksRoute = (): string =>
  '/assignment/applications/qualification-dashboard';
export const getRankingTasksRoute = (): string => '/assignment/jobs/qualification-ranking';
export const getOnboardingDataRoute = (): string => '/account/jobbers/count';
export const getOperationalDataRoute = (): string =>
  `/assignment/jobs/enterprise-operation-dashboard`;

export const getOperationalDataHourlyRoute = ({
  startAtFrom,
  startAtTo,
  enterprise,
}: OperationalValueParams): string => {
  let url = `/assignment/jobs/hourly-operation-dashboard?startAtFrom=${startAtFrom}&startAtTo=${startAtTo}`;
  if (enterprise !== '') {
    url += `&enterprise=${enterprise}`;
  }
  return url;
};
