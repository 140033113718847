export default {
  stores: {
    coordinator: {
      title: 'Coordinadores sedes',
      subtitle: 'Revisa el listado de coordinadores de sedes',
      kpi: ['Coordinadores', 'Coordinadores nuevos'],
      actions: { seeProfile: 'Ver perfil' },
      buttonsActions: {
        info: 'Ver información',
        create: 'Crear coordinador',
        delete: 'Eliminar coordinador',
      },
      filters: {
        search: { label: 'Buscar', placeholder: 'Busca por coordinador o email' },
        vertical: { label: 'Vertical', placeholder: 'Selecciona' },
        company: { label: 'Cliente comercial', placeholder: 'Selecciona' },
        date: 'Fecha de creación',
        clean: 'Limpiar filtros',
      },
      table: {
        columns: ['Coordinador', 'Cliente comercial', 'Teléfono', 'Fecha creación', 'Acciones'],
      },
      deleteModal: {
        title: 'Eliminar coordinador de sede',
        text: 'Al eliminar al coordinador {{input}}, no podrás recuperar esta información.',
        okText: 'Eliminar coordinador ',
      },
      cancelModal: {
        new: {
          title: 'Descartar creación de coordinador',
          text: 'Al salir, se perderán todos los datos ingresados.',
          okText: 'Descartar coordinador',
          cancelText: 'Cancelar',
        },
        edit: {
          title: 'Cambios sin guardar',
          text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          okText: 'Salir sin guardar',
          cancelText: 'Guardar cambios',
        },
      },
      alerts: {
        newSuccess: 'Coordinador <b>{{name}}</b> se ha creado exitosamente.',
        editSuccess: 'Los cambios se han guardado con éxito.',
        deleteSuccess: 'El coordinador se ha eliminado con éxito',
      },
      errors: {
        409: 'El RUT ingresado pertenece a un coordinador creado anteriormente',
      },
      newDrawer: {
        new: {
          title: 'Creación coordinador sede',
          subtitle: 'Ingresa los datos obligatorios (*) para crear nuevo coordinador de sede',
          submit: 'Crear coordinador',
        },
        edit: {
          title: 'Edición coordinador sede',
          subtitle: 'Realiza los cambios necesarios del coordinador',
          submit: 'Guardar cambios',
        },
        formTitles: ['Cliente', 'Datos coordinador'],
        inputs: {
          company: {
            label: 'Empresa *',
            required: 'Selecciona una empresa',
          },
          comercialClient: {
            label: 'Cliente comercial *',
            required: 'Selecciona un cliente',
          },
          names: {
            label: 'Nombres *',
            required: 'Ingresa los nombres',
            patterError: 'Ingresa los nombres',
          },
          surnames: {
            label: 'Apellidos *',
            required: 'Ingresa los apellidos',
            patterError: 'Ingresa los apellidos',
          },
          email: {
            label: 'Email *',
            required: 'Ingresa un email',
            patternError: 'Ingresa un email válido',
          },
          phoneNumber: {
            label: 'Teléfono *',
            required: 'Ingresa un teléfono',
          },
          profilePicture: {
            button: 'Cargar fotografía',
          },
          store: {
            title: 'Sedes asociadas al coordinador',
            label: 'Sedes',
            placeholder: 'Selecciona una o más sedes',
            selected: 'Sedes seleccionadas',
          },
          details: {
            title: 'Detalles del trabajo',
            label: 'Tipo',
            options: {
              presential: 'Presencial',
              remote: 'Remoto',
            },
          },
          workday: {
            title: 'Jornada',
            options: {
              full_time: 'Full time',
              part_time: 'Part time',
            },
          },
          money: {
            title: 'Sueldo coordinador',
            payment: {
              label: 'Pago coordinador',
            },
            entry: {
              label: 'Cobro cliente',
            },
            helper: 'Ingresa un valor entre {{range}}',
          },
        },
      },
      profile: [
        { title: 'Cliente', subtitle: 'Cliente comercial' },
        { title: 'Datos coordinador', subtitle: ['RUT', 'Teléfono'] },
        { title: 'Sedes asociadas al coordinador' },
        { title: 'Detalles del trabajo', subtitle: ['Tipo de trabajo', 'Jornada'] },
        { title: 'Sueldo coordinador', subtitle: ['Pago coordinador (mensual)', 'Cobro cliente'] },
      ],
    },
    stores: {
      messages: ['Aquí podrás ver, editar y crear las sedes asociados a este cliente.'],
      actions: [
        'Crear sede',
        'Editar sede',
        'Eliminar sede',
        'Agregar incentivo',
        'Bloquear incentivos',
        'Activar incentivos',
      ],
      filters: [{ label: 'Buscar', placeholder: 'Sede' }],
      cleanFilters: 'Limpiar filtros',
      columns: ['Sede', 'Ubicación', 'Incentivos', 'Acciones'],
      columnsContent: {
        incentive: {
          secure: 'Pago asegurado',
          blocked: 'Incentivos bloqueados',
          without: 'Sin incentivos',
          tooltip: {
            title: 'Días de validez',
            jobberPayment: 'Pago Jobber',
            clientInvoice: 'Cobro cliente',
            alldays: 'Asegurado aplica todos los días',
          },
        },
      },
      incentive: {
        alert: {
          create_success: 'El incentivo <b>{{name}}</b> ha sido creado con éxito.',
          create_error: 'No se logró crear el incentivo.',
          update_success: 'El incentivo <b>{{name}}</b> ha sido actualizado con éxito.',
          update_error: 'No se logró actualizar el incentivo.',
          delete_success: 'El incentivo <b>{{name}}</b> ha sido eliminado con éxito.',
          delete_error: 'No se logró eliminar el incentivo.',
          block_success: 'Los incentivos de la sede <b>{{name}}</b> han sido bloqueados con éxito.',
          block_error: 'No se logró bloquear los incentivos.',
          unblock_success:
            'Los incentivos de la sede <b>{{name}}</b> han sido activados con éxito.',
          unblock_error: 'No se logró activar los incentivos.',
        },
        delete: {
          title: 'Eliminar incentivo sede',
          description:
            'Al confirmar la eliminación del incentivo de pago asegurado <b>”{{name}}”</b>, este ya no podrá ser utilizado.',
          cancel: 'Cancelar',
          ok: 'Eliminar incentivo',
        },
        detail: {
          title: 'Pago asegurado',
          title_enterprise: 'Pago asegurado general',
          enterprise: 'SMU',
          store: 'Sede',
          region: 'Región',
          location: 'Ubicación',
          commune: 'Comuna',
          name: 'Nombre incentivo',
          days: 'Días de validez',
          days_validate: 'Asegurado aplica todos los días',
          payment: 'Pago Jobber',
          invoice: 'Cobro cliente',
          history: {
            title: 'Historial',
            subtitle: 'Revisa el historial de creación y edición del incentivo',
            current: 'Actual: ',
            last: 'Anterior: ',
            types: {
              add: 'Creación de incentivo',
              edit: 'Edición de <b>”{{key}}”</b>',
              block: 'Bloqueo de incentivo',
              unblock: 'Incentivo desbloqueado',
            },
            creator: {
              add: 'Creado por {{name}}',
              edit: 'Editado por {{name}}',
              block: 'Bloqueado por {{name}}',
              unblock: 'Desbloqueado por {{name}}',
            },
            changes: {
              clientPayment: 'Cobro cliente',
              jobberPayment: 'Pago Jobber',
              name: 'Nombre incentivo',
              dates: 'Días  de validez',
            },
          },
        },
        block: {
          title: 'Bloquear incentivos de sede',
          description:
            'Al confirmar, los incentivos ya creados y futuros, no podrán ser aplicados en tareas.',
          detail: 'Puedes volver a activar los incentivos cuando quieras.',
          cancel: 'Cancelar',
          ok: 'Bloquear incentivos',
        },
        unblock: {
          title: 'Activar incentivos de sede',
          description:
            'Al confirmar, los incentivos ya creados y futuros, podrán ser aplicados en tareas.',
          detail: 'Puedes volver a bloquearlos cuando quieras.',
          cancel: 'Cancelar',
          ok: 'Activar incentivos',
        },
      },
      references: {
        title: 'Referencia lugar de ingreso',
        empty: ['No hay imagen de referencia'],
        actions: ['Entendido'],
      },
      drawer: {
        new: {
          title: 'Creación de sede cliente comercial',
          subtitle: 'Ingresa los datos obligatorios (*) para crear la o las sedes',
          message: 'Se ha creado la sede {{input}} exitosamente',
          actions: 'Crear sede',
          cancellationModal: {
            title: 'Descartar creación de sede',
            text: 'Al salir, se perderán todos los cambios realizados.',
            actions: ['Cancelar', 'Descartar creación'],
          },
        },
        edit: {
          title: 'Edición sede',
          subtitle: 'Realiza los cambios necesarios en la sede',
          message: 'Se ha modificado la sede {{input}} exitosamente',
          actions: 'Guardar cambios',
          file: {
            status: {
              loading: 'Cargando...',
            },
            defaultName: 'Imagen',
          },
          cancellationModal: {
            title: 'Edición de sede',
            text: 'Los cambios se verán reflejados en las órdenes de trabajo futuras. No afectará a las pasadas ya creadas o en curso.',
            actions: ['Cancelar', 'Salir sin guardar'],
          },
          codes: {
            shopper: {
              ok: 'Código shopper',
              err: 'No tiene código de shopper asociado.',
            },
            carried: {
              ok: 'Códigos de operador',
              err: 'No tiene códigos de operador asociados.',
            },
          },
        },
        delete: {
          title: 'Eliminar sede',
          message: 'Se ha elminado la sede {{input}} exitosamente',
          actions: ['Eliminar sede', 'Cancelar'],
          description:
            'Al eliminar la sede {{input}}, no estará disponible para crear nuevas órdenes de trabajo. No afectarán a las pasadas ya creadas o en curso.',
        },
        errors: {
          409: 'El nombre ingresado ya existe. Intenta con uno distinto',
        },
        form: {
          actions: ['Agregar otra sede'],
          messages: [
            'Agrega sólo el nombre de la sede, sin el nombre del cliente. Por ejemplo “Portugal” y NO “Lider Portugal”. Ya que podría afectar la visualización del nombre de la tarea en la App. ',
            'No hemos encontrado la dirección ingresada. Marca la dirección en el mapa.',
          ],
          sections: ['Sede', 'Ubicación'],
          mapHelper: 'Desplazate en el mapa y marca el punto donde se encuentra la sede.',
          inputs: [
            {
              label: 'Nombre sede',
              required: 'Ingrese un nombre para la sede',
              pattern: 'El nombre ingresado no es correcto',
              min: 'El nombre de la sede debe tener un mínimo de {{input}} caracteres',
            },
            {
              label: 'Dirección',
              required: 'Debe ingresar una dirección',
            },
            {
              label: 'Referencias lugar de ingreso',
              required: 'Debe ingresar una referencia',
              min: 'La referencia debe tener un mínimo de {{input}} caracteres',
              max: 'La referencia debe tener un máximo de {{input}} caracteres',
              placeholder:
                'Ingresa indicaciones para que el Jobber puede identificar rapidamente el lugar',
            },
            {
              label: 'Fotografía lugar de ingreso (opcional)',
              actions: ['Cargar fotografía'],
            },
            {
              label: 'Radio de asistencia *',
              required: 'Debe seleccionar un radio de asistencia',
              help: 'Distancia cercana a la sede, desde la que el Jobber podrá marcar asistencia.',
            },
            {
              label: 'Número de calle',
              required: 'Debe ingresar el número de calle',
            },
            {
              label: 'Días que se podrán realizar Cursos presenciales',
              selection: {
                selectAll: 'Seleccionar todos',
                unselectAll: 'Borrar selección',
              },
              weekDay: {
                monday: 'Lunes',
                tuesday: 'Martes',
                wednesday: 'Miércoles',
                thursday: 'Jueves',
                friday: 'Viernes',
                saturday: 'Sábado',
                sunday: 'Domingo',
              },
            },
            {
              label: 'Rappi Id',
              alreadyExists: 'El Rappi Id ingresado ya está asociado a otra sede',
            }
          ],
        },
      },
    },
  },
};
