import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import server from '@services/api';
import { RequestStatusEnum } from '@utils/enum';

import { getFineAndDiscountRoute, getFinesAndDiscountsRoute } from './ts/routes';
import {
  FinesAndDiscounts,
  FinesAndDiscountsParams,
  Data,
  Doc,
  FineAndDiscountDelete,
  FineAndDiscount,
} from './ts/finesAndDiscounts.interfaces';

export const finesAndDiscountsAdapter = createEntityAdapter();

export const initialState = finesAndDiscountsAdapter.getInitialState({
  finesAndDiscounts: { data: undefined as Data, loading: RequestStatusEnum.IDLE },
  fineAndDiscount: { data: undefined as Doc, loading: RequestStatusEnum.IDLE },
  error: null,
  message: null,
  loading: 'idle',
});

export const getFinesAndDiscounts = createAsyncThunk<Data, FinesAndDiscountsParams>(
  'finesAndDiscounts/getFinesAndDiscounts',
  async (params, thunkApi: any) => {
    const { finesAndDiscounts } = thunkApi.getState();
    const { loading } = finesAndDiscounts.finesAndDiscounts;

    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }

    const response: FinesAndDiscounts = await server({
      method: 'get',
      url: getFinesAndDiscountsRoute(),
      params,
    });

    return response.data;
  }
);

export const deleteFineAndDiscount = createAsyncThunk<FineAndDiscountDelete, string>(
  'finesAndDiscounts/deleteFineAndDiscount',
  async (id, thunkApi: any) => {
    const { finesAndDiscounts } = thunkApi.getState();
    const { loading } = finesAndDiscounts.fineAndDiscount;

    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }

    const response: FineAndDiscountDelete = await server({
      method: 'delete',
      url: getFineAndDiscountRoute(id),
    });

    return response;
  }
);

export const postFineAndDiscount = createAsyncThunk(
  'finesAndDiscounts/postFineAndDiscount',
  async (data: any, thunkApi: any) => {
    const { finesAndDiscounts } = thunkApi.getState();
    const { loading } = finesAndDiscounts.fineAndDiscount;

    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }

    const response: FineAndDiscount = await server({
      method: 'post',
      url: getFinesAndDiscountsRoute(),
      data,
    });

    return response;
  }
);

export const putFineAndDiscount = createAsyncThunk(
  'finesAndDiscounts/updateFineAndDiscount',
  async (data: any, thunkApi: any) => {
    const { finesAndDiscounts } = thunkApi.getState();
    const { loading } = finesAndDiscounts.fineAndDiscount;

    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }

    const response: any = await server({
      method: 'put',
      url: getFineAndDiscountRoute(data._id),
      data,
    });

    return response;
  }
);

const finesAndDiscountsSlice = createSlice({
  name: 'finesAndDiscounts',
  initialState,
  reducers: {
    getEditFineAndDiscountData: (state, action) => {
      state.fineAndDiscount = { loading: RequestStatusEnum.IDLE, data: action.payload };
    },
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload;
    },
  },
  extraReducers: {
    [String(getFinesAndDiscounts.pending)]: (state) => {
      if (state.finesAndDiscounts.loading === RequestStatusEnum.IDLE) {
        state.finesAndDiscounts.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(getFinesAndDiscounts.fulfilled)]: (state, action: PayloadAction<FinesAndDiscounts>) => {
      if (state.finesAndDiscounts.loading === RequestStatusEnum.PENDING) {
        state.finesAndDiscounts = { loading: RequestStatusEnum.IDLE, data: action.payload.data };
      }
    },
    [String(getFinesAndDiscounts.rejected)]: (state, action) => {
      if (state.finesAndDiscounts.loading === RequestStatusEnum.PENDING) {
        state.finesAndDiscounts.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
    [String(deleteFineAndDiscount.pending)]: (state) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.IDLE) {
        state.fineAndDiscount.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(deleteFineAndDiscount.fulfilled)]: (state) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.PENDING) {
        state.fineAndDiscount.loading = RequestStatusEnum.IDLE;
      }
    },
    [String(deleteFineAndDiscount.rejected)]: (state, action) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.PENDING) {
        state.fineAndDiscount.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
    [String(postFineAndDiscount.pending)]: (state) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.IDLE) {
        state.fineAndDiscount.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(postFineAndDiscount.fulfilled)]: (state) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.PENDING) {
        state.fineAndDiscount.loading = RequestStatusEnum.IDLE;
      }
    },
    [String(postFineAndDiscount.rejected)]: (state, action) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.PENDING) {
        state.fineAndDiscount.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
    [String(putFineAndDiscount.pending)]: (state) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.IDLE) {
        state.fineAndDiscount.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(putFineAndDiscount.fulfilled)]: (state) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.PENDING) {
        state.fineAndDiscount.loading = RequestStatusEnum.IDLE;
      }
    },
    [String(putFineAndDiscount.rejected)]: (state, action) => {
      if (state.fineAndDiscount.loading === RequestStatusEnum.PENDING) {
        state.fineAndDiscount.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
  },
});

export const { getEditFineAndDiscountData, setMessage } = finesAndDiscountsSlice.actions;

export default finesAndDiscountsSlice.reducer;
