export default {
  localities: {
    activeLocalities: {
      emptyDisabled:
        'Actualmente tu $t(global.region, lowercase) está DESACTIVADO. Debes activarlo para poder activar la o las $t(global.commune_plural, lowercase) que necesites',
    },
    initial:
      'Selecciona un $t(global.region, lowercase) para ver sus $t(global.commune_plural, lowercase) activas y agregar otras',
    disabledLocalities: {
      subtitle:
        'Listado de $t(global.commune_plural, lowercase) desactivadas del $t(global.region, lowercase)',
    },
    upperLevel: {
      active: {
        true: {
          successMsg: '$t(global.region) desactivado exitosamente',
        },
        false: {
          successMsg: '$t(global.region) activado exitosamente',
        },
      },
    },
    toggleActiveSecondLevel: {
      activatedMsg:
        'Has agregado {{count}} nueva $t(global.commune, lowercase) activa al $t(global.region)',
      activatedMsg_plural:
        'Has agregado {{count}} nuevas $t(global.commune_plural, lowercase) activas al $t(global.region)',
      deactivatedMsg:
        'Has desactivado {{count}} $t(global.commune, lowercase) del $t(global.region)',
      deactivatedMsg_plural:
        'Has desactivado {{count}} $t(global.commune_plural, lowercase) del $t(global.region)',
      deactivatedAllMsg:
        'Has desactivado todas las $t(global.commune_plural, lowercase) del $t(global.region)',
    },
    enableSecondLevel: {
      select: {
        all: 'Activar todas $t($t(global.communeWArticle, {"count": 0 }), lowercase) del $t($t(global.region), lowercase)',
      },
    },
  },
};
