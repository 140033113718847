export const fetchShopperOrdersRoute = (): string => '/delivery/shopper-orders';
export const updateStatusShopperOrderRoute = (status): string =>
  `/delivery/shopper-orders/status/${status}`;
export const closeShopperOrderRoute = (_id: string): string =>
  `delivery/shopper-orders/${_id}/close`;
export const fixFaliedCreationRoute = (_id: string): string =>
  `/delivery/shopper-orders/${_id}/fix-failed-created`;
export const updateShopOrderKilometerRoute = (_id: string): string =>
  `/delivery/shopper-orders/${_id}/validate-kms`;
export const createSMUShopOrderRoute = (): string => `/delivery/shopper-orders`;
export const restoreShopOrderRoute = (): string => `/delivery/shopper-orders/re-publish`;
export const changeStoreShopOrderRoute = (id: string): string =>
  `/delivery/shopper-orders/${id}/store`;
export const getDeliveryVehiclesRoute = () => '/delivery/vehicles';
export const getBeetrackOrderRoute = () => `/delivery/walmart/order`;
export const assignPickerToShopOrderRoute = (): string => '/delivery/shopper-orders/picker';
export const closePickerOrderRoute = (id: string): string =>
  `/delivery/shopper-orders/${id}/close-picker`;
export const calculateKmsRoute = (id: string): string =>
  `/delivery/shopper-orders/${id}/calculate-kms`;
export const overweightCloneShopOrderRoute = (): string => `/delivery/shopper-orders/overweight`;

export const updatePayCompleteOrderRoute = (shopperOrderId: string): string =>
  `/delivery/shopper-orders/${shopperOrderId}/update-pay-complete-order`;
