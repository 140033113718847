import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import server from '@services/api';
import i18n from 'i18next';

import {
  EnterpriseParams,
  Enterprise,
  Doc,
  FindEnterprise,
  EnterpriseIncentiveParams,
} from './ts/enterprises.interfaces';
import {
  fetchEnterprisesRoute,
  fetchEnterpriseRoute,
  updateEnterpriseIncentiveRoute,
} from './ts/routes';
import { RequestStatusEnum } from '@utils/enum';

export const enterprisesAdapter = createEntityAdapter();

export const initialState = enterprisesAdapter.getInitialState({
  enterprises: { docs: [] as [] | Doc, loading: RequestStatusEnum.IDLE },
  enterprise: {
    data: undefined as Doc,
    requestStatus: false,
    loading: RequestStatusEnum.IDLE,
    message: undefined,
  },
  jobTask: { data: [] as [], loading: RequestStatusEnum.IDLE },
  loading: RequestStatusEnum.IDLE,
  message: undefined,
  error: null,
  valor: 0,
  currentRequestId: undefined,
  incentiveState: {
    status: RequestStatusEnum.IDLE,
    type: null,
    message: null,
  },
});

export const getEnterprises = createAsyncThunk(
  'enterprises/getEnterprises',
  async (params: EnterpriseParams, { getState }: any) => {
    const { enterprises } = getState();
    const { loading } = enterprises?.enterprises;
    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: Enterprise = await server({
      method: 'get',
      url: fetchEnterprisesRoute(),
      params,
    });
    return response?.data;
  }
);

export const getEnterprise = createAsyncThunk(
  'enterprises/getEnterprise',
  async (id: string, { getState }: any) => {
    const { enterprises } = getState();
    const { loading } = enterprises?.enterprise;
    if (loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: FindEnterprise = await server({
      method: 'get',
      url: fetchEnterpriseRoute(id),
    });
    return response?.data;
  }
);

export const createEnterprise = createAsyncThunk(
  'enterprises/createEnterprise',
  async (data: any, thunkApi: any) => {
    try {
      const { enterprises } = thunkApi.getState();
      const { loading } = enterprises;

      if (loading !== RequestStatusEnum.PENDING) {
        return;
      }

      const response: any = await server({
        method: 'post',
        url: fetchEnterprisesRoute(),
        data,
      });

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response, null);
    }
  }
);

export const updateEnterprise = createAsyncThunk(
  'enterprises/updateEnterprise',
  async (data: any, thunkApi: any) => {
    try {
      const response: FindEnterprise = await server({
        method: 'put',
        url: fetchEnterpriseRoute(data.id),
        data,
      });

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response, null);
    }
  }
);

export const deleteEnterprise = createAsyncThunk(
  'enterprises/deleteEnterprise',
  async (_ = null, { getState, rejectWithValue }: any) => {
    try {
      const { enterprises } = getState();
      const { loading, data } = enterprises?.enterprise;
      if (loading !== RequestStatusEnum.PENDING) {
        return;
      }

      const response: FindEnterprise = await server({
        method: 'delete',
        url: fetchEnterpriseRoute(data._id),
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateEnterpriseIncentive = createAsyncThunk(
  'enterprises/updateEnterpriseIncentive',
  async (params: EnterpriseIncentiveParams, { rejectWithValue }: any) => {
    try {
      const response: FindEnterprise = await server({
        method: 'put',
        url: updateEnterpriseIncentiveRoute(params.enterpriseId),
        data: params.body,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const enterpriseSlice = createSlice({
  name: 'enterprises',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload;
      state.enterprise.loading = RequestStatusEnum.IDLE;
      state.enterprise.message = action.payload;
    },
    resetIncentiveAlert: (state) => {
      state.incentiveState = { status: RequestStatusEnum.IDLE, type: null, message: null };
    },
    clearEnterpriseSelect: (state) => {
      state.enterprise = {
        data: undefined as Doc,
        requestStatus: false,
        loading: RequestStatusEnum.IDLE,
        message: undefined,
      };
    },
  },
  extraReducers: {
    [String(getEnterprises.pending)]: (state) => {
      if (state.enterprises.loading === RequestStatusEnum.IDLE) {
        state.enterprises.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(getEnterprises.fulfilled)]: (state, action) => {
      if (state.enterprises.loading === RequestStatusEnum.PENDING) {
        state.enterprises = { loading: RequestStatusEnum.IDLE, ...action.payload.data };
      }
    },
    [String(getEnterprises.rejected)]: (state, action) => {
      if (state.enterprises.loading === RequestStatusEnum.PENDING) {
        state.enterprises.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(getEnterprise.pending)]: (state) => {
      if (state.enterprise.loading === RequestStatusEnum.IDLE) {
        state.enterprise.loading = RequestStatusEnum.PENDING;
        state.enterprise.requestStatus = true;
      }
    },
    [String(getEnterprise.fulfilled)]: (state, action) => {
      if (state.enterprise.loading === RequestStatusEnum.PENDING) {
        state.enterprise = {
          requestStatus: false,
          loading: RequestStatusEnum.IDLE,
          message: undefined,
          ...action.payload,
        };
      }
    },
    [String(getEnterprise.rejected)]: (state, action) => {
      state.enterprise.requestStatus = false;
      if (state.enterprise.loading === RequestStatusEnum.PENDING) {
        state.enterprise.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(createEnterprise.pending)]: (state) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(createEnterprise.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.message = i18n.t('enterprises.drawer.new.message');
      }
    },
    [String(createEnterprise.rejected)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(updateEnterprise.pending)]: (state) => {
      if (state.enterprise.loading === RequestStatusEnum.IDLE) {
        state.enterprise.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(updateEnterprise.fulfilled)]: (state, action) => {
      if (state.enterprise.loading === RequestStatusEnum.PENDING) {
        state.enterprise.loading = RequestStatusEnum.SUCCESS;
        state.enterprise.message = i18n.t('enterprises.drawer.edit.message');
        state.enterprise.data = action.payload.data.data;
      }
    },
    [String(updateEnterprise.rejected)]: (state, action) => {
      if (state.enterprise.loading === RequestStatusEnum.PENDING) {
        state.enterprise.loading = RequestStatusEnum.ERROR;
        state.error = action.error;
      }
    },

    [String(deleteEnterprise.pending)]: (state) => {
      if (state.enterprise.loading === RequestStatusEnum.IDLE) {
        state.enterprise.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(deleteEnterprise.fulfilled)]: (state) => {
      if (state.enterprise.loading === RequestStatusEnum.PENDING) {
        state.enterprise.loading = RequestStatusEnum.SUCCESS;
        state.message = i18n.t('enterprises.drawer.delete.message');
        state.enterprise.data = undefined;
      }
    },
    [String(deleteEnterprise.rejected)]: (state, action) => {
      if (state.enterprise.loading === RequestStatusEnum.PENDING) {
        state.enterprise.loading = RequestStatusEnum.ERROR;
        state.error = action.error;
        const status = action.payload.status;
        if (status === 412) state.enterprise.message = i18n.t('enterprises.drawer.delete.error');
      }
    },

    [String(updateEnterpriseIncentive.pending)]: (state) => {
      state.incentiveState = { status: RequestStatusEnum.PENDING, type: null, message: null };
    },
    [String(updateEnterpriseIncentive.fulfilled)]: (state, action) => {
      state.incentiveState = {
        status: RequestStatusEnum.SUCCESS,
        type: 'success',
        message: i18n.t(
          `enterprises.enterprisesProfile.tabs.information.incentive.form.alerts.${
            action?.meta?.arg?.isEdit ? 'update' : 'success'
          }`
        ),
      };
    },
    [String(updateEnterpriseIncentive.rejected)]: (state) => {
      state.incentiveState = {
        status: RequestStatusEnum.ERROR,
        type: 'error',
        message: i18n.t(
          'enterprises.enterprisesProfile.tabs.information.incentive.form.alerts.error'
        ),
      };
    },
  },
});

export const { setMessage, resetIncentiveAlert, clearEnterpriseSelect } = enterpriseSlice.actions;

export default enterpriseSlice.reducer;
