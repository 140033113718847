import { title } from 'process';

export default {
  tasks: {
    jobTypes: {
      title: 'Tipo de tareas',
      subtitle: 'Revisa el listado de tareas creadas',
      kpi: ['Tareas', 'Verticales', 'Tareas nuevas'],
      buttonsActions: {
        create: 'Crear tipo de tarea',
        delete: 'Eliminar tipo de tarea',
      },
      filters: {
        search: { label: 'Buscar', placeholder: 'Buscar por tarea' },
        vertical: { label: 'Vertical', placeholder: 'Selecciona' },
        date: 'Fecha de creación',
        clean: 'Limpiar filtros',
      },
      table: { columns: ['Tarea/Vertical', 'Descripción', 'Fecha creación', 'Acciones'] },
      deleteModal: {
        title: 'Eliminar tipo de tarea',
        text: 'Al eliminar el tipo de tarea {{input}}, no podrás recuperar esta información.',
        okText: '$t(tasks.jobTypes.deleteModal.title)',
      },
      cancelModal: {
        new: {
          title: 'Descartar creación tipo de tarea',
          text: 'Al salir, se perderán todos los datos ingresados.',
          okText: 'Descartar tipo de tarea',
          cancelText: 'Cancelar',
        },
        edit: {
          title: 'Cambios sin guardar',
          text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          okText: 'Salir sin guardar',
          cancelText: 'Guardar cambios',
        },
      },
      alerts: {
        newSuccess:
          'El tipo de tarea se ha creado exitosamente. Puedes revisarlo en la tabla y editar posteriormente.',
        editSuccess: 'Los cambios se han guardado con éxito.',
        deleteSuccess: 'El tipo de tarea se ha eliminado con éxito',
      },
      newDrawer: {
        new: {
          title: 'Creación tipo de tarea',
          subtitle: 'Ingresa los datos obligatorios (*) para crear nuevo tipo de tarea',
          submit: 'Crear tipo de tarea',
        },
        edit: {
          title: 'Edición tipo de tarea',
          subtitle: 'Realiza los cambios necesarios en la tarea',
          submit: 'Guardar cambios',
        },
        formTitle: 'Información',
        inputs: {
          vertical: {
            label: 'Vertical *',
            required: 'Selecciona una vertical',
          },
          name: {
            label: 'Nombre de tarea *',
            required: 'Ingresa un nombre a la tarea',
            patterError: 'Ingresa un nombre válido',
            min: 'Debe ingresar un mínimo de {{input}} caracteres',
          },
          description: {
            label: 'Definición *',
            placeholder: 'Describe la o las funciones que tendrá este tipo de tarea',
            required: 'Ingresa una definición para el tipo de tarea',
            min: 'Debe ingresar un mínimo de {{input}} caracteres',
            max: 'Límite de caracteres alcanzado',
          },
        },
      },
      profile: {
        title: 'Detalle tipo de tarea',
        sections: ['Fecha creación', 'Vertical', 'Descripción'],
      },
    },
    jobs: {
      dashboard: {
        title: 'Tareas',
        subtitle: 'Revisa el listado de tareas programadas y plantillas de orden de trabajo',
        actionButtons: {
          title: 'Programar orden de trabajo',
          normal: 'Nueva',
          template: 'En base a plantilla',
          bulk: 'Creación masiva',
          edit: 'Edición masiva',
        },
        bulkAssignButton: 'Asignación masiva con plantilla',
        bulkAssignModal: {
          title: 'Asignación masiva con plantilla',
          text: 'Asigna masivamente a los Jobber ingresando el Id_tarea y Id_jobber.',
          format: {
            title: 'Plantilla de carga masiva',
            text: 'Peso máximo de 10 mb - Formato: csv',
            download: 'Descargar plantilla base',
            upload: 'Cargar plantilla',
          },
          downloadLink: {
            develop: `https://tj-public-assets-dev.s3.amazonaws.com/assignments.csv?${new Date().getTime()}`,
            staging: `https://tj-public-assets-stg.s3.amazonaws.com/assignments.csv?${new Date().getTime()}`,
            production: `https://tj-public-assets.s3.amazonaws.com/assignments.csv?${new Date().getTime()}`,
          },
          buttonSubmit: 'Cargar plantilla',
          alertInfo: 'Se enviará un correo con los resultados de las asignaciones.',
          alertOk:
            'Planilla cargada exitosamente. Hemos enviado un correo con los resultados de la asignación.',
        },
        massiveStatus: {
          success:
            'Planilla cargada exitosamente. Hemos enviado un correo con los resultados de la asignación.',
        },
        bulkCreateModal: {
          title: '{{type}} masiva de tareas ',
          text: 'Para poder {{type}} tareas masivas, debes aplicar al menos un filtro para ver que tareas serán {{second}}. Recuerda que solo puedes {{type}} un máximo de 100 tareas.',
          okText: 'Entendido',
        },
        bulkUpdateButtons: {
          title: 'Acciones',
          cancel: {
            okButton: 'Cancelar tareas',
            text: 'Tareas seleccionadas ({{quantity}}) quedarán en estado “Cancelada”, quedando inactivas y ubicandose al final de la tabla.',
            title: 'Cancelar tareas',
            option: 'Cancelar tareas',
            success: 'Tareas ({{quantity}}) canceladas con éxito.',
            error: 'Error al cancelar tareas ({{quantity}}). Codigo: {{code}}',
          },
          delete: {
            okButton: 'Eliminar tareas',
            text: 'Tareas seleccionadas ({{quantity}}) desaparecerán de la tabla y no podrás recuperar esta información.',
            title: 'Eliminar tareas',
            option: 'Eliminar tareas',
            success: 'Tareas ({{quantity}}) eliminadas con éxito.',
            error: 'Error al eliminar tareas ({{quantity}}). Codigo: {{code}}',
          },
          assign: {
            title: 'Dar pago de asegurado',
            okButton: 'Dar pago asegurado',
            text: 'Al confirmar, el incentivo será agregado a la tarea y se pagará a todos los Jobbers.',
            success: 'Pago de asegurado agregado con éxito.',
            error: 'Error al agregar pago de asegurado',
          },
          deny: {
            title: 'Quitar pago de asegurado',
            okButton: 'Quitar pago asegurado',
            text: 'Al confirmar, el incentivo será quitado de la tarea y no será pagado a los Jobbers.',
            success: 'Pago de asegurado quitado con éxito.',
            error: 'Error al quitar pago de asegurado',
          },
          notPermitted: {
            title: 'No es posible editar el asegurado SMU',
            okButton: 'Entendido',
            subTitle:
              'Se ha ejecutado el asegurado en uno o más Jobbers, por lo que no es posible quitarlo.',
            text: 'Solo podrás realizar esta acción, si deshaces el proceso de incentivos ya ejecutado.',
          },
        },
        tabs: {
          jobs: {
            title: 'Tareas programadas',
            filters: {
              select: 'Selecciona',
              search: {
                title: 'Buscar',
                subtitle: 'Busca por ID de tarea',
              },
              client: 'Cliente',
              stores: 'Sede',
              jobType: 'Tipo tarea',
              date: 'Fecha ejecución tarea',
              status: 'Estados',
              statusTooltip:
                'Al filtrar por “Estado”, podrás seleccionar varias tareas y realizar acciones de manera masiva.',
              applicationStatus: 'Estado Jobbers',
              responsable: 'Responsable OT',
              responsableDropdown: {
                responsibleOperator: 'OPS',
                kam: 'KAM',
                search: 'Busca por nombre',
              },

              relevance: {
                title: 'Relevancia tarea',
                tooltip:
                  'Al filtrar por “Relevancia tarea”, podrás seleccionar las tareas que han sido destacadas.',
                featured: 'Destacadas',
                notFeatured: 'No destacadas',
              },
              clean: 'Limpiar filtros',
            },
            filterSearch: { title: 'Buscar', placeholder: 'Busca por ID' },
            filterClear: { title: 'Limpiar filtros' },
            filterDatePicker: { title: 'Fecha ejecución tarea' },
            kpis: [
              'Total cupos solicitados',
              'Asignación de cupos',
              'Cancelación Jobbers',
              'Fallos',
            ],
            tooltipPaymentSchema: {
              label: '{{count}} variable',
              label_plural: '{{count}} variables',
              tooltip: {
                title: 'Esquema de pago',
                gross: '{{name}} (montos brutos)',
                liquid: '{{name}} (montos líquidos)',
              },
            },
            columns: [
              {
                title: 'ID Tarea',
                tooltip: 'Relevancia tarea',
                detailToltip:
                  'Al destacar la tarea, esta aparecerá en el carrusel de inicio de la App del Jobber.',
              },
              'Estado',
              'Sede/Cliente',
              'Tipo tarea',
              'Fecha publicación',
              'Fecha ejecución',
              {
                title: 'Estadísticas',
                tooltips: {
                  presents: '{{count}} presente',
                  presents_plural: '{{count}} presentes',
                  finalized: '{{count}} finalizado',
                  finalized_plural: '{{count}} finalizados',
                  assignedQuotas: '{{count}} cupo asignado',
                  assignedQuotas_plural: '{{count}} cupos asignados',
                  requestedQuotas: '{{count}} cupo total',
                  requestedQuotas_plural: '{{count}} cupos totales',
                  applied: '{{count}} postulación',
                  applied_plural: '{{count}} postulaciones',
                  missing: '{{count}} ausente',
                  missing_plural: '{{count}} ausentes',
                  canceledPostAssigned: '{{count}} cancelado post asig.',
                  canceledPostAssigned_plural: '{{count}} cancelados post asig.',
                },
              },
              {
                title: 'Acciones',
                options: [
                  'Editar Tarea',
                  'Editar Orden de trabajo',
                  'Cancelar Tarea',
                  'Eliminar Tarea',
                  'Publicar Tarea',
                  'Editar esquema de cobro',
                  'Copiar link de tarea (marketing)',
                  'Quitar pago de asegurado',
                  'Dar pago de asegurado',
                  'Editar esquema de pago',
                ],
              },
            ],
          },
          templates: {
            title: 'Plantillas de orden de trabajo',
            filters: {
              select: 'selecciona',
              search: {
                title: 'Buscar',
                subtitle: 'Busca por plantilla',
              },
              client: 'Cliente',
              stores: 'Sede',
              jobType: 'Tipo tarea',
              date: 'Fecha último uso',
              clean: 'Limpiar filtros',
              viewStatus: {
                title: 'Visibilidad de plantilla',
                options: {
                  public: 'Pública',
                  private: 'Privada',
                },
              },
            },
            kpis: ['Plantillas creadas', 'Plantillas en uso', 'Plantillas nuevas'],
            columns: [
              {
                title: 'Plantilla',
                job: '{{count}} tarea',
                job_plural: '{{count}} tareas',
              },
              'Descripción',
              {
                title: 'Visibilidad de  plantilla',
                tag: {
                  public: {
                    title: 'Pública',
                    tooltip:
                      'Plantilla puede ser visualizada y utilizada por el cliente en el Portal Empresa y también por el Staff.',
                  },
                  private: {
                    title: 'Privada',
                    tooltip: 'Plantilla solo puede ser visualizada y utilizada por el Staff.',
                  },
                },
              },
              'Último uso Staff',
              {
                title: 'Acciones',
                options: [
                  'Utilizar plantilla',
                  'Editar plantilla',
                  'Quitar visibilidad al cliente (Privada)',
                  'Dar visibilidad al cliente (Pública)',
                  'Eliminar',
                ],
              },
            ],
            disabledAttributes: {
              store: {
                tag: 'Sede eliminada',
                popover: 'Esta sede fue eliminada por lo que esta plantilla no puede ser utilizada',
              },
              enterprise: {
                tag: 'Cliente eliminado',
                popover:
                  'Este cliente fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              job_type: {
                tag: 'Tipo de tarea eliminado',
                popover:
                  'Este tipo de tarea fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              coordinator: {
                tag: 'Coordinador eliminado',
                popover:
                  'Este coordinador fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              payment_schema: {
                tag: 'Esquema de pagos eliminado',
                popover:
                  'Este esquema de pagos fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              invoice_schema: {
                tag: 'Esquema de cobro eliminado',
                popover:
                  'Este esquema de cobro fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              many: {
                text: 'Los siguientes atributos también fueron eliminados:',
                attributes: {
                  store: 'Sede',
                  enterprise: 'Cliente',
                  job_type: 'Tipo de tarea',
                  coordinator: 'Coordinador',
                  payment_schema: 'Esquema de pago',
                  invoice_schema: 'Esquema de cobro',
                },
              },
            },
            modal: {
              public: {
                title: 'Dar visibilidad al cliente',
                body: 'Al confirmar, el cliente podrá ver y utilizar esta plantilla para crear nuevos requerimientos y el estado de visibilidad cambiará a <strong>“Pública”.</strong>',
                alert: {
                  info: 'Tanto el nombre como la descripción que hayas dado a la plantilla, será visualizada por el cliente.',
                  success:
                    'Se ha cambiado la visibilidad de la plantilla a <strong>“Pública”.</strong> Desde ahora el cliente podrá ver y utilizar esta plantilla.',
                  error: 'Error al actualizar la visibilidad de la plantilla.',
                },
                okButton: 'Dar visibilidad',
              },
              private: {
                title: 'Quitar visibilidad al cliente',
                body: 'Al confirmar, el cliente ya no podrá ver ni utilizar esta plantilla para crear nuevos requerimientos y el estado de visibilidad cambiará a <strong>“Privada”.</strong>',
                alert: {
                  info: 'La visibilidad no afecta requerimientos ya creados.',
                  success:
                    'Se ha cambiado la visibilidad de la plantilla a <strong>“Privada”.</strong>',
                  error: 'Error al actualizar la visibilidad de la plantilla.',
                },
                okButton: 'Quitar visibilidad',
              },
            },
          },
          extraHours: {
            title: 'Validación horas extras',
            subtitle: 'Revisar el listado de Jobbers que solicitan pago de horas extras.',
            button: 'Acciones',
            filters: {
              select: 'Seleccione',
              client: 'Cliente',
              stores: 'Sede',
              jobType: 'Tipo tarea',
            },
            filterSearch: { title: 'Buscar', placeholder: 'Busca por ID de tarea' },
            filterClear: { title: 'Limpiar filtros' },
            filterDatePicker: { title: 'Fecha ejecución tarea' },
            columns: {
              jobId: 'ID Tarea',
              jobber: 'Jobber',
              jobEnterprise: 'Sede/Cliente',
              jobType: 'Tipo de tarea',
              jobDate: 'Fecha Ejecución',
              presentAt: 'Hora entrada',
              finishAt: 'Hora salida',
              actions: 'Acciones',
            },
            actions: {
              title: 'Acciones',
              accept: 'Aceptar horas extras',
              modify: 'Modificar horas extras',
              reject: 'Rechazar horas extras',
            },
            accept: {
              title: 'Aceptar horas extras',
              okText: 'Aceptar horas extras',
              text: 'Jobbers seleccionados quedarán con el horario de salida marcado y se pagarán sus horas extras.',
              successToast: 'Horas extras aceptadas',
            },
            modify: {
              title: 'Modificar horas extras',
              okText: 'Modificar horas extras',
              text: 'Jobbers quedarán con el mismo horario de salida que ingreses más abajo.',
              jobTime: 'Ejecución: {{- date}}  -  {{start}} - {{end}} hrs.',
              successToast: 'Horas extras modificadas',
            },
            reject: {
              title: 'Rechazar horas extras',
              okText: 'Rechazar horas extras',
              text: 'Jobbers quedarán con el horario de salida definido en la tarea y le pago de horas extras no será considerado.',
              successToast: 'Horas extras rechazadas',
            },
            history: {
              accept: {
                text: 'El Jobber <strong>{{name}}</strong> quedará con el horario de salida marcado y se pagarán sus horas extras.',
                successToast: '<strong>Horas extras</strong> aceptadas con éxito.',
              },
              modify: {
                text: 'El Jobber <strong>{{name}}</strong> quedará con el mismo horario de salida que ingreses más abajo.',
                successToast: '<strong>Horas extras</strong> modificadas con éxito.',
              },
              reject: {
                text: 'El Jobber <strong>{{name}}</strong> quedará con el horario de salida definido en la tarea y el pago de horas extras no será considerado.',
                successToast: '<strong>Horas extras</strong> rechazadas con éxito.',
              },
            },
            modal: {
              select: {
                label: 'Jobbers seleccionados *',
                placeholder: 'Selecciona o busca por Jobber, RUT o email',
              },
              floatInputDate: 'Fecha de salida',
              floatInputHour: 'Hora de salida',
              dateError: 'Hora fuera del rango permitido',
            },
          },
          applications: {
            title: 'Applications',
            subtitle: 'Revisa el listado de Jobbers que  han participado de una tarea.',
            filters: {
              select: 'Selecciona',
              search: {
                job: {
                  title: 'Buscar Tarea',
                  subtitle: 'Busca por ID exacto de la tarea',
                },
                jobber: {
                  title: 'Buscar Jobber',
                  subtitle: 'Busca por nombre, apellido o correo del Jobber',
                },
              },
              client: 'Cliente',
              stores: 'Sede',
              jobType: 'Tipo tarea',
              date: 'Fecha ejecución tarea',
              status: 'Estados Tarea',
              statusTooltip:
                'Al filtrar por “Estado Tarea”, podrás seleccionar varias tareas y realizar acciones de manera masiva.',
              applicationStatus: 'Estado Applications',
              secureSmu: 'Asegurado SMU',
              responsableDropdown: {
                responsibleOperator: 'OPS',
                kam: 'KAM',
                search: 'Busca por nombre',
              },
              payInsuredStatus: {
                executed: 'Asegurado ejecutado',
                uninsured: 'Sin asegurado',
                not_executed: 'Con asegurado no ejecutado',
                no_information: 'Sin información de asegurado',
              },
              clean: 'Limpiar filtros',
            },
            filterSearch: { title: 'Buscar', placeholder: 'Busca por Jobber o ID Tarea' },
            filterClear: { title: 'Limpiar filtros' },
            filterDatePicker: { title: 'Fecha ejecución tarea' },
            variable: '{{count}} variable',
            variable_plural: '{{count}} variables',
            columns: [
              {
                title: 'Jobber',
              },
              'Tipo tarea',
              'Sede/Cliente',
              'Fecha ejecución',
              'ID Tarea',
              'Estado Application',
              {
                title: 'Acciones',
                options: ['Dar asegurado SMU', 'No dar asegurado SMU', 'Desasignar Jobber'],
              },
            ],
            massiveInsuredAction: {
              assign_insured: {
                title: 'Dar Asegurado SMU',
                text: 'Al confirmar, <strong>{{count}} Application</strong> recibirá el asegurado que corresponda y este se verá reflejado en el pago.',
                text_plural:
                  'Al confirmar, las <strong>{{count}} Applications</strong> recibirán el asegurado que corresponda y este se verá reflejado en el pago.',
                okText: 'Dar asegurado',
              },
              denied: {
                title: 'No dar asegurado SMU',
                text: 'Al confirmar, no se dará el asegurado a <strong>{{count}} Applications</strong> y pasarán a estado “No dar asegurado”.',
                text_plural:
                  'Al confirmar, no se dará el asegurado a <strong>{{count}} Application</strong> y pasará a estado “No dar asegurado”.',
                okText: 'No dar asegurado',
              },
              both: 'Acciones en asegurados SMU',
              missingSelectedApplications:
                'Desde la tabla, selecciona uno o más Applications para realizar esta acción.',
              alert: {
                error:
                  'Ocurrió un error al realizar la acción. Actualiza la página e intenta nuevamente.',
                assign_insured: 'Asegurado SMU agregado con éxito.',
                denied: 'Asegurado SMU eliminado con éxito.',
              },
              okButton: 'Entendido',
            },
            assign_insured: {
              title: 'Dar asegurado SMU',
              subtitle: 'Jobber {{name}}',
              okText: 'Dar asegurado',
              text: 'Al confirmar, el Jobber <strong>recibirá el asegurado que corresponda y</strong> este se verá reflejado en su pago.',
              successToast: 'Pago asegurado SMU agregado con éxito.',
            },
            denied: {
              title: 'No dar asegurado SMU',
              subtitle: 'Jobber {{name}}',
              okText: 'No dar asegurado',
              text: 'Al confirmar, el Jobber <strong>ya no recibirá el asegurado que le corresponde</strong> y será eliminado de su pago.',
              successToast: 'Pago asegurado SMU eliminado con éxito.',
            },
            buttons: {
              presentAndFinishEdition: 'Editar entrada y/o salida masivamente',
            },
            modal: {
              title: 'Editar entrada y/o salida masivamente',
              subtitle:
                'Realiza cambios en la entrada y/o salida de las applications de una o más tareas. Los resultados de la edición serán enviadas a tu correo.',
              titleUpload: 'Edición de ingreso/salida masiva',
              uploadButton: 'Cargar plantilla',
              button: 'Confirmar edición',
              download: 'Descargar plantilla base',
              downloadLink: {
                develop: `https://tj-public-assets-dev.s3.amazonaws.com/Edicio%CC%81n+masiva+de+entrada+y+salida.csv?${new Date().getTime()}`,
                staging: `https://tj-public-assets-stg.s3.amazonaws.com/Edicio%CC%81n+masiva+de+entrada+y+salida.csv?${new Date().getTime()}`,
                production: `https://tj-public-assets.s3.amazonaws.com/Edicio%CC%81n+masiva+de+entrada+y+salida.csv?${new Date().getTime()}`,
              },
            },
            massiveStatus: {
              success:
                'Edición de entrada y/o salida realizada con éxito. Hemos enviado un correo con los resultados de la misma.',
              error:
                'Ocurrió un error al realizar la edición de entrada y/o salida. Intenta nuevamente.',
            },
          },
          requiresApproval: {
            title: 'Validación tareas',
            columns: {
              jobCode: {
                title: 'ID Tarea',
              },
              status: {
                title: 'Estado',
              },
              store: {
                title: 'Sede/Cliente',
              },
              jobType: {
                title: 'Tipo tarea',
              },
              publishAt: {
                title: 'Fecha publicación',
              },
              startAt: {
                title: 'Fecha ejecución',
              },
              statistics: {
                title: 'Estadísticas',
              },
              actions: {
                title: 'Acciones',
                options: {
                  accept: 'Validar tarea',
                  reject: 'Eliminar tarea',
                },
              },
            },
            alerts: {
              deleteSuccess: 'La tarea {{code}} ha sido eliminada.',
              acceptSuccess: 'La tarea {{code}} ha sido creada exitosamente.',
            },
          },
        },
      },
      modalActions: {
        deleteTask: {
          title: 'Eliminar tarea',
          text: 'Al eliminar la tarea {{job}} - {{client}} - {{store}}, no podrás recuperar esta información y esta desaparecerá de la tabla.',
          okText: 'Eliminar tarea',
          message: 'La tarea ha sido eliminada exitosamente',
        },
        cancelTask: {
          title: 'Cancelar tarea',
          text: 'Al cancelar la tarea {{job}} - {{client}} - {{store}}, esta quedará inactiva y se verá al final de la tabla. ',
          okText: 'Cancelar tarea',
          message: 'La tarea ha sido cancelada exitosamente, ahora puede ser eliminada',
        },
        publishTask: {
          title: 'Publicar tarea',
          text: 'Al realizar esta acción la tarea {{job}} - {{client}} - {{store}}, quedará publicada y será visible en la app.',
          okText: 'Publicar tarea',
          message: 'La tarea ha sido publicada exitosamente',
        },
        deleteTemplate: {
          title: 'Eliminar plantilla',
          public: {
            body: 'Al borrar esta plantilla, no podrá ser utilizada para crear nuevas tareas o requerimientos por parte del cliente.',
            alert: 'La eliminación no afecta a tareas o requerimientos ya creados.',
          },
          private: {
            body: 'Al borrar esta plantilla, no podrá ser utilizada para crear nuevas tareas.',
            alert: 'La eliminación no afecta tareas ya creadas.',
          },
          okText: 'Eliminar plantilla',
          successMessage: 'Plantilla eliminada exitosamente.',
          errorMessage: 'No pudimos eliminar la plantilla. Intenta nuevamente.',
        },
        editEntrySchema: {
          title: 'Editar esquema de cobro cliente',
          subtitle: 'Edita el esquema de cobro que se aplicará a la tarea {{job}}.',
          okText: 'Editar esquema',
          success: 'Esquema de cobro editado con éxito.',
          repeatHelper:
            'Cambio del esquema de cobro, solo afectará esta tarea y no sus repeticiones.',
          schema: {
            label: 'Esquema de cobro',
          },
          info: {
            comment: 'Descripción esquema de cobro',
            subtitle: '{{jobType}} {{enterprise}} {{store}}',
            description:
              'Podrás editar el cobro de las variables que ya existen en la tarea y no podrás agregar o eliminar ninguna.',
            variables: 'Variable(s)',
            simpleVariable: '{{name}} (variable fija)',
            staggeredVariable: '{{name}} (escalonada)',
            staggeredValue: 'De {{min}} a {{max}} {{name}}',
            staggeredValueInf: 'De {{min}} {{name}} en adelante',
            submit: 'Editar esquema de cobro',
          },
        },
        approveJob: {
          title: 'Crear tarea',
          text: 'Al confirmar, la tarea <strong>{{code}}</strong> será creada y publicada en caso de que corresponda.',
          alert:
            'Podrás visualizar esta tarea en la sección de <strong>Tareas programadas.</strong>',
          okText: 'Confirmar creación',
        },
      },
      drawer: {
        new: {
          title: 'Programar orden de trabajo',
          subtitle: 'Ingresa los datos obligatorios (*) para programar la orden de trabajo',
          message:
            'Orden de trabajo ha sido programada exitosamente. En la tabla podrás revisar cada tarea de manera independiente.',
          submitButton: 'Programar orden de trabajo',
          cancelModalOptions: {
            title: 'Descartar orden de trabajo',
            okText: 'Descartar orden de trabajo',
            cancelText: 'Cancelar',
            text: 'Al salir, perderás los datos ingresados ',
          },
          error: 'Completa los campos obligatorios para poder avanzar',
          overlappedJobsError: 'Existe conflicto con el horario de una tarea ya existente',
          selectTimesPublish: [
            'Publicar ahora',
            '1 día antes de la ejecución',
            '2 días antes de la ejecución',
            '1 semana antes de la ejecución',
          ],
          entrySchema: {
            title: 'Cobro cliente',
            subtitle: 'En caso de repetir esta tarea, se aplicará el mismo esquema para todas.',
            schema: {
              label: 'Esquema de cobro *',
            },
            info: {
              comment: 'Descripción esquema de cobro (opcional)',
              variables: 'Variable(s)',
            },
          },
          paymentSchema: {
            title: 'Pago Jobber',
            subtitle:
              'Elige el esquema de pagos e ingresa un valor en las variables previamente asignadas.',
            lunchHelper: {
              true: 'Esquema de pago SI considera el pago de la hora de almuerzo (horario definido o no).',
              false:
                'Esquema de pago NO considera el pago de la hora de almuerzo (horario definido o no).',
            },
            differentAlert:
              'Debes tener presente que existe una diferencia entre las variables del esquema de cobro y pago.',
            typeHelper: {
              liquid:
                'Variables deben tener valores ingresados en montos $t(global.enums.paymentType.liquid, uppercase)S.',
              gross:
                'Variables deben tener valores ingresados en montos $t(global.enums.paymentType.gross, uppercase)S.',
              zero: 'Puedes ingresar un “cero” en aquellas variables que no deseas considerar en el pago. No serán visibles para el Jobber.',
              zeroError: 'Ingresa al menos una variable superior a 0.',
            },
            inputs: [
              {
                label: 'Esquema de pagos *',
                min: 'Ingresa un valor mayor a $t(global.currencySymbol){{min}}',
                max: 'Ingresa un valor menor a $t(global.currencySymbol){{max}}',
                range:
                  'Ingresa un valor entre $t(global.currencySymbol){{min}} y $t(global.currencySymbol){{max}}',
              },
            ],
          },
          insured: {
            title: 'Pago de asegurado Jobber (Incentivo)',
            subtitle: 'Incentivo que aplica a esta tarea. Puedes desactivarlo si lo deseas.',
            checkbox: 'No aplicar pago de asegurado para esta tarea',
          },
          extraInfo: {
            title: 'Información adicional (opcional)',
            subtitle:
              'Puedes agregar un link con los horarios y paradas, un documento, una fotografía o lo que necesites comunicar adicionalmente para esta tarea. Puedes agregar más de un archivo/link.',
            addFile: {
              content: 'Adjuntar archivo',
              helper: 'Peso máx. 10 mb - Formato: jpg, pdf, png',
            },
            addLink: {
              content: 'Insertar Link',
              helper: 'URL del contenido que verá el Jobber',
            },
            inputs: [
              {
                label: 'Texto para mostrar *',
                placeholder: 'Ej: Ver horarios y paradas',
              },
              {
                label: 'URL *',
                placeholder: 'Ej: www.horariosyparadasjobber.cl',
              },
            ],
          },
          originTypeByStore: 'Inducción específica por sede',
          steps: [
            {
              title: 'Orden trabajo',
              missingSkillMessage:
                'Para este cliente y tipo de tarea, no existe una Skill creada. Dirígete al Gestor de inducciones para crear una.',
              missingSchemaMessage:
                'Para este cliente, sede y tipo de tarea, no existe un Esquema de pago creado. Dirígete al perfil del cliente para crear uno.',
              schemaWithoutRuleMessage:
                'No podrá continuar con el flujo hasta que se arregle la regla.',
              withoutEntrySchemaMessage:
                'Para este cliente, sede y tipo de tarea, no existe un Esquema de cobro creado. Dirígete al perfil del cliente para crear uno.',
              template: {
                title: 'Plantilla orden de trabajo',
                subtitle: 'Selecciona la plantilla que utilizarás como base',
                label: 'Plantilla',
                placeholder: 'Plantilla orden de trabajo',
                required: 'Selecciona una plantilla',
              },
              responsableInfo:
                'En caso de no seleccionar un staff, el creador de la OT quedará como responsable.',
              sections: [
                {
                  title: 'Orden de trabajo',
                  inputs: [
                    {
                      label: 'Fecha ejecución tareas',
                      required: 'Ingresa la fecha de ejecución de la(s) tarea(s)',
                    },
                    {
                      label: 'Cliente comercial',
                      required: 'Selecciona un cliente',
                    },
                    {
                      label: 'Sede',
                      required: 'Selecciona una sede',
                    },
                    {
                      label: 'Tipo de tarea',
                      required: 'Selecciona un tipo de tarea',
                    },
                    {
                      label: 'Staff responsable (opcional)',
                    },
                    {
                      label: 'KAM responsable (opcional)',
                    },
                    {
                      label: 'Subnombre tipo de tarea (opcional)',
                    },
                  ],
                },
                {
                  title: 'Detalles orden de trabajo',
                  inputs: [
                    {
                      label: 'Descripción',
                      required: 'Ingresa una descripción para la orden de trabajo',
                      placeholder:
                        'Ingresa una descripción de la o las tareas que deberá realizar el Jobber.    Omite información respecto a pagos, horarios y transporte.',
                      max: 'Límite de caracteres alcanzado',
                    },
                  ],
                },
                {
                  title: 'Ubicación orden de trabajo',
                  labels: [
                    'Radio de asistencia',
                    'Referencia lugar de ingreso',
                    'URL Google Maps',
                    'Fotografía lugar de ingreso',
                  ],
                  actions: {
                    map: 'Ver mapa',
                  },
                },
              ],
            },
            {
              title: 'Jobber',
              inputs: [
                {
                  required: 'Selecciona al menos una inducción',
                },
                {
                  required: 'Selecciona el dispositivo a utilizar',
                },
                {
                  label: 'Elementos',
                  required: 'Selecciona los Elementos de Protección Personal',
                },
                {
                  options: {
                    spanish: 'Si',
                    any: 'No',
                  },
                  required: 'Selecciona el idioma del Jobber',
                },
                {
                  required: 'Selecciona el sexo del Jobber',
                },
              ],
              sections: [
                {
                  title: 'Inducción utilizada (skill)',
                  subtitle: 'Para este cliente y tipo de tarea, se está utilizando la inducción ',
                  subtitleEnd: ' como requisito necesario para postular a la tarea.',
                  subtitleBoth:
                    'Para este cliente y tipo de tarea, se están utilizando las inducciones ',
                  checkbox: 'Utilizar inducción general',
                  radio: {
                    specific_store: 'Utilizar inducción específica por sede ',
                    specific: 'Utilizar inducción específica ',
                    general: 'Utilizar inducción general ',
                    both: 'Utilizar inducción general y/o específica.',
                  },
                  alert:
                    'Al seleccionar esta opción, más Jobbers podrán visualizar la(s) tarea(s).',
                  alertBoth:
                    'Al seleccionar esta opción, más Jobbers podrán visualizar la(s) tarea(s). Jobbers sólo verán los requisitos de la inducción general al postular.',
                },
                {
                  label: 'Requiere curso en terreno',
                  subtitleStart: 'Para este cliente y sede, se está utilizando el curso ',
                  subtitleEnd: ' como requisito necesario para postular a la tarea.',
                },
                {
                  title: 'Jobbers con inducción pendiente',
                  subtitle:
                    'Selecciona como verán la tarea aquellos Jobbers que NO aplican con la o las inducciones seleccionadas.',
                  radio: {
                    true: 'Visualizar tarea con inducción pendiente.',
                    false: 'No visualizar tarea.',
                  },
                },
                'Dispositivos',
                'Elementos de Protección Personal (EPP)',
                '¿El jobber necesita hablar español?',
                'Sexo Jobber',
              ],
            },
            {
              title: 'Tareas y pagos',
              messageInfo: 'Recuerda que cada tarea, se verá de manera independiente en el listado',
              messageNextDay: 'Esta tarea finaliza el día siguiente a la ejecución',
              messageQuotas:
                'Se ha reducido el número de cupos solicitados de la tarea, por lo que debes desasignar a 1 Jobber.',
              messageQuotas_plural:
                'Se ha reducido el número de cupos solicitados de la tarea, por lo que debes desasignar a {{count}} Jobbers.',
              messageHasConflict: 'Existe conflicto en el horario de las tareas',
              messageJobFinishedDate:
                'Esta fecha ya transcurrió {{day}}/{{month}}/{{year}}, por lo que los Jobbers asignados posteriormente, quedarán en estado finalizado, con la hora de ingreso y salida definida en la(s) tarea(s).',
              messageJobFinishedDateRequiringApproval:
                'Tareas creadas posterior a la fecha de ejecución deberán ser validadas por staff senior',
              messageJobFinishedHour:
                'Acabas de seleccionar una hora y fecha ya transcurridas, por lo que los Jobbers asignados posteriormente, quedarán en estado Finalizado, con la hora de ingreso y salida definida en la tarea.',
              messageJobFinishedHourSameDay:
                'Acabas de seleccionar una hora ya transcurrida, por lo que los Jobbers asignados posteriormente, quedarán en estado Finalizado, con la hora de ingreso y salida definida en la tarea.',
              messageJobOngoing:
                'Acabas de seleccionar una hora y fecha en curso, por lo que los Jobbers asignados posteriormente, quedarán en estado Presente, con la hora de ingreso definida en la tarea.',
              empty: {
                message: 'Faltan datos por completar',
                field: 'sin datos',
              },
              actions: ['Agregar otra tarea'],
              sections: ['Cupos tarea (Jobbers)', 'Horario tarea'],
              labels: {
                headerPanel: ['Horario tarea', 'Cupos solicitados'],
              },
              inputs: [
                {
                  label: 'Coordinador tarea (opcional)',
                },
                {
                  label: 'Solicitados',
                  required: 'Ingresa la cantidad de Jobbers',
                },
                {
                  label: 'Sobrecupos (opcional)',
                },
                {
                  label: 'Hora inicio',
                  required: 'Ingresa una hora de inicio',
                  sameTime: 'Ya existe una tarea que posee este horario',
                  notAllowed: 'Hora fuera del rango permitido',
                },
                {
                  label: 'Hora término',
                  required: 'Ingresa una hora de término',
                  notAllowed: 'Hora fuera del rango permitido',
                },
                {
                  label: 'Horario de almuerzo',
                },
                {
                  label: 'Sin horario definido',
                  required: 'sdsd',
                },
                {
                  title: 'Tiempo de almuerzo *',
                  options: '{{option}} minutos',
                  text: 'Selecciona el tiempo que tendrá el Jobber para almorzar. Estos minutos pueden ser utilizados en el horario que estime el local el día de la tarea.',
                },
                {
                  label: 'Comentario específico de la tarea (opcional)',
                  placeholder: 'Ingresa los detalles específicos para esta tarea',
                  max: 'Límite de caracteres alcanzado',
                },
              ],
            },
            {
              title: 'Publicación',
              subtitle: 'Publicación y repetición de tareas',
              label: 'Publicación de tareas',
              executionTasks: 'Ejecución tareas: {{input}}',
              titleCheck: 'Definir una publicación para todas las tareas',
              resumenEndDates: 'Resumen repetición tareas',
              resumenEndDatesValue: 'Desde: {{startDate}} Hasta: {{endDate}}',
              helpTexts: ['Publicación'],
              description:
                'Define la anticipación con la que quieres que el Jobber vea las tareas en la App. Por ejemplo, 2 días antes de la fecha de ejecución a las 10:00 hrs.',
              inputs: [
                {
                  label: 'Publicación en la App',
                  required: 'Este campo es obligatorio',
                  invalidOption: 'Esa fecha ya paso, te recomendamos publicar ahora mismo',
                },
                {
                  label: 'Hora de publicación',
                  required: 'Este campo es obligatorio',
                },
                {
                  label: 'Repetir tarea(s)',
                  textInfo: 'Aplica para todos las tareas creadas en el paso anterior',
                  title: 'Se repite(n) días:',
                  subtitle: 'Selecciona los días que quieres que se repita la o las tareas.',
                  selection: {
                    selectAll: 'Seleccionar todos',
                    unselectAll: 'Borrar selección',
                  },
                  schemasAlert:
                    'Esquemas de “cobro cliente” y “pago Jobber”, serán los mismos que los definidos en la creación de cada tarea del paso 3.',
                  warning:
                    'Una vez creada la orden de trabajo, no podrás modificar la repetición de la(s) tarea(s).',
                },
              ],
            },
            [
              {
                title: 'Orden de trabajo',
                dateInfo: 'Ejecución tareas',
                message:
                  'Revisa que todos los datos estén correctos, antes de confirmar la programación de la orden',
                fields: [
                  'Cliente comercial',
                  'Sede',
                  'Tipo de tarea',
                  'OPS responsable',
                  'KAM responsable',
                  'Detalles orden de trabajo',
                  'Descripción',
                  'Ubicación orden de trabajo',
                  'Radio de asistencia',
                  'Referencias de lugar de ingreso',
                  'Fotografía lugar de ingreso',
                  'URL Google Maps',
                  'Subnombre tipo de tarea',
                ],
                actions: {
                  reference: 'Ver imagen',
                  map: 'Ver mapa',
                },
              },
              {
                title: 'Jobber',
                fields: [
                  {
                    title: 'Inducción',
                    isGeneral: ' (Inducción general)',
                  },
                  'Curso en terreno',
                  'Visualización inducción',
                  'Dispositivo',
                  'Sexo Jobber',
                  'Elementos de Protección Personal (EPP)',
                  'Idioma',
                ],
              },
              {
                title: 'Tareas y pagos',
                fields: [
                  'Coordinador tarea',
                  'Cupos solicitados',
                  'Sobrecupos',
                  'Horario tarea',
                  'Horario almuerzo',
                  'Si (sin horario definido)',
                  'Descripción',
                  {
                    title: 'Cobro cliente',
                    schema: 'Esquema de cobro',
                    store: 'Sede',
                    currency: 'Moneda',
                    jobtype: 'Tarea',
                    variables: 'Variable(s)',
                  },
                  {
                    title: 'Pago Jobber',
                    schema: 'Esquema de pago',
                  },
                  {
                    title: 'Pago horario de almuerzo',
                    options: {
                      true: 'Hora de almuerzo será pagada',
                      false: 'No pagado',
                    },
                  },
                  'Información adicional',
                  'Archivos',
                  'Links',
                ],
              },
              {
                title: 'Publicación y repetición de tareas',
                publication: 'Publicación',
                repeatTasks: 'Repetición de tareas',
                days: 'Días',
              },
              {
                checkbox: 'Guardar orden de trabajo como plantilla',
                subtitle:
                  'Cuando programas una orden de trabajo y también la guardas como plantilla, puedes utilizarla posteriormente como base para futuras ordenes de trabajo.',
                inputs: [
                  {
                    label: 'Nombre plantilla *',
                    placeholder: 'Ingresa un nombre para identificar esta plantilla',
                    required: 'Ingresa un nombre para la plantilla',
                    repeated: 'El nombre ingresado ya existe. Intenta con uno distinto',
                  },
                  {
                    label: 'Descripción *',
                    placeholder:
                      'Agregar una descripción que indique por ejemplo, en que casos se debe utilizar esta plantilla.',
                    required: 'Ingresa una descripción para la plantilla',
                  },
                ],
                update: {
                  checkbox: 'Actualizar plantilla',
                  subtitle:
                    'Cuando programas una orden de trabajo y también la guardas como plantilla, puedes utilizarla posteriormente como base para futuras ordenes de trabajo.',
                },
              },
              {
                switch: 'Habilitar plantilla al cliente',
                subtitle:
                  'El cliente podrá utilizar esta plantilla, para crear sus próximos requerimientos desde el Portal Empresas. Tanto el nombre como la descripción de la plantilla, será visualizada por el cliente.',
                alerts: {
                  info: 'Si activas esta opción, debes considerar que los cambios posteriores que realices en la plantilla, también afectarán al cliente.',
                  warning:
                    'Al crear 2 o más tareas en una plantilla, no podrás habilitar la visibilidad por parte del cliente en el Portal Empresas.',
                },
              },
            ],
          ],
        },
        edit: {
          title: 'Edición tarea',
          subtitle: 'Realiza los cambios necesarios en la tarea',
          jobTitle: 'Tarea',
          dateInfo: 'Fecha de ejecución tarea',
          message: 'Tarea modificada exitosamente',
          submitButton: 'Actualizar tarea',
          cancelModalOptions: {
            title: 'Cambios sin guardar',
            cancelText: '$t(global.saveChanges)',
            okText: '$t(global.exitWithoutSaving)',
            text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          },
          error: 'Completa los campos obligatorios para poder avanzar',
          negativeQuotasError:
            'No puedes disminuir la cantidad de Jobbers solicitados. Para disminuir los cupos solicitados o sobrecupos, primero desasigna la misma cantidad de Jobbers.',
          selectTimesPublish: [
            'Publicar ahora',
            '1 día antes de la ejecución',
            '2 días antes de la ejecución',
            '1 semana antes de la ejecución',
          ],
          jobHoursEditError: {
            before:
              'El horario ingresado modifica el estado de la tarea.\
            Ingresa un horario anterior a las {{hours}} hrs para no modificarlo.',
            after:
              'El horario ingresado modifica el estado de la tarea.\
            Ingresa un horario posterior a las {{hours}} hrs para no modificarlo.',
            bothBefore:
              'El horario ingresado modifica el estado de la tarea.\
            Ingresa un horario de inicio anterior a las {{start}} hrs y una hora de termino anterior a las {{end}} hrs para no modificar.',
            bothAfter:
              'El horario ingresado modifica el estado de la tarea.\
            Ingresa un horario de inicio posterior a las {{start}} hrs y una hora de termino posterior a las {{end}} hrs para no modificar.',

            start: {
              before:
                'El horario ingresado modifica el estado de la tarea. Ingresa una hora de inicio anterior a las {{start}} hrs. para no modificarlo.',
              after:
                'El horario ingresado modifica el estado de la tarea. Ingresa una hora de inicio posterior a las {{start}} hrs. para no modificarlo.',
            },
            end: {
              before:
                'El horario ingresado modifica el estado de la tarea. Ingresa una hora de término anterior a las {{end}} hrs. para no modificarlo.',
              after:
                'El horario ingresado modifica el estado de la tarea. Ingresa una hora de término posterior a las {{end}} hrs. para no modificarlo.',
            },
            both: {
              before:
                'El horario ingresado modifica el estado de la tarea.\
              Ingresa una hora de inicio anterior a las {{start}} hrs. y una hora de término anterior a las {{end}} hrs',
              after:
                'El horario ingresado modifica el estado de la tarea.\
              Ingresa una hora de inicio posterior a las {{start}} hrs. y una hora de término posterior a las {{end}} hrs',
              mix: 'El horario ingresado modifica el estado de la tarea.\
              Ingresa una hora de inicio anterior a las {{start}} hrs. y una hora de término posterior a las {{end}} hrs',
            },
          },
          sections: ['Cupos tarea (Jobbers)', 'Horario tarea'],
          inputs: [
            {
              label: 'Coordinador tarea (opcional)',
            },
            {
              label: 'Solicitados',
              required: 'Ingresa la cantidad de Jobbers',
            },
            {
              label: 'Sobrecupos (opcional)',
            },
            {
              label: 'Hora inicio',
              required: 'Ingresa una hora de inicio',
              sameTime: 'Ya existe una tarea que posee este horario',
            },
            {
              label: 'Hora término',
              required: 'Ingresa una hora de término',
              helper: 'Tiempo de almuerzo',
            },
            {
              label: 'Horario de almuerzo',
            },
            {
              label: 'Sin horario definido',
            },
            {
              label: 'Comentario específico de la tarea (opcional)',
              placeholder: 'Ingresa los detalles específicos para esta tarea',
            },
            {
              label: 'Entrega más información del porqué del cambio (opcional)',
              placeholder: 'Entrega más información del porqué del cambio',
            },
          ],
          extraInfo: {
            title: 'Información adicional (opcional)',
            subtitle:
              'Puedes agregar un link con los horarios y paradas, un documento, una fotografía o lo que necesites comunicar adicionalmente para esta tarea.',
            addFile: {
              content: 'Adjuntar archivo',
              helper: 'Peso máx. 10 mb - Formato: jpg, pdf, png',
            },
            addLink: { content: 'Insertar Link', helper: 'URL del contenido que verá el Jobber' },
            inputs: [
              {
                label: 'Texto para mostrar *',
                placeholder: 'Ej: Ver horarios y paradas',
              },
              {
                label: 'URL *',
                placeholder: 'Ej: www.horariosyparadasjobber.cl',
              },
            ],
          },
          changesComment: {
            title: 'Agregar un comentario sobre el o los cambios',
            subtitle:
              'Antes de guardar, deja un comentario para que tu equipo esté informado, sobre los cambios en:',
          },
          touchedFields: {
            coordinator: 'Coordinador',
            requiredQuotas: 'Cupos tarea solicitados',
            extraQuotas: 'Sobrecupos',
            startAt: 'Hora inicio tarea',
            endAt: 'Hora término tarea',
            lunchStartAt: 'Hora inicio almuerzo',
            lunchEndAt: 'Hora término almuerzo',
            lunch: 'Horario de almuerzo',
            noDefinedLunchTime: 'Almuerzo definido',
            comments: 'Comentario específico de la tarea',
            urls: 'Información adicional',
          },
        },
        edit_template: {
          title: 'Edición plantilla orden de trabajo',
          subtitle: 'Realiza los cambios necesarios en la plantilla',
          submitButton: 'Guardar cambios',
          cancelModalOptions: {
            title: 'Cambios sin guardar',
            cancelText: '$t(global.saveChanges)',
            okText: '$t(global.exitWithoutSaving)',
            text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          },
          inputs: [
            {
              label: 'Nombre plantilla *',
              placeholder: 'Ingresa un nombre para identificar esta plantilla',
              required: 'Ingresa un nombre para la plantilla',
              repeated: 'El nombre ingresado ya existe. Intenta con uno distinto',
              min: 'Debe ingresar un mínimo de {{input}} caracteres',
            },
          ],
        },
      },
      updatePaymentSchemaVariablesModal: {
        title: 'Edición de esquema de pago',
        not_allowed_title: 'No es posible editar el esquema de pago',
        subTitle: '{{jobType}} {{enterprise}} {{store}}',
        info: 'Podrás editar el pago de las variables que ya existen en la tarea y no podrás agregar o eliminar ninguna.',
        not_allowed_info:
          'Uno o más Applications tienen una <strong>orden de pago en estado  Liquidada o Pagada,</strong> por lo que no es posible realizar este cambio',
        variableSection: 'Variable(s)',
        simpleVariable: '{{name}} (variable fija)',
        staggeredVariable: '{{name}} (escalonada)',
        staggeredValue: 'De {{min}} a {{max}} {{name}}',
        staggeredValueInf: 'De {{min}} {{name}} en adelante',
        alertInfo1:
          'Esta edición se aplicará a <strong>todas las Applications y órdenes de pago Por pagar o Bloqueadas.</strong>',
        alertInfo2:
          'Ten presente que una disminución en el pago podría traer problemas legales. Por favor revisa antes de confirmar.',
        submitButton: 'Editar esquema de pago',
        successAlert: 'Esquema de pago editado con éxito.',
        errorAlert: 'No pudimos editar el esquema de pago. Intenta nuevamente.',
        closeModalButton: 'Entendido',
      },
    },
    template: {
      dashboard: {
        actionButtons: {
          title: 'Acciones en plantillas',
          create: 'Crear nueva plantilla',
          edit: 'Editar plantilla',
        },
      },
      drawer: {
        new: {
          title: 'Crear nueva plantilla',
          subtitle: 'Ingresa los datos obligatorios (*) para crear la plantilla.',
          submitButton: 'Crear plantilla',
          cancelModalOptions: {
            title: 'Cambios sin guardar',
            okText: 'Salir sin guardar',
            cancelText: 'Continuar editando',
            text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          },
          alerts: {
            multitasks:
              'Si creas 2 o más tareas en una plantilla, no podrás habilitar la visibilidad por parte del cliente en el Portal Empresas.',
            success: 'Plantilla creada exitosamente. ',
          },
          steps: {
            publishing: {
              subtitle: 'Publicación de la tarea',
              tasks: '{{count}} Tareas',
            },
            review: {
              alerts: {
                info: 'Revisa que todos los datos estén correctos, antes de confirmar la plantilla.',
              },
              saveTemplate: {
                title: 'Datos de la plantilla',
                subtitle:
                  'Ingresa un nombre y descripción para identificar rápidamente esta plantilla.',
              },
              publication: {
                title: 'Publicación',
                description: {
                  now: 'La publicación se realizará al crear la tarea',
                  '1|day': 'Publicación 1 día antes de la ejecución a las {{hour}} hrs.',
                  '2|days': 'Publicación 2 días antes de la ejecución a las {{hour}} hrs.',
                  '1|week': 'Publicación 1 semana antes de la ejecución a las {{hour}} hrs.',
                },
              },
            },
          },
        },
        edit: {
          title: 'Edición de plantilla',
          subtitle: 'Realiza los cambios necesarios en la plantilla.',
          submitButton: 'Actualizar plantilla',
          cancelModalOptions: {
            title: 'Cambios sin guardar',
            okText: 'Salir sin guardar',
            cancelText: 'Continuar editando',
            text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          },
          steps: {
            selectOrder: {
              title: 'Plantilla',
              subtitle: 'Selecciona la plantilla que editarás.',
              input: {
                label: 'Plantilla *',
                required: 'Selecciona una plantilla',
              },
              alerts: {
                info: 'Recuerda que los cambios que realices en la plantilla, también afectarán al cliente.',
              },
            },
          },
          alerts: {
            success: 'Cambios guardados con éxito.',
          },
        },
      },
    },
    bulkCreate: {
      title: 'Creación masiva de tareas',
      subtitle: 'Crea tareas masivas',
      titleEdit: 'Edición masiva de tareas',
      detailModal: {
        openDescription: 'Ver detalle',
        openStore: 'Ver ubicación',
        okText: 'Aceptar',
        description: {
          title: 'Detalles orden de trabajo',
          comments: 'Comentario específico de la tarea',
        },
      },
      invalidModal: {
        title: 'Creación masiva de tareas',
        text: '1 o más tareas no tienen los campos completos',
        okText: 'Aceptar',
      },
      submitModal: {
        title: 'Creación masiva de tareas',
        emptyText: 'No hay tareas nuevas para crear, agregue alguna para continuar',
        text: 'Al dar click en Confirmar se creará {{count}} tarea (Sin contar repeticiones)',
        text_plural:
          'Al dar click en Confirmar se crearán {{count}} tareas (Sin contar repeticiones)',
        failedText:
          'Al dar click en Confirmar se creará {{count}} tarea (Solo se creará la tarea que falló en el intento anterior, sin contar repeticiones)',
        failedText_plural:
          'Al dar click en Confirmar se crearán {{count}} tareas (Solo se crearán las tareas que fallaron en el intento anterior, sin contar repeticiones)',
        repeatPublishText:
          'La publicación de las tareas repetidas será asignada según la diferencia entre las fechas de ejecución y publicación de la tarea principal, si la publicación es inmediata las repeticiones también se publicarán de forma inmediata.',
        pending: 'Creando tareas...',
        counter: 'Progreso: {{count}} de {{total}} tareas',
        failed: '{{count}} tarea no pudo ser creada',
        failed_plural: '{{count}} tareas no pudieron ser creadas',
        success: '{{count}} tarea creada',
        success_plural: '{{count}} tareas creadas',
        resultSuccess: 'Fue creada {{count}} tarea (Sin contar repeticiones)',
        resultSuccess_plural: 'Fueron creadas {{count}} tareas (Sin contar repeticiones)',
        resultFailed: 'No se pudo crear {{count}} tarea (Sin contar repeticiones)',
        resultFailed_plural: 'No se pudieron crear {{count}} tareas (Sin contar repeticiones)',
        okText: 'Confirmar',
      },
      submitEditModal: {
        title: 'Edición masiva de tareas',
        emptyText: 'No hay tareas para editar, agregue alguna para continuar',
        text: 'Al dar click en Confirmar se editará {{count}} tarea(s)',
        failedText:
          'Al dar click en Confirmar se editará {{count}} tarea (Solo se editará la tarea que falló en el intento anterior)',
        failedText_plural:
          'Al dar click en Confirmar se crearán {{count}} tareas (Solo se crearán las tareas que fallaron en el intento anterior)',
        failed: '{{count}} tarea no pudo ser editada',
        failed_plural: '{{count}} tareas no pudieron ser editadas',
        success: '{{count}} tarea editada',
        success_plural: '{{count}} tareas editadas',
        resultSuccess: 'Fue editada {{count}} tarea',
        resultSuccess_plural: 'Fueron editadas {{count}} tareas',
        resultFailed: 'No se pudo editar {{count}} tarea',
        resultFailed_plural: 'No se pudieron editar {{count}} tareas',
        message: 'Edición masiva finalizada',
        descriptionSuccess: 'Todas las tareas fueron editadas correctamente.',
        descriptionInfo: 'Una o mas tareas no fueron editadas correctamente.',
      },
      actions: {
        add: {
          input: 'Ingresa ID de la tarea',
          button: 'Agregar tarea',
          success: 'Tarea agregada al final de la lista',
          error: 'No existe una tarea con el código ingresado',
        },
        submit: 'Crear tareas masivas',
        submitEdit: 'Editar tareas masivas',
      },
      columns: {
        validation: {
          title: 'Estado',
          tags: {
            valid: 'Esta tarea tiene todos los campos completos y válidos',
            invalid: 'Esta tarea no tiene todos los campos completos y válidos',
            new: 'Esta tarea acaba de ser agregada',
            publishDateError:
              'La fecha de publicación debe ser por lo menos 1 día anterior a la fecha de ejecución',
            statusError: {
              finished:
                'La hora elegida modifica el estado de la tarea a Finalizada. Ingrese una hora apropiada para evitar ese cambio.',
              ongoing:
                'La hora elegida modifica el estado de la tarea a En curso. Ingrese una hora apropiada para evitar ese cambio.',
              published:
                'La hora elegida modifica el estado de la tarea a Publicada. Ingrese una hora apropiada para evitar ese cambio.',
            },
            repeatError: 'Datos para repetir tarea incompletos',
            repeat: 'La tarea se creará para el día {{- dates}}',
            repeat_plural: 'La tarea se creará para los días {{- dates}}',
            failed: 'Esta tarea no pudo ser creada (Código: {{code}})',
            success:
              'Esta tarea fue creada correctamente (Código: {{code}}), no se tomará en cuenta en la siguiente creación masiva',
          },
        },
        code: 'ID Tarea Original',
        job: 'Sede / Cliente / Tipo de Tarea',
        jobber: {
          title: 'Perfil Jobber',
          epps: 'Elementos de Protección Personal',
          cellphoneOS: 'Dispositivo: ',
          language: 'Idioma: ',
          genderAllowed: 'Sexo Jobber: ',
        },
        responsible: 'Responsables',
        quotas: 'Cupos',
        startAt: 'Fecha ejecución',
        lunch: {
          title: 'Horario de almuerzo',
          switchText: '¿Tiene hora de almuerzo?',
          inputs: [
            {
              label: 'Hora inicio',
              required: 'Ingresar una hora de inicio',
            },
            {
              label: 'Hora término',
              required: 'Ingresar una hora de término',
            },
            {
              label: 'Hora término',
              required: 'Ingresar una hora de término',
            },
            {
              label: 'Sin horario definido',
            },
          ],
        },
        publishAt: 'Fecha publicación',
        repeat: 'Repetir tarea (Opcional)',
        entrySchema: 'Esquema de cobro',
        paymentSchema: 'Esquema de pagos',
        actions: {
          title: 'Acciones',
          menu: {
            clone: 'Clonar',
            delete: 'Eliminar',
          },
        },
      },
      editable: {
        responsible: {
          responsibleOperator: 'Staff responsable (opcional)',
          kam: 'KAM responsable (opcional)',
        },
        quotas: {
          requiredQuotas: 'Solicitados',
          extraQuotas: 'Sobrecupos (Opcional)',
          errorTag: 'Cupos muy bajos',
          errorMessage:
            'Se ha reducido el número de cupos solicitados de la tarea, por lo que debes desasignar a 1 Jobber.',
          errorMessage_plural:
            'Se ha reducido el número de cupos solicitados de la tarea, por lo que debes desasignar a {{count}} Jobbers.',
        },
        startAt: {
          date: 'Fecha de ejecución',
          startHour: 'Hora inicio',
          endHour: 'Hora término',
        },
        publishAt: {
          date: 'Fecha de publicación',
          hour: 'Hora de publicación',
          alerts: {
            finished: {
              tag: 'Tarea finalizada',
              popover:
                'La fecha de ejecución ya transcurrió, por lo que los Jobbers asignados posteriormente, quedarán en estado Finalizado, con la hora de ingreso y salida definida en la tarea.',
            },
            ongoing: {
              tag: 'Tarea en curso',
              popover:
                'Esta tarea se creará en curso, por lo que los Jobbers asignados posteriormente, quedarán en estado Presente, con la hora de ingreso definida en la tarea.',
            },
          },
        },
        repeat: {
          days: 'Días',
          range: 'Rango',
        },
        entrySchema: {
          schema: 'Esquema de cobro',
          variables: {
            title: 'Variable(s)',
            popoverTitle: 'Descripción esquema',
            popoverText:
              'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
          },
          alert: {
            recommended: 'Esquema recomendado',
            unmatched: 'Sin esquema recomendado',
          },
          tooltip: {
            recommended:
              '<strong>Esquema recomendado.</strong><br/> De acuerdo al día y hora de inicio de la tarea, el esquema de cobro recomendado para esta Sede y Tipo de tarea, es el esquema “{{invoiceSchema}}”. Puedes cambiarlo si lo deseas.',
            unmatched:
              '<strong>Sin esquema recomendado.</strong><br/> Ningún esquema de cobro coincide con el día y hora de inicio de la tarea. Selecciona manualmente el esquema que aplicará.',
          },
        },
        paymentSchema: {
          schema: 'Esquema de pagos',
          variables: {
            min: 'Valor mayor a $t(global.currencySymbol){{min}}',
            max: 'Valor menor a $t(global.currencySymbol){{max}}',
            range:
              'Valor entre $t(global.currencySymbol){{min}} y $t(global.currencySymbol){{max}}',
          },
        },
        insured: 'No aplicar pago de asegurado (opcional)',
      },
    },
    jobDetails: {
      subtitle: 'ID de tarea: {{code}}',
      actions: {
        back: 'Volver al listado de tareas',
        edit: 'Editar tarea',
        showDetails: {
          true: 'Ver menos detalles',
          false: 'Ver más detalles',
        },
        downloadReport: 'Descargar reporte de Jobbers',
        assignJobber: 'Asignar Jobbers no postulados',
      },
      cancelledAlert: 'La tarea fue cancelada por lo que no puedes realizar cambios en ella.',
      reportError: 'Ocurrió un error al descargar el archivo, inténtelo de nuevo',
      modal: {
        alertModal: {
          title: 'Jobber en período de desconexión',
          okText: 'Entendido',
        },
        assigned: {
          title: 'Asignar Jobber',
          okText: 'Asignar Jobber',
          text: 'Al asignar al Jobber {{jobber}}, quedará asignado/a a la tarea {{job}}',
          successMessage: 'El Jobber seleccionado fue asignado exitosamente.',
          successMessage_plural: 'Los Jobbers seleccionados fueron asignados exitosamente.',
          alertMessage:
            'El jobber {{jobbers}}, ya se encuentra asignado a otra tarea, por lo que no fue asignado a esta.',
          alertMessage_plural:
            'Los Jobbers {{jobbers}}, ya se encuentran asignados a otra tarea, por lo que no fueron asignados a esta.',
          disconnectedMessage:
            'El Jobber {{jobbers}} no puede ser asignado, ya que se encuentra en su período de desconexión (12 horas), según la nueva “Ley de plataformas digitales”.',
          disconnectedMessage_plural:
            'Los Jobbers {{jobbers}} se encuentran en su período de desconexión, por lo que no pueden ser asignados. Para continuar, quítalos(as) y selecciona otros Jobbers.',
          withOutContractMessage:
            'El Jobber {{jobbers}} no cuenta con todos los contratos firmados. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
          withOutContractMessage_plural:
            'Los Jobbers {{jobbers}} no cuentan con con todos los contratos firmados. Para continuar, quitalos(as) y selecciona otros Jobbers.',
          withExpiredDocumentsMessage:
            'El Jobber {{jobbers}} tiene documentos vencidos. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
          withExpiredDocumentsMessage_plural:
            'Los Jobbers {{jobbers}} tienen documentos vencidos. Para continuar, quitalos(as) y selecciona otros Jobbers.',
          bulk: {
            title: 'Asignar Jobbers',
            okText: 'Asignar Jobbers',
            text: 'Selecciona el o los Jobber que quieres asignar a esta tarea. Una vez asignados, podrás verlos en la sección “Jobbers asignados”.',
          },
          manual: {
            title: 'Asignar Jobbers no postulados',
            okText: 'Asignar Jobbers',
            text: 'Acá podrás agregar aquellos Jobbers que no hayan postulado a la tarea, para que asistan a ella. Una vez asignados, podrás verlos en la sección “Jobbers asignados”.',
            overlapped: {
              same: 'El Jobber {{jobbers}} ya fue asignado a esta tarea. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
              same_plural:
                'Los Jobbers {{jobbers}} ya se encuentran asignados(as) a esta tarea. Para continuar, quitalos(as) y selecciona otros Jobbers.',
              other:
                'El Jobber {{jobbers}} se encuentra asignado a una tarea en el mismo horario. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
              other_plural:
                'Los Jobbers {{jobbers}} se encuentran asignados(as) a una tarea en el mismo horario. Para continuar, quitalos(as) y selecciona otros Jobbers.',
            },
            warningOverlapped: {
              text: 'Los siguientes Jobbers, están en una tarea en curso:',
              jobCode: 'ID tarea {{code}}',
            },
            blockedMessage:
              'El Jobber {{jobbers}} se encuentra bloqueado. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
            blockedMessage_plural:
              'Los Jobbers {{jobbers}} se encuentran bloqueados. Para continuar, quitalos(as) y selecciona otros Jobbers.',
            specificBlockedMessage: {
              byStore:
                'El Jobber <strong>{{name}}</strong> se encuentra restringido para la sede de esta tarea. ',
              byStore_plural:
                'Los Jobbers <strong>{{name}}</strong> se encuentran restringidos para la sede de esta tarea. ',
              byJobType:
                'El Jobber <strong>{{name}}</strong> se encuentra restringido para este tipo de tarea. ',
              byJobType_plural:
                'Los Jobbers <strong>{{name}}</strong> se encuentran restringidos para este tipo de tarea. ',
              byEnterprise:
                'El Jobber <strong>{{name}}</strong> se encuentra restringido para el cliente de esta tarea. ',
              byEnterprise_plural:
                'Los Jobbers <strong>{{name}}</strong> se encuentran restringidos para el cliente de esta tarea. ',
              continue:
                'Para continuar, quita a <strong>{{name}}</strong> y selecciona otro Jobber.',
              continue_plural: 'Para continuar, quítalos(as) y selecciona otros Jobbers.',
            },

            disconnectedMessage:
              'El Jobber {{jobbers}} se encuentra en su período de desconexión, por lo que no puede ser asignado. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
            disconnectedMessage_plural:
              'Los Jobbers {{jobbers}} se encuentran en su período de desconexión, por lo que no pueden ser asignados. Para continuar, quítalos(as) y selecciona otros Jobbers.',
            withOutContractMessage:
              'El Jobber {{jobbers}} no cuenta con todos los contratos firmados. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
            withOutContractMessage_plural:
              'Los Jobbers {{jobbers}} no cuentan con con todos los contratos firmados. Para continuar, quitalos(as) y selecciona otros Jobbers.',
            withExpiredDocumentsMessage:
              'El Jobber {{name}}, tiene {{count}} documento vencido, lo que podría generar problemas si es fiscalizado.',
            withExpiredDocumentsMessage_plural:
              'El Jobber {{name}}, tiene {{count}} documentos vencidos, lo que podría generar problemas si es fiscalizado.',
            jobbersWithExpiredDocumentsMessage:
              'Los Jobbers {{name}}, tienen documentos vencidos, lo que podría generar problemas si son fiscalizados.',
            withToExpireDocumentsMessage:
              'El Jobber {{name}}, tiene {{count}} documento próximo a vencer ({{date}}).',
            withToExpireDocumentsMessage_plural:
              'El Jobber {{name}}, tiene {{count}} documentos próximos a vencer ({{date}}).',
            jobbersWithToExpireDocumentsMessage:
              'Los Jobbers {{name}}, tienen documentos próximos a vencer.',
            select: {
              label: 'Jobbers *',
              placeholder: 'Selecciona o busca por RUT o email',
              searchText: 'Ingresa 3 caracteres para buscar',
              emptyText: 'No hay datos',
            },
          },
          expiredDocument: {
            title: 'Jobber con documentos vencidos',
            subtitle: 'Documento vencidos',
            description:
              'Ten en cuenta que el Jobber {{jobber}} tiene {{count}} documento vencido, lo que podría generar problemas si es fiscalizado.',
            description_plural:
              'Ten en cuenta que el Jobber {{jobber}} tiene {{count}} documentos vencidos, lo que podría generar problemas si es fiscalizado.',
          },
          toExpireDocument: {
            title: 'Jobber con documentos por vencer',
            subtitle: 'Documento por vencer',
            description:
              'Ten en cuenta que el Jobber {{jobber}} tiene {{count}} documento próximo a vencer ({{date}}).',
            description_plural:
              'Ten en cuenta que el Jobber {{jobber}} tiene {{count}} documentos próximos a vencer ({{date}}).',
          },
          multipleCase: {
            title: 'Jobber con documentos vencidos y por vencer',
            description:
              'Ten en cuenta que el Jobber {{jobber}} tiene {{count}} documento vencido, lo que podría generar problemas si es fiscalizado. ',
            description_plural:
              'Ten en cuenta que el Jobber {{jobber}} tiene {{count}} documentos vencidos, lo que podría generar problemas si es fiscalizado. ',
            toExpireDocument: 'Además, tiene {{count}} documento próximo a vencer ({{date}})',
            toExpireDocument_plural:
              'Además, tiene {{count}} documentos próximos a vencer ({{date}})',
          },
        },
        cancelled: {
          title: 'Descartar Jobber',
          okText: 'Descartar Jobber',
          text: 'Al descartar la postulación del Jobber {{jobber}}, este/a será eliminado de la tabla.',
          successMessage: 'El Jobber seleccionado fue descartado exitosamente.',
          bulk: {
            title: 'Descartar Jobbers',
            okText: 'Descartar Jobbers',
            text: 'Selecciona el o los Jobber que quieres descartar de esta tarea. Una vez descartados, serán eliminados de la tabla.',
            successMessage: 'Los Jobbers seleccionados fueron descartados exitosamente.',
          },
        },
        deallocated: {
          title: 'Desasignar Jobber',
          okText: 'Desasignar Jobber',
          closeText: 'Entendido',
          text: 'Al desasignar al Jobber {{jobber}}, este/a será transladado a la lista de postulaciones.',
          successMessage: 'El Jobber seleccionado fue desasignado exitosamente.',
          successMessage_plural: 'Los Jobbers seleccionados fueron desasignados exitosamente.',
          canBeDeallocated:
            'Además, la orden de pago asociada en estado <strong>{{moneyOrderStatus}}</strong>, será eliminada.',
          cantBeDeallocated:
            'No podrás desasignar al Jobber <strong>{{jobber}}</strong>, ya que cuenta con una orden de pago en <strong>estado {{moneyOrderStatus}}.</strong>',
          moneyOrderStatus: {
            payable: 'Por pagar',
            paid_out: 'Pagado',
            settled: 'Liquidado',
            reprocess: 'En reproceso',
            blocked: 'Bloqueada',
          },
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Desasignado" por un staff',
              default: 'No pudimos desasignar al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Desasignar Jobbers',
            okText: 'Desasignar Jobbers',
            text: 'Selecciona el o los Jobber que quieres desasignar de esta tarea. Una vez desasignados, serán transladados a la lista de postulaciones.',
          },
        },
        absent: {
          title: 'Marcar ausente',
          okText: 'Marcar ausente',
          text: 'Jobber quedará en estado Ausente, independiente si la tarea ha terminado o no.',
          successMessage: 'El Jobber quedó marcado como Ausente exitosamente.',
          successMessage_plural: 'Los Jobbers quedaron marcados como Ausente exitosamente.',
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Ausente" por un staff',
              default: 'No pudimos marcar como ausente al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Marcar ausente',
            okText: 'Marcar ausente',
            text: 'Jobbers',
            textBold: ' quedarán ausentes',
            textEnd: ', independiente de si la tarea ha terminado o no.',
            failedMessage: 'El Jobber {{jobbers}} ya fue marcado como "Ausente" por un staff',
            failedMessage_plural:
              'Los Jobbers {{jobbers}} ya fueron marcados como "Ausente" por un staff',
          },
        },
        present: {
          title: 'Marcar presente',
          okText: 'Marcar presente',
          text: 'Selecciona el horario en que el Jobber ingresó a la tarea. El Jobber quedará presente el mismo día de ejecución de la tarea ({{- date}})',
          successMessage: 'El Jobber quedó marcado como Presente exitosamente.',
          successMessage_plural: 'Los Jobbers quedaron marcados como Presente exitosamente.',
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Presente" por un staff',
              default: 'No pudimos marcar como presente al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Marcar presente',
            okText: 'Marcar presente',
            text: 'Jobbers quedarán',
            textBold: ' presentes el mismo día y hora de entrada a la tarea ',
            textEnd: '{{- value}}',
            extraText:
              'Para modificar esta hora/fecha, puedes hacerlo desde el detalle de cada Jobber.',
            failedMessage: 'El Jobber {{jobbers}} ya fue marcado como "Presente" por un staff',
            failedMessage_plural:
              'Los Jobbers {{jobbers}} ya fueron marcados como "Presente" por un staff',
          },
        },
        finished: {
          title: 'Marcar salida',
          okText: 'Marcar salida',
          text: 'Selecciona el horario en que el Jobber terminó a la tarea. Se marcará la salida el mismo día de ejecución de la tarea ({{- date}})',
          successMessage: 'El Jobber quedó marcado como Finalizado exitosamente.',
          successMessage_plural: 'Los Jobbers quedaron marcados como Finalizado exitosamente.',
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Finalizado" por un staff',
              default: 'No pudimos marcar como finalizado al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Marcar salida',
            okText: 'Marcar salida',
            text: 'Jobbers quedarán',
            textBold: ' finalizados el mismo día y hora de salida a la tarea ',
            textEnd: '{{- value}}',
            extraText:
              'Para modificar esta hora/fecha, puedes hacerlo desde el detalle de cada Jobber.',
            failedMessage: 'El Jobber {{jobbers}} ya fue marcado como "Finalizado" por un staff',
            failedMessage_plural:
              'Los Jobbers {{jobbers}} ya fueron marcados como "Finalizado" por un staff',
          },
        },
        absentToFinished: {
          title: 'Marcar asistencia',
          okText: 'Marcar Asistencia',
          text: 'Se marcará la hora de entrada y salida automaticamente. La asistencia se marcará el mismo día de ejecución de la tarea.',
          successMessage: 'El Jobber quedó marcado como Finalizado exitosamente.',
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Finalizado" por un staff',
              default: 'No pudimos marcar como finalizado al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Marcar asistencia',
            okText: 'Marcar asistencia',
            text: 'Se marcará la hora de entrada y salida automaticamente. La asistencia se marcará el mismo día de ejecución de la tarea.',
            extraText:
              'Para modificar esta hora/fecha, puedes hacerlo desde el detalle de cada Jobber.',
          },
        },
        select: {
          label: 'Jobbers seleccionados *',
          placeholder: 'Selecciona o busca por Jobber, RUT o email',
        },
        deleteComment: {
          title: 'Eliminar comentario',
          text: 'Al eliminar, perderas el comentario de “{{commentType}}”',
          okText: 'Eliminar comentario',
        },
        sendRoster: {
          button: 'Enviar nómina de Jobbers',
          title: 'Enviar nómina de asignados, presentes y finalizados',
          okText: 'Enviar nómina',
          text: 'Acá podrás revisar la nómina con los distintos estados del Jobber en la tarea. KAM y Staff responsables recibirán una copia del email.',
          text2: 'Puedes ingresar más de un email.',
          label: 'Email destinatario(s)*',
          suggestions: 'Agregar: ',
          optionsTitle: 'Selecciona el o los datos del Jobber que serán enviados',
          options: ['Nombre y apellido', 'Correo', 'Documento', 'Teléfono'],
          successMessage: 'La nómina se ha enviado exitosamente al email {{emails}}',
          successMessage_plural: 'La nómina se ha enviado exitosamente a los emails {{emails}}',
        },
        blockJobber: {
          title: 'Bloqueo específico',
          text: 'El Jobber <strong>{{name}}</strong> se encuentra bloqueado. Intenta asignar a otro jobber.',
        },
      },
      details: {
        jobTitle: 'Detalles Orden de trabajo',
        enterprise: 'Cliente comercial: ',
        store: 'Sede: ',
        type: 'Tipo de tarea: ',
        typeDescription: 'Descripción tipo de tarea: ',
        publishAt: 'Fecha de ejecución: ',
        responsibleOperator: 'Staff responsable: ',
        kam: 'KAM responsable: ',
        taskTitle: 'Detalles Tarea',
        coordinator: 'Coordinador tarea: ',
        requiredQuotas: 'Cupos solicitados: ',
        extraQuotas: 'Sobrecupos: ',
        schedule: 'Horario tarea: ',
        lunchTime: 'Almuerzo: ',
        hourlyRate: 'Pago por hora: ',
        skuRate: 'Pago por SKU: ',
        entryReference: 'Referencias de lugar de ingreso',
        map: {
          title: 'URL Google Maps',
          action: 'Ver mapa',
        },
        entryPhoto: 'Fotografía lugar de ingreso',
        details: 'Detalles orden de trabajo',
        epps: 'Elementos de Protección Personal (EPP)',
        cellphoneOS: 'Dispositivo: ',
        language: 'Idioma: ',
        genderAllowed: 'Sexo Jobber: ',
        comments: 'Comentario específico de la tarea',
        paymentSchema: 'Esquema de pago',
        paymentSchemaPrefix: 'Pago por',
        invoiceSchema: 'Esquema de cobro',
        paymentTypeLabel: 'Tipo de pago: ',
        paymentSchemaName: 'Nombre: ',
        paymentType: {
          gross: 'Montos brutos',
          liquid: 'Montos líquidos',
        },
        invoiceSchemaPrefix: 'Cobro por',
        extraInfo: 'Información adicional',
        additionalLinks: 'Links',
        additionalFiles: 'Archivos',
      },
      applications: {
        title: 'Postulaciones a la tarea',
        subtitle: 'Revisa el listado de Jobbers que están postulando a la tarea',
        cards: [
          'Total de postulaciones',
          {
            title: 'Cupos solicitados',
            extra: '{{count}} sobrecupo',
            extra_plural: '{{count}} sobrecupos',
          },
          'Cupos asignados',
        ],
        actions: {
          cancelJobbers: 'Descartar Jobbers',
          assignJobbers: 'Asignar Jobbers',
        },
        filters: [
          { title: 'Cancelaciones', placeholder: 'Selecciona' },
          { title: 'Ocupación', placeholder: 'Selecciona' },
          { title: 'Rotación', placeholder: 'Selecciona' },
          { title: 'Riesgo', placeholder: 'Selecciona' },
        ],
        filterSearch: { title: 'Buscar', placeholder: 'Busca por Jobber' },
        filterClear: { title: 'Limpiar filtros' },
        maxQuotasAlert: 'Puedes seleccionar un máximo de 1 Jobber para ser asignado.',
        maxQuotasAlert_plural:
          'Puedes seleccionar un máximo de {{count}} Jobbers para ser asignados.',
        filledQuotasAlert:
          'Se han asignados todos los cupos solicitados para esta tarea. Revisa el seguimiento de los Jobbers en la sección "Jobbers asignados".',
        asignFinishedAlert:
          'Aquellos Jobbers que sean asignados finalizada la tarea, quedarán por defecto con horario de ingreso y salida, de acuerdo al horario fijado de la tarea. Si deseas modificar este horario, debes hacerlo en el detalle de cada Jobber.',
        table: {
          columns: [
            'Jobber',
            {
              CL: 'Región/Comuna',
              PE: 'Depto./Provincia',
              CO: 'Depto./Municipio',
              MX: 'Estado/Municipio',
            },
            {
              title: 'Rotación',
              popover: 'Tareas asignadas en igual cliente esta semana (lunes a domingo)',
            },
            {
              title: 'Riesgo',
              popover:
                'Porcentaje de riesgo que tiene el Jobber de cancelar la tarea. Cálculo en base a cancelaciones y ausentismo anterior.',
            },
            {
              title: 'Ocupación',
              popover:
                'Número de días en que el jobber ha sido asignados / Número de días en que el jobber ha postulado',
            },
            'Estado',
            'Acciones',
          ],
          expandable: {
            lastMonth: 'Últimos 30 días',
            always: 'Período completo',
            absences: 'Ausencias',
            finalized: 'Finalización',
            preAssignmentCancellations: 'Cancelación pre-asignación',
            postAssignmentCancellations: 'Cancelación post-asignación',
            stay: {
              title: 'Estadía en tareas',
              info: {
                text1:
                  'Es el porcentaje del tiempo que el jobber está en sus tareas. Por ejemplo, si el jobber hizo una tarea de 12:00-17:00 hrs y estuvo de 12:30-16:30, su estadía fue de 4/5 horas, lo que equivale a un 80% de estadía.',
                text2:
                  'Aquí se muestra el promedio de esos porcentajes para todas las tareas finalizadas por el jobber',
              },
              value: '{{value}}%',
            },
            puntuality: {
              title: 'Puntualidad',
              info: {
                title: 'Criterios de puntualidad:',
                item1:
                  '- Muy puntual: Demora 5 min o menos entre que inicia la tarea y marca entrada',
                item2: '- Puntual: Demora 15 min o menos entre que inicia la tarea y marca entrada',
                item3:
                  '- Poco puntual: Demora 30 min o menos entre que inicia la tarea y marca entrada',
                item4:
                  '- Impuntual: Demora más de 30 min entre que inicia la tarea y marca entrada',
              },
              very_puntual: 'Muy puntual',
              puntual: 'Puntual',
              not_very_puntual: 'Poco puntual',
              not_puntual: 'Impuntual',
              no_info: 'Sin información',
            },
            lastMinuteAssignment: {
              title: 'Disponibilidad inmediata',
              value: '{{lastMinuteAssistance}}/{{lastMinuteAssignment}}/{{lastMinuteAvailable}}',
              tooltip: {
                allJobs: 'En todas las tareas:',
                sameJob: 'En igual tarea:',
                applied: '- {{count}} vez aceptó la disponibilidad inmediata',
                applied_plural: '- {{count}} veces aceptó la disponibilidad inmediata',
                assigned: '- {{count}} vez fue asignado a último minuto',
                assigned_plural: '- {{count}} veces fue asignado a último minuto',
                finalized: '- {{count}} vez asistió en tarea asignada a último minuto',
                finalized_plural: '- {{count}} veces asistió en tarea asignada a último minuto',
              },
            },
            confirmationAttendance: {
              title: 'Confirmación de asistencia',
              value: '{{presentWithConfirmedAttendance}}/{{confirmationAttendance}}',
              allJobs: 'cumplió en todas las tareas',
              sameJob: 'cumplió en igual tarea',
              tooltip: {
                allJobs: 'En todas las tareas:',
                sameJob: 'En igual tarea:',
                applied: '- {{count}} confirmación de asistencia',
                applied_plural: '- {{count}} confirmaciones de asistencia',
                finalized: '- {{count}} asistencia tras confirmar',
                finalized_plural: '- {{count}} asistencias tras confirmar',
              },
            },
          },
          stats: {
            job: '{{count}} tarea',
            job_plural: '{{count}} tareas',
            assigned: '{{count}} asignación',
            assigned_plural: '{{count}} asignaciones',
            applied: 'de {{count}} postulación',
            applied_plural: 'de {{count}} postulaciones',
            allJobs: 'en todas las tareas',
            sameJob: 'en igual tarea',
          },
          emptyText: 'No hay postulaciones',
          earlyAssignment: {
            tag: 'Con disponibilidad inmediata',
            tooltip: {
              title: 'Con disponibilidad inmediata',
              body: 'El jobber indicó al momento de postular que si es asignado a último momento estaría dispuesto a asistir',
            },
          },
        },
        newJobbers: {
          tooltip: {
            tag: {
              text: 'Jobber con menos de 3 tareas realizadas en verticales de retail y labour',
            },
            switch: {
              text: 'Jobbers con menos de 3 tareas realizadas en verticales de retail y labour',
              label: 'Ver sólo Jobbers nuevos',
            },
          },
          new: 'Nuevo',
          jobberBlocked: 'Bloqueo específico',
        },
      },
      assigned: {
        title: 'Jobbers Asignados',
        subtitle: 'Revisa los Jobbers asignados a la tarea',
        cards: ['Asignados', 'Presentes', 'Finalizados', 'Ausentes', 'Cancelados post asig.'],
        actions: {
          bulkUpdate: 'Cambiar estado',
        },
        filters: [
          { title: 'Asistencia', placeholder: 'Selecciona' },
          {
            title: 'Estado',
            placeholder: 'Selecciona',
            tooltip:
              'Al filtrar por “Estado”, podrás seleccionar varios Jobber y modificar su estado de manera masiva.',
          },
          {
            title: {
              CL: 'Región',
              PE: 'Provincia',
              CO: 'Departamento',
              MX: 'Estado',
            },
          },
          {
            title: {
              CL: 'Comuna',
              PE: 'Distrito',
              CO: 'Municipio',
              MX: 'Municipio',
            },
          },
        ],
        filterSearch: { title: 'Buscar', placeholder: 'Busca por Jobber' },
        filterClear: { title: 'Limpiar filtros' },
        filterReason: {
          title: 'Tipo de ajuste',
          reasonOptions: {
            kms_difference: 'Ajuste diferencia KMS',
            sku_difference: 'Ajuste diferencia SKU',
            units_difference: 'Ajuste diferencia unidades',
            secure_difference: 'Ajuste diferencia asegurado',
            incentive_difference: 'Ajuste diferencia incentivo',
            extra_payment: 'Pago extra especial',
          },
        },
        filterIncentive: {
          title: 'Incentivos',
          incentiveOptions: {
            secure_smu: 'Asegurado SMU',
          },
        },

        table: {
          columns: [
            'Jobber',
            'Asistencia',
            'Hora entrada',
            'Hora salida',
            'Horas a pagar',
            'Estado',
            'Acciones',
          ],
          expandable: ['$t(global.email)', 'Ubicación'],
          emptyText: 'No hay Jobber asignados',
          actions: {
            present: 'Marcar presente',
            absent: 'Marcar ausente',
            deallocated: 'Desasignar Jobber',
            comment: 'Dejar un comentario',
            finished: 'Marcar salida',
            absentToFinished: 'Marcar asistencia',
          },
        },
      },
      applied: {
        table: {
          actions: {
            present: 'Marcar presente',
            absent: 'Marcar ausente',
            deallocated: 'Desasignar Jobber',
            comment: 'Dejar un comentario',
            finished: 'Marcar salida',
            absentToFinished: 'Marcar asistencia',
          },
        },
        tag: {
          expired: '{{count}} Documento vencido',
          expired_plural: '{{count}} Documentos vencidos',
          toExpire: '{{count}} Documento por vencer',
          toExpire_plural: '{{count}} Documentos por vencer',
          multipleCase: '{{count}} Doc. vencido y {{countTwo}} Doc. por vencer',
          multipleCase_plural: '{{count}} Doc. vencidos y {{countTwo}} Doc. por vencer',
        },
        expirationAlert: {
          expired:
            'Ten en cuenta que el Jobber tiene {{count}} documento vencido, lo que podría generar problemas si es fiscalizado.',
          expired_plural:
            'Ten en cuenta que el Jobber tiene {{count}} documentos vencidos, lo que podría generar problemas si es fiscalizado.',
          toExpire: 'Ten en cuenta que el Jobber tiene {{count}} documento por vencer ({{date}}).',
          toExpire_plural:
            'Ten en cuenta que el Jobber tiene {{count}} documentos por vencer ({{date}}).',
          multipleCase: {
            expiredDocument:
              'Ten en cuenta que el Jobber tiene {{count}} documento vencido, lo que podría generar problemas si es fiscalizado.',
            expiredDocument_plural:
              'Ten en cuenta que el Jobber tiene {{count}} documentos vencidos, lo que podría generar problemas si es fiscalizado.',
            toExpireDocument: ' Además, tiene {{count}} documento próximo a vencer ({{date}}).',
            toExpireDocument_plural:
              ' Además, tiene {{count}} documentos próximos a vencer ({{date}}).',
          },
        },
      },
      history: {
        header: {
          title: 'Datos Jobber',
          nationality: 'Nacionalidad',
          phone: 'Teléfono',
          address: 'Dirección',
          email: '$t(global.email)',
          region: '$t(global.region)',
          commune: '$t(global.commune)',
          documentType: {
            ce: 'CE',
            rut: 'RUT',
            ruc: 'RUC',
            dni: 'DNI',
            ptp: 'PTP',
            ppt: 'PPT',
            cpp: 'CPP',
            curp: 'CURP',
            pasaporte: 'Pasaporte',
            cedula_de_identidad: 'CI',
            cedula_de_ciudadania: 'CC',
            cedula_de_extranjeria: 'CE',
            pep: 'PEP',
            numero_de_identificacion_tributaria: 'NIT',
          },
          gender: {
            title: 'Género',
            male: 'Masculino',
            female: 'Femenino',
          },
        },
        applicationTimes: {
          title: 'Tiempo en la tarea',
          startAt1: 'Inicio de la',
          startAt2: 'tarea',
          endAt1: 'Término de la',
          endAt2: 'tarea',
          presentAt1: 'Entrada',
          presentAt2: 'Jobber',
          presentAtTooltipTitle: 'Entrada Jobber',
          presentAtTooltipDescription:
            'La entrada se calculará cuando el Jobber ingrese su primer pedido asociado a la tarea asignada en la App, y se denotará como el tiempo máximo entre el inicio de la tarea y el ingreso del primer pedido menos 1 hora.',
          finishAt1: 'Salida',
          finishAt2: 'Jobber',
          finishAtTooltipTitle: 'Salida  Jobber',
          finishAtTooltipDescription1:
            'Si el Jobber marca salida de la tarea, la salida debe ser el mínimo entre la hora de salida y la hora de término de la tarea.',
          finishAtTooltipDescription2:
            'Si al Jobber se le finaliza la tarea de forma automática (al pasar 3 horas de la finalización de la tarea), su hora de salida será la hora de finalización de la última ruta asociada a esa tarea.',
          percentage1: 'Estadía en',
          percentage2: 'la tarea',
          percentageTooltipTitle: 'Estadía en la tarea',
          percentageTooltipDescription:
            'Se obtiene de (entrada - salida) / (hora inicio - hora término) x 100',
          percentageValue: '{{percentage}}%',
        },
        records: {
          title: 'Historial del Jobber en la tarea',
          actions: {
            jobber: {
              applied: 'Jobber postula a la tarea',
              present: 'Jobber marca como presente',
              preAssignmentCancellation: 'Jobber cancela postulacion',
              postAssignmentCancellation: 'Jobber cancela tarea asignada',
              finished: 'Jobber marca salida',
              delayedCompletion: 'Jobber marca salida con atraso',
              assigned: 'Confirmación de jobber',
              reasonType: {
                extra_hours: 'Realicé horas extra',
                forgot_to_mark: 'Olvidé marcar la salida',
                no_battery: 'Me quedé sin batería',
                other: 'Otro',
                personal_motives: 'Motivos personales',
                late_assignment: 'Me asignaron tarde a la tarea',
                same_day_assignment: 'Fui asignado a una tarea el mismo día',
                automatic: '3 horas posterior al término de la tarea',
              },
            },
            staff: {
              applied: 'Staff postula a la tarea',
              assigned: 'Staff asigna jobber a la tarea',
              present: 'Staff marca como presente',
              absent: 'Staff marca como ausente',
              deallocated: 'Staff desasigna Jobber',
              finished: 'Staff marca salida',
              delayedCompletion: 'Salida marcada por el sistema',
              reasonType: {
                automatic: '3 horas posterior al término de la tarea',
              },
            },
            notification: {
              assigned: 'Envío de notificación “Asignación”',
              deallocated: 'Envío de notificación "Desasignado"',
              job_started: 'Envío de notificación “Comienzo tarea”',
              job_finished: 'Envío de notificación “Finalización tarea”',
              readed: 'Jobber revisa la notificación',
              create_finished_application: 'Envío notificación "Asistencia a tarea finalizada"',
            },
          },
          staffActions: {
            assigned: 'Asignado por',
            applied: 'Postulado por',
            present: 'Marcado por',
            absent: 'Marcado por',
            deallocated: 'Desasignado por',
            finished: 'Marcado por',
          },
          card: {
            present: {
              title: 'Editar hora y fecha de ingreso',
              floatInputDate: 'Fecha de ingreso',
              floatInputHour: 'Hora de ingreso',
            },
            finished: {
              title: 'Editar hora y fecha de salida',
              floatInputDate: 'Fecha de salida',
              floatInputHour: 'Hora de salida',
            },
            verifyUpdate: {
              title: 'Edición impactará orden de pago',
              message: 'Al confirmar la edición, esta impactará el cálculo de la orden de pago.',
              button: 'Confirmar edición',
            },
            text: 'Puedes modificar la hora, seleccionando un horario anterior o posterior a lo marcado por el Jobber. También puedes modificar la fecha si el turno abarca 2 días.',
            error: 'Hora fuera del rango permitido',
            cancelButton: 'Cancelar',
            updateButton: 'Guardar Cambios',
          },
          delayedCompletion: {
            checkbox: 'Confirmar horas extra (opcional)',
            alert: {
              authorizeOvertime:
                'Selecciona esta opción solo si el cliente comercial ha autorizado el pago de horas extras y si efectivamente fueron realizadas por el Jobber.',
            },
            modal: {
              authorizeOvertime: {
                title: 'Confirmar horas extras',
                body: 'Al confirmar, estás autorizando la realización de ellas. Puedes modificar el horario de salida del Jobber, en caso de ser necesario',
                okText: 'Confirmar',
              },
              unauthorizeOvertime: {
                title: 'Rechazar horas extras',
                body: 'Las horas extras ya no serán consideras para este Jobber.',
                okText: 'Rechazar',
              },
            },
          },
          tracking: {
            title: 'Coordenadas de salida:',
            tag: 'Copiar coordenadas',
            popover: 'Coordenadas copiadas',
          },
          extraHours: {
            accepted: 'Aceptadas por',
            modified: 'Modificadas por',
            rejected: 'Rechazadas por',
            automaticallyRejected: 'Rechazadas automáticamente',
            hoursAndMinutes: 'Horas extras: {{hours}} hora(s) y {{minutes}} minuto(s).',
            minutes: 'Horas extras: {{minutes}} minuto(s).',
            editBy: 'Hora editada por {{name}},',
          },
        },
        parallelJobs: {
          title: 'Tareas postuladas en el mismo horario por el Jobber',
        },
        comments: {
          title: 'Comentarios',
          subtitle: 'Revisa el historial de comunicación del Jobber y la tarea.',
          new: {
            message: 'El comentario se ha creado con éxito',
          },
          update: {
            message: 'Los cambios se han guardado con éxito',
          },
          delete: {
            message: 'El comentario fue eliminado con éxito',
          },
          type: {
            JOBBER_CALL: 'Llamada jobber',
            JOBBER_MESSAGE: 'Mensaje jobber',
            STORE_COORDINATOR_CONTACT: 'Contacto con coordinador de sala',
            CLIENT_CONTACT: 'Contacto con cliente',
            OTHER: 'Otro',
          },
          buttons: {
            addComment: 'Agregar comentario',
            saveChanges: 'Guardar cambios',
          },
          form: {
            create: {
              title: 'Agregar comentario',
              body: 'Aquí puedes dejar registro de llamadas y mensajes enviados al Jobber. También puedes agregar feedback del cliente o coordinador de sala en relación al Jobber.',
            },
            update: {
              title: 'Editar comentario',
              body: 'Al editar el comentario, este actualizará la hora y fecha en que fue agregado.',
            },
            commentType: 'Tipo de comentario *',
            message: {
              label: 'Escribe un comentario *',
              placeholder: 'Ingresa más detalles del comentario',
            },
          },
        },
        payment: {
          card: {
            paymentDetail: 'Detalle pago',
            hoursToPay: 'Horas por pagar',
            paymentOrderId: 'ID orden de pago {{paymentOrderId}}',
          },
        },
      },
      hourInfoModal: {
        button: 'Entendido',
        present: {
          button: 'Criterios en Hora de entrada',
          title: 'Criterios en Hora de entrada',
          item1:
            'Si Staff marca o modifica la entrada, esta queda registrada <strong>de acuerdo a lo que staff indique.</strong>',
          item2:
            'Si Jobber marca entrada <strong>entre 30 min. antes del inicio de tarea y 5 min.</strong> después del inicio de tarea, se registra como entrada el inicio de la tarea.',
          item3:
            'Si Jobber marca entrada entre <strong>5 min. del inicio de tarea y el final de la tarea,</strong> se registra como entrada la hora de marcación del Jobber.',
        },
        finish: {
          button: 'Criterios en Hora de salida',
          title: 'Criterios en Hora de salida',
          item1:
            'Si Staff marca o modifica la salida, esta queda registrada de acuerdo a lo que staff indique.',
          item2:
            'Si Jobber marca <strong>salida entre el inicio de la tarea y antes de 30 min. del término</strong> de la tarea, se registra como salida lo marcado por el Jobber.',
          item3:
            'Si Jobber marca <strong>salida entre 30 min. antes del término y 30 min. después del término</strong> de la tarea, se registra como salida el término de la tarea.',
          item4:
            'Si Jobber marca <strong>salida entre 30 min. después del término y antes de 3 horas después del término</strong> de la tarea y NO se autorizaron horas extras, se registra el término de la tarea. Si estas se autorizan, se registra lo marcado por el Jobber.',
          item5:
            'Si pasan <strong>3 horas desde el término de la tarea y el jobber no ha marcado salida,</strong> esta se realiza de forma automática, quedando como hora de finalización la hora de término de la tarea.',
        },
      },
    },
    applicationDetails: {
      executedOrders: 'Pedidos ejecutados en la tarea',
      securedAmount: 'Monto asegurado',
      orderNumber: 'N° pedido',
      emptyOrders: 'Sin información de pedidos',
      totalOrders: 'Total pedidos',
      insuredTotal: 'Ganado por asegurado',
      totalToPay: 'Total a pagar',
      pending: 'Pendiente',
    },
  },
};
