import { setApplicantPpes } from '@features/applicants/applicantsSlice';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import server from '@services/api';

import { RequestStatusEnum } from '@utils/enum';
import { clearState as clearStateUtils } from '@utils/functions';
import { PutEpps } from './ts/components.interfaces';
import { ppeRoute } from './ts/routes';

export const applicantsAdapter = createEntityAdapter();

const initialState = applicantsAdapter.getInitialState({
  formActionsComponent: { step: 0 },
  personalProtectionItems: { status: RequestStatusEnum.IDLE },
  error: undefined,
});

export const putEpps = createAsyncThunk(
  'components/putEpps',
  async ({ payload, _id }: PutEpps, { dispatch }) => {
    const response = await server.put(ppeRoute(_id), { ppes: payload });
    if (response?.status === 200) dispatch(setApplicantPpes({ ppes: payload }));
    return response;
  }
);

const componentsSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    setFormActionsComponentStep: (state, action: PayloadAction<number>) => {
      state.formActionsComponent.step = action.payload;
    },
    clearState: clearStateUtils,
  },
  extraReducers: {
    [putEpps.pending.toString()]: (state) => {
      state.personalProtectionItems.status = RequestStatusEnum.PENDING;
    },
    [putEpps.fulfilled.toString()]: (state) => {
      state.personalProtectionItems.status = RequestStatusEnum.SUCCESS;
    },
    [putEpps.rejected.toString()]: (state, action) => {
      state.personalProtectionItems.status = RequestStatusEnum.ERROR;
      state.error = action.error;
    },
  },
});

export const { setFormActionsComponentStep, clearState } = componentsSlice.actions;
export default componentsSlice.reducer;
