import auth from './modules/auth';
import applicants from './modules/applicants';
import staffs from './modules/staffs';
import localities from './modules/localities';
import courses from './modules/courses';
import enterprises from './modules/enterprises';
import tasks from './modules/tasks';
import stores from './modules/stores';
import finance from './modules/finance';
import skills from './modules/skills';
import home from './modules/home';
import blocklist from './modules/blocklist';
import payments from './modules/payments';
import delivery from './modules/delivery';
import dashboard from './modules/dashboard';
import notifications from './modules/notifications';
import requirements from './modules/requirements';

export default {
  ...auth,
  ...applicants,
  ...staffs,
  ...localities,
  ...courses,
  ...enterprises,
  ...tasks,
  ...stores,
  ...finance,
  ...skills,
  ...home,
  ...blocklist,
  ...payments,
  ...delivery,
  ...dashboard,
  ...notifications,
  ...requirements,
  global: {
    actions: 'Acciones',
    add: 'Agregar',
    address: 'Dirección',
    all_female_plural: 'Todas',
    all_female: 'Toda',
    all_plural: 'Todos',
    all: 'Todo',
    allRegionWArticle_plural: 'Todas $t(global.regionWArticle_plural, lowercase)',
    allRegionWArticle: 'Toda $t(global.regionWArticle, lowercase)',
    cancel: 'Cancelar',
    client: 'Cliente',
    company: 'Empresa',
    continue: 'Continuar',
    create: 'Crear',
    currencySymbol: '$',
    delete: 'Eliminar',
    disabled_female: 'Desactivada',
    disabled: 'Desactivado',
    drivingLicence: 'Licencia de conducción',
    edit: 'Editar',
    email: 'Email',
    emailLower: 'email',
    enabled_female: 'Activada',
    enabled: 'Activado',
    exit: 'Salir',
    exitWithoutSaving: 'Salir sin guardar',
    from: 'Desde',
    getBack: 'Volver',
    getBackToHome: 'Regresar al Inicio',
    hoursShort: 'hrs.',
    id: 'ID',
    job: 'Tarea',
    meter_plural: 'metros',
    meter: 'metro',
    minute_plural: 'minutos',
    minute: 'minuto',
    name: 'Nombre',
    namePattern: /^[ÑñA-Za-záéíóúÁÉÍÓÚÜü]+[ ÑñA-Za-záéíóúÁÉÍÓÚÜü-]*$/,
    namePatternWNumbers: /^[ÑñA-Za-záéíóúÁÉÍÓÚÜü´`'&\d]+[ ÑñA-Za-záéíóúÁÉÍÓÚÜü-\d]*$/,
    new: 'Nuevo',
    next: 'Siguiente',
    no: 'No',
    noInfo: 'Sin información',
    other: 'Otro',
    pageNotExist: 'Lo sentimos, la página que visitaste no existe.',
    password: 'Contraseña',
    pending: 'Pendiente',
    phone: 'Teléfono',
    post: 'Correo',
    pswdLower: 'contraseña',
    save: 'Guardar',
    saveChanges: 'Guardar cambios',
    saving: 'Guardando',
    search: 'Buscar',
    sectionNotFound: 'Lo sentimos, la sección esta en construcción',
    select: 'Selecciona',
    surname: 'Apellido',
    to: 'Hasta',
    yes: 'Sí',
    none: 'Ninguno',
    sex: {
      male: 'Masculino',
      female: 'Femenino',
    },
    regexPatterns: {
      urls: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
    },
    error: {
      required: 'Ingresa tu {{input}}',
      requiredAlternative: 'Ingresa el {{inputName}}',
      emailFormat: 'Formato de email no corresponde',
      emailNoExist: 'Email no se encuentra registrado',
      onlyLetters: 'El campo {{input}} solo admite texto',
      onlyNumber: 'El campo {{input}} solo admite números',
      min: 'El campo debe contener mínimo {{input}} caracteres',
      max: 'El campo debe contener máximo {{input}} caracteres',
      message:
        'No pudimos guardar los cambios. Intenta nuevamente o contacta a soporte. Código: {{code}}',
      customMessage: 'No pudimos guardar los cambios. Error: {{msg}}',
    },
    supportEmail: 'ayuda@timejobs.work',
    logout: 'Cerrar sesión',
    settings: {
      options: {
        title: 'Opciones',
        support: 'Soporte Time Jobs',
        help: 'Ayuda',
      },
      auth: {
        closeSession: 'Cerrar Sesión',
      },
      country: {
        phoneLengthWithoutPrefix: 10,
        accountNumberLength: 32,
        accountType: ['cc', 'ca'],
        docs: [''],
        maxLengthIdentityDocument: 12,
      },
    },
    messages: {
      cancelModal: {
        title: 'Advertencia!',
        text: 'Si abandonas ahora el form se eliminaran los cambios y datos que hayas introducido. ¿Seguro/a que quieres salir?',
        okText: 'Salir',
      },
    },
    vehicles: {
      moto: 'Moto',
      auto: 'Auto',
      van: 'Van',
      bicimoto: 'Bicimoto',
      furgon: 'Furgon',
      none: 'Ninguno',
    },
    version: 'Versión {{version}}',
    selected: '{{count}} seleccionado',
    selected_plural: '{{count}} seleccionados',
    roles: {
      admin: 'Admin',
      staff: 'Admin Staff',
      staff_onboarding: 'Onboarding',
      staff_legal: 'Legal',
      staff_finance: 'Finanzas',
      staff_commercial: 'Comercial',
      staff_marketing: 'Marketing',
      staff_senior: 'Staff Senior',
    },
    status: {
      blocked: 'Desactivado',
      unblocked: 'Activado',
    },
    instructions: {
      upload: {
        title: 'Foto de {{input}} (opcional)',
        subtitle: 'Peso máximo de 10mb - Formato: {{input}}',
      },
    },
    enums: {
      contactTypes: {
        finance: 'Finanzas',
        operations: 'Operaciones',
        commercial: 'Comercial',
        marketing: 'Marketing',
      },
      verticals: {
        labour: 'Labour',
        seller: 'Seller',
        platform: 'Platform',
        storage: 'Bodegaje',
        retail: 'Retail',
        supervisor: 'Supervisor',
        last_mille: 'Última Milla',
        customer_service: 'Servicio al Cliente',
        restaurant: 'Restaurante',
        intern: 'Interno/a',
        est: 'Trabajos con contratos',
      },
      operativeSystem: {
        android: 'Android',
        ios: 'IOS',
        any: 'Cualquiera',
        allNoneUpper: 'todos',
      },
      sexEnum: {
        female: 'Mujer',
        male: 'Hombre',
        any: 'Mixto',
      },
      daysWeekEnum: {
        byLetters: {
          monday: 'L',
          tuesday: 'M',
          wednesday: 'M',
          thursday: 'J',
          friday: 'V',
          saturday: 'S',
          sunday: 'D',
        },
      },
      jobStatus: {
        published: {
          title: 'Publicada',
          type: 'green',
        },
        scheduled: {
          title: 'Programada',
          type: 'orange',
        },
        assigned: {
          title: 'Asignada',
          type: 'purple',
        },
        ongoing: {
          title: 'En curso',
          type: 'blue',
        },
        finished: {
          title: 'Finalizada',
          type: 'geekblue',
        },
        cancelled: {
          title: 'Cancelada',
          type: 'red',
        },
      },
      enterpriseType: {
        business_client: 'Cliente comercial',
        timejobs: 'Time Jobs',
      },
      operativeSystemEnum: {
        android: 'Android',
        ios: 'iOS',
        any: 'Cualquiera',
      },
      vehiclesEnum: {
        moto: 'Moto',
        auto: 'Auto',
        van: 'Van',
        bicimoto: 'Bicimoto',
        furgon: 'Furgón',
        none: 'Ninguno',
      },
      epp: {
        anti_cut_gloves: 'Guantes',
        google_glasses: 'Lentes',
        helmet: 'Casco',
        face_mask: 'Mascarilla',
        reflective_vest: 'Chaleco reflector',
        safety_shoes: 'Zapatos de seguridad',
        t_shirt: 'Polera / Polerón Time Jobs',
      },
      jobApplicationStatus: {
        applied: { title: 'Postulado', type: 'green' },
        assigned: { title: 'Asignado', type: 'geekblue' },
        present: { title: 'Presente', type: 'green' },
        finished: { title: 'Finalizado', type: 'geekblue' },
        absent: { title: 'Ausente', type: 'red' },
        cancelled: { title: 'Cancelado', type: 'red' },
        deallocated: { title: 'Desasignado', type: 'white' },
        overlapped: { title: 'Asignado en otra tarea', type: 'yellow' },
        cancelled_pre: { title: 'Cancelado Pre Asignación', type: 'red' },
        cancelled_post: { title: 'Cancelado Post Asignación', type: 'red' },
        assignedInOtherJob: { title: 'Asignado en otra tarea', type: '' },
      },
      assignationType: {
        application: 'Asig. con postulación',
        no_application: 'Asig. sin postulación',
        massive: 'Asig. con plantilla',
        automatic: 'Asig. automática',
      },
      statusAttendance: {
        unconfirmed: { label: 'Asist. sin confirmar', css: 'ant-tag-purple' },
        confirmed: { label: 'Asist. confirmada', css: 'ant-tag-green' },
        cancelled: { label: 'Asist. cancelada', css: 'ant-tag-red' },
      },
      JobApplicationStatusLists: {
        applied: { title: 'Postulados', type: 'green' },
        assigned: { title: 'Asignados', type: 'geekblue' },
        presents: { title: 'Presentes', type: 'green' },
        deallocated: { title: 'Desasignados', type: 'white' },
        finalized: { title: 'Finalizados', type: 'geekblue' },
        absents: { title: 'Ausentes', type: 'red' },
        preAssignmentCancellations: { title: 'Cancelados Pre Asignación', type: 'red' },
        postAssignmentCancellations: { title: 'Cancelados Post Asignación', type: 'red' },
      },
      ApplicationStatusList: {
        applied: { title: 'Postulados', type: 'green' },
        assigned: { title: 'Asignados', type: 'geekblue' },
        present: { title: 'Presentes', type: 'green' },
        deallocated: { title: 'Desasignados', type: 'white' },
        finished: { title: 'Finalizados', type: 'geekblue' },
        absent: { title: 'Ausentes', type: 'red' },
        cancelled: {
          title: 'Cancelada',
          type: 'red',
        },
      },
      PayInsuredStatus: {
        isManual: { title: 'Asegurado manual', type: 'violet' },
        isAutomatic: { title: 'Asegurado automático', type: 'board' },
        denied: { title: 'No dar asegurado', type: 'red' },
        unassigned: { title: 'Asegurado no definido', type: 'gray' },
        unearned: { title: 'No ganó asegurado', type: 'yellow' },
      },
      allowsInsurance: {
        allows: { title: 'Tarea permite asegurado', type: 'geekblue' },
        denies: { title: 'Tarea no permite asegurado', type: 'yellow' },
      },
      jobAttendanceStatus: {
        pending: 'Pendiente',
        confirmed: 'Confirmada',
        late: 'Tardanza',
      },
      languagesEnum: {
        spanish: 'Español',
        any: 'Cualquiera',
      },
      documentType: {
        ce: 'C.E. (Carnet de Extranjería)',
        rut: 'R.U.T. (Registro Único Tributario)',
        ruc: 'R.U.C. (Registro Único de Contribuyentes)',
        dni: 'D.N.I. (Documento Nacional de Identidad)',
        ptp: 'P.T.P. (Permiso Temporal de Permanencia)',
        ppt: 'P.P.T. (Permiso por Protección Temporal)',
        cpp: 'C.P.P. (Carné de Permiso temporal de Permanencia)',
        curp: 'C.U.R.P. (Clave Única de Registro de Población)',
        pasaporte: 'Pasaporte',
        cedula_de_identidad: 'C.I. (Cédula de Identidad)',
        cedula_de_ciudadania: 'C.C. (Cédula de Ciudadanía)',
        cedula_de_extranjeria: 'C.E. (Cédula de Extranjería)',
        pep: 'P.E.P. (Permiso Especial de Permanencia)',
        numero_de_identificacion_tributaria: 'N.I.T. (Número de Identificación Tributaria)',
      },
      documentTypeShort: {
        ce: 'CE',
        rut: 'RUT',
        ruc: 'RUC',
        dni: 'DNI',
        ptp: 'PTP',
        cpp: 'CPP',
        curp: 'CURP',
        pasaporte: 'Pasaporte',
        cedula_de_identidad: 'CI',
        cedula_de_ciudadania: 'CC',
        cedula_de_extranjeria: 'CE',
        pep: 'PEP',
        numero_de_identificacion_tributaria: 'NIT',
      },
      coursesTypes: {
        e_learning: 'E-learning',
        meets: 'Meet',
        on_site: 'Terreno',
      },
      centerCategories: {
        personalData: 'Datos personales',
        bankingData: 'Datos bancarios',
        otherDocuments: 'Documentos',
        records: 'Antecedentes',
        otherData: 'Otros datos',
        frameworkAgreement: 'Acuerdo marco',
        profilePhoto: 'Foto de perfil',
        contract: 'Contrato',
      },
      vehicleDocumentKeyCodeMatchEnum: {
        carDriverLicense: '$t(global.drivingLicence)',
        carOwnershipCard: 'Tarjeta de propiedad',
        carRegistrationCertificate: 'Permiso de circulación',
        carRoadWorthinessTest: 'Revisión técnica CITV',
        carSoat: 'SOAT',
        mopedDriverLicense: '$t(global.drivingLicence)',
        mopedOwnershipCard: '',
        mopedRegistrationCertificate: 'Permiso de circulación',
        mopedSoat: 'SOAT',
        motorcycleDriverLicense: '$t(global.drivingLicence)',
        motorcycleOwnershipCard: 'Tarjeta de propiedad',
        motorcycleRegistrationCertificate: 'Permiso de circulación',
        motorcycleRoadWorthinessTest: 'Revisión técnico-mecánica',
        motorcycleSoat: 'SOAT',
        vanDriverLicense: '$t(global.drivingLicence)',
        vanOwnershipCard: 'Tarjeta de propiedad',
        vanRegistrationCertificate: 'Permiso de circulación',
        vanRoadWorthinessTest: 'Revisión técnica CITV',
        vanSoat: 'SOAT',
        furgonDriverLicense: '$t(global.drivingLicence)',
        furgonRegistrationCertificate: 'Permiso de circulación',
        carInsurance: 'Seguro auto (No SOAP)',
      },
      extraDocumentsMatchEnum: {
        vaccinationCard: 'Carnet de vacunación',
        foodHandlingCertificate: 'Certificado Manipulación de alimentos',
        epsCertificate: 'Certificado de EPS actualizado',
        pensionCertificate: 'Certificado de pensión actualizado',
        healthCardWithFoodHandling: 'Carnet de sanidad con manipulación de alimentos',
      },
      nationalities: {
        Chile: 'Chilena',
        CL: 'Chilena',
        Venezuela: 'Venezolana',
        VE: 'Venezolana',
        Perú: 'Peruana',
        PE: 'Peruana',
        Haití: 'Haitina',
        HT: 'Haitina',
        Colombia: 'Colombiana',
        CO: 'Colombiana',
        Ecuador: 'Ecuatoriana',
        EC: 'Ecuatoriana',
        Argentina: 'Argentina',
        AR: 'Argentina',
      },
      skillStatus: {
        pending_staff: 'Pendiente staff',
        finalized: 'Finalizada',
        in_progress: 'En progreso',
        no_progress: 'Sin progreso',
        none: 'Ninguno',
      },
      paymentType: {
        liquid: 'Líquido',
        gross: 'Bruto',
      },
      moneyOrderStatus: {
        payable: { title: 'Por pagar', type: 'light_blue' },
        paid_out: { title: 'Pagado', type: 'green' },
        settled: { title: 'Liquidado', type: 'pink' },
        reprocess: { title: 'En reproceso', type: 'warning' },
        blocked: { title: 'Pago bloqueado', type: 'red' },
      },
      moneyOrderShopperCreationType: {
        manual: { title: 'Orden manual', type: 'purple' },
        massive: { title: 'Orden masiva', type: 'orange' },
        automatic: { title: 'Orden automática', type: 'green' },
      },
      settlementStatus: {
        payable: { title: 'Por pagar', type: 'green' },
        paid_out: { title: 'Pagado', type: 'light_blue' },
        reprocess: { title: 'En reproceso', type: 'warning' },
      },
      recordPaymentType: {
        regularitation: 'Regularización',
        incentives: 'Incentivos',
        miscalculation: 'Error de cálculo',
        extra_hours: 'Horas extras',
        lack_of_job_creation: 'Falta de creación de tarea',
        marking_error: 'Fallo en la marcación',
        sctr: 'SCTR',
      },
      shopperOrderStatus: {
        ready: { title: 'Lista', type: 'gold' },
        reserved: { title: 'Reservado', type: 'purple' },
        ongoing: { title: 'En curso', type: 'green' },
        arrived: { title: 'Llegó al destino', type: 'magenta' },
        finished: { title: 'Finalizada', type: 'geekblue' },
        proved: { title: 'Foto verificada', type: 'geekblue' },
        cancelled_by_staff: { title: 'Cancelada por staff', type: 'red' },
        cancelled_by_company: { title: 'Cancelada por cliente', type: 'red' },
        no_receiver: { title: 'Sin moradores', type: 'red' },
        client_doesnt_receive: { title: 'Rechazado', type: 'red' },
        wrong_address: { title: 'Dirección errónea', type: 'gold' },
        not_proved: { title: 'Orden desasociada', type: 'gold' },
        partial_delivered: { title: 'Entrega parcial', type: 'gold' },
        delivered: { title: 'Entregada', type: 'green' },
        disassociated: { title: 'Desasociado', type: 'red' },
        undefined: { title: 'No definido', type: 'gold' },
        failed_creation: { title: 'Creado con errores', type: 'red' },
        difference_in_kms: { title: 'Diferencia en Km', type: 'geekblue' },
      },
      shopperOrderTypes: {
        shopper: 'Shopper',
        driver: 'Driver',
        picker: 'Picker',
      },
      shopperOrderExtraTypes: {
        pickup: 'Pickup',
      },
      pendingDocuments: {
        records: 'Antecedentes',
        id_card_front: 'Cédula de identidad frontal',
        id_card_reverse: 'Cédula de identidad reverso',
        registration_certificate: 'Permiso de circulación',
        driver_license: 'Licencia de conducir',
        visa_application: 'Trámite de visa',
      },
      pendingDocumentsStatus: {
        objected: 'Ha sido objetado',
        not_objected: 'No ha sido objetado',
      },
      onSitesStatus: {
        scheduled: 'Agendado',
        cancelled: 'Cancelado',
        absent: 'Ausente',
        present: 'Presente',
        finished: 'Finalizado',
      },
    },
    tablePagination: ['Página ', ' de ', 'Mostrando ', ' ─ ', ' de ', ' registros'],
    inputs: {
      documentNumber: {
        title: 'Número de documento',
        lower: '$t(global.inputs.documentNumber.title, lowercase)',
        message: 'Ingresa un $t(global.inputs.documentNumber.lower), válido',
      },
      docSel: {
        title: 'Tipo de documento',
        lower: '$t(global.inputs.docSel.title, lowercase)',
        required: 'Selecciona el tipo de documento',
      },
    },
    currencies: {
      USD: 'Dolar',
      CLP: 'Peso Chileno',
      COP: 'Peso Colombiano',
      PEN: 'Nuevo sol',
      MXN: 'Peso Méxicano',
      UF: 'Unidad de fomento',
    },
    pagination: {
      previous: 'Anterior',
      next: 'Siguiente',
      pageSize: '{{number}} / página',
    },
  },
};
