import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import {
  getDeletelistRoute,
  restoreJobberRoute,
  deleteListReportRoute,
} from '@features/deleteList/ts/routes';

import server from '@services/api';
import { getDeletelistPayload } from '@features/deleteList/ts/deleteList.interface';
import { RequestStatusEnum } from '../../utils/enum';

export const deleteListAdapter = createEntityAdapter();

const initialState = deleteListAdapter.getInitialState({
  deletelist: { docs: [] as [], loading: false },
  restore: { status: undefined, loading: false },
  error: null,
  deleteListReport: RequestStatusEnum.IDLE,
});

export const getDeletelist = createAsyncThunk(
  'deletelist/getDeletelist',
  async (payload: getDeletelistPayload) => {
    const response = await server.get(getDeletelistRoute(), {
      params: { ...payload },
    });
    return response.data.data;
  }
);

export const getDeleteListReport = createAsyncThunk(
  'deletelist/getDeleteListReport',
  async (params: getDeletelistPayload) => {
    const response = await server({
      method: 'get',
      url: deleteListReportRoute(),
      params,
    });
    return response;
  }
);

export const restoreJobber = createAsyncThunk('deletelist/restoreJobber', async (_id: string) => {
  const response = await server.put(restoreJobberRoute(_id));
  return response;
});

const deletelistSlice = createSlice({
  name: 'deletelist',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getDeletelist.pending)]: (state) => {
      state.deletelist.loading = true;
    },
    [String(getDeletelist.fulfilled)]: (state, action) => {
      state.deletelist = { loading: false, ...action.payload };
    },
    [String(getDeletelist.rejected)]: (state, action) => {
      state.deletelist.loading = false;
      state.error = action.error;
    },

    [String(restoreJobber.pending)]: (state) => {
      state.restore.loading = true;
    },
    [String(restoreJobber.fulfilled)]: (state, status) => {
      state.restore = { loading: false, status: status };
    },
    [String(restoreJobber.rejected)]: (state, action) => {
      state.restore.loading = false;
      state.error = action.error;
    },

    [String(getDeleteListReport.pending)]: (state) => {
      state.deleteListReport = RequestStatusEnum.PENDING;
    },
    [String(getDeleteListReport.fulfilled)]: (state) => {
      state.deleteListReport = RequestStatusEnum.SUCCESS;
    },
    [String(getDeleteListReport.rejected)]: (state, action) => {
      state.deleteListReport = RequestStatusEnum.ERROR;
      state.error = action.error;
    },
  },
});

export default deletelistSlice.reducer;
