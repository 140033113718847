import auth from './modules/auth';
import applicants from './modules/applicants';
import localities from './modules/localities';
import skills from '../es/modules/skills';

export default {
  ...auth,
  ...applicants,
  ...localities,
  ...skills,
  global: {
    region: 'Estado',
    region_plural: 'Estados',
    regionWArticle: 'El estado',
    regionWArticle_plural: 'Los estados',
    allRegionWArticle: 'Todo $t(global.regionWArticle, lowercase)',
    allRegionWArticle_plural: 'Todos $t(global.regionWArticle_plural, lowercase)',
    commune: 'Municipio',
    communeWArticle: 'El municipio',
    commune_plural: 'Municipios',
    communeWArticle_plural: 'Los municipios',

    inputs: {
      documentNumber: {
        title: 'CURP',
        lower: 'curp',
        pattern: /^([a-zA-Z]|\d){18}$/,
        message: { curp: 'Debes ingresar 18 dígitos o letras' },
        maximumDocumentLength: 18,
      },
      phoneNumber: {
        pattern: /^(\d){10}$/,
        message: 'Ingresa un teléfono de 10 dígitos',
      },
    },
  },
  settings: {
    country: {
      phonePrefix: '+55',
      name: 'México',
      phoneLengthWithoutPrefix: 10,
    },
  },
};
