import * as Sentry from '@sentry/nextjs';

/**
 * * Types of sentry errors
 * Sentry.Severity.Critical;
 * Sentry.Severity.Debug;
 * Sentry.Severity.Fatal;
 * Sentry.Severity.Info;
 * Sentry.Severity.Log;
 * Sentry.Severity.Warning;
 */

// * Gets the user's token
const getLocalStorageUser = async () => {
  const aux = await localStorage.getItem('user');
  return aux;
};

/**
 * Code: JD-UTILS-SE-0004
 * Description: send a log to sentry
 * @param {String} category ->
 * @param {Json} data ->
 * @param {String} type -> type of error
 * @param {String} errorJS -> This represents a general error that you want to send (catch)
 * @param {String} tjError -> This locates the function of where it was propitiated
 * @param {Boolean} clear -> This clears the breadcrumb
 */
export const sendLog = async (
  category: string,
  data: JSON,
  type: string,
  errorJS = null,
  tjError = 'FX-UTILS-SE-0004',
  clear = false
) => {
  try {
    const user = (await getLocalStorageUser()) ?? null;

    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && errorJS) {
      Sentry.addBreadcrumb({
        category,
        data,
        level: Sentry.Severity[type],
      });

      Sentry.captureException(errorJS, (scope) => {
        if (clear) scope.clear();
        if (user) {
          const { email, id } = JSON.parse(user);
          scope.setUser({ id, email, other: JSON.parse(user) });
        }

        scope.setTags({
          tjError,
        });

        return scope;
      });
    }
  } catch (error) {
    Sentry.captureException(error, (scope) => {
      scope.setTags({
        tjError: 'FX-UTILS-SE-0004',
      });
      return scope;
    });
  }
};

export default { sendLog };
