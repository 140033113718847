import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import server from '@services/api';
import { RequestStatusEnum } from '@utils/enum';

import {
  enterpriseVariablesRoute,
  enterpriseSchemesRoute,
  enterprisePostSchemesRoute,
  enterprisePutSchemesRoute,
  enterpriseDeleteShemeRoute,
  entriesSchemeRoute,
  entrySchemeUniqueRoute,
} from './ts/routes';

export const schemesAdapter = createEntityAdapter();

export const initialState = schemesAdapter.getInitialState({
  variables: { data: [] as [], loading: false },
  schemes: { docs: [], loading: false },
  oneScheme: {} as {},
  postDataSchemes: { loading: false as any, data: [] as any },
  deleteSchemeData: { data: [] as any },
  notification: false as any,
  loading: 'idle',
  error: null,
  valor: 0,
  entriesSchemes: { loading: false, docs: [], totalDocs: 0 },
  stateCreateEntryScheme: {
    loading: false,
    success: false,
    error: false,
    msgVisible: false,
  },
  stateUpdateEntryScheme: {
    loading: false,
    success: false,
    error: false,
    msgVisible: false,
  },
  stateDeleteEntryScheme: {
    loading: false,
    success: false,
    error: false,
    msgVisible: false,
  },
});

// obtener variables
export const getVariables = createAsyncThunk(
  'enterprises/getVariables',
  async (params: any, { getState }: any) => {
    const { loading } = getState().schemes;
    if (loading !== 'pending') {
      return;
    }

    const response = await server({
      method: 'get',
      url: enterpriseVariablesRoute(),
      params,
    });

    return response?.data;
  }
);

// obtener esquemas de pago
export const getSchemes = createAsyncThunk(
  'enterprises/getSchemes',
  async (params: any, { getState }: any) => {
    const { loading } = getState().schemes;
    if (loading !== 'pending') {
      return;
    }

    const response = await server({
      method: 'get',
      url: enterpriseSchemesRoute(),
      params,
    });

    return response?.data;
  }
);

// crear nuevo esquema de pago
export const postSchemes = createAsyncThunk(
  'enterprises/postScheme',
  async (data: any, { getState }: any) => {
    const { loading } = getState().schemes;
    if (loading !== 'pending') {
      return;
    }

    const response: any = await server({
      method: 'post',
      url: enterprisePostSchemesRoute(),
      data,
    });

    return response?.data;
  }
);

// editar esquema de pago
export const putSchemes = createAsyncThunk(
  'enterprises/updateScheme',
  async (data: any, { getState }: any) => {
    const { loading } = getState().schemes;
    if (loading !== 'pending') {
      return;
    }

    const response: any = await server({
      method: 'put',
      url: enterprisePutSchemesRoute(data._id),
      data,
    });

    return response?.data;
  }
);

// eliminar esquema de pago
export const deleteScheme = createAsyncThunk(
  'enterprises/getDeleteScheme',
  async (id: any, { getState }: any) => {
    const { loading } = getState().schemes;
    if (loading !== 'pending') {
      return;
    }

    const response: any = await server({
      method: 'delete',
      url: enterpriseDeleteShemeRoute(id),
    });

    return response?.data;
  }
);

/* ENTRIES SCHEMES */

export const getEntriesSchemes = createAsyncThunk(
  'delivery/getEntriesSchemes',
  async (params: object, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'get',
        url: entriesSchemeRoute(),
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createEntryScheme = createAsyncThunk(
  'delivery/createEntryScheme',
  async (payload: object, { rejectWithValue }: any) => {
    try {
      const response = await server.post(entriesSchemeRoute(), payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateEntryScheme = createAsyncThunk(
  'delivery/updateEntryScheme',
  async (payload: { data: object; code: string }, { rejectWithValue }: any) => {
    try {
      const response = await server.put(entrySchemeUniqueRoute(payload.code), payload.data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteEntryScheme = createAsyncThunk(
  'delivery/deleteEntryScheme',
  async (code: string, { rejectWithValue }: any) => {
    try {
      const response = await server.delete(entrySchemeUniqueRoute(code));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const validateDuplicateSchemas = (allData: Array<Record<string, unknown>>) => {
  const filtered = allData.reduce((acum, next) => {
    const finder = acum.find((item) => item._id === next?._id);
    if (finder) return acum;
    return [...acum, next];
  }, []);

  return filtered;
};

const schemesSlice = createSlice({
  name: 'schemes',
  initialState,
  reducers: {
    getEditSchemeData: (state, action) => {
      state.oneScheme = action.payload;
    },
    clearSchemes: (state) => {
      state.schemes = { docs: [], loading: false };
    },
    clearNotification: (state) => {
      state.notification = false;
    },
    clearPostDataSchemes: (state) => {
      state.postDataSchemes = { loading: false, data: [] };
    },
    resetStateCreateEntryScheme: (state) => {
      state.stateCreateEntryScheme.loading = false;
      state.stateCreateEntryScheme.success = false;
      state.stateCreateEntryScheme.error = false;
      state.stateCreateEntryScheme.msgVisible = false;
    },
    resetStateUpdateEntryScheme: (state) => {
      state.stateUpdateEntryScheme.loading = false;
      state.stateUpdateEntryScheme.success = false;
      state.stateUpdateEntryScheme.error = false;
      state.stateUpdateEntryScheme.msgVisible = false;
    },
    resetStateDeleteEntryScheme: (state) => {
      state.stateDeleteEntryScheme.loading = false;
      state.stateDeleteEntryScheme.success = false;
      state.stateDeleteEntryScheme.error = false;
      state.stateDeleteEntryScheme.msgVisible = false;
    },
    setValuesToScheme: (state, action) => {
      const { payments, entries } = action.payload;

      const auxPayments = validateDuplicateSchemas([...state.schemes.docs, payments]);
      const auxEntries = validateDuplicateSchemas([...state.entriesSchemes.docs, entries]);

      state.schemes.docs = auxPayments;
      state.entriesSchemes.docs = auxEntries;
    },
  },
  extraReducers: {
    // obtener tipos de tareas
    [String(getVariables.pending)]: (state) => {
      state.variables.loading = true;

      state.loading = RequestStatusEnum.PENDING;
    },
    [String(getVariables.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.variables = { loading: false, ...action.payload };
    },
    [String(getVariables.rejected)]: (state, action) => {
      state.variables.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
    // obtener esquema de pago
    [String(getSchemes.pending)]: (state, action) => {
      state.schemes.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        if (!action?.meta?.arg?.preserveInitialState) state.entriesSchemes.docs = [];
      }
    },
    [String(getSchemes.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;

        const olds = state.schemes.docs;
        const news = action.payload?.data?.docs || [];
        const filtered = validateDuplicateSchemas([...news, ...olds]);

        state.schemes = { loading: false, ...action.payload?.data, docs: filtered };
      }
    },
    [String(getSchemes.rejected)]: (state, action) => {
      state.schemes.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
    // crear nuevo esquema
    [String(postSchemes.pending)]: (state) => {
      state.postDataSchemes.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(postSchemes.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.postDataSchemes = { loading: false, ...action.payload };
        state.notification = { alert: 'createScheme' };
      }
    },
    [String(postSchemes.rejected)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.postDataSchemes = { loading: false, data: '400' };
        state.error = action.error;
      }
    },

    // actualizar  esquema
    [String(putSchemes.pending)]: (state) => {
      state.postDataSchemes.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(putSchemes.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.postDataSchemes = { loading: false, ...action.payload };
        state.notification = { alert: 'editScheme' };
      }
    },
    [String(putSchemes.rejected)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.postDataSchemes = { loading: false, data: '400' };
        state.error = action.error;
      }
    },
    // borrar esquema de pago
    [String(deleteScheme.pending)]: (state) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(deleteScheme.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteSchemeData = { ...action.payload };
        state.notification = { alert: 'deleteScheme' };
      }
    },
    [String(deleteScheme.rejected)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteSchemeData = { data: '400' };
        state.error = action.error;
      }
    },

    // get ENTRIES SCHEMES

    [String(getEntriesSchemes.pending)]: (state, action) => {
      state.entriesSchemes.loading = true;
      state.entriesSchemes.totalDocs = 0;
      if (!action?.meta?.arg?.preserveInitialState) state.entriesSchemes.docs = [];
    },
    [String(getEntriesSchemes.fulfilled)]: (state, action) => {
      const olds = [...state.entriesSchemes.docs];
      const news = action.payload?.data?.data.docs || [];
      const filtered = validateDuplicateSchemas([...olds, ...news]);

      state.entriesSchemes = { loading: false, ...action.payload?.data?.data, docs: filtered };
    },
    [String(getEntriesSchemes.rejected)]: (state, action) => {
      state.entriesSchemes.loading = false;
    },

    // CREATE

    [String(createEntryScheme.pending)]: (state, action) => {
      state.stateCreateEntryScheme.loading = true;
      state.stateCreateEntryScheme.success = false;
      state.stateCreateEntryScheme.error = false;
      state.stateCreateEntryScheme.msgVisible = false;
    },
    [String(createEntryScheme.fulfilled)]: (state, action) => {
      state.stateCreateEntryScheme.loading = false;
      state.stateCreateEntryScheme.success = true;
      state.stateCreateEntryScheme.error = false;
      state.stateCreateEntryScheme.msgVisible = true;
    },
    [String(createEntryScheme.rejected)]: (state, action) => {
      state.stateCreateEntryScheme.loading = false;
      state.stateCreateEntryScheme.success = false;
      state.stateCreateEntryScheme.error = true;
      state.stateCreateEntryScheme.msgVisible = true;
    },

    // UPDATE

    [String(updateEntryScheme.pending)]: (state, action) => {
      state.stateUpdateEntryScheme.loading = true;
      state.stateUpdateEntryScheme.success = false;
      state.stateUpdateEntryScheme.error = false;
      state.stateUpdateEntryScheme.msgVisible = false;
    },
    [String(updateEntryScheme.fulfilled)]: (state, action) => {
      state.stateUpdateEntryScheme.loading = false;
      state.stateUpdateEntryScheme.success = true;
      state.stateUpdateEntryScheme.error = false;
      state.stateUpdateEntryScheme.msgVisible = true;
    },
    [String(updateEntryScheme.rejected)]: (state, action) => {
      state.stateUpdateEntryScheme.loading = false;
      state.stateUpdateEntryScheme.success = false;
      state.stateUpdateEntryScheme.error = true;
      state.stateUpdateEntryScheme.msgVisible = true;
    },

    // UPDATE

    [String(deleteEntryScheme.pending)]: (state, action) => {
      state.stateDeleteEntryScheme.loading = true;
      state.stateDeleteEntryScheme.success = false;
      state.stateDeleteEntryScheme.error = false;
      state.stateDeleteEntryScheme.msgVisible = false;
    },
    [String(deleteEntryScheme.fulfilled)]: (state, action) => {
      state.stateDeleteEntryScheme.loading = false;
      state.stateDeleteEntryScheme.success = true;
      state.stateDeleteEntryScheme.error = false;
      state.stateDeleteEntryScheme.msgVisible = true;
    },
    [String(deleteEntryScheme.rejected)]: (state, action) => {
      state.stateDeleteEntryScheme.loading = false;
      state.stateDeleteEntryScheme.success = false;
      state.stateDeleteEntryScheme.error = true;
      state.stateDeleteEntryScheme.msgVisible = true;
    },
  },
});

export const {
  clearSchemes,
  clearNotification,
  clearPostDataSchemes,
  getEditSchemeData,
  resetStateCreateEntryScheme,
  resetStateUpdateEntryScheme,
  resetStateDeleteEntryScheme,
  setValuesToScheme,
} = schemesSlice.actions;

export default schemesSlice.reducer;
