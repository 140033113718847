import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestStatusEnum } from '@utils/enum';
import { GetInfo, IResponseData } from './ts/requirements.interfaces';
import server from '@services/api';
import i18n from 'i18next';
import {
  approvedRequirementRoute,
  fetchRequirementDetailRoute,
  fetchRequirementsListRoute,
  rejectedRequirementRoute,
} from './ts/routes';

export const requirementsAdapter = createEntityAdapter();

export const inicialState: any = requirementsAdapter.getInitialState({
  requirementInfo: {
    loading: RequestStatusEnum.IDLE,
    currentRequestId: null,
    data: null,
    error: null,
  },
  requirementsList: {
    loading: RequestStatusEnum.IDLE,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
    error: false,
    currentRequestId: null,
  },
  rejectedRequirement: {
    loading: RequestStatusEnum.IDLE,
    error: null,
  },
  approvedRequirement: {
    loading: RequestStatusEnum.IDLE,
    updatedRequirement: null,
    alertMessages: {
      message: '',
      type: null,
    },
    error: null,
  },
});

export const getRequirementsList = createAsyncThunk(
  'delivery/getRequirementsList',
  async (params: any, { getState }: any) => {
    const { requirementsList } = getState().requirements;

    if (requirementsList?.loading !== RequestStatusEnum.PENDING) return;
    const response: IResponseData = await server({
      method: 'get',
      url: fetchRequirementsListRoute(),
      params,
    });

    return response.data;
  }
);

export const getRequirementInfo = createAsyncThunk(
  'requirement/getInfo',
  async (id: string, { getState, requestId }: any) => {
    const { loading, currentRequestId } = getState().requirements.requirementInfo;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: GetInfo = await server.get(fetchRequirementDetailRoute(id));
    return response?.data;
  }
);

export const updateRejectedRequirement = createAsyncThunk(
  'requirements/updateRejectedRequirement',
  async (body: { _id: string; rejectedReason: string }, { rejectWithValue }) => {
    try {
      const response = await server.put(rejectedRequirementRoute(body._id), body);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue('Failed with status code: ' + response.status);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateApprovedRequirement = createAsyncThunk(
  'requirements/updateApprovedRequirement',
  async (body: { _id: string }, { rejectWithValue }) => {
    try {
      const response = await server.put(approvedRequirementRoute(body._id));
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue('Failed with status code: ' + response.status);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const requirementsSlice = createSlice({
  name: 'requirements',
  initialState: inicialState,
  reducers: {
    clearAlertMsg: (state) => {
      state.approvedRequirement.alertMessages = { type: '', message: '' };
    },
  },
  extraReducers: {
    [String(getRequirementsList.pending)]: (state, action) => {
      if (state.requirementsList.loading === RequestStatusEnum.IDLE) {
        state.requirementsList.loading = RequestStatusEnum.PENDING;
        state.requirementsList.error = false;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getRequirementsList.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.requirementsList.loading === RequestStatusEnum.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.requirementsList = {
          loading: RequestStatusEnum.IDLE,
          ...action.payload.data,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(getRequirementsList.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.requirementsList.loading === RequestStatusEnum.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.requirementsList = {
          loading: RequestStatusEnum.IDLE,
          error: action.error,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(getRequirementInfo.pending)]: (state, action) => {
      if (state.requirementInfo.loading === RequestStatusEnum.IDLE) {
        state.requirementInfo.loading = RequestStatusEnum.PENDING;
        state.requirementInfo.error = false;
        state.requirementInfo.currentRequestId = action.meta.requestId;
      }
    },
    [String(getRequirementInfo.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.requirementInfo.loading === RequestStatusEnum.PENDING &&
        state.requirementInfo.currentRequestId === requestId
      ) {
        state.requirementInfo = {
          loading: RequestStatusEnum.IDLE,
          data: { ...action.payload.data },
        };
        state.currentRequestId = undefined;
      }
    },
    [String(getRequirementInfo.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.requirementInfo.loading === RequestStatusEnum.PENDING &&
        state.requirementInfo.currentRequestId === requestId
      ) {
        state.requirementInfo = {
          loading: RequestStatusEnum.IDLE,
          error: action.error,
        };
        state.requirementInfo.currentRequestId = undefined;
      }
    },

    [String(updateRejectedRequirement.pending)]: (state) => {
      state.rejectedRequirement.loading = RequestStatusEnum.PENDING;
    },
    [String(updateRejectedRequirement.fulfilled)]: (state) => {
      state.rejectedRequirement.loading = RequestStatusEnum.IDLE;
    },
    [String(updateRejectedRequirement.rejected)]: (state, action) => {
      state.rejectedRequirement.loading = RequestStatusEnum.IDLE;
      state.rejectedRequirement.error = action.error;
    },

    [String(updateApprovedRequirement.pending)]: (state) => {
      state.approvedRequirement = {
        loading: RequestStatusEnum.PENDING,
        alertMessages: {
          updatedRequirement: null,
          type: null,
        },
      };
    },
    [String(updateApprovedRequirement.fulfilled)]: (state, action) => {
      state.approvedRequirement = {
        loading: RequestStatusEnum.IDLE,
        updatedRequirement: action.payload.data,
        alertMessages: {
          message: i18n.t('requirements.alerts.approved.success', {
            code: action.payload.data?.jobCode,
          }),
          type: RequestStatusEnum.SUCCESS,
        },
      };
    },
    [String(updateApprovedRequirement.rejected)]: (state, action) => {
      state.approvedRequirement = {
        loading: RequestStatusEnum.IDLE,
        updatedRequirement: null,
        alertMessages: {
          message: i18n.t('global.error.message', {
            code: action.payload.error.code,
          }),
          type: RequestStatusEnum.ERROR,
        },
        error: action.payload.error,
      };
    },
  },
});

export const { clearAlertMsg } = requirementsSlice.actions;
export default requirementsSlice.reducer;
