import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import server from '@services/api';
import { RequestStatusEnum } from '../../utils/enum';
import {
  changeDocumentsDateRequstPayloadType,
  updateJobberRequestStatusPayloadType,
} from './ts/jobberRequests.interfaces';
import {
  changeDocumentsDateRequestsRoute,
  getJobberRequestsRoute,
  updateJobberRequestStatusRoute,
} from './ts/jobberRequests.routes';

const jobberRequestsAdapter = createEntityAdapter();

const initialState = jobberRequestsAdapter.getInitialState({
  loading: RequestStatusEnum.IDLE,
  jobberRequests: {
    loading: RequestStatusEnum.IDLE,
    docs: [],
    totalDocs: 0,
    error: null,
  },
  closeRequest: {
    loading: RequestStatusEnum.IDLE,
    visibleMessage: false,
    type: 'success',
    jobber: '',
  },
  updateDate: {
    loading: RequestStatusEnum.IDLE,
    type: null,
  },
});

export const getJobberRequests = createAsyncThunk(
  'jobberRequests/getJobberRequests',
  async (params: Record<string, unknown>, { getState }: any) => {
    const { jobberRequests } = getState().jobberRequests;
    if (jobberRequests?.loading !== RequestStatusEnum.PENDING) return;
    const response = await server({ method: 'get', url: getJobberRequestsRoute(), params });
    return response.data;
  }
);

export const updateJobberRequestStatus = createAsyncThunk(
  'jobberRequests/updateJobberRequestStatus',
  async (payload: updateJobberRequestStatusPayloadType, { getState }: any) => {
    const { jobberRequests } = getState().jobberRequests;
    if (jobberRequests?.loading === RequestStatusEnum.PENDING) return;
    const response = await server({
      method: 'put',
      url: updateJobberRequestStatusRoute(payload.code),
      data: payload.body,
    });
    return response.data;
  }
);

export const changeDocumentsDateRequest = createAsyncThunk(
  'jobberRequests/changeDocumentsDateRequest',
  async (payload: changeDocumentsDateRequstPayloadType) => {
    const response = await server({
      method: 'put',
      url: changeDocumentsDateRequestsRoute(payload.jobberId, payload.section),
      data: payload.body,
    });
    return response.data;
  }
);

const jobberRequestsSlice = createSlice({
  name: 'jobberRequests',
  initialState,
  reducers: {
    restartCloseRequest: (state) => {
      state.closeRequest.visibleMessage = false;
      state.closeRequest.type = null;
      state.closeRequest.jobber = null;
    },
  },
  extraReducers: {
    [String(getJobberRequests.pending)]: (state) => {
      state.loading = RequestStatusEnum.PENDING;
      state.jobberRequests.loading = RequestStatusEnum.PENDING;
    },
    [String(getJobberRequests.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.jobberRequests = { loading: RequestStatusEnum.IDLE, ...action.payload.data };
    },
    [String(getJobberRequests.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.jobberRequests.loading = RequestStatusEnum.IDLE;
      state.jobberRequests.error = action.error;
    },

    // UPDATE JOBBER REQUEST STATUS

    [String(updateJobberRequestStatus.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.closeRequest.loading = RequestStatusEnum.PENDING;
      state.closeRequest.type = null;
      state.closeRequest.visibleMessage = false;
      state.closeRequest.jobber = action?.meta?.arg?.jobber || '';
    },
    [String(updateJobberRequestStatus.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.closeRequest.loading = RequestStatusEnum.IDLE;
      state.closeRequest.type = 'success';
      state.closeRequest.visibleMessage = true;
    },
    [String(updateJobberRequestStatus.rejected)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.closeRequest.loading = RequestStatusEnum.IDLE;
      state.closeRequest.type = 'error';
      state.closeRequest.visibleMessage = true;
    },

    // UPDATE DATE REQUEST STATUS

    [String(changeDocumentsDateRequest.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.updateDate.loading = RequestStatusEnum.PENDING;
      state.updateDate.type = null;
    },
    [String(changeDocumentsDateRequest.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.updateDate.loading = RequestStatusEnum.IDLE;
      state.updateDate.type = 'success';
    },
    [String(changeDocumentsDateRequest.rejected)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.updateDate.loading = RequestStatusEnum.IDLE;
      state.updateDate.type = 'error';
    },
  },
});

export const { restartCloseRequest } = jobberRequestsSlice.actions;

export default jobberRequestsSlice.reducer;
