import imageCompression from 'browser-image-compression';
import i18n from 'i18next';
import moment from 'moment';

import { message } from 'antd';

import {
  cleanObjects,
  compareMinutes,
  getExtension,
  mergeDateTime,
  uploadFiles,
} from '@utils/functions';
import {
  LanguagesEnum,
  PublicationDatesEnum,
  PuntualityEnum,
  TemplateViewStatusEnum,
  VariableKindEnum,
} from '@utils/enum';
import { lunchHoursLimit, lunchHoursLimitByCountry } from '@utils/consts';
import { JobApplicationStatusEnum } from './jobs.enum';
import {
  bulkAbsentJobApplicationRoute,
  bulkAssignJobApplicationRoute,
  bulkCancelJobApplicationRoute,
  bulkFinishedJobApplicationsRoute,
  bulkPresentJobApplicationRoute,
  updateJobApplicationRoute,
} from './routes';
import AccessControlApplicationActionsConfig from '@utils/applicationActionConfig';
import dayjs from 'dayjs';

const PUBLISH_TYPE_BACKEND = {
  now: 'now',
  '1|day': 'in_one_day',
  '2|days': 'in_two_days',
  '1|week': 'in_one_week',
};

export const disabledHours = (hour = null, between = false, maxDifference = 1) => {
  const hours = [];
  for (let index = 0; index < hour + 1; index++) {
    hours.push(index);
  }
  if (between) {
    for (let index = hour + 2 + maxDifference; index < 24; index++) {
      hours.push(index);
    }
  }
  return hours;
};

export const fillDisabledHours = (hour = null, after = true, includeCurrentHour = false) => {
  const hours = [];
  if (after) {
    for (let index = !includeCurrentHour ? hour : hour + 1; index < 24; index++) {
      hours.push(index);
    }
  } else {
    for (let index = 0; index < (!includeCurrentHour ? hour + 1 : hour); index++) {
      hours.push(index);
    }
  }
  return hours;
};

export const fillDisabledMinutes = (minute = null, after = true, includeCurrentMinutes = false) => {
  const minutes = [];
  if (after) {
    for (let index = !includeCurrentMinutes ? minute : minute + 1; index < 60; index++) {
      minutes.push(index);
    }
  } else {
    for (let index = 0; index < (!includeCurrentMinutes ? minute + 1 : minute); index++) {
      minutes.push(index);
    }
  }
  return minutes;
};

export const disabledEndAt = (date, startAt) => {
  let dateEnd = dayjs(date);
  const dateStart = dayjs(startAt);
  if (!date) return undefined;

  if (dateStart.isAfter(dateEnd)) {
    dateEnd = dateEnd.add(1, 'day');
  }

  if (date.isSame(startAt, 'hour')) {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
      return dateEnd.diff(dateStart, 'minute') < 9;
    } else {
      return dateEnd.diff(dateStart, 'minute') >= 0;
    }
  }

  if (dateEnd.diff(dateStart, 'minute') > 0)
    return dateEnd.diff(dateStart, 'minute') < 59 || dateEnd.diff(dateStart, 'minute') > 1020;
};

export const disabledLunchStartAt = (date, isUntilNextDay, startAt, endAt) => {
  if (!date) return undefined;
  if (isUntilNextDay) {
    if (compareMinutes(date, startAt) < 0) return compareMinutes(endAt, date) < lunchHoursLimit.MIN;
    else return compareMinutes(date, startAt) < lunchHoursLimit.MAX;
  } else {
    return (
      compareMinutes(date, startAt) < lunchHoursLimit.MAX ||
      compareMinutes(endAt, date) < lunchHoursLimit.MIN
    );
  }
};

export const disabledLunchEndtAt = (date, isUntilNextDay, lunchStartAt, endAt, country) => {
  if (!date || !lunchStartAt) return undefined;
  const today = moment().date();
  const limits = lunchHoursLimitByCountry[country] || lunchHoursLimit;
  return (
    compareMinutes(date, lunchStartAt.date(today)) < limits.MIN ||
    compareMinutes(date, lunchStartAt.date(today)) > limits.MAX ||
    (!isUntilNextDay && compareMinutes(endAt, date) < 0)
  );
};

export const isDateOffsetBeforeNow = (date, offset) => {
  if (!date || !offset) return false;
  const eleSplit: Array<string> = offset.split('|');
  const subTractDate = date.clone().subtract({ [eleSplit[1]]: eleSplit[0] });
  return subTractDate < moment();
};

export const saveFile = async (payload, onComplete) => {
  const { file, onSuccess, onError } = payload;
  const imageFile = file;

  const options = {
    maxSizeMB: 10,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const ext = getExtension(file.name);
    let finalFile = file;
    if (ext !== 'pdf') finalFile = await imageCompression(imageFile, options);
    const response: any = await uploadFiles({
      file: finalFile,
      fileType: ext,
      urlToSave: undefined,
      saveKey: false,
    });
    if (response.status === 200) {
      onComplete(response.data);
      onSuccess();
    } else {
      message.error(i18n.t(`applicants.review.upload.formatError`));
      onError();
    }
  } catch (error) {
    message.error(i18n.t(`applicants.review.upload.genericError`));
    onError();
  }
};

const dateSetting = (publishedDateType, date, publishedTimeAll) => {
  if (publishedDateType !== PublicationDatesEnum.NOW && date && publishedTimeAll) {
    const eleSplit: Array<string> = publishedDateType.split('|');
    const cloneExecutionDate = moment(
      date.format('DD-MM-YYYY') + ' ' + publishedTimeAll.format('HH:mm'),
      'DD-MM-YYYY HH:mm'
    );

    return cloneExecutionDate.subtract({
      [eleSplit[1]]: eleSplit[0],
    });
  }
};

// // this function tries to sum on each input, this needs many if's because bulk and individual creation have different flows
export const calculateTotalValues = (formElements: Record<string, any>) => {
  const paymentVariables = [
    ...(formElements.paymentSchemaComplete?.variables || []),
    ...(formElements.paymentSchemaComplete?.staggeredVariables || []),
  ];

  let totalValue = 0;

  for (const payment of paymentVariables) {
    if (payment.kind === VariableKindEnum.STAGGERED) {
      totalValue = totalValue + payment.staggeredValues.reduce((acc, p) => acc + (p.value || 0), 0);
    } else if (Array.isArray(formElements[`paymentVariable_${payment.code}`])) {
      const steps = formElements[`paymentVariable_${payment.code}`];
      totalValue = totalValue + steps.reduce((acc, p) => acc + (p.value || 0), 0);
    } else {
      totalValue =
        totalValue +
        (payment.value || Number(formElements[`paymentVariable_${payment.code}`]) || 0);
    }
  }

  return totalValue;
};

const combineSimpleAndStaggeredVars = (formElements: Record<string, any>) => {
  const combined = [];

  formElements.paymentSchemaComplete?.variables?.forEach((obj) => {
    combined.push({
      _id: obj._id,
      code: obj.code,
      name: obj.name,
      value: Number(formElements[`paymentVariable_${obj.code}`]),
    });
  });

  formElements.paymentSchemaComplete?.staggeredVariables?.forEach((obj) => {
    const steps = formElements[`paymentVariable_${obj.code}`];
    const staggeredValues = steps.length
      ? steps.map((item) => ({ ...item, max: item.max || 'Infinity' }))
      : [];
    combined.push({
      _id: obj._id,
      code: obj.code,
      name: obj.name,
      kind: VariableKindEnum.STAGGERED,
      staggeredValues,
    });
  });

  return combined;
};

export const setOriginalType = (skill: any): string => {
  if (skill?.enterprise && skill?.store) {
    return 'jobtype_enterprise_store';
  }

  if (skill?.enterprise) {
    return 'jobtype_enterprise';
  }

  if (!skill?.enterprise) {
    return 'jobtype';
  }
};

export const createPayloadForTaskForm: any = (elements: any) => {
  const {
    date,
    publishedAllTasks,
    publishedDateType,
    publishedTimeAll,
    genderAllowed,
    cellphoneOS,
    description,
    speakSpanish,
    enterpriseComplete,
    jobTypeComplete,
    storeComplete,
    eppsComplete,
    skills: s,
    template,
    responsibleOperatorComplete,
    kamComplete,
    applicableWithoutSkills,
    subName,
  } = elements;

  const skills = s.filter(Boolean);
  const jobs = [];
  let publishAt: any = moment();

  if (publishedAllTasks && ![PublicationDatesEnum.NOW].includes(publishedDateType)) {
    publishAt = dateSetting(publishedDateType, date, publishedTimeAll);
  }

  if (!elements.repeatTasks || !elements.repeatTasksDays) elements.repeatTasksDays = [true];
  for (let i = 0; i < elements.repeatTasksDays.length; i++) {
    if (!elements.repeatTasksDays[i]) continue;

    elements.jobs.forEach((ele) => {
      const repeatDate = date.clone().add(i, 'days');
      const startAt = moment(
        repeatDate.format('DD-MM-YYYY') + ' ' + ele.startAtField.format('HH:mm'),
        'DD-MM-YYYY HH:mm'
      ).toISOString();

      const endDate = ele.isUntilNextDay ? moment(repeatDate).add(1, 'days') : repeatDate;
      const endAt = moment(
        endDate.format('DD-MM-YYYY') + ' ' + ele.endAtField.format('HH:mm'),
        'DD-MM-YYYY HH:mm'
      ).toISOString();
      let lunchTime = [];
      let lunchMinutes;
      if (!ele.haveLunchTime) {
        lunchTime = null;
        lunchMinutes = null;
      } else if (
        !ele.noDefinedLunchTime &&
        ele.noDefinedLunchTime !== undefined &&
        !ele.lunchMinutes
      ) {
        const lunchDate = ele.lunchStartAt.diff(ele.startAtField) < 0 ? endDate : repeatDate;
        const lunchStartAt = moment(
          lunchDate.format('DD-MM-YYYY') + ' ' + ele.lunchStartAt.format('HH:mm'),
          'DD-MM-YYYY HH:mm'
        ).toISOString();
        const lunchEndAt = moment(
          lunchDate.format('DD-MM-YYYY') + ' ' + ele.lunchEndAt.format('HH:mm'),
          'DD-MM-YYYY HH:mm'
        ).toISOString();

        lunchTime.push(lunchStartAt, lunchEndAt);
        lunchMinutes = null;
      } else if (ele.lunchMinutes) {
        lunchMinutes = ele.lunchMinutes;
      }

      let jobPublishAt = null;
      if (
        !publishedAllTasks &&
        ele.publishedDateType &&
        ele.publishedDateType !== PublicationDatesEnum.NOW
      ) {
        jobPublishAt = dateSetting(ele.publishedDateType, repeatDate, ele.publishedTime);
      }

      jobs.push({
        startAt,
        endAt,
        lunchTime,
        lunchMinutes,
        applicableWithoutSkills,
        publishAt: jobPublishAt ? jobPublishAt?.toISOString() : publishAt?.toISOString(),
        comments: ele.comments,
        requiredQuotas: ele.requiredQuotas,
        extraQuotas: ele.extraQuotas === '' ? null : ele.extraQuotas,
        coordinator: ele.supervisorComplete
          ? {
              ...ele.supervisorComplete,
              enterprise: ele.supervisorComplete?.enterprise?._id ?? null,
            }
          : null,
        cellphoneOS: cellphoneOS === 'any' ? ['android', 'ios'] : [cellphoneOS],
        genderAllowed: genderAllowed === 'any' ? ['female', 'male'] : [genderAllowed],
        ppes: eppsComplete,
        skills: skills.map((skill) => ({
          ...skill,
          originType: setOriginalType(skill),
        })),
        onSiteCourses:
          elements.needsOnSiteCourse && skills.length === 1 && skills[0].enterprise
            ? skills[0]?.onSite?.map((obj) => obj.course) || []
            : [],
        additionalLinks: ele.urls?.filter((obj) => obj.link) || [],
        additionalFiles: ele.urls?.filter((obj) => obj.file) || [],
        invoiceSchema: ele.entrySchema,
        paymentSchema: {
          _id: ele.paymentSchemaComplete?._id,
          name: ele.paymentSchemaComplete?.name,
          description: ele.paymentSchemaComplete?.description,
          paymentType: ele.paymentSchemaComplete?.paymentType,
          payLunch: !!ele.paymentSchemaComplete?.payLunch,
          paymentRule: ele.paymentSchemaComplete?.paymentRule,
          paymentRuleParameters: ele.paymentSchemaComplete?.paymentRuleParameters,
          variables: combineSimpleAndStaggeredVars(ele),
        },
        payInsured: ele.payInsured !== undefined ? !ele.payInsured : false,
      });
    });
  }

  return cleanObjects(
    {
      template: template || '60eb0a8ed931395c7f170f45',
      description,
      speakSpanish: speakSpanish === LanguagesEnum.SPANISH,
      store: storeComplete,
      jobType: cleanObjects({ ...jobTypeComplete, subName }),
      enterprise: enterpriseComplete,
      responsibleOperator: responsibleOperatorComplete,
      kam: kamComplete,
      jobs,
    },
    true
  );
};

const mapStaggeredVariablesValues = (form: Record<string, any>) => {
  const staggeredVariables = [];

  form.paymentSchemaComplete?.staggeredVariables?.map((s) => {
    const values = form[`paymentVariable_${s.code}`];
    const steps = s.steps.map((step) => {
      const v = values.find(({ min, max }) => step.min === min && step.max === max);

      return {
        min: step.min,
        max: step.max ? step.max : 'Infinity',
        value: v.value,
      };
    });
    staggeredVariables.push({
      ...s,
      steps,
    });
  });

  return staggeredVariables;
};

const mapSimpleVariablesValues = (form: Record<string, any>) => {
  const variables = [];

  form.paymentSchemaComplete.variables.map((obj) => {
    variables.push({
      _id: obj._id,
      code: obj.code,
      name: obj.name,
      value: Number(form[`paymentVariable_${obj.code}`]),
    });
  });

  return variables;
};

export const createPayloadForTemplate: any = (elements: any) => {
  const {
    genderAllowed,
    cellphoneOS,
    description,
    speakSpanish,
    enterprise,
    jobType,
    store,
    eppsComplete,
    templateName,
    templateDescription,
    publishedAllTasks,
    publishedDateType,
    publishedTimeAll,
    date,
    skills: s,
    responsibleOperator,
    kam,
    subName,
    applicableWithoutSkills,
  } = elements;

  const skills = s.filter(Boolean);
  let publishAt: any = moment();

  if (publishedAllTasks && ![PublicationDatesEnum.NOW].includes(publishedDateType)) {
    publishAt = dateSetting(publishedDateType, date, publishedTimeAll);
  }

  const jobs = elements.jobs.map((ele) => {
    const startAt = moment(
      date.format('DD-MM-YYYY') + ' ' + ele.startAtField.format('HH:mm'),
      'DD-MM-YYYY HH:mm'
    ).toISOString();

    const endDate = ele.isUntilNextDay ? moment(date).add(1, 'days') : date;
    const endAt = moment(
      endDate.format('DD-MM-YYYY') + ' ' + ele.endAtField.format('HH:mm'),
      'DD-MM-YYYY HH:mm'
    ).toISOString();

    let lunchTime = [];
    let lunchMinutes;
    if (!ele.haveLunchTime) {
      lunchTime = null;
      lunchMinutes = null;
    } else if (!ele.noDefinedLunchTime && ele.noDefinedLunchTime !== undefined) {
      const lunchDate = ele.lunchStartAt.diff(ele.startAtField) < 0 ? endDate : date;
      const lunchStartAt = moment(
        lunchDate.format('DD-MM-YYYY') + ' ' + ele.lunchStartAt.format('HH:mm'),
        'DD-MM-YYYY HH:mm'
      ).toISOString();
      const lunchEndAt = moment(
        lunchDate.format('DD-MM-YYYY') + ' ' + ele.lunchEndAt.format('HH:mm'),
        'DD-MM-YYYY HH:mm'
      ).toISOString();

      lunchTime.push(lunchStartAt, lunchEndAt);
      lunchMinutes = null;
    } else if (ele.lunchMinutes) {
      lunchMinutes = ele.lunchMinutes;
    }

    let jobPublishAt = null;
    if (
      !publishedAllTasks &&
      ele.publishedDateType &&
      ele.publishedDateType !== PublicationDatesEnum.NOW
    ) {
      jobPublishAt = dateSetting(ele.publishedDateType, date, ele.publishedTime);
    }

    return cleanObjects(
      {
        comments: ele.comments,
        requiredQuotas: ele.requiredQuotas,
        extraQuotas: ele.extraQuotas === '' ? null : ele.extraQuotas,
        additionalLinks: ele.urls?.filter((obj) => obj.link) || [],
        additionalFiles: ele.urls?.filter((obj) => obj.file) || [],
        skills: skills.map((skill) => ({
          ...skill,
          originType: skill?.enterprise === undefined ? 'jobtype' : 'jobtype_enterprise',
        })),
        onSiteCourses:
          elements.needsOnSiteCourse && skills.length === 1 && skills[0].enterprise
            ? skills[0]?.onSite?.map((obj) => obj.course._id) || []
            : [],
        publishOption:
          PUBLISH_TYPE_BACKEND[publishedAllTasks ? publishedDateType : ele.publishedDateType],
        startAt,
        endAt,
        lunchTime,
        lunchMinutes,
        publishAt: jobPublishAt ? jobPublishAt?.toISOString() : publishAt?.toISOString(),
        days: elements.repeatTasksDays || [true],
        invoiceSchema: ele.entrySchema,
        paymentSchema: {
          ...ele.paymentSchemaComplete,
          variables: mapSimpleVariablesValues(ele),
          staggeredVariables: mapStaggeredVariablesValues(ele),
        },
        coordinator: ele.supervisor != '' ? ele.supervisor : undefined,
      },
      true
    );
  });

  return cleanObjects(
    {
      cellphoneOS: cellphoneOS === 'any' ? ['android', 'ios'] : [cellphoneOS],
      ppes: eppsComplete,
      genderAllowed: genderAllowed === 'any' ? ['female', 'male'] : [genderAllowed],
      speakSpanish: speakSpanish === LanguagesEnum.SPANISH,
      jobs,
      enterprise,
      store,
      jobType,
      jobTypeSubName: subName,
      description,
      responsibleOperator:
        responsibleOperator && responsibleOperator !== 'none' ? responsibleOperator : undefined,
      kam: kam && kam !== 'none' ? kam : undefined,

      name: templateName,
      definition: templateDescription,
      viewStatus: elements.jobs.length > 1 ? TemplateViewStatusEnum.PRIVATE : elements.viewStatus,
      applicableWithoutSkills,
    },
    true
  );
};

export const createPayloadForEditTaskForm: any = (elements: any, initialJob: any) => {
  const {
    date,
    publishedAllTasks,
    publishedDateType,
    publishedTimeAll,
    genderAllowed,
    cellphoneOS,
    description,
    speakSpanish,
    eppsComplete,
    skills: s,
    responsibleOperatorComplete,
    kamComplete,
  } = elements;

  const skills = s.filter(Boolean);
  let publishAt: any = moment();

  if (publishedAllTasks && ![PublicationDatesEnum.NOW].includes(publishedDateType)) {
    publishAt = dateSetting(publishedDateType, date, publishedTimeAll);
  }
  const ele = elements.jobs[0];
  const startAt = moment(
    date.format('DD-MM-YYYY') + ' ' + ele.startAtField.format('HH:mm'),
    'DD-MM-YYYY HH:mm'
  ).toISOString();

  const endDate = ele.isUntilNextDay ? moment(date).add(1, 'days') : date;
  const endAt = moment(
    endDate.format('DD-MM-YYYY') + ' ' + ele.endAtField.format('HH:mm'),
    'DD-MM-YYYY HH:mm'
  ).toISOString();

  let lunchTime = [];
  let lunchMinutes;
  if (!ele.haveLunchTime) {
    lunchTime = null;
    lunchMinutes = null;
  } else if (!ele.noDefinedLunchTime && ele.noDefinedLunchTime !== undefined) {
    const lunchDate = ele.lunchStartAt.diff(ele.startAtField) < 0 ? endDate : date;
    const lunchStartAt = moment(
      lunchDate.format('DD-MM-YYYY') + ' ' + ele.lunchStartAt.format('HH:mm'),
      'DD-MM-YYYY HH:mm'
    ).toISOString();
    const lunchEndAt = moment(
      lunchDate.format('DD-MM-YYYY') + ' ' + ele.lunchEndAt.format('HH:mm'),
      'DD-MM-YYYY HH:mm'
    ).toISOString();

    lunchTime.push(lunchStartAt, lunchEndAt);
    lunchMinutes = null;
  } else if (ele.lunchMinutes) {
    lunchMinutes = ele.lunchMinutes;
  }

  let jobPublishAt = null;
  if (
    !publishedAllTasks &&
    ele.publishedDateType &&
    ele.publishedDateType !== PublicationDatesEnum.NOW
  ) {
    jobPublishAt = dateSetting(ele.publishedDateType, date, ele.publishedTime);
  }

  const payload = cleanObjects(
    {
      description,
      speakSpanish: speakSpanish === LanguagesEnum.SPANISH,
      removeLunchTime: lunchTime === null,
      startAt,
      endAt,
      lunchTime,
      lunchMinutes,
      publishAt: jobPublishAt ? jobPublishAt?.toISOString() : publishAt?.toISOString(),
      comments: ele.comments,
      requiredQuotas: ele.requiredQuotas,
      extraQuotas: ele.extraQuotas === '' ? null : ele.extraQuotas,
      coordinator: ele.supervisorComplete
        ? {
            ...ele.supervisorComplete,
            enterprise: ele.supervisorComplete?.enterprise?._id ?? null,
          }
        : null,
      cellphoneOS: cellphoneOS === 'any' ? ['android', 'ios'] : [cellphoneOS],
      genderAllowed: genderAllowed === 'any' ? ['female', 'male'] : [genderAllowed],
      ppes: eppsComplete || [],
      skills: skills.map((skill) => ({
        ...skill,
        originType: skill?.enterprise === undefined ? 'jobtype' : 'jobtype_enterprise',
      })),
      onSiteCourses:
        elements.needsOnSiteCourse && skills.length === 1 && skills[0].enterprise
          ? skills[0]?.onSite?.map((obj) => obj.course) || []
          : [],
      responsibleOperator:
        responsibleOperatorComplete && Object.keys(responsibleOperatorComplete).length > 0
          ? responsibleOperatorComplete
          : null,
      kam: kamComplete && Object.keys(kamComplete).length > 0 ? kamComplete : null,
      additionalLinks: ele.urls?.filter((obj) => obj.link) || [],
      additionalFiles: ele.urls?.filter((obj) => obj.file) || [],
      invoiceSchema: ele.entrySchema,
      paymentSchema: {
        _id: ele.paymentSchemaComplete?._id,
        name: ele.paymentSchemaComplete?.name,
        description: ele.paymentSchemaComplete?.description,
        paymentType: ele.paymentSchemaComplete?.paymentType,
        payLunch: !!ele.paymentSchemaComplete?.payLunch,
        paymentRule: ele.paymentSchemaComplete?.paymentRule,
        paymentRuleParameters: ele.paymentSchemaComplete?.paymentRuleParameters,
        variables: combineSimpleAndStaggeredVars(ele),
      },
      removeCoordinator: !ele.supervisor,
      removeExtraQuotas: !ele.extraQuotas,
      removeComments: !ele.comments,
      removeResponsibleOperator:
        !responsibleOperatorComplete || Object.keys(responsibleOperatorComplete).length === 0,
      removeKam: !kamComplete || Object.keys(kamComplete).length === 0,
      payInsured: !ele.payInsured,
    },
    true
  );
  if (moment(initialJob.startAt).toISOString() === startAt) delete payload.startAt;
  if (moment(initialJob.endAt).toISOString() === endAt) delete payload.endAt;
  return payload;
};

export const createPayloadForBulkTask: any = (element: any, jobData: any, userId: string) => {
  const {
    startAt,
    startHour,
    endAt,
    responsibleOperator,
    kam,
    publishAt,
    publishAtHour,
    requiredQuotas,
    extraQuotas,
    entrySchema,
    paymentSchemaComplete,
    repeatDates,
  } = element;

  const jobs = [];

  const publishNow =
    !publishAt ||
    !publishAtHour ||
    mergeDateTime(publishAt, publishAtHour)?.isSameOrBefore(moment());
  const publishDiff = publishAt?.startOf('day').diff(startAt?.startOf('day'), 'days');

  for (let i = 0; i < repeatDates.length; i++) {
    const jobDate = repeatDates[i]?.clone();

    const startDate = mergeDateTime(jobDate, startHour);
    const publishAtDate = mergeDateTime(jobDate?.clone()?.add(publishDiff, 'days'), publishAtHour);

    const endDate = mergeDateTime(
      compareMinutes(endAt, startHour) <= 0 ? jobDate?.clone()?.add(1, 'days') : jobDate,
      endAt
    );

    let lunchTime = [];
    let lunchMinutes;
    if (!element.haveLunchTime) {
      lunchTime = null;
      lunchMinutes = null;
    } else if (
      !element.noDefinedLunchTime &&
      element.noDefinedLunchTime !== undefined &&
      !element.lunchMinutes
    ) {
      const lunchDate = element.lunchStartAt.diff(element.startAtField) < 0 ? endDate : jobDate;
      const lunchStartAt = moment(
        lunchDate.format('DD-MM-YYYY') + ' ' + element.lunchStartAt.format('HH:mm'),
        'DD-MM-YYYY HH:mm'
      ).toISOString();
      const lunchEndAt = moment(
        lunchDate.format('DD-MM-YYYY') + ' ' + element.lunchEndAt.format('HH:mm'),
        'DD-MM-YYYY HH:mm'
      ).toISOString();

      lunchTime.push(lunchStartAt, lunchEndAt);
      lunchMinutes = null;
    } else if (element.lunchMinutes) {
      lunchMinutes = element.lunchMinutes;
    }

    const job = {
      applicableWithoutSkills: jobData.applicableWithoutSkills,
      comments: jobData.comments,
      cellphoneOS: jobData.cellphoneOS,
      genderAllowed: jobData.genderAllowed,
      ppes: jobData.ppes,
      skills: jobData.skills,
      onSiteCourses: jobData.onSiteCourses,
      additionalLinks: jobData.additionalLinks,
      additionalFiles: jobData.additionalFiles,
      startAt: startDate.toISOString(),
      endAt: endDate.toISOString(),
      lunchTime,
      lunchMinutes,
      publishAt:
        publishNow || startDate?.isSameOrBefore(moment())
          ? moment().toISOString()
          : publishAtDate.toISOString(),
      requiredQuotas: Number(requiredQuotas),
      extraQuotas: extraQuotas === '' ? null : Number(extraQuotas),
      invoiceSchema: entrySchema,
      paymentSchema: {
        _id: paymentSchemaComplete?._id,
        name: paymentSchemaComplete?.name,
        description: paymentSchemaComplete?.description,
        paymentType: paymentSchemaComplete?.paymentType,
        payLunch: !!paymentSchemaComplete?.payLunch,
        paymentRule: paymentSchemaComplete?.paymentRule,
        paymentRuleParameters: paymentSchemaComplete?.paymentRuleParameters,
        variables: paymentSchemaComplete?.variables?.map((variable) => {
          const variableAux = { ...variable };
          if (variable.kind === VariableKindEnum.STAGGERED) {
            variableAux.staggeredValues = variable.staggeredValues.map((item) => ({
              ...item,
              max: item.max || 'Infinity',
            }));
          }
          return variableAux;
        }),
      },
      payInsured: element.payInsured !== undefined ? !element.payInsured : false,
    };
    jobs.push(job);
  }

  return cleanObjects(
    {
      template: jobData.template,
      description: jobData.description,
      speakSpanish: jobData.speakSpanish,
      store: { ...jobData.store, creator: userId },
      jobType: jobData?.type,
      enterprise: jobData.enterprise,
      responsibleOperator:
        responsibleOperator && responsibleOperator?.names ? responsibleOperator : null,
      kam: kam && kam?.names ? kam : null,
      jobs,
    },
    true
  );
};

export const createUpdateApplicationRoute = (data: any) => {
  let route: string;
  if (
    data.jobbers?.length === 1 &&
    [
      JobApplicationStatusEnum.ABSENT,
      JobApplicationStatusEnum.PRESENT,
      JobApplicationStatusEnum.FINISHED,
    ].includes(data.status)
  ) {
    route = updateJobApplicationRoute(data.jobbers[0]);
  } else if (data.status === JobApplicationStatusEnum.ASSIGNED)
    route = bulkAssignJobApplicationRoute();
  else if (data.status === JobApplicationStatusEnum.DEALLOCATED)
    route = bulkCancelJobApplicationRoute();
  else if (data.status === JobApplicationStatusEnum.PRESENT)
    route = bulkPresentJobApplicationRoute();
  else if (data.status === JobApplicationStatusEnum.ABSENT) route = bulkAbsentJobApplicationRoute();
  else if (data.status === JobApplicationStatusEnum.FINISHED)
    route = bulkFinishedJobApplicationsRoute();
  return route;
};

export const filterSameJob = (list, job) => {
  if (!list) return [];
  return list.filter(
    (obj) =>
      obj?.enterprise === job?.enterprise?._id &&
      obj?.jobType === job?.type?._id &&
      obj?.store === job?.store?._id
  );
};
export const filterSameEnterprise = (list, enterprise) => {
  if (!list) return [];
  return list.filter((obj) => obj?.enterprise === enterprise);
};

export const getPuntualityScore = (puntuality: Array<{ value: number }>): PuntualityEnum => {
  if (!puntuality?.length) return PuntualityEnum.NO_INFO;
  const score = puntuality.reduce((acc, curr) => {
    acc += curr.value;
    return acc;
  }, 0);

  const average = score / puntuality.length;

  if (average <= 5) return PuntualityEnum.VERY_PUNTUAL;
  if (average <= 15) return PuntualityEnum.PUNTUAL;
  if (average <= 30) return PuntualityEnum.NOT_VERY_PUNTUAL;
  return PuntualityEnum.NOT_PUNTUAL;
};

export const getStayScore = (stay: Array<{ value: number }>): number => {
  if (!stay?.length) return 0;
  const score = stay.reduce((acc, curr) => {
    acc += curr.value;
    return acc;
  }, 0);

  return Math.floor(score / stay.length);
};

export const separateByTime = (metric: Array<any>) => {
  if (!metric) return [];
  const previousMonth = moment().startOf('day').subtract(30, 'day');

  const aux = metric.reduce((prev, current) => {
    const date = moment(current.day).startOf('day');

    if (date.isAfter(previousMonth)) prev.push(current);

    return prev;
  }, []);

  return aux;
};

export const checkEditDisabledField = (jobStatus, field) => {
  return jobStatus
    ? AccessControlApplicationActionsConfig[jobStatus].editJobDisabled.includes(field)
    : false;
};

export const hoursToPay = (payload) => {
  return calculateHoursToPay(
    payload.paymentSchema.variables.find((v) => v.code === 'hour')?.quantity
  );
};

export const calculateHoursToPay = (quantity) => {
  const hoursToPay = quantity || 0;
  const hours = Math.trunc(hoursToPay);
  const minFloat = Number((hoursToPay - hours).toFixed(2));
  const mins = Math.round(minFloat * 60);
  if (hours > 0 && mins > 0) return `${hours} hrs. y ${mins} min.`;
  if (hours > 0 && mins <= 0) return `${hours} hrs.`;
  if (hours <= 0 && mins > 0) return `0 hrs. y ${mins} min.`;

  return 0;
};

export const separateArray = (arr: Array<any>, n: number): Array<Array<any>> => {
  const aux = Array.from(arr);
  return new Array(Math.ceil(aux.length / n)).fill(undefined).map((_) => aux.splice(0, n));
};

export const getAmountOfExtraHours = (payload) => {
  const duration = moment.duration(moment(payload.finishAt).diff(payload?.presentAt));
  const hours = duration.get('hours');
  const mins = duration.get('minutes');

  if (hours > 0 && mins > 0) return `${hours} hrs. y ${mins} min.`;
  if (hours > 0 && mins <= 0) return `${hours} hrs.`;
  if (hours <= 0 && mins > 0) return `0 hrs. y ${mins} min.`;

  return 0;
};
