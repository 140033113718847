export default {
  staffs: {
    title: 'Roles y perfiles',
    subtitle: 'Listado de integrantes del Staff',
    list: {
      columns: {
        id: 'ID',
        name: '$t(global.name)',
        surname: '$t(global.surname)',
        email: '$t(global.email)',
        role: 'Roles',
        status: 'Estado',
        actions: 'Acciones',
      },
      actions: {
        details: 'Ver',
        edit: 'Editar staff',
        block: 'Desactivar staff',
      },
    },
    actions: {
      create: 'Crear nuevo perfil',
      disable: 'Desactivar perfil',
      disable_plural: 'Desactivar perfiles',
    },
    editStaff: {
      title: 'Editar perfil staff',
      subtitle: 'Acá puedes editar los datos del perfil seleccionado',
      successMsg: 'Perfil staff editado exitosamente',
      inputs: {
        names: 'Nombre',
        surnames: 'Apellido',
        email: '$t(global.email)',
        repeatEmail: 'Confirma el email',
        role: {
          name: 'Rol',
          admin: 'Admin',
          staff: 'Admin Staff',
          staff_onboarding: 'Onboarding',
          staff_finance: 'Finanzas',
        },
      },
      button: 'Guardar cambios',
    },
    createStaff: {
      title: 'Crear nuevo perfil',
      subtitle:
        'Llena los datos que se requieren a continuación para crear al nuevo perfil de staff',
      successMsg: 'Nuevo perfil staff creado exitosamente',
      inputs: {
        names: {
          required: 'Ingresa el nombre',
          formatError: 'Ingresa un nombre válido',
        },
        surnames: {
          required: 'Ingresa el apellido',
          formatError: 'Ingresa un apellido válido',
        },
        email: {
          required: 'Ingresa el email',
          formatError: 'Ingresa un email válido',
          alreadyTaken: 'Este email ya se encuentra registrado',
        },
        repeatEmail: {
          required: 'Confirma el email',
          notSame: 'Los correos no coinciden',
        },
        roles: {
          required: 'Ingresa algún rol',
        },
      },
      button: 'Crear perfil staff',
    },
    bulkDisable: {
      title: 'Desactivar perfil Staff',
      title_plural: 'Desactivar perfiles Staff',
      subtitle: 'Estás a punto de desactivar el siguiente perfil staff',
      subtitle_plural: 'Estás a punto de desactivar los siguientes perfiles staff',
      submit: 'Desactivar perfil',
      submit_plural: 'Desactivar perfiles',
      alert: 'Recuerda que al desactivar el perfil, ya no tendrá acceso a la plataforma.',
      alert_plural:
        'Recuerda que al desactivar los perfiles, ya no tendrán acceso a la plataforma.',
      comment: {
        text: 'Comentario sobre la desactivación de este perfil (opcional)',
        text_plural: 'Comentario sobre la desactivación de estos perfiles (opcional)',
        placeholder: 'Ingresa el motivo',
      },
      success: 'Perfil staff desactivado exitosamente',
      success_plural: 'Perfiles staff desactivados exitosamente',
    },
  },
};
