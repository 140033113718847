import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import LocalStorageService from '@utils/localStorage';
import {
  getOauthTokenRoute,
  sendOtpRoute,
  checkTokenRoute,
  logoutRoute,
} from '@features/auth/ts/routes';

import server from '@services/api';
import i18n from '@i18n/index';

import { User } from './ts/auth.interface';

const localStorageService = LocalStorageService.getService();

export const authAdapter = createEntityAdapter();

export const initialState = authAdapter.getInitialState({
  user: { _id: '', countryIso: undefined } as User,
  login: { loading: false, status: '' },
  loggingOut: false,
  error: null,
});

const setStaff = (state, action) => {
  state.user = action.payload.data.user;
  i18n.changeLanguage(`es-${action.payload.data.user.countryIso}`);
  localStorageService.setToken({
    accessToken: action.payload.data.accessToken,
    refreshToken: action.payload.data.refreshToken,
  });
};

export const logInEmail = createAsyncThunk(
  'auth/login',
  async (payload: { email: string }, thunkAPI) => {
    const { email } = payload;
    try {
      const response = await server({
        method: 'post',
        url: sendOtpRoute(),
        data: { email },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const logInToken = createAsyncThunk(
  'auth/login/validate',
  async (payload: { otp: string; email: string }, thunkAPI) => {
    let { otp, email } = payload;
    otp = encodeURIComponent(otp);
    email = encodeURIComponent(email);
    try {
      const response = await server({
        method: 'post',
        url: getOauthTokenRoute(),
        data: `grant_type=otp&username=${email}&otp=${otp}`,
        auth: {
          username: process.env.NEXT_PUBLIC_USER,
          password: process.env.NEXT_PUBLIC_PASSWORD,
        },
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const registerApp = createAsyncThunk('auth/registerApp', async () => {
  const refreshToken = localStorageService.getRefreshToken();
  const grantType =
    refreshToken && refreshToken !== 'undefined' ? 'refresh_token' : 'client_credentials';
  const data = refreshToken && refreshToken !== 'undefined' ? `&refresh_token=${refreshToken}` : '';
  const response = await server({
    method: 'post',
    url: getOauthTokenRoute(),
    data: `grant_type=${grantType}${data}`,
    auth: {
      username: process.env.NEXT_PUBLIC_USER,
      password: process.env.NEXT_PUBLIC_PASSWORD,
    },
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  });
  return { ...response.data, type: grantType };
});

export const loginFromLabours = createAsyncThunk(
  'auth/loginFromLabours',
  async (payload: { accessToken: string; refreshToken: string }) => {
    const response = await server({
      method: 'get',
      url: checkTokenRoute(),
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${payload.accessToken}`,
      },
    });
    response.data.data.accessToken = payload.accessToken;
    response.data.data.refreshToken = payload.refreshToken;
    return response.data;
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  const response = await server.delete(logoutRoute());
  return response.data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: setStaff,
    clearState: (state, b: { payload: { key: string; subkey: string } }) => {
      if (state[b.payload?.key]?.[b.payload?.subkey])
        state[b.payload.key][b.payload?.subkey] = 'idle';
    },
    clearLogout: (state) => {
      state.loggingOut = false;
    },
  },
  extraReducers: {
    [String(logInEmail.fulfilled)]: (state) => {
      state.login.loading = false;
      state.login.status = '';
    },
    [String(logInEmail.pending)]: (state) => {
      state.login.loading = true;
      state.login.status = '';
    },
    [String(logInEmail.rejected)]: (state, action) => {
      const status = action?.payload?.status;
      if (status === 400 || status === 401 || status === 409) {
        state.login.status = 'notFound';
      }
      if (status === 403 || status === 412) {
        state.login.status = 'blocked';
      }
      state.login.loading = false;
    },
    [String(logInToken.fulfilled)]: (state, action) => {
      state.login.loading = false;
      setStaff(state, action);
      state.login.status = 'success';
    },
    [String(logInToken.pending)]: (state) => {
      state.login.loading = true;
      state.login.status = '';
    },
    [String(logInToken.rejected)]: (state, action) => {
      const status = action?.payload?.status;
      if (status === 400 || status === 401 || status === 409) state.login.status = 'notFound';

      if (status === 429) state.login.status = 'maxLoginRetries';

      state.login.loading = false;
    },
    [String(registerApp.fulfilled)]: (state, action) => setStaff(state, action),
    [String(registerApp.rejected)]: (state, action) => (state.error = action?.payload?.error),

    [String(loginFromLabours.fulfilled)]: (state, action) => {
      state.login.loading = false;
      setStaff(state, action);
      state.login.status = 'success';
    },
    [String(loginFromLabours.pending)]: (state) => {
      state.login.loading = true;
      state.login.status = '';
    },
    [String(loginFromLabours.rejected)]: (state) => {
      state.login.loading = false;
    },
    [String(logout.fulfilled)]: (state) => {
      state.user = initialState.user;
      state.loggingOut = true;
    },
  },
});

export const { clearState, clearLogout } = authSlice.actions;
export default authSlice.reducer;
