export default {
  enterprisesDeprecated: {
    title: 'Mantenedor clientes comerciales y sedes',
    subtitle: 'Revisa el listado de clientes comerciales',
    actions: ['Crear sede', 'Crear cliente'],
    table: {
      columns: [
        'Cliente',
        'Sede',
        'País',
        'Ubicación',
        'Fecha creación',
        {
          title: 'Acciones',
          buttonsActions: [
            'Crear sede',
            'Editar sede',
            'Eliminar sede',
            'Editar cliente comercial',
            'Eliminar cliente comercial',
          ],
        },
      ],
    },
  },
  enterprises: {
    title: 'Clientes',
    subtilte: 'Revisa la información del cliente seleccionado',
    noData: {
      title: 'Selecciona un cliente comercial',
      subtitle:
        'Para ver, editar y crear información de un cliente, debes seleccionarlo en la barra lateral.',
    },
    form: {
      searchcliente: {
        placeholder: 'Busca por cliente o razón social',
      },
    },
    actions: {
      new: 'Crear Cliente',
    },
    enterprisesProfile: {
      businessName: 'Razón Social',
      turn: 'Giro',
      economicGroup: 'Grupo económico',
      tabs: {
        information: {
          title: 'Información',
          subtitle: 'Aquí podrás ver y editar la información del cliente seleccionado.',
          actions: ['Editar cliente', 'Eliminar cliente'],
          contacts: {
            commercial: 'Contacto Comercial',
            finance: 'Contacto Finanzas',
            other: 'Contacto de {{input}}',
            name: 'Nombre y Apellido',
            post: 'Cargo',
            email: 'Email',
            phone: 'Teléfono',
          },
          address: {
            title: 'Ubicación',
            regionName: 'Region',
            cityName: 'Dirección',
            localityName: 'Comuna',
          },
          typeTask: {
            title: 'Tipos de Tareas',
          },
          enterprisePortal: {
            title: 'Portal empresas',
            subtitle:
              'Selecciona la anticipación con la que el cliente podrá visualizar los Jobbers asignados y presentes de una tarea.',
            jobberPayrollAnticipation: {
              label: 'Visualización de Jobbers',
              option: '1 día antes',
              option_plural: '{{count}} días antes',
            },
          },
          taskAssignment: {
            title: 'Asignación de tareas',
          },
          staff: {
            title: 'CSM y Staff responsables',
            staff: 'Staff',
            csm: 'CSM',
          },
          incentive: {
            title: 'Incentivo general',
            action: 'Crear incentivo',
            empty: 'Sin incentivo creado',
            payment: 'Pago Jobber',
            invoice: 'Cobro cliente',
            form: {
              title_general: 'Incentivo general',
              title_specific: 'Incentivo sede',
              title_general_plural: 'Edición incentivo general',
              title_specific_plural: 'Edición Incentivo sede',
              subtitle: 'Pago asegurado',
              helper: 'Ingresa los criterios que se deben cumplir, para aplicar el incentivo.',
              enterprise: 'SMU',
              store: {
                store: 'Sede',
                region: 'Región',
                location: 'Ubicación',
                commune: 'Comuna',
              },
              payment: {
                label: 'Pago Jobber *',
                required: 'El pago jobber es requerido',
                helper: 'Ingresa un valor entre {{range}}',
              },
              invoice: {
                label: 'Cobro cliente *',
                required: 'El cobro cliente es requerido',
                helper: 'Ingresa un valor entre {{range}}',
              },
              dates: {
                title: 'Días de validez',
                label: 'Agregar fechas',
                cancel: 'Cancelar',
                add: 'Agregar fechas',
                edit: 'Editar fechas',
                update: 'Actualizar fechas',
              },
              checkbox: {
                label: 'Sin fechas definidas',
                helper: 'Asegurado aplicará todos los días',
              },
              name: {
                label: 'Nombre incentivo *',
                required: 'El nombre es requerido',
              },
              alert: {
                info: 'Ingresa un nombre que describa el incentivo. Ej. <b>"Cyber".</b>',
              },
              close: 'Salir',
              submit: 'Crear incentivo',
              update: 'Guardar cambios',
              cancel: 'Cancelar',
              alerts: {
                success: 'El incentivo <b>Pago asegurado general</b> ha sido creado con éxito',
                update: 'El incentivo <b>Pago asegurado general</b> ha sido actualizado con éxito',
                error: 'No se logró crear el incentivo',
              },
            },
          },
        },
        stores: {
          title: 'Sedes',
          subtitle: 'Aquí podrás ver, editar y crear las sedes asociados a este cliente.',
        },
        paymentSchemes: {
          title: 'Esquemas de Pagos',
          titleSecundario: 'Esquema de Pago',
          subtitle: 'Aquí podrás ver y crear los esquemas de pago de este cliente.',
          actions: {
            edit: 'Editar',
            new: 'Crear esquema de pagos',
            delete: {
              tooltip: 'Eliminar',
              title: 'Eliminar esquema de pago',
              message: 'Se ha eliminado el esquema de pago {{input}} exitosamente',
              description:
                'Al eliminar esquema de pago {{input}}, no estará disponible para crear nuevas órdenes de trabajo. No afectarán a las pasadas ya creadas o en curso.',
              actions: ['Cancelar', 'Eliminar esquema de pago'],
            },
            exit: 'Salir',
          },
          form: {
            title: 'Creación de esquemas de pago',
            subtitle: 'Ingresa los datos obligatorios (*) para crear los esquemas de pago',
            subtitleSe: 'Aquí podrás crear los esquemas de pago para este cliente.',
            actions: {
              new: {
                title: 'Crear esquema',
                cancellationModal: {
                  title: 'Descartar creación de esquema',
                  text: 'Al salir, se perderán todos los cambios realizados.',
                  actions: ['Cancelar', 'Descartar esquema de pago'],
                },
              },
              edit: {
                title: 'Guardar Cambios',
                cancellationModal: {
                  title: 'Edición de esquema de pago',
                  text: 'Los cambios se verán reflejados en órdenes de trabajo futuras, en estado Programada y no afectará ordenes pasadas o en curso.',
                  actions: ['Cancelar', 'Salir sin Guardar'],
                },
              },
            },
            inputs: [
              {
                label: 'Nombre esquema',
                alert:
                  'Aquí no agregues el nombre del cliente. Por ejemplo “Esquema Puente Alto” y NO “Esquema Lider Puente Alto”. El esquema ya está asociado al cliente.',
                rules: 'Es requerido el campo.',
              },
              {
                label: 'Sede',
                placeHolder: 'Selecciona',
                rules: 'Es requerido el campo.',
              },
              {
                label: 'Tipo de Tarea',
                placeHolder: 'Selecciona',
                rules: 'Es requerido el campo.',
              },
              {
                label: 'Descripción',
                placeHolder:
                  'Agrega una descripción que ayude a identificar de mejor manera este esquema de pago.',
                subtitle: 'Descripción esquema de pago.',
                rules: 'Es requerido el campo.',
              },
              {
                label: 'Variables',
                placeHolder: 'Selecciona',
                alert: 'Todas las variables que sumes serán parte de este esquema de pago.',
                rules: 'Es requerido el campo.',
                actions: {
                  add: 'Agregar otra variable',
                },
              },
              {
                label: 'Tipo de monto',
                subtitle:
                  'Selecciona el tipo de monto que verá el Jobber en la App. Aplica para todas las variables agregadas.',
                required: 'Es requerido el campo.',
                texts: {
                  liquid: '$t(global.enums.paymentType.liquid)',
                  gross: '$t(global.enums.paymentType.gross)',
                },
                options: {
                  liquid: 'Monto $t(global.enums.paymentType.liquid, lowercase)',
                  gross: 'Monto $t(global.enums.paymentType.gross, lowercase)',
                },
              },
              {
                checkbox: 'Hora de almuerzo pagada',
                subtitle: 'Dentro del pago, se considerará el horario destinado al almuerzo.',
              },
              {
                label: 'Fecha de vencimiento',
                subtitle: 'Selecciona que tipo fecha de vencimiento tendrá el pago de las tareas.',
                required: 'Es requerido el campo.',
                options: {
                  day_and_week: {
                    text: 'Día de la semana',
                    title: 'Día vencimiento',
                    description:
                      'Selecciona el día definido de corte y el tiempo posterior a que las tareas sean pagadas. Ejemplo: miércoles - próxima semana',
                    alertTexts: {
                      start: 'Vencimiento será el día ',
                      boldSameWeek:
                        '{{day}} e incluirá tareas realizadas hasta el siguiente {{day}}, incluyendo el mismo día. ',
                      bold: '{{day}} e incluirá tareas realizadas durante {{count}} semana hasta el siguiente {{day}}. ',
                      bold_plural:
                        '{{day}} e incluirá tareas realizadas durante {{count}} semanas hasta el siguiente {{day}}. ',
                      endSameWeek: 'Ejemplo: desde el {{day}} {{start}} al {{day}} {{end}}.',
                      end: 'Ejemplo: desde el {{day}} {{start}} al {{day}} {{end}}.',
                    },
                    inputs: {
                      weekDay: {
                        label: 'Día de vencimiento *',
                        options: {
                          monday: 'Lunes',
                          tuesday: 'Martes',
                          wednesday: 'Miércoles',
                          thursday: 'Jueves',
                          friday: 'Viernes',
                        },
                      },
                      weeks: {
                        label: 'Semanas transcurridas *',
                        sameWeek: 'Misma semana',
                        option: '{{count}} semana',
                        option_plural: '{{count}} semanas',
                      },
                    },
                  },
                  fortnight: {
                    text: 'Quincenal',
                    alertTexts: [
                      {
                        bold: 'Tareas ejecutadas antes del día 16 de cada mes,',
                        normal:
                          ' serán pagadas durante ese día. En caso de que el 16 no sea un día hábil, el pago será el siguiente día hábil.',
                      },
                      {
                        bold: 'Tareas ejecutadas posterior al 16 de cada mes,',
                        normal:
                          ' serán pagadas el último día hábil del mes. En caso de que este día no sea hábil, serán pagadas el primer día hábil del mes siguiente.',
                      },
                    ],
                  },
                },
              },
              {
                label: 'Rango de días/horas: ',
                days: 'Rango días',
                hours: 'Rango horas',
              },
              {
                checkbox: 'Utilizar rango escalonado',
                title: 'Rangos escalonados en {{var}}',
                subtitle:
                  'Es importante que el último rango que definas, tenga marcada la opción "Sin límites", para definirlo como final.',
                info: 'Podrás ingresar números enteros y decimales. Por ejemplo: 5 o 5.5 km.',
                min: 'Desde',
                max: 'Hasta',
                required: 'El valor es requerido',
                error: 'Debes ingresar un valor o marcar la opción "Sin límite" para continuar',
                limit: 'Sin límite',
                add: 'Agregar otro campo',
              },
            ],
          },
          alerts: {
            notData: 'No hay esquemas de pago creados para este cliente.',
            createScheme: 'Se ha creado el esquema de pago exitosamente.',
            deleteScheme: 'Se ha eliminado el esquema de pago exitosamente.',
            editScheme: 'Se ha editado el esquema de pago exitosamente.',
          },
        },
        entriesSchemes: {
          title: 'Esquemas de Cobro',
          subtitle: 'Aquí podrás ver y crear los esquemas de cobro que se hará al cliente.',
          action: 'Crear esquema de cobro',
          form: {
            head: '{{type}} de esquemas de cobro',
            subhead: 'Ingresa los datos obligatorios (*) para crear los esquemas de cobro',
            title: 'Aquí podrás crear los esquemas de cobro para este cliente.',
            subtitle: 'Esquema de cobro',
            okBtn: 'Crear esquema',
            okBtnEdit: 'Guardar cambios',
            close: {
              title: 'Descartar {{type}} de esquema de cobros',
              subtitle: 'Al salir, se perderán todos los cambios realizados.',
              okBtn: 'Descartar cambios',
              cancel: 'Cancelar',
            },
            name: {
              title: 'Nombre esquema *',
              msg: 'Nombre esquema es querido',
              alert:
                'Ingresa un nombre que describa en que casos aplicar este esquema de cobro. Ej. "Esquema lunes a viernes".',
            },
            store: {
              title: 'Sede *',
              desc: 'Aplica para todas las sedes',
              msg: 'La sede es requerida',
            },
            typeTask: {
              title: 'Tipo de tarea *',
              msg: 'Tipo de tarea es requerida',
              placeholder: 'Tipo de tarea',
              subname: {
                title: 'Subnombre del tipo de tarea',
                placeholder: 'Subnombre',
              },
            },
            taskAssignment: {
              title: 'Asignación de tareas',
              manual: {
                label: 'Manual (Staff)',
                message: 'La asignación de Jobbers será a cargo del Staff.',
              },
              auto: {
                label: 'Automática',
                message:
                  'La asignación de Jobbers será de manera automática, siempre y cuándo el Jobber cumpla con los críterios definidos en la tarea. ',
              },
            },
            money: {
              title: 'Moneda *',
              msg: 'Moneda requerida',
              placeholder: 'Moneda',
            },
            vars: {
              title: 'Variable(s)',
              subtitle:
                'Tarifas debe estar expresadas en montos brutos y corresponden al valor unitario de cada variable.',
              first: 'Variable',
              second: 'Tarifa cliente',
              first_msg: 'La variable es requerida',
              second_msg: 'La tarifa cliente es requerida',
              stag: {
                checkbox: 'Utilizar rango escalonado',
                title: 'Rangos escalonados en {{var}}',
                subtitle:
                  'Es importante que el último rango que definas, tenga marcada la opción "Sin límites", para definirlo como final.',
                info: 'Podrás ingresar números enteros decinales. Por ejemplo: 5 o 5.5 km.',
                error:
                  'En caso de no crear más rangos, debes marcar el último como "Sin límite" para continuar.',
                errorGeneral:
                  'Para continuar, ingresa un valor en el "Hasta" de la variable "{{var}}" o marcar la opción "Sin límite" para continuar.',
                min: 'Desde',
                max: 'Hasta',
                value: 'Tarifa unitaria',
                limit: 'Sin límite',
                add: 'Agregar otro rango',
                required: 'Ingresa un valor',
              },
            },
            desc: {
              title: 'Descripción',
              msg: 'Se acepta 300 caracteres como máximo',
              input: 'Descripción esquema (opcional)',
              placeholder:
                'Agrega una descripción que ayude a identificar de mejor manera este esquema. Ej. "Aplica para tareas de lunes a viernes en todo horario".',
            },
            weekdays: {
              checkbox: {
                label: 'Utilizar rango de días y horas en los que aplicará el esquema.',
                detail:
                  'Al crear tareas, por defecto se utilizará el esquema de cobro cuyo rango contenga el día y hora de inicio de la tarea.',
              },
              title: 'Rango de días',
              description: 'Este esquema aplicará de acuerdo a los rangos definidos acá.',
              weekdays: {
                label: 'Rango días',
                list: {
                  monday: 'Lu',
                  tuesday: 'Ma',
                  wednesday: 'Mie',
                  thursday: 'Ju',
                  friday: 'Vi',
                  saturday: 'Sá',
                  sunday: 'Do',
                  monday_plural: 'Lunes',
                  tuesday_plural: 'Martes',
                  wednesday_plural: 'Miércoles',
                  thursday_plural: 'Jueves',
                  friday_plural: 'Viernes',
                  saturday_plural: 'Sábado',
                  sunday_plural: 'Domingo',
                },
              },
              dayhours: {
                label: 'Rango horas',
                from: { label: 'Desde *', required: 'La fecha de inicio es requerida.' },
                to: { label: 'Hasta *', required: 'La fecha de finalización es requerida.' },
              },
              error: {
                list: [
                  'El rango seleccionado traslapa con el esquema de cobro ',
                  '<b>“{{name}}”.</b>',
                  ' Selecciona otro rango para continuar.',
                ],
                popover: ['Rango días: {{days}}', 'Rango horas: {{hours}}'],
              },
            },
            dayType: {
              title: 'Días en que aplicará: ',
              label: 'Días en que aplicará *',
              monday_saturday: 'Lunes a Sábado',
              sunday_holiday: 'Domingo / Festivos',
              none: 'No Definido',
            },
          },
          delete: {
            title: 'Eliminar esquema de cobro',
            okBtn: 'Eliminar esquema de cobro',
            cancel: 'Cancelar',
            description:
              'Al eliminar el esquema de cobro {{name}}, no podrá ser utilizado para crear órdenes de trabajo. Su eliminación no afectará órdenes ya creadas o en curso.',
          },
          alert: {
            create: { ok: 'Esquema creado exitosamente', err: 'No se creó el esquema' },
            update: { ok: 'Esquema actualizado exitosamente', err: 'No se actualizó el esquema' },
            delete: { ok: 'Esquema eliminado exitosamente', err: 'No se eliminó el esquema' },
            obtain: { ok: 'No hay esquemas de cobro creados para este cliente.' },
          },
        },
        finesAndDiscounts: {
          title: 'Multas y Descuentos',
          subtitle:
            'Aquí podrás agregar y ver las multas o descuentos que se cargarán a cada sede.',

          columns: [
            'Sede',
            'Tipo',
            'Ejecución',
            'Descuento',
            {
              title: 'Acciones',
              options: ['Editar multa', 'Editar descuento', 'Eliminar multa', 'Eliminar descuento'],
            },
          ],
          emptyText: 'No tienes multas o descuentos creados',
          type: {
            discounts: 'descuento',
            fines: 'multa',
          },
          fineType: {
            claim: 'reclamo de servicio',
            ontime: 'ontime',
            other: 'otro',
          },
          form: {
            actions: {
              new: {
                title: 'Agregar multa o descuento SMU',
                alternativeTitle: 'Agregar multa o descuento',
                subtitle:
                  'Esta información será incorporada en la prefactura, en la hoja “Detalle Gastos Adicionales”.',
                buttonTitle: 'Agregar multa/descuento',
                cancellationModal: {
                  title: 'Descartar multa o descuento SMU',
                  text: 'Al salir, se perderán todos los cambios realizados.',
                  actions: ['Cancelar', 'Descartar'],
                },
                message: {
                  fines:
                    'La multa "{{fineType}}", de la sede "{{store}}", ha sido creada con éxito. La información será incorporada en la Prefactura, en la hoja “Detalle Gastos Adicionales”.',
                  discounts:
                    'El descuento de la sede "{{store}}", ha sido creado con éxito. La información será incorporada en la Prefactura, en la hoja “Detalle Gastos Adicionales”.',
                },
              },
              edit: {
                title: 'Edición multa o descuento SMU',
                subtitle:
                  'Esta información será incorporada en la prefactura, en la hoja “Detalle Gastos Adicionales”.',
                buttonTitle: 'Guardar cambios',
                cancellationModal: {
                  title: 'Edición multa o descuento SMU',
                  text: 'Al salir, se perderán todos los cambios realizados.',
                  actions: ['Cancelar', 'Salir sin Guardar'],
                },
                message: {
                  fines:
                    'La multa "{{fineType}}", de la sede "{{store}}", ha sido editada con éxito.',
                  discounts: 'El descuento de la sede "{{store}}", ha sido editado con éxito.',
                },
              },
              delete: {
                title: 'Eliminar {{type}}',
                message: {
                  fines:
                    'La multa "{{fineType}}", de la sede "{{store}}", ha sido eliminada con éxito.',
                  discounts: 'El descuento de la sede "{{store}}", ha sido eliminado con éxito.',
                },
                actions: ['Eliminar {{type}}', 'Cancelar'],
                description: {
                  fines:
                    'Al confirmar, la multa "{{fineType}}" de la sede {{store}}, será eliminada del listado y no aparecerá en la Prefactura.',
                  discounts:
                    'Al confirmar, el descuento  de la sede {{store}}, será eliminada del listado y no aparecerá en la Prefactura.',
                },
              },
            },
            inputs: [
              {
                placeHolder: 'Local',
                rules: 'Es requerido el campo.',
              },
              {
                label: 'Fecha de ejecución',
                placeHolder: 'día / mes / año',
                rules: 'Es requerido el campo.',
              },
              {
                label: '¿Qué deseas agregar?',
                placeHolder: 'Selecciona',
                rules: 'Es requerido el campo.',
              },
              {
                label: 'Multa por',
                rules: 'Es requerido el campo.',
              },
              {
                label: 'Otro',
                placeHolder: 'Ingresa el motivo de la multa',
                rules: 'Es requerido el campo.',
                empty: 'El campo no puede contener solo espacios en blanco.',
              },
              {
                label: 'Descuento',
                placeHolder: 'Ingresa el motivo del descuento',
                rules: 'Es requerido el campo.',
                empty: 'El campo no puede contener solo espacios en blanco.',
              },
              {
                label: 'Monto a descontar',
                placeHolder: '0',
                rules: 'Es requerido el campo.',
              },
            ],
          },
        },
        clientsProfiles: {
          title: 'Perfiles',
          subtitle: 'Crea y edita los perfiles que podrán ingresar al Portal empresas.',
          button: {
            newClient: 'Crear nuevo perfil',
          },
          columns: {
            profile: 'Nombre',
            rol: {
              title: 'Rol',
              content: {
                enterprise: 'Usuario',
                enterprise_admin: 'Administrador',
              },
            },
            state: {
              title: 'Estado',
              chip: {
                active: 'Activo',
                blocked: 'Desactivado',
              },
              tooltip: {
                title: 'Motivo de desactivación',
                body: 'Desactivado por {{staff}}',
              },
            },
            actions: {
              title: 'Acciones',
            },
          },
          modal: {
            createClient: {
              title: 'Crear nuevo perfil',
              subtitle: 'Completa los datos requeridos para crear un nuevo perfil.',
              okButton: 'Crear perfil',
              alert: {
                info: 'Una vez creado el perfil, el usuario recibirá un correo de activación para acceder al Portal Empresas',
              },
              inputs: {
                names: {
                  label: 'Nombre *',
                  errors: {
                    required: 'Ingresa el nombre',
                  },
                },
                surnames: {
                  label: 'Apellido *',
                  errors: {
                    required: 'Ingresa el apellido',
                  },
                },
                email: {
                  label: 'Email *',
                  errors: {
                    required: 'Ingresa el email',
                    formatError: 'Formato de email no corresponde.',
                    existsEmail: 'Existe un perfil asociado a este email. Ingresa uno distinto.',
                  },
                },
                repeatEmail: {
                  label: 'Confirmación de email *',
                  errors: {
                    required: 'Ingresa el email',
                    notMatch: 'Los correos no coinciden.',
                  },
                },
              },
              select: {
                roles: {
                  label: 'Rol *',
                  options: {
                    enterprise: 'Usuario',
                    enterprise_admin: 'Administrador',
                  },
                  errors: {
                    required: 'Ingresa el rol',
                  },
                },
              },
              table: {
                enterprise: {
                  title: 'El rol <strong>“Usuario”</strong> le permite al usuario:',
                  list: {
                    first: '- Crear, editar, visualizar y eliminar requerimientos.',
                    second: '- Gestionar nóminas y asistencias.',
                  },
                },
                enterprise_admin: {
                  title: 'El rol <strong>“Administrador”</strong> le permite al usuario:',
                  list: {
                    first: '- Crear, editar, visualizar y eliminar requerimientos.',
                    second: '- Gestionar nóminas y asistencias.',
                    third: '- Revisar la facturación.',
                  },
                },
              },
            },
            remove: {
              title: 'Remover rol Administrador',
              subtitle: '{{name}} - Administrador',
              okButton: 'Remover rol Administrador',
              body: 'Al confirmar, el usuario dejará de ser <strong>“Administrador” y pasará a rol “Usuario”,</strong> que solo le permitirá crear, editar, visualizar y eliminar requerimientos, gestionar nóminas y asistencias.',
            },
            add: {
              title: 'Cambiar a rol Administrador',
              subtitle: '{{name}} - Usuario',
              okButton: 'Cambiar a rol Administrador',
              body: 'Al confirmar, el usuario dejará de ser <strong>“Usuario” y pasará a rol “Administrador”,</strong> lo que le permitirá:',
              list: {
                first: '- Crear, editar, visualizar y eliminar requerimientos.',
                second: '- Gestionar nóminas y asistencias.',
                third: '- Revisar la facturación.',
              },
            },
            block: {
              title: 'Desactivar perfil',
              subtitle: '{{name}} - Rol {{rol}}',
              okButton: 'Desactivar perfil',
              body: 'Al confirmar, la cuenta será desactivada y ya no podrá ingresar nuevamente al Portal Empresas.',
              textArea: {
                placeholder: 'Ingresa el porque de la medida.',
                label: 'Motivo de desactivación *',
                maxLength: '{{count}}/{{maxLength}} caracteres',
                errors: {
                  required: 'Ingresa el motivo para continuar.',
                },
              },
            },
            unblock: {
              title: 'Reactivar perfil',
              subtitle: '{{name}} - Rol {{rol}}',
              okButton: 'Reactivar perfil',
              body: 'Al confirmar la reactivación, el usuario podrá realizar todas las acciones disponibles del <strong>Rol {{role}}.</strong>',
            },
          },
          alert: {
            create: {
              success: 'Perfil creado exitosamente.',
              error: 'Perfil no puedo ser creado. Intenta nuevamente.',
            },
            edit: {
              add: {
                success: 'El Rol ha cambiado a <strong>“Administrador”</strong> exitosamente.',
                error: 'El rol no pudo ser cambiado. Intenta nuevamente.',
              },
              remove: {
                success: 'Rol <strong>“Administrador”</strong> removido exitosamente.',
                error: 'El rol no pudo ser removido. Intenta nuevamente.',
              },
            },
            blocked: {
              success: 'El perfil <strong>“{{name}}”</strong> fue desactivado exitosamente.',
              error: 'El perfil no pudo ser desactivado. Intenta nuevamente.',
            },
            unblocked: {
              success: 'Perfil reactivado exitosamente.',
              error: 'El perfil no pudo ser reactivado. Intenta nuevamente.',
            },
          },
          actions: {
            remove: 'Remover rol Administrador',
            blocked: 'Desactivar perfil',
            unblocked: 'Reactivar perfil',
            add: 'Otorgar rol Administrador',
          },
          roles: {
            enterprise: 'Usuario',
            enterprise_admin: 'Administrador',
          },
          filters: {
            search: {
              label: 'Buscar',
              placeholder: 'Busca por nombre o email',
            },
          },
        },
      },
      paymentSchemes: {},
    },

    drawer: {
      buttonsActions: ['Agregar otro contacto'],
      new: {
        title: 'Creación cliente comercial',
        subtitle: 'Ingresa los datos obligatorios (*) para crear el cliente comercial',
        buttonsActions: ['Crear cliente comercial'],
        message:
          'El cliente comercial se ha creado exitosamente. Puedes revisarlo en el buscador lateral.',
        cancelModal: {
          text: 'Al salir, se perderán todos los cambios realizados.',
          title: 'Descartar creación de cliente ',
          okText: 'Descartar cliente',
          cancelText: 'Cancelar',
        },
      },
      edit: {
        title: 'Edición cliente comercial',
        subtitle: 'Ingresa los datos obligatorios (*) para crear el cliente comercial',
        message: 'El cliente comercial se ha editado exitosamente. ',
        buttonsActions: ['Guardar cambios'],
        cancelModal: {
          text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          title: 'Cambios sin guardar',
          okText: 'Salir sin guardar',
          cancelText: 'Guardar cambios',
        },
      },
      delete: {
        title: 'Eliminar cliente',
        okText: 'Eliminar cliente',
        message: 'El cliente comercial se ha eliminado exitosamente.',
        error: 'No se pudo eliminar el cliente porque tiene tareas creadas.',
        text: 'Al eliminar el cliente comercial {{input}}, no estará disponible para crear nuevas órdenes de trabajo.',
      },
      errors: {
        409: 'El valor ingresado pertenece a un cliente comercial creado anteriormente',
      },
      form: {
        sections: [
          {
            title: 'Información',
            subtitle: 'Ingresa los datos que serán utilizados en la facturación',
            inputs: [
              {
                label: 'Razón social',
                required: 'Ingresa la razón social',
              },
              {
                label: 'Nombre comercial',
                required: 'Ingresa el nombre comercial',
                message: 'Ingresa caracteres alfanumericos',
              },
              {
                label: 'RUT',
                required: 'Ingresa el RUT',
              },
              {
                label: 'Giro',
                required: 'Ingresa el giro',
              },
              {
                label: 'Teléfono',
                required: 'Ingresa el telefono',
              },
              {
                label: 'Grupo económico (opcional)',
                required: 'Ingresa el grupo económico',
              },
            ],
          },
          {
            title: 'Ubicación',
            inputs: [
              {
                required: 'Selecciona la $t(global.region)',
              },
              {
                required: 'Selecciona la $t(global.commune)',
              },
              {
                required: 'Ingresa la dirección',
              },
            ],
          },
          {
            title: 'Tipo de tareas',
            inputs: [
              {
                label: 'Tipo de tareas',
                placeholder: 'Selecciona',
                required: 'Selecciona el tipo de tareas para este cliente',
              },
            ],
          },
          {
            title: 'CSM y Staff responsables',
            inputs: [
              {
                label: 'Staff',
              },
              {
                label: 'CSM',
              },
            ],
          },
          {
            title: 'Portal empresas',
            subtitle:
              'Selecciona la anticipación con la que el cliente podrá visualizar los Jobbers asignados y presentes de un requerimiento en asignación.',
            inputs: [
              {
                label: 'Visualización de Jobbers *',
                option: '1 día antes',
                option_plural: '{{count}} días antes',
              },
            ],
          },
          {
            title: 'Contactos',
            subtitle: 'Ingresa los datos de la persona con quien se mantendrá contacto',
            panelTitle: 'Contacto {{input}}',
            typeContacts: ['comercial', 'finanzas', 'alternativo'],
            inputs: [
              {
                label: 'Nombre y apellido',
                required: 'Ingresa nombre y apellido',
              },
              {
                label: 'Cargo',
                required: 'Ingresa el cargo',
              },
              {
                label: 'Tipo de contacto',
                required: 'Agrega el tipo de contacto',
              },
              {
                label: 'Email',
                required: 'Ingresa un email',
              },
              {
                label: 'Teléfono',
                required: 'Ingresa un teléfono',
              },
            ],
          },
          {
            title: 'Asignación de tareas',
            inputs: [
              {
                options: {
                  manual: 'Manual (Staff)',
                  auto: 'Automática',
                },
              },
              {
                auto: 'La asignación de Jobbers será de manera automática, siempre y cuándo el Jobber cumpla con los críterios definidos en la tarea. ',
                manual: 'La asignación de Jobbers será a cargo del Staff.',
              },
            ],
          },
          {},
        ],
        selectRegionInput: {
          placeholder: 'Seleccione una Provincia',
        },
        selectComuneInput: {
          placeholder: 'Seleccione un Distruto',
        },
        paymentRule: {
          title: 'Vencimiento quincenal',
          description: 'Todas la tareas tendrán este criterio, solo ten presente que:',
          first:
            'Las tareas ejecutadas entre el <strong>1 y el 15 de cada mes,</strong> se pagan el 20 de ese mismo mes.',
          second:
            'Las tareas ejecutadas <strong>entre el 16 y el último día del mes</strong> se pagan el 5 del mes siguiente.',
          third:
            'Si el día de <strong>vencimiento cae día sábado o domingo,<strong> la fecha de vencimiento será el siguiente día hábil.',
        },
      },
    },
  },
};
