import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import server from '@services/api';
import i18n from 'i18next';

import { RequestStatusEnum } from '@utils/enum';
import {
  createJobOrderTemplateRoute,
  deleteJobOrderTemplatesRoute,
  getJobOrderTemplatesRoute,
  updateJobOrderTemplateRoute,
  updateViewStatusJobOrderTemplateRoute,
} from './ts/routes';
import { sendLog } from '@utils/SentryError';
import {
  CreateJobOrderTemplatePayload,
  GetJobOrderTemplatesPayload,
  GetTemplatePayload,
  UpdateJobOrderTemplatePayload,
  UpdateViewStatusJobOrderTemplatePayload,
} from './ts/templates.interfaces';
import { ActionsEnum } from './ts/templates.enum';

export const templatesAdapter = createEntityAdapter();

export const initialState = templatesAdapter.getInitialState({
  jobOrderTemplate: {
    action: ActionsEnum.CREATE,
    status: RequestStatusEnum.IDLE,
    alertMessage: {},
  },
  listJobOrderTemplates: {
    action: ActionsEnum.LIST,
    status: RequestStatusEnum.IDLE,
    selectData: { docs: [] },
    data: { docs: [], previous: null, next: null, totalDocs: null },
  },
  listJobOrderTemplatesForSelect: {
    docs: [],
    loading: false,
    defaultValue: undefined,
  },
  deleteJobOrderTemplate: {
    action: ActionsEnum.DELETE,
    status: RequestStatusEnum.IDLE,
  },
  templateDetails: {
    loading: RequestStatusEnum.IDLE,
    data: { docs: [], previous: null, next: null, totalDocs: null },
  },
});

export const createJobOrderTemplate = createAsyncThunk(
  'templates/createJobOrderTemplate',
  async (payload: CreateJobOrderTemplatePayload, { getState, rejectWithValue }: any) => {
    try {
      const { status } = getState().templates?.jobOrderTemplate;
      if (status !== RequestStatusEnum.PENDING) return;

      const response = await server.post(createJobOrderTemplateRoute(), payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateJobOrderTemplate = createAsyncThunk(
  'templates/updateJobOrderTemplate',
  async (payload: UpdateJobOrderTemplatePayload, { getState, rejectWithValue }: any) => {
    try {
      const { status } = getState().templates?.jobOrderTemplate;
      if (status !== RequestStatusEnum.PENDING) return;

      const response = await server.put(updateJobOrderTemplateRoute(payload.templateId), payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateViewStatusJobOrderTemplate = createAsyncThunk(
  'templates/updateViewStatusJobOrderTemplate',
  async (payload: UpdateViewStatusJobOrderTemplatePayload, { getState, rejectWithValue }: any) => {
    try {
      const { status } = getState().templates?.jobOrderTemplate;
      if (status !== RequestStatusEnum.PENDING) return;

      const response = await server.put(
        updateViewStatusJobOrderTemplateRoute(payload.templateId),
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getJobOrderTemplates = createAsyncThunk(
  'templates/getJobOrderTemplates',
  async (payload: GetJobOrderTemplatesPayload, { getState, rejectWithValue }: any) => {
    try {
      const { status } = getState().templates?.listJobOrderTemplates;
      if (status !== RequestStatusEnum.PENDING) return;

      const response = await server.get(getJobOrderTemplatesRoute(), { params: payload });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getJobOrderTemplatesForSelect = createAsyncThunk(
  'templates/getJobOrderTemplatesForSelect',
  async (params: GetJobOrderTemplatesPayload) => {
    const response = await server.get(getJobOrderTemplatesRoute(), { params });
    return response.data;
  }
);

export const getTemplateDetails = createAsyncThunk(
  'templates/getTemplateDetails',
  async (payload: GetTemplatePayload, { getState, rejectWithValue }: any) => {
    try {
      const { loading } = getState().templates?.templateDetails;
      if (loading !== RequestStatusEnum.PENDING) return;
      const response = await server.get(getJobOrderTemplatesRoute(), { params: payload });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteJobOrderTemplate = createAsyncThunk(
  'templates/deleteJobOrderTemplates',
  async (_id: string, { getState, rejectWithValue }: any) => {
    try {
      const { status } = getState().templates?.deleteJobOrderTemplate;
      if (status !== RequestStatusEnum.PENDING) return;

      const response = await server.delete(deleteJobOrderTemplatesRoute(_id));
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    clearState: (state, b: { payload: { key: string; subkey?: string; base?: any } }) => {
      const { key, subkey, base } = b.payload ?? {};
      const originalState = base ?? RequestStatusEnum.IDLE;
      if (subkey && state[key]?.[subkey]) state[key][subkey] = originalState;
      else if (key && state[key]) state[key] = originalState;
    },
  },
  extraReducers: {
    [String(createJobOrderTemplate.pending)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.IDLE) {
        state.jobOrderTemplate.action = ActionsEnum.CREATE;
        state.jobOrderTemplate.status = RequestStatusEnum.PENDING;
      }
    },
    [String(createJobOrderTemplate.fulfilled)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.PENDING) {
        state.jobOrderTemplate.status = RequestStatusEnum.IDLE;
        state.jobOrderTemplate.alertMessage = {
          type: 'success',
          text: i18n.t('tasks.template.drawer.new.alerts.success'),
        };
      }
    },
    [String(createJobOrderTemplate.rejected)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.PENDING) {
        state.jobOrderTemplate.status = RequestStatusEnum.IDLE;
        state.jobOrderTemplate.alertMessage = {
          type: 'error',
          text: action.error,
        };
        sendLog(
          'Store - Templates',
          action?.meta?.arg,
          'Fatal',
          action.error,
          'BO-TEMPLATES_SLICE-65'
        );
      }
    },
    [String(updateJobOrderTemplate.pending)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.IDLE) {
        state.jobOrderTemplate.action = ActionsEnum.UPDATE;
        state.jobOrderTemplate.status = RequestStatusEnum.PENDING;
      }
    },
    [String(updateJobOrderTemplate.fulfilled)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.PENDING) {
        state.jobOrderTemplate.status = RequestStatusEnum.IDLE;
        state.jobOrderTemplate.alertMessage = {
          type: 'success',
          text: i18n.t('tasks.template.drawer.edit.alerts.success'),
        };
      }
    },
    [String(updateJobOrderTemplate.rejected)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.PENDING) {
        state.jobOrderTemplate.status = RequestStatusEnum.IDLE;
        state.jobOrderTemplate.alertMessage = {
          type: 'error',
          text: action.error,
        };
        sendLog(
          'Store - Templates',
          action?.meta?.arg,
          'Fatal',
          action.error,
          'BO-TEMPLATES_SLICE-136'
        );
      }
    },

    [String(updateViewStatusJobOrderTemplate.pending)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.IDLE) {
        state.jobOrderTemplate.action = ActionsEnum.UPDATE;
        state.jobOrderTemplate.status = RequestStatusEnum.PENDING;
      }
    },
    [String(updateViewStatusJobOrderTemplate.fulfilled)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.PENDING) {
        state.jobOrderTemplate.status = RequestStatusEnum.IDLE;
        state.jobOrderTemplate.alertMessage = {
          type: 'success',
          text: i18n.t(
            `tasks.jobs.dashboard.tabs.templates.modal.${action.meta.arg.viewStatus}.alert.success`
          ),
        };
      }
    },
    [String(updateViewStatusJobOrderTemplate.rejected)]: (state, action) => {
      if (state.jobOrderTemplate.status === RequestStatusEnum.PENDING) {
        state.jobOrderTemplate.status = RequestStatusEnum.IDLE;
        state.jobOrderTemplate.alertMessage = {
          type: 'error',
          text: i18n.t(
            `tasks.jobs.dashboard.tabs.templates.modal.${action.meta.arg.viewStatus}.alert.error`
          ),
        };
        sendLog(
          'Store - Templates',
          action?.meta?.arg,
          'Fatal',
          action.error,
          'BO-TEMPLATES_SLICE-136'
        );
      }
    },

    [String(getJobOrderTemplates.pending)]: (state, action) => {
      if (state.listJobOrderTemplates.status === RequestStatusEnum.IDLE) {
        state.listJobOrderTemplates.action = action.meta.arg?.forSelect
          ? ActionsEnum.SELECT_LIST
          : ActionsEnum.LIST;
        state.listJobOrderTemplates.status = RequestStatusEnum.PENDING;
      }
    },
    [String(getJobOrderTemplates.fulfilled)]: (state, action) => {
      if (state.listJobOrderTemplates.status === RequestStatusEnum.PENDING) {
        const { forSelect, hideDisabled } = action.meta.arg;
        const field = forSelect ? 'selectData' : 'data';

        state.listJobOrderTemplates.status = RequestStatusEnum.IDLE;
        state.listJobOrderTemplates[field] = hideDisabled
          ? {
              ...action.payload?.data?.data,
              docs: action.payload?.data?.data?.docs.filter(
                (obj) => obj.disabledAttributes?.length === 0
              ),
            }
          : action.payload?.data?.data;
      }
    },
    [String(getJobOrderTemplates.rejected)]: (state, action) => {
      if (state.listJobOrderTemplates.status === RequestStatusEnum.PENDING) {
        state.listJobOrderTemplates.status = RequestStatusEnum.IDLE;
        sendLog(
          'Store - Templates',
          action?.meta?.arg,
          'Fatal',
          action.error,
          'BO-TEMPLATES_SLICE-106'
        );
      }
    },

    [String(getJobOrderTemplatesForSelect.pending)]: (state) => {
      state.listJobOrderTemplatesForSelect.loading = true;
    },
    [String(getJobOrderTemplatesForSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;
      const newDocs =
        next || firstLoad
          ? [
              ...state.listJobOrderTemplatesForSelect?.docs,
              ...action.payload.data?.docs?.filter(
                (obj) => obj._id !== state.listJobOrderTemplatesForSelect?.defaultValue
              ),
            ]
          : action.payload.data?.docs;

      state.listJobOrderTemplatesForSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue: next || firstLoad ? state.listJobOrderTemplatesForSelect?.defaultValue : id,
      };
    },
    [String(getJobOrderTemplatesForSelect.rejected)]: (state, action) => {
      state.listJobOrderTemplatesForSelect.loading = false;
    },

    [String(getTemplateDetails.pending)]: (state, action) => {
      if (state.templateDetails.loading === RequestStatusEnum.IDLE) {
        state.templateDetails.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(getTemplateDetails.fulfilled)]: (state, action) => {
      if (state.templateDetails.loading === RequestStatusEnum.PENDING) {
        state.templateDetails = {
          ...action.payload?.data?.data,
          loading: RequestStatusEnum.IDLE,
        };
      }
    },
    [String(getTemplateDetails.rejected)]: (state, action) => {
      if (state.templateDetails.loading === RequestStatusEnum.PENDING) {
        state.templateDetails.loading = RequestStatusEnum.IDLE;
        sendLog(
          'Store - Templates',
          action?.meta?.arg,
          'Fatal',
          action.error,
          'BO-TEMPLATES_SLICE-106'
        );
      }
    },

    [String(deleteJobOrderTemplate.pending)]: (state, action) => {
      if (state.deleteJobOrderTemplate.status === RequestStatusEnum.IDLE) {
        state.deleteJobOrderTemplate.action = ActionsEnum.CREATE;
        state.deleteJobOrderTemplate.status = RequestStatusEnum.PENDING;
      }
    },
    [String(deleteJobOrderTemplate.fulfilled)]: (state, action) => {
      if (state.deleteJobOrderTemplate.status === RequestStatusEnum.PENDING) {
        state.deleteJobOrderTemplate.status = RequestStatusEnum.IDLE;
      }
    },
    [String(deleteJobOrderTemplate.rejected)]: (state, action) => {
      if (state.deleteJobOrderTemplate.status === RequestStatusEnum.PENDING) {
        state.deleteJobOrderTemplate.status = RequestStatusEnum.IDLE;
        sendLog(
          'Store - Templates',
          action?.meta?.arg,
          'Fatal',
          action.error,
          'BO-TEMPLATES_SLICE-65'
        );
      }
    },
  },
});

export const { clearState } = templatesSlice.actions;
export default templatesSlice.reducer;
