export default {
  blocklist: {
    title: 'Jobbers bloqueados y eliminados',
    subtitle:
      'Revisa el listado de Jobbers bloqueados y de Jobbers que solicitaron la eliminación de sus cuentas (inactivos).',
    tabs: {
      blockedJobbers: 'Jobbers bloqueados',
      deletedJobbers: 'Jobbers eliminados',
    },
    home: {
      blockedJobbers: {
        title: 'Blocklist',
        subTitle: 'Desde las acciones podrás desbloquear los Jobbers.',
        buttonText: '+ Nuevo bloqueo',
        table: {
          id: 'ID',
          name: 'Nombre',
          surname: 'Apellido',
          type: {
            block: 'Tipo de bloqueo',
            restriction: 'Tipo de restricción',
          },
          blockType: { general: 'General', specific: 'Específico' },
          lastModification: 'Última modificación',
          staff: 'Staff responsable',
          actions: 'Acciones',
          restriction: {
            client: 'Cliente',
            client_premises: 'Cliente/Sede',
            client_task: 'Cliente/Tipo de tarea',
            task: 'Tipo de tarea',
            task_premises: 'Tipo de tarea/Sede',
          },
        },
        actions: {
          newBlocked: 'Nuevo bloqueo',
          restriction: 'Nueva restricción',
          unrestriction: 'Eliminar restricción',
          block: 'Bloquear',
          unblock: 'Desbloquear Jobber',
          changeBlock: 'Modificar tipo de bloqueo',
        },
        filters: {
          options: {
            blockType: { general: 'General', specific: 'Específico' },
            restrictionType: {
              client: 'Cliente',
              client_premises: 'Cliente/Sede',
              client_task: 'Cliente/Tipo de tarea',
              task: 'Tipo de tarea',
              task_premises: 'Tipo de tarea/Sede',
            },
          },
          filterSearch: { title: 'Buscar', placeholder: 'Busca por ID, Nro de documento o correo' },
          filterDatePicker: { title: 'Fecha última modificación' },
          filterClear: { title: 'Limpiar filtros' },
          placeholder: 'Selecciona',
        },
        downloadReports: {
          button: 'Descargar Bloqueos',
          alerts: {
            success: 'Reporte enviado a su correo con éxito.',
            error: 'No se pudo descargar el reporte. Por favor intente nuevamente.',
          },
        },
      },
      deletedJobbers: {
        title: 'DeletedList',
        subTitle: 'Desde las acciones podrás reactivar las cuentas.',
        table: {
          user: 'Usuario',
          location: 'Regíon / comuna',
          deletedAt: 'Fecha de eliminación',
          deletedBy: 'Staff que eliminó',
          status: 'Estados',
          actions: 'Acciones',
        },
        actions: {
          restore: 'Reactivar cuenta',
        },
        filters: {
          options: {
            blockType: { general: 'General', specific: 'Específico' },
            restrictionType: {
              client: 'Cliente',
              client_premises: 'Cliente/Sede',
              client_task: 'Cliente/Tipo de tarea',
              task: 'Tipo de tarea',
              task_premises: 'Tipo de tarea/Sede',
            },
          },
          filterSearch: {
            title: 'Buscar Jobbers',
            placeholder: 'Busca por nombre o correo',
          },
          filterDatePicker: { title: 'Fecha de eliminación' },
          filterClear: { title: 'Limpiar filtros' },
          placeholder: 'Selecciona',
        },
        downloadReports: {
          button: 'Descargar Eliminados',
          alerts: {
            success: 'Reporte enviado a su correo con éxito.',
            error: 'No se pudo descargar el reporte. Por favor intente nuevamente.',
          },
        },
      },
    },
    summary: {
      blocked: {
        title: 'Jobber bloqueado',
        staff: 'Staff responsable:',
        userBlocked: 'Jobber bloqueado',
        evidence: {
          photo: 'Foto ',
          document: 'Documento ',
          title: 'Evidencia',
          alt: 'Evidencia fotográfica',
          notEvidence: 'No se registró evidencia',
        },
        reason: {
          noncompliance: 'Incumplimiento',
        },
        titleCard: 'Bloqueo',
      },
      restriction: {
        titleCard: 'Restricción',
        avatarRestriction: 'Restringido',
        tag: {
          viewJobs: 'Puede ver tareas',
        },
        cards: {
          staffName: 'Staff responsable',
          reason: 'Motivo',
          description: 'Comentario',
          kind: 'Tipo de bloqueo',
          restriction: 'Restricción',
          enterprise: 'Cliente',
          premises: 'Sede',
          task: 'Tipo de tarea',
          evidence: 'Evidencia',
        },
      },
      history: 'Historial de bloqueo',
      editGeneral: {
        title: 'Editar información',
        subtitle: 'Selecciona la información que quieras editar',
        alerts: {
          success: 'Información editada exitosamente',
          error: 'Ocurrió un error en la edición, intenta de nuevo por favor',
        },
      },
      button: {
        save: 'Guardar',
        cancel: 'Cancelar',
      },
    },
    blockModal: {
      title: 'Bloquear Jobber',
      subtitle:
        'Selecciona el motivo de bloqueo y luego agrega un comentario que entregue más detalles. También puedes agregar un documento o imagen de respaldo.',
      nameJobber: 'Jobber',
      rules: {
        description: 'Ingresa una descripción',
      },
      inputs: {
        names: {
          label: 'Jobber *',
          placeholder: 'Buscar por Jobber',
        },
        kind: {
          label: 'Selecciona el tipo de bloqueo *',
          general: 'General',
          specific: 'Específico',
        },
        restrictionType: {
          label: 'Tipo de restricción *',
          client: 'Cliente',
          client_premises: 'Cliente/Sede',
          client_task: 'Cliente/Tipo de tarea',
          task: 'Tipo de Tarea',
          task_premises: 'Tipo de tarea/Sede',
        },
        client: {
          label: 'Cliente comercial *',
          required: 'Selecciona un cliente',
        },
        premises: {
          label: 'Sede *',
          required: 'Selecciona una sede',
        },
        task: {
          label: 'Tarea *',
          required: 'Selecciona un tipo de tarea',
        },
        textarea: {
          label: 'Escribe un comentario *',
          placeholder: 'Ingresa más detalles del motivo del bloqueo del Jobber',
        },
        reason: {
          label: 'Motivo de bloqueo *',
          values: ['Otro', 'Mal comportamiento', 'Acusación de robo', 'Incumplimiento'],
          other: 'Motivo *',
        },
        file: {
          button: 'Cargar evidencia',
        },
      },
      checkbox: {
        viewJobs:
          'Permitir al jobber visualizar las tareas disponibles. El staff no podrá asignar al jobber si postula mientras esté vigente el blocklist específico.',
      },
      evidence: {
        title: 'Evidencia (opcional)',
        subtitle: 'Peso máximo de 10 mb - Formato: jpg, png, pdf',
      },
      status: {
        success: `El jobber {{name}} {{surname}} fue bloqueado exitosamente `,
        error: 'No pudimos realizar el bloqueo del Jobber. Intenta nuevamente',
      },
    },
    deleteBlockModalConfirmation: {
      title: 'Eliminar restricción',
      subtitle1: 'Al eliminar esta restricción, el Jobber ',
      subtitle2: ' podrá postular y asistir a tareas de este cliente y sede.',
      back: 'Cancelar',
      okConfirm: 'Eliminar',
    },
    unblock: {
      modal: {
        buttonOk: 'Desbloquear Jobber',
      },
      actions: {
        newRestriction: 'Nueva restricción',
      },
      title: 'Desbloquear Jobber',
      subtitle:
        'Al desbloquear el Jobber {{name}} {{surname}} este(a) quedará activo(a) y podrá postular y asistir a tareas',
      status: {
        error: 'No pudimos realizar el desbloqueo del Jobber. Intenta nuevamente',
      },
      labelRestriction: 'Restricción *',
      subtitleRestriction: 'Selecciona la restricción que deseas eliminar',
    },
    restoreModal: {
      title: 'Reactivar cuenta',
      message: {
        isJobber: {
          true: 'Al confirmar la reactivación, el usuario pasará a la lista de “Jobbers activos” y podrá postular a tareas.',
          false:
            'Al confirmar la reactivación, el usuario pasará a la lista de “Pendientes Applicant”.',
        },
      },
    },
    errors: {
      inputs: {
        names: 'Por favor ingrese un nombre válido',
      },
    },
    alerts: {
      blockedSuccess: 'Bloqueo creado exitosamente',
      restrictionSuccess: 'Restricción creada exitosamente',
      unBlockSuccess: `El jobber {{name}} {{surname}} fue desbloqueado exitosamente `,
      unRestrictionSuccess: `Restricción eliminada satisfactoriamente`,
      errorUnrestriction:
        'Hubo un error al eliminar la restricción. Recargue la página e intente nuevamente, por favor',
      alertMessage:
        'Al eliminar esta restricción, el Jobber {{names}} {{surnames}} podrá postular y asistir a tareas de este cliente y sede.',
      restoreSuccess: {
        isJobber: {
          true: 'El usuario “{{fullName}}” ha sido reactivado exitosamente. Podrás revisarlo en la vista de “Jobbers activos”.',
          false:
            'El usuario “{{fullName}}” ha sido reactivado exitosamente. Podrás revisarlo en la vista de “Pendientes Applicant”.',
        },
      },
      restoreError: 'El usuario “{{fullName}}” no pudo ser reactivado. Intenta nuevamente.',
    },

    safelist: {
      tabs: { safelist: 'Safelist' },
      home: { subTitle: 'Acá aparecen ...falta texto...' },
    },
    blockInfo: {
      title: 'Historial de bloqueos',
      btnShowMore: '+ Ver más',
      btnShowLess: '- Ver menos',
      titleCollapse: {
        blocked: 'Bloqueo',
        clientSede: 'Cliente/Sede',
        client: 'Cliente',
        typeTask: 'Tipo de tarea',
      },
      newBlocked: 'Jobber es bloqueado',
      jobberUnBlocked: 'Jobber es desbloqueado',
      addEvidence: 'Se añade nueva evidencia',
      addRestriction: 'Se crea nueva restricción',
      editBlockedRestriction: 'Se modifica restricción',
      editBlockedGeneral: 'Se modifica bloqueo',
      deletedRestriction: 'Se elimina restricción',
      status: {
        new: 'Nuevo',
        unBlocked: 'Desbloqueado',
        edit: 'Editado',
        newRestriction: 'Nueva restricción',
        deleteRestriction: 'Restricción eliminada',
        editRestriction: 'Restricción editada',
      },
      tags: {
        unblocked: 'Desbloqueado por ',
        editBlocked: 'Bloqueado por ',
        editRestriction: 'Editado por ',
        createRestriction: 'Creada por ',
        deleted: 'Eliminada por ',
      },
    },
  },
};
