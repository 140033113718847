import {
  NpsLegendEnum,
  NpsAtributeEnum,
  qualificationTasksEnum,
} from '@features/dashboard/ts/dashboard.enums';
import { capitalize } from 'lodash';
import i18n from 'i18next';
import moment from 'moment';
import { ApexOptions } from 'apexcharts';

const styleFontFamily = {
  fontFamily: 'Nunito Sans',
};

const noData = {
  text: 'Sin datos que mostrar ...',
  align: 'center',
  verticalAlign: 'middle',
  offsetX: 0,
  offsetY: 0,
  style: {
    color: '#000000',
    fontSize: '14px',
    ...styleFontFamily,
  },
};

const formatPercentage = (val) => `${!isNaN(val) ? val : 0}%`;

export const npsConfig = {
  mixedChart: (labels) => ({
    chart: {
      type: 'line',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [3],
      offsetY: -8,
      background: {
        enabled: false,
        dropShadow: {
          enabled: false,
        },
      },
      style: {
        fontSize: '14px',
        colors: ['#000'],
        ...styleFontFamily,
      },
      formatter: formatPercentage,
    },
    stroke: {
      curve: 'straight',
      colors: ['transparent', 'transparent', 'transparent', '#FF6477'],
      width: [4, 4, 4, 3],
    },
    markers: {
      size: 6,
      strokeWidth: 0,
    },
    xaxis: {
      categories: labels,
      labels: {
        style: styleFontFamily,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      max: 100,
      labels: {
        style: styleFontFamily,
        formatter: (val) =>
          formatPercentage(!isNaN(val) && val > 0 && val <= 100 ? Math.round(val) : 0),
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      style: styleFontFamily,
      y: {
        formatter: function (val, ctx) {
          if (ctx.seriesIndex === 3) return null;
          return formatPercentage(val);
        },
      },
      x: {
        formatter: function (_, ctx) {
          return `${capitalize(ctx.w.globals.categoryLabels[ctx.dataPointIndex])} - NPS ${
            ctx.series[3][ctx.dataPointIndex]
          }%`;
        },
      },
    },
    legend: {
      ...styleFontFamily,
      markers: {
        fillColors: [null, null, null, 'transparent'],
        radius: 2,
        customHTML: function () {
          return `
            <div style="position: relative; height: 12px; width: 12px; z-index: -1;">
              <div style="position: absolute; height: 2px; width: 12px; background-color: #FF6477; top: 5px"></div>
              <div style="position: absolute; height: 6px; width: 6px; background-color: #FF6477; top: 3px; left: 3px; border-radius: 100px;"></div>
            </div>`;
        },
      },
    },
    noData,
  }),
  pieChart: (labels, jobbers) => ({
    chart: {
      width: 380,
      type: 'pie',
    },
    jobbers,
    labels: labels,
    legend: {
      ...styleFontFamily,
      position: 'left',
    },
    tooltip: {
      style: styleFontFamily,
      y: {
        formatter: (_, ctx) => `${ctx.config.jobbers[ctx.dataPointIndex]} jobbers`,
      },
    },
    dataLabels: {
      style: styleFontFamily,
    },
    colors: ['#00D8B7', '#008FF8'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    noData,
  }),
  barChart: (barTotal, barLabels) => ({
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
      },
    },
    legend: styleFontFamily,
    dataLabels: { enabled: false, style: styleFontFamily },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      style: styleFontFamily,
      y: {
        formatter: function (val) {
          return `${Math.ceil((Number(val) * barTotal) / 100)} (${Number(val).toFixed(2)}%)`;
        },
      },
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        style: {
          fontSize: '14px',
          ...styleFontFamily,
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: '14px',
          ...styleFontFamily,
        },
        formatter: (val) => formatPercentage(Number(parseFloat(String(val)).toFixed(2))),
      },
      categories: barLabels,
    },
    noData,
  }),
};

export const qualificationTasksConfig = {
  mixedChart: (labels: Array<string>) => ({
    chart: {
      type: 'line',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: false,
        columnWidth: '33%',
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [5],
      offsetY: -10,
      background: {
        enabled: false,
        dropShadow: {
          enabled: false,
        },
      },
      style: {
        fontSize: '14px',
        colors: ['#373D3F'],
        ...styleFontFamily,
      },
      formatter: (val) => {
        return `${(val * 5) / 100}`;
      },
    },
    colors: ['#FF455F', '#8C75D5', '#FFBC52', '#4CE7A9', '#3CA0F9', '#FF6477'],
    stroke: {
      show: true,
      curve: 'straight',
      width: [0, 0, 0, 0, 0, 3],
    },
    markers: {
      size: 6,
      strokeWidth: 1,
    },
    xaxis: {
      categories: labels,
      labels: {
        style: styleFontFamily,
      },
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        style: styleFontFamily,
        formatter: (val, ctx) =>
          formatPercentage(!isNaN(val) && val > 0 && val <= 100 ? Math.round(val) : 0),
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      style: styleFontFamily,
      y: {
        formatter: function (val, ctx) {
          if (ctx.seriesIndex === 5) return null;
          return `${ctx.w.config.series[ctx.seriesIndex].extra[ctx.dataPointIndex] || 0} Jobbers`;
        },
      },
      x: {
        formatter: (_, ctx) => {
          return `Total Jobbers ${ctx.w.config.series[5].extra[ctx.dataPointIndex] || 0}`;
        },
      },
    },
    legend: {
      ...styleFontFamily,
      markers: {
        fillColors: [null, null, null, null, null, 'transparent'],
        radius: 2,
        customHTML: function () {
          return `
            <div style="position: relative; height: 12px; width: 12px; z-index: -1;">
              <div style="position: absolute; height: 2px; width: 12px; background-color: #FF6477; top: 5px"></div>
              <div style="position: absolute; height: 6px; width: 6px; background-color: #FF6477; top: 3px; left: 3px; border-radius: 100px;"></div>
            </div>`;
        },
      },
    },
    noData,
  }),

  starsChart: (labels: Array<string>) => ({
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        distributed: true,
        columnWidth: '75%',
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: false,
          orientation: 'horizontal',
        },
      },
    },
    colors: [
      '#3CA0F9',
      '#4CE7A9',
      '#FFBC52',
      '#FF6477',
      '#8C75D5',
      '#3F6FEA',
      '#B3AC04',
      '#DB3FE9',
    ],
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => `${val} %`,
      textAnchor: 'middle',
      distributed: true,
      offsetX: 0,
      offsetY: -20,
      style: {
        ...styleFontFamily,
        fontSize: '12px',
        fontWeight: 'bold',
        colors: ['#373D3F'],
      },
    },
    xaxis: {
      type: 'category',
      categories: labels,
      position: 'bottom',
      labels: {
        show: false,
      },
    },
    yaxis: {
      max: 100,
      min: 0,
      labels: {
        show: true,
        style: styleFontFamily,
        formatter: (val) => parseFloat(val).toFixed(0),
      },
    },
    tooltip: {
      style: styleFontFamily,
      y: { formatter: (_, ctx) => ctx.w.config.series[0].values[ctx.dataPointIndex] },
      x: { formatter: (val) => val },
    },
    legend: {
      ...styleFontFamily,
      fontWeight: 400,
      fontSize: '14px',
      labels: {
        colors: '#373D3F',
      },
      markers: {
        width: 12,
        height: 12,
        strokeColor: '#fff',
        radius: 2,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 3,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    noData,
  }),
};

export const onboardingChartConfig = {
  barChart: (categoriesLabels, extraInfo, colors): ApexOptions => ({
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 0,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
      style: styleFontFamily,
    },
    colors,
    xaxis: {
      type: 'category',
      categories: categoriesLabels,
      min: 0,
      max: 100,
      range: 10,
      labels: {
        style: {
          fontSize: '14px',
          ...styleFontFamily,
        },
        formatter: (val) => {
          return `${val}%`;
        },
      },
    },
    yaxis: {
      labels: {
        minWidth: 325,
        maxWidth: 325,
        style: {
          fontSize: '14px',
          fontWeight: 600,
          ...styleFontFamily,
        },
        formatter(val) {
          return `${val}`;
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    annotations: {
      extra: extraInfo,
    } as ApexAnnotations,
    tooltip: {
      style: styleFontFamily,
      y: {
        title: {
          formatter() {
            return '';
          },
        },
        formatter(percent, opts) {
          const index = opts.dataPointIndex;
          const qty = opts.w.config.annotations.extra[index].qty;
          const type = opts.w.config.annotations.extra[index].type;
          return `${qty} ${type}: ${parseFloat(String(percent)).toFixed(2)}%`;
        },
      },
    },
  }),
};

/**
 * FORMATTED DATA FUNCTIONS
 * @param data
 * @returns
 */

export const formatProbabilitiesData = (data: any) => {
  let aux = [];
  const second_aux = {
    [NpsLegendEnum.ONE_SIX]: [],
    [NpsLegendEnum.SEVEN_EIGHT]: [],
    [NpsLegendEnum.NINE_TEN]: [],
    [NpsLegendEnum.NPS]: [],
  };

  const years = Object.keys({ ...data }).sort((prev, next) => (prev > next ? 1 : -1));
  years.forEach((year) => {
    aux = [...aux, ...Object.values(data[year])];
  });

  aux.splice(0, 1);
  aux.push({});

  aux.forEach((item) => {
    second_aux[NpsLegendEnum.ONE_SIX].push(item[`detractor%`] || 0);
    second_aux[NpsLegendEnum.SEVEN_EIGHT].push(item[`passive%`] || 0);
    second_aux[NpsLegendEnum.NINE_TEN].push(item[`promoters%`] || 0);
    second_aux[NpsLegendEnum.NPS].push(item[`nps%`] || 0); // only in percent
  });

  const probabilities = [
    { name: NpsLegendEnum.ONE_SIX, value: second_aux[NpsLegendEnum.ONE_SIX] },
    { name: NpsLegendEnum.SEVEN_EIGHT, value: second_aux[NpsLegendEnum.SEVEN_EIGHT] },
    { name: NpsLegendEnum.NINE_TEN, value: second_aux[NpsLegendEnum.NINE_TEN] },
    { name: NpsLegendEnum.NPS, value: second_aux[NpsLegendEnum.NPS] },
  ];

  return probabilities;
};

export const formatProblemsData = (data: any, date?: any) => {
  const currentYear = moment(date ? date : undefined).year();
  const currentMonth = moment(date ? date : undefined).month();
  const currentData = data[currentYear][currentMonth - (date ? 0 : 1)];

  const getPreviousInfo = (quantity: number) => {
    const auxDate = moment(date ? date : undefined).subtract(quantity, 'months');
    const auxData = data[auxDate.year()][auxDate.month()];
    return {
      date: auxDate,
      value: auxData ? auxData.problem[`yes%`] : 0,
    };
  };

  const kind = currentData?.kind;

  const problems = Object.values(NpsAtributeEnum).map((item) => ({
    name: item,
    value: kind ? kind[`${item}%`] : 0,
  }));

  const formattedData = {
    data: [
      {
        name: 'Si',
        value: currentData?.problem ? currentData?.problem[`yes%`] : 0,
        jobbers: currentData?.problem?.yes || 0,
      },
      {
        name: 'No',
        value: currentData?.problem ? currentData?.problem[`no%`] : 0,
        jobbers: currentData?.problem?.no || 0,
      },
    ],
    problems,
    previousMonths: [
      getPreviousInfo(1),
      getPreviousInfo(2),
      getPreviousInfo(3),
      getPreviousInfo(4),
    ],
    totalJobbersxMonth: currentData?.total || 0,
    totalProblemsxMonth: (currentData?.problem?.yes || 0) + (currentData?.problem?.no || 0),
    totlaProblemsKindxMonth: Object.entries({ ...kind }).reduce(
      (acc, [label, value]) => acc + Number(label.includes('%') ? 0 : value),
      0
    ),
    allJobbers: currentData?.activeJobbers || 0,
  };

  return formattedData;
};

export const formatCalificationTaksData = (data: any) => {
  let aux = [];
  const second_aux = {
    [qualificationTasksEnum.ONE]: [],
    [qualificationTasksEnum.TWO]: [],
    [qualificationTasksEnum.THREE]: [],
    [qualificationTasksEnum.FOUR]: [],
    [qualificationTasksEnum.FIVE]: [],
    [qualificationTasksEnum.AVG]: [],
  };

  const years = Object.keys({ ...data }).sort((prev, next) => (prev > next ? 1 : -1));
  years.forEach((year) => {
    aux = [...aux, ...Object.values(data[year])];
  });

  aux.forEach((item) => {
    second_aux[qualificationTasksEnum.ONE].push({ avg: item['1%'], value: item['1'] });
    second_aux[qualificationTasksEnum.TWO].push({ avg: item['2%'], value: item['2'] });
    second_aux[qualificationTasksEnum.THREE].push({ avg: item['3%'], value: item['3'] });
    second_aux[qualificationTasksEnum.FOUR].push({ avg: item['4%'], value: item['4'] });
    second_aux[qualificationTasksEnum.FIVE].push({ avg: item['5%'], value: item['5'] });
    second_aux[qualificationTasksEnum.AVG].push({
      avg: Number(parseFloat(String((item.average * 100) / 5)).toFixed(1)),
      value: item.total,
    });
  });

  const starsLastYear = [
    { name: qualificationTasksEnum.ONE, value: second_aux[qualificationTasksEnum.ONE] },
    { name: qualificationTasksEnum.TWO, value: second_aux[qualificationTasksEnum.TWO] },
    { name: qualificationTasksEnum.THREE, value: second_aux[qualificationTasksEnum.THREE] },
    { name: qualificationTasksEnum.FOUR, value: second_aux[qualificationTasksEnum.FOUR] },
    { name: qualificationTasksEnum.FIVE, value: second_aux[qualificationTasksEnum.FIVE] },
    { name: qualificationTasksEnum.AVG, value: second_aux[qualificationTasksEnum.AVG] },
  ];

  return starsLastYear;
};

export const formatCalificationsByStars = (data: any, date: any) => {
  if (!Object.values({ ...data }).length) return { positive: [], negative: [] };
  const currentData = data[date.year()][date.month()] ?? {};

  const info = (type: 'positive' | 'negative') => {
    const filteredInfo = (isEmpty?: boolean) =>
      Object.entries({ ...currentData?.highlighted })
        .map(([key, values]) => ({
          percent: values[type + '%'],
          value: values[type],
          name: i18n.t(`dashboard.tabs.ratings.charts.problemsBar.labels.${key}`),
        }))
        .filter((item) => (isEmpty ? true : item.value && item.percent))
        .sort((prev, next) => (prev.percent < next.percent ? 1 : -1));

    return filteredInfo(filteredInfo().length === 0);
  };

  return {
    positive: info('positive'),
    negative: info('negative'),
    totals: { positive: currentData.positive, negative: currentData.negative },
  };
};
