export default {
  dashboard: {
    tabs: {
      comingSoon: 'Próximamente',
      ratings: {
        charts: {
          problemsBar: {
            labels: {
              WORK_ENVIRONMENT: 'Entorno de tarea',
            },
          },
        },
      },
    },
  },
};
