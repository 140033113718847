import { JobApplicationStatusEnum, JobStatusEnum } from '@features/tasks/ts/jobs.enum';

const AccessControlApplicationActionsConfig = {
  [JobStatusEnum.SCHEDULED]: {
    [JobApplicationStatusEnum.ASSIGNED]: ['deallocated'],
    editJobDisabled: [
      'date',
      'enterprise',
      'store',
      'jobType',
      'repeatDays',
      'applicableWithoutSkills',
    ],
    actions: ['cancel'],
  },

  [JobStatusEnum.PUBLISHED]: {
    [JobApplicationStatusEnum.ASSIGNED]: ['present', 'deallocated'],
    [JobApplicationStatusEnum.PRESENT]: ['deallocated'],
    editJobDisabled: [
      'date',
      'enterprise',
      'store',
      'jobType',
      'applicableWithoutSkills',
      'paymentSchema',
      'publishDate',
      'repeatDays',
    ],
    actions: ['cancel'],
  },

  [JobStatusEnum.ASSIGNED]: {
    [JobApplicationStatusEnum.ASSIGNED]: ['present', 'deallocated'],
    [JobApplicationStatusEnum.PRESENT]: ['deallocated'],
    editJobDisabled: [
      'date',
      'enterprise',
      'store',
      'jobType',
      'applicableWithoutSkills',
      'paymentSchema',
      'publishDate',
      'repeatDays',
    ],
    actions: ['cancel'],
  },

  [JobStatusEnum.ONGOING]: {
    [JobApplicationStatusEnum.ASSIGNED]: ['present', 'absent', 'deallocated'],
    [JobApplicationStatusEnum.PRESENT]: ['finished'],
    editJobDisabled: [
      'date',
      'enterprise',
      'store',
      'jobType',
      'description',
      'paymentSchema',
      'paymentSchemaVariables',
      'publishDate',
      'repeatDays',
      'applicableWithoutSkills',
      'cellphoneOS',
      'epps',
      'speakSpanish',
      'genderAllowed',
      'extraQuotas',
      'lunch',
      'comments',
    ],
    actions: ['cancel'],
  },

  [JobStatusEnum.FINISHED]: {
    [JobApplicationStatusEnum.PRESENT]: ['finished'],
    [JobApplicationStatusEnum.ASSIGNED]: ['absentToFinished'],
    [JobApplicationStatusEnum.FINISHED]: ['deallocated'],
    [JobApplicationStatusEnum.ABSENT]: ['absentToFinished'],
    [JobApplicationStatusEnum.APPLIED]: ['absentToFinished'],
    editJobDisabled: [],
  },
  [JobStatusEnum.CANCELLED]: {
    actions: ['delete'],
    editJobDisabled: [],
  },
};

export default AccessControlApplicationActionsConfig;
