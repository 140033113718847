import auth from './modules/auth';
import applicants from './modules/applicants';
import localities from './modules/localities';
import tasks from './modules/tasks';
import dashboard from './modules/dashboard';
import stores from './modules/stores';
import enterprises from './modules/enterprises';

export default {
  ...auth,
  ...tasks,
  ...applicants,
  ...localities,
  ...dashboard,
  ...stores,
  ...enterprises,
  global: {
    region: 'Provincia',
    region_plural: 'Provincias',
    regionWArticle: 'La provincia',
    regionWArticle_plural: 'Las provincias',
    commune: 'Distrito',
    communeWArticle: 'El distrito',
    commune_plural: 'Distritos',
    communeWArticle_plural: 'Los distritos',
    currencySymbol: 'S/',
    inputs: {
      docSel: { dni: 'DNI', cepe: 'CE', ptp: 'PTP', cpp: 'CPP', ruc: 'RUC' },
      documentNumber: {
        pattern: {
          dni: /^(\d){8}$/,
          ce: /^(\d){0,12}$/,
          ptp: /^(\d){0,12}$/,
          cpp: /^(\d){0,12}$/,
          ruc: /^(\d){11}$/,
        },
        message: {
          dni: 'Ingresa 8 dígitos',
          ce: 'Ingresa hasta 12 dígitos',
          ptp: 'Ingresa hasta 12 dígitos',
          cpp: 'Ingresa hasta 12 dígitos',
          ruc: 'Ingresa 11 dígitos',
        },
        maximumDocumentLength: {
          dni: 8,
          ce: 12,
          ptp: 12,
          cpp: 12,
          ruc: 11,
        },
      },
      phoneNumber: {
        pattern: /^(\d){9}$/,
        message: 'Ingresa un teléfono de 9 dígitos',
      },
    },
    settings: {
      country: {
        phonePrefix: '+51',
        name: 'Perú',
        phoneLengthWithoutPrefix: 9,
      },
    },
    enums: {
      centerCategories: {
        frameworkAgreement: 'Suscripción',
      },
      paymentType: {
        liquid: 'Neto',
      },
    },
  },
};
