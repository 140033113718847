import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

import { IResponseData } from './ts/payments.interfaces';
import { RequestStatusEnum } from '@utils/enum';
import server from '@services/api';
import {
  fetchMoneyOrderRoute,
  fetchMoneyOrdersRoute,
  fetchSettlementsRoute,
  createManualMoneyOrderRoute,
  fetchSettlementRoute,
  createSettlementProcessRoute,
  loadSettlementPaymentsRoute,
  downloadSettlementsFileRoute,
  updateMoneyOrderStatusRoute,
  deleteSettlementRoute,
  fetchProcessesRoute,
  deleteProcessRoute,
  loadMoneyOrdersMassiveRoute,
  fetchMoneyOrdersShopperRoute,
  fetchShopperProcessesRoute,
  fetchShopperSettlementsRoute,
  createSettlementShopperProcessRoute,
  fetchMoneyOrdersShopperNyShopperCode,
  createManualShopperMoneyOrderRoute,
  loadMassiveShopperRoute,
  fetchMoneyOrderShopperRoute,
  updateMoneyOrderShopperStatusRoute,
  loadShopperSettlementPaymentsRoute,
  downloadShopperSettlementsFileRoute,
  deleteShopperProcessRoute,
  deleteShopperSettlementRoute,
  fetchIncentiveProcessesRoute,
  fetchIncentiveProcessRoute,
  deleteIncentiveProcessRoute,
  createIncentiveShopperProcessRoute,
  fetchShopperSettlementRoute,
  downloadShopperSettlementPaymentsRoute,
  downloadLabourSettlementPaymentsRoute,
  fetchMoneyOrderShopperByArrRoute,
} from './ts/routes';

export const paymentsAdapter = createEntityAdapter();

export const initialState: any = paymentsAdapter.getInitialState({
  moneyOrders: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  moneyOrdersShopper: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  settlements: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  shopperSettlements: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  processes: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  shopperProcesses: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  incentiveProcesses: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  incentiveProcess: {
    loading: RequestStatusEnum.IDLE,
  },
  createIncentiveProcess: {
    message: '',
    error: null,
    loading: RequestStatusEnum.IDLE,
  },
  moneyOrder: {
    loading: RequestStatusEnum.IDLE,
  },
  moneyOrderShopper: {
    loading: RequestStatusEnum.IDLE,
  },
  moneyOrderStatus: {
    message: '',
    loading: RequestStatusEnum.IDLE,
  },
  moneyOrderShopperStatus: {
    message: '',
    loading: RequestStatusEnum.IDLE,
  },
  settlement: {
    loading: RequestStatusEnum.IDLE,
  },
  shopperSettlement: {
    loading: RequestStatusEnum.IDLE,
  },
  manualMoneyOrder: {
    message: '',
    loading: RequestStatusEnum.IDLE,
  },
  settlementProcess: {
    message: '',
    error: null,
    loading: RequestStatusEnum.IDLE,
  },
  settlementShopperProcess: {
    message: '',
    error: null,
    loading: RequestStatusEnum.IDLE,
  },
  settlementsFile: {
    message: '',
    loading: RequestStatusEnum.IDLE,
  },
  shopperSettlementsFile: {
    message: '',
    loading: RequestStatusEnum.IDLE,
  },
  settlementPayments: {
    message: '',
    loading: RequestStatusEnum.IDLE,
  },
  shopperSettlementPayments: {
    message: '',
    loading: RequestStatusEnum.IDLE,
  },
  shopperSettlementReport: {
    message: '',
    loading: RequestStatusEnum.IDLE,
    type: '',
  },
  labourSettlementReport: {
    message: '',
    loading: RequestStatusEnum.IDLE,
    type: '',
  },
  massiveOrders: {
    message: '',
    error: false,
    loading: RequestStatusEnum.IDLE,
  },
  deleteSettlementResult: {
    message: '',
    error: '',
    loading: RequestStatusEnum.IDLE,
  },
  deleteProcessResult: {
    message: '',
    error: '',
    loading: RequestStatusEnum.IDLE,
  },
  createMoneyOrderShopper: undefined,
  loading: RequestStatusEnum.IDLE,
});

export const getMoneyOrders = createAsyncThunk(
  'payments/getMoneyOrders',
  async (params: any, { getState }: any) => {
    const { moneyOrders } = getState().payments;
    if (moneyOrders?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchMoneyOrdersRoute(),
      params,
    });
    return response.data;
  }
);

export const getMoneyOrdersShopper = createAsyncThunk(
  'payments/getMoneyOrdersShopper',
  async (params: any, { getState }: any) => {
    const { moneyOrdersShopper } = getState().payments;
    if (moneyOrdersShopper?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchMoneyOrdersShopperRoute(),
      params,
    });
    return response.data;
  }
);

export const getMoneyOrder = createAsyncThunk(
  'payments/getMoneyOrder',
  async (moneyOrderId: string, { getState }: any) => {
    const { moneyOrder } = getState().payments;
    if (moneyOrder?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchMoneyOrderRoute(moneyOrderId),
    });

    return response.data;
  }
);

export const getMoneyOrderShopper = createAsyncThunk(
  'payments/getMoneyOrderShopper',
  async (moneyOrderId: string, { getState }: any) => {
    const { moneyOrderShopper } = getState().payments;
    if (moneyOrderShopper?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchMoneyOrderShopperRoute(moneyOrderId),
    });

    return response.data;
  }
);

export const getMoneyOrderShopperByArr = createAsyncThunk(
  'payments/getMoneyOrderShopper',
  async (moneyOrderData: { current: number; limit: number; id: string[] }, { getState }: any) => {
    const { moneyOrderShopper } = getState().payments;
    if (moneyOrderShopper?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchMoneyOrderShopperByArrRoute(),
      params: moneyOrderData,
    });

    return response.data;
  }
);

export const updateMoneyOrderStatus = createAsyncThunk(
  'payments/updateMoneyOrderStatus',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, moneyOrderStatus } = getState().payments;
      if (moneyOrderStatus?.loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId)
        return;

      const response: IResponseData = await server({
        method: 'put',
        url: updateMoneyOrderStatusRoute(body.moneyOrderId),
        data: {
          status: body.status,
          blockComment: body?.blockComment,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const updateMoneyOrderShopperStatus = createAsyncThunk(
  'payments/updateMoneyOrderShopperStatus',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, moneyOrderShopperStatus } = getState().payments;
      if (
        moneyOrderShopperStatus?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      )
        return;

      const response: IResponseData = await server({
        method: 'put',
        url: updateMoneyOrderShopperStatusRoute(body.moneyOrderId),
        data: {
          status: body.status,
          blockComment: body?.blockComment,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const createManualMoneyOrder = createAsyncThunk(
  'payments/createManualMoneyOrder',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, manualMoneyOrder } = getState().payments;
      if (
        manualMoneyOrder?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'post',
        url: createManualMoneyOrderRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const getSettlements = createAsyncThunk(
  'payments/getSettlements',
  async (params: any, { getState, requestId }: any) => {
    const { currentRequestId, settlements } = getState().payments;
    if (settlements?.loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchSettlementsRoute(),
      params,
    });
    return response.data;
  }
);

export const getShopperSettlements = createAsyncThunk(
  'payments/getShopperSettlements',
  async (params: any, { getState, requestId }: any) => {
    const { currentRequestId, shopperSettlements } = getState().payments;
    if (
      shopperSettlements?.loading !== RequestStatusEnum.PENDING ||
      requestId !== currentRequestId
    ) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchShopperSettlementsRoute(),
      params,
    });
    return response.data;
  }
);

export const getShopperSettlement = createAsyncThunk(
  'payments/getShopperSettlement',
  async (settlementId: string, { getState, requestId }: any) => {
    const { currentRequestId, shopperSettlement } = getState().payments;
    if (
      shopperSettlement?.loading !== RequestStatusEnum.PENDING ||
      requestId !== currentRequestId
    ) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchShopperSettlementRoute(settlementId),
    });

    return response.data;
  }
);

export const getSettlement = createAsyncThunk(
  'payments/getSettlement',
  async (settlementId: string, { getState, requestId }: any) => {
    const { currentRequestId, settlement } = getState().payments;
    if (settlement?.loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchSettlementRoute(settlementId),
    });

    return response.data;
  }
);
export const createSettlementProcess = createAsyncThunk(
  'payments/createSettlementProcess',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, settlementProcess } = getState().payments;
      if (
        settlementProcess?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'post',
        url: createSettlementProcessRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const createSettlementShopperProcess = createAsyncThunk(
  'payments/createSettlementShopperProcess',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, settlementShopperProcess } = getState().payments;
      if (
        settlementShopperProcess?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }

      const response: IResponseData = await server({
        method: 'post',
        url: createSettlementShopperProcessRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const loadIncentiveProcess = createAsyncThunk(
  'payments/loadIncentiveProcess',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, createIncentiveProcess } = getState().payments;
      if (
        createIncentiveProcess?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'post',
        url: createIncentiveShopperProcessRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const downloadSettlementsFile = createAsyncThunk(
  'payments/downloadSettlementsFile',
  async (params: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, settlementsFile } = getState().payments;
      if (
        settlementsFile?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'get',
        url: downloadSettlementsFileRoute(),
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const downloadShopperSettlementsFile = createAsyncThunk(
  'payments/downloadShopperSettlementsFile',
  async (params: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, shopperSettlementsFile } = getState().payments;
      if (
        shopperSettlementsFile?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'get',
        url: downloadShopperSettlementsFileRoute(),
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const loadSettlementPayments = createAsyncThunk(
  'payments/loadSettlementPayments',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, settlementPayments } = getState().payments;
      if (
        settlementPayments?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'post',
        url: loadSettlementPaymentsRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const loadShopperSettlementPayments = createAsyncThunk(
  'payments/loadShopperSettlementPayments',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, shopperSettlementPayments } = getState().payments;
      if (
        shopperSettlementPayments?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'post',
        url: loadShopperSettlementPaymentsRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const downloadShopperSettlementPayments = createAsyncThunk(
  'payments/downloadShopperSettlementPayments',
  async (params: any, { getState, rejectWithValue }: any) => {
    try {
      const { shopperSettlementReport } = getState().payments;
      if (shopperSettlementReport?.loading !== RequestStatusEnum.PENDING) return;

      const response: any = await server({
        method: 'get',
        url: downloadShopperSettlementPaymentsRoute(),
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const downloadLabourSettlementPayments = createAsyncThunk(
  'payments/downloadLabourSettlementPayments',
  async (params: any, { getState, rejectWithValue }: any) => {
    try {
      const { labourSettlementReport } = getState().payments;
      if (labourSettlementReport?.loading !== RequestStatusEnum.PENDING) return;

      const response: any = await server({
        method: 'get',
        url: downloadLabourSettlementPaymentsRoute(),
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const loadMassiveManual = createAsyncThunk(
  'payments/loadMassiveManual',
  async (body: any, { rejectWithValue }) => {
    try {
      const response: IResponseData = await server({
        method: 'post',
        url: loadMoneyOrdersMassiveRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const loadMassiveShopper = createAsyncThunk(
  'payments/loadMassiveShopper',
  async (body: { key: string; timezone: string }, { rejectWithValue }) => {
    try {
      const response: IResponseData = await server({
        method: 'post',
        url: loadMassiveShopperRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteSettlement = createAsyncThunk(
  'payments/deleteSettlement',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, deleteSettlementResult } = getState().payments;
      if (
        deleteSettlementResult?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'delete',
        url: deleteSettlementRoute(body._id),
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const deleteShopperSettlement = createAsyncThunk(
  'payments/deleteShopperSettlement',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, deleteSettlementResult } = getState().payments;
      if (
        deleteSettlementResult?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'delete',
        url: deleteShopperSettlementRoute(body._id),
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const getProcesses = createAsyncThunk(
  'payments/getProcesses',
  async (params: any, { getState }: any) => {
    const { processes } = getState().payments;
    if (processes?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchProcessesRoute(),
      params,
    });
    return response.data;
  }
);

export const getShopperProcesses = createAsyncThunk(
  'payments/getShopperProcesses',
  async (params: any, { getState }: any) => {
    const { shopperProcesses } = getState().payments;
    if (shopperProcesses?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchShopperProcessesRoute(),
      params,
    });
    return response.data;
  }
);

export const getIncentiveProcesses = createAsyncThunk(
  'payments/getIncentiveProcesses',
  async (params: any, { getState }: any) => {
    const { incentiveProcesses } = getState().payments;
    if (incentiveProcesses?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchIncentiveProcessesRoute(),
      params,
    });
    return response.data;
  }
);

export const getIncentiveProcess = createAsyncThunk(
  'payments/getIncentiveProcess',
  async (_id: any, { getState }: any) => {
    const { incentiveProcess } = getState().payments;
    if (incentiveProcess?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: IResponseData = await server({
      method: 'get',
      url: fetchIncentiveProcessRoute(_id),
    });
    return response.data;
  }
);

export const deleteProcess = createAsyncThunk(
  'payments/deleteProcess',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, deleteProcessResult } = getState().payments;
      if (
        deleteProcessResult?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'delete',
        url: deleteProcessRoute(body._id),
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const deleteShopperProcess = createAsyncThunk(
  'payments/deleteShopperProcess',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, deleteProcessResult } = getState().payments;
      if (
        deleteProcessResult?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'delete',
        url: deleteShopperProcessRoute(body._id),
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const deleteIncentiveProcess = createAsyncThunk(
  'payments/deleteIncentiveProcess',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, deleteProcessResult } = getState().payments;
      if (
        deleteProcessResult?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const response: IResponseData = await server({
        method: 'delete',
        url: deleteIncentiveProcessRoute(body._id),
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const getMoneyOrdersShopperByShopperCode = createAsyncThunk(
  'payments/getMoneyOrdersShopperByShopperCode',
  async (params: { shopperCode: string; sequentialClonation?: number }, { rejectWithValue }) => {
    try {
      const response: IResponseData = await server({
        method: 'get',
        url: fetchMoneyOrdersShopperNyShopperCode(),
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createManualShopperMoneyOrder = createAsyncThunk(
  'payments/createManualShopperMoneyOrder',
  async (data: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await server({
        method: 'post',
        url: createManualShopperMoneyOrderRoute(),
        data,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    toggleMoneyOrdersRefresh: (state) => {
      state.moneyOrders.refresh = !state.moneyOrders.refresh;
    },
    clearField: (state, b) => {
      const { field } = b.payload ?? {};
      state[field] = {
        message: '',
        loading: RequestStatusEnum.IDLE,
      };
    },
    clearSettlementProcess: (state, b) => {
      const { clearError } = b.payload ?? {};
      state.settlementProcess = {
        message: '',
        error: clearError ? null : state.settlementProcess.error,
        loading: RequestStatusEnum.IDLE,
      };
    },
    clearCreateManualShopperMoneyOrderState: (state) => {
      state.createMoneyOrderShopper = undefined;
    },
    clearShopperSettlementReport: (state) => {
      state.shopperSettlementReport = {
        message: '',
        loading: RequestStatusEnum.IDLE,
        type: '',
      };
    },
  },
  extraReducers: {
    [String(getMoneyOrders.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.moneyOrders.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(getMoneyOrders.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrders = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
      state.currentRequestId = undefined;
    },
    [String(getMoneyOrders.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrders.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },
    [String(getMoneyOrdersShopper.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.moneyOrdersShopper.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(getMoneyOrdersShopper.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrdersShopper = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
      state.currentRequestId = undefined;
    },
    [String(getMoneyOrdersShopper.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrdersShopper.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(getMoneyOrder.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.moneyOrder.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(getMoneyOrder.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrder = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
      state.currentRequestId = undefined;
    },
    [String(getMoneyOrder.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrder.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(getMoneyOrderShopper.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.moneyOrderShopper.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(getMoneyOrderShopper.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrderShopper = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
      state.currentRequestId = undefined;
    },
    [String(getMoneyOrderShopper.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrderShopper.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(updateMoneyOrderStatus.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.moneyOrderStatus.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updateMoneyOrderStatus.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.moneyOrderStatus = {
          message: i18n.t(`payments.moneyOrderList.statusUpdate.${arg?.status}`),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(updateMoneyOrderStatus.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.moneyOrderStatus.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(updateMoneyOrderShopperStatus.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.moneyOrderShopperStatus.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updateMoneyOrderShopperStatus.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.moneyOrderShopperStatus = {
          message: i18n.t(`payments.moneyOrderList.statusUpdate.${arg?.status}`),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(updateMoneyOrderShopperStatus.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.moneyOrderShopperStatus.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [String(createManualMoneyOrder.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.manualMoneyOrder.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(createManualMoneyOrder.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.manualMoneyOrder = {
          message: i18n.t('payments.manualPaymentOrder.alerts.create'),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(createManualMoneyOrder.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.manualMoneyOrder.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getSettlements.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.settlements.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getSettlements.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlements = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getSettlements.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlements.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getShopperSettlements.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.shopperSettlements.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getShopperSettlements.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlements = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getShopperSettlements.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlements.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getSettlement.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.settlement.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getSettlement.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlement = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getSettlement.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlement.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(createSettlementProcess.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.settlementProcess.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(createSettlementProcess.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlementProcess = {
          message: i18n.t('payments.createSettlementProcess.alerts.create'),
          error: null,
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(createSettlementProcess.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlementProcess = {
          message: '',
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.settlementProcess.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(createSettlementShopperProcess.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.settlementShopperProcess.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(createSettlementShopperProcess.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlementShopperProcess = {
          message: i18n.t('payments.createSettlementProcess.alerts.create'),
          error: null,
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(createSettlementShopperProcess.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlementShopperProcess = {
          message: '',
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.settlementShopperProcess.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(loadIncentiveProcess.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.createIncentiveProcess.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(loadIncentiveProcess.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.createIncentiveProcess = {
          message: 'success',
          error: null,
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(loadIncentiveProcess.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.createIncentiveProcess = {
          message: '',
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(downloadSettlementsFile.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.settlementsFile.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(downloadSettlementsFile.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlementsFile = {
          message: i18n.t('payments.settlementList.downloadSettlementsFileSuccess'),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(downloadSettlementsFile.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlementsFile.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(downloadShopperSettlementsFile.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.shopperSettlementsFile.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(downloadShopperSettlementsFile.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlementsFile = {
          message: i18n.t('payments.settlementList.downloadSettlementsFileSuccess'),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(downloadShopperSettlementsFile.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlementsFile.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [String(loadMassiveManual.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.massiveOrders.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(loadMassiveManual.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.massiveOrders = {
          message: i18n.t(`payments.moneyOrderList.massiveStatus.success`),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(loadMassiveManual.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.massiveOrders = {
          message: i18n.t(`payments.moneyOrderList.massiveStatus.error`),
          loading: RequestStatusEnum.IDLE,
          error: true,
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(loadMassiveShopper.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;

      state.massiveOrders = {
        message: '',
        error: false,
        loading: RequestStatusEnum.PENDING,
      };
    },
    [String(loadMassiveShopper.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;

      state.massiveOrders = {
        message: i18n.t(`payments.shoppers.massive.alerts.success`),
        error: false,
        loading: RequestStatusEnum.SUCCESS,
      };
    },
    [String(loadMassiveShopper.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;

      state.massiveOrders = {
        message: i18n.t(`payments.shoppers.massive.alerts.error`),
        error: true,
        loading: RequestStatusEnum.IDLE,
      };
    },

    [String(loadSettlementPayments.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.settlementPayments.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(loadSettlementPayments.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlementPayments = {
          message: i18n.t('payments.settlementList.loadSettlementPaymentsSuccess'),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(loadSettlementPayments.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.settlementPayments.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(loadShopperSettlementPayments.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.shopperSettlementPayments.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(loadShopperSettlementPayments.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlementPayments = {
          message: i18n.t('payments.settlementList.loadSettlementPaymentsSuccess'),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(loadShopperSettlementPayments.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlementPayments.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(downloadShopperSettlementPayments.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.shopperSettlementReport.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(downloadShopperSettlementPayments.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlementReport = {
          message: i18n.t('payments.settlementList.modal.download.alert.success'),
          loading: RequestStatusEnum.SUCCESS,
          type: 'success',
        };
        state.currentRequestId = undefined;
      }
    },
    [String(downloadShopperSettlementPayments.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlementReport = {
          message: i18n.t('payments.settlementList.modal.download.alert.error'),
          loading: RequestStatusEnum.SUCCESS,
          type: 'error',
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(downloadLabourSettlementPayments.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.labourSettlementReport.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(downloadLabourSettlementPayments.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.labourSettlementReport = {
          message: i18n.t('payments.settlementList.modal.download.alert.success'),
          loading: RequestStatusEnum.SUCCESS,
          type: 'success',
        };
        state.currentRequestId = undefined;
      }
    },
    [String(downloadLabourSettlementPayments.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.labourSettlementReport = {
          message: i18n.t('payments.settlementList.modal.download.alert.error'),
          loading: RequestStatusEnum.SUCCESS,
          type: 'error',
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(deleteSettlement.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.deleteSettlementResult.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(deleteSettlement.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteSettlementResult = {
          message: i18n.t('payments.settlementList.alerts.delete.success', { code: arg.code }),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(deleteSettlement.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteSettlementResult = {
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(deleteShopperSettlement.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.deleteSettlementResult.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(deleteShopperSettlement.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteSettlementResult = {
          message: i18n.t('payments.settlementList.alerts.delete.success', { code: arg.code }),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(deleteShopperSettlement.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteSettlementResult = {
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [String(getProcesses.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.processes.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getProcesses.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.processes = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getProcesses.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.processes.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getShopperProcesses.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.shopperProcesses.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getShopperProcesses.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperProcesses = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getShopperProcesses.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperProcesses.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getIncentiveProcesses.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.incentiveProcesses.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getIncentiveProcesses.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.incentiveProcesses = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getIncentiveProcesses.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.incentiveProcesses.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getIncentiveProcess.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.incentiveProcess.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getIncentiveProcess.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.incentiveProcess = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getIncentiveProcess.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.incentiveProcess.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(deleteProcess.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.deleteProcessResult.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(deleteProcess.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteProcessResult = {
          message: i18n.t('payments.processesList.alerts.delete.success', { code: arg.code }),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(deleteProcess.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteProcessResult = {
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(deleteShopperProcess.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.deleteProcessResult.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(deleteShopperProcess.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteProcessResult = {
          message: i18n.t('payments.processesList.alerts.delete.success', { code: arg.code }),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(deleteShopperProcess.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteProcessResult = {
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(deleteIncentiveProcess.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.deleteProcessResult.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(deleteIncentiveProcess.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteProcessResult = {
          message: i18n.t('payments.incentives.undo.alerts.delete.success', { code: arg.code }),
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(deleteIncentiveProcess.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.deleteProcessResult = {
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getMoneyOrdersShopperByShopperCode.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.moneyOrdersShopper.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(getMoneyOrdersShopperByShopperCode.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrdersShopper = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
      state.currentRequestId = undefined;
    },
    [String(getMoneyOrdersShopperByShopperCode.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.moneyOrdersShopper.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(createManualShopperMoneyOrder.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
      state.createMoneyOrderShopper = {
        loading: RequestStatusEnum.PENDING,
      };
    },
    [String(createManualShopperMoneyOrder.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.createMoneyOrderShopper = {
        type: 'success',
        message: i18n.t('payments.shoppers.createManual.alerts.success'),
        loading: RequestStatusEnum.IDLE,
      };
    },
    [String(createManualShopperMoneyOrder.rejected)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.createMoneyOrderShopper = {
        type: 'error',
        message: i18n.t('payments.shoppers.createManual.alerts.error'),
        loading: RequestStatusEnum.IDLE,
      };
    },
    [String(getShopperSettlement.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.shopperSettlement.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getShopperSettlement.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlement = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getShopperSettlement.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.shopperSettlement.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const {
  toggleMoneyOrdersRefresh,
  clearField,
  clearSettlementProcess,
  clearCreateManualShopperMoneyOrderState,
  clearShopperSettlementReport,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
