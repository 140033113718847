export default {
  applicants: {
    jobberDetails: {
      editUser: {
        inputs: {
          ruc: {
            required: 'Ingresa tu RUC',
            title: 'RUC',
            lower: 'ruc',
            lengthError: 'Tu RUC puede contener 11 digitos',
            pattern: /^[0-9]{11}$/,
          },
        },
      },
    },
    review: {
      noVisualizer: {
        frameworkAgreement: [
          'Para aprobar la suscripción debes validar de que el(la) aplicante asistió a la oficina y realizó el pago correspondiente.',
          ' ',
          ' ',
        ],
      },
      visualizer: {
        frameworkAgreement: {
          title: 'Suscripción',
        },
        files: {
          frameworkAgreement: 'Suscripción',
        },
        alert: {
          approvedFramework: 'Suscripción aprobada por staff.',
        },
        otherDocuments: {
          title: 'Otros documentos',
          expiration: {
            PE: {
              suspensionCertificate: 'Documento tiene validez hasta el ',
            },
            CO: {
              PEP: 'Documento tiene una validez de 2 años. Vence el ',
            },
            CL: {
              visa_application: 'Documento tiene una validez de 6 meses. Vence el ',
            },
          },
          expirationDate: {
            title: 'Vencimiento de documento',
            label: 'Fecha de vencimiento *',
          },
          issueDate: {
            title: 'Emisión de documentos',
            label: 'Fecha de emisión *',
          },
          alert: {
            error: 'No se logró actualizar la fecha de vencimiento',
            success: 'Fecha de vencimiento actualizada.',
          },
          textInfo:
            'Recuerda verificar que la fecha informada por el Jobber, coincida con la fecha indicada en el documento.',
          kindIdentifyDocuments: {
            id: { front: 'DNI frontal', reverse: 'DNI reverso' },
            PTP: { front: 'PTP frontal', reverse: 'PTP reverso' },
            foreign_ID: {
              front: 'Carnet de Extranjería frontal',
              reverse: 'Carnet de Extranjería reverso',
            },
            PEP: { front: 'PEP (pág. 1)', reverse: 'PEP (pág. 2)' },
            visa_application: {
              front: 'Trámite de visa definitiva (pág. 1)',
              reverse: 'Trámite de visa definitiva (pág. 2)',
            },
          },
        },
        tooltip: {
          files: {
            identityCardFront: 'DNI',
            identityCardReverse: 'DNI',
            id: 'DNI',
            foreign_ID: 'Carnet de extranjería',
            ptp: 'PTP (Permiso temporal de permanencia)',
          },
        },
      },
    },
    filters: {
      byDocument: {
        options: {
          id_front: 'DNI frontal',
          id_reverse: 'DNI reverso',
        },
      },
    },
  },
};
