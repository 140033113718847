export default {
  notifications: {
    page: {
      title: 'Notificaciones',
      subtitle:
        'Selecciona uno o más Jobbers para enviar una notificación. También puedes hacer un envío masivo mediante plantilla.',
      button: 'Envío en base a plantilla',
    },
    modalTemplate: {
      title: 'Envío en base a plantilla',
      subtitle:
        'Envía distintas notificaciones descargando y completando la siguiente plantilla. Recibirás un correo indicando que notificaciones son o no aptas.',
      alert: {
        info: {
          title: 'Recuerda completar los datos establecidos en la plantilla.',
          rules: {
            first: 'ID_Jobber',
            second: 'Titulo (40 caracteres máximo)',
            third: 'Contenido (135 caracteres máximo)',
          },
        },
        success: {
          title:
            'Plantilla cargada con éxito. Hemos enviado un correo indicando que notificaciones son o no aptas.<br/> El resultado del envió se irá actualizando en la pestaña <strong>“Estado de notificaciones enviadas”,</strong> en medida que estas se envien.',
        },
        error: {
          title: 'Ocurrió un error al cargar la plantilla. Intenta nuevamente.',
        },
      },
      uploadButton: {
        title: 'Plantilla de notificaciones',
        button: 'Cargar plantilla',
        fileName: 'plantilla base',
      },
      linkButton: 'Descargar plantilla base',
      okButton: 'Confirmar envío',
      downloadLink: {
        develop: `https://tj-public-assets-dev.s3.amazonaws.com/Plantilla+notificaciones.csv?${new Date().getTime()}`,
        staging: `https://tj-public-assets-stg.s3.amazonaws.com/Plantilla+notificaciones.csv?${new Date().getTime()}`,
        production: `https://tj-public-assets.s3.amazonaws.com/Plantilla+notificaciones.csv?${new Date().getTime()}`,
      },
    },
    filters: {
      send: {
        filterSearch: {
          title: 'Buscar',
          placeholder: 'Buscar',
        },
        filterClear: { title: 'Limpiar filtros' },
        filterDatePicker: { title: 'Fecha de envío' },
        role: {
          title: 'Rol',
          options: {
            jobber_applicant: 'Applicant',
            jobber: 'Jobber',
          },
        },
        vehicle: 'Vehículo',
        device: 'Dispositivo',
        documents: 'Documentos completados',
        induction: 'Inducción',
        inductions: 'Inducciones',
        activation_date: 'Fecha de activación',
        last_skill_date: 'Fecha última inducción',
        last_task_date: 'Fecha última tarea',
        selectPlaceholder: 'Selecciona',
        staff: 'Staff responsable',
        date_sent: 'Fecha de envío',
      },
      sent: {
        filterSearch: {
          title: 'Buscar',
          placeholder: 'Busca por notificación',
        },
        selectPlaceholder: 'Selecciona',
        staff: 'Staff responsable',
        date_sent: 'Fecha de envío',
        filterDatePicker: { title: 'Fecha de envío' },
        filterClear: { title: 'Limpiar filtros' },
      },
      sendType: {
        title: 'Tipo de envío',
        selectPlaceholder: 'Selecciona',
        options: {
          by_template: 'Vía plantilla',
          normal: 'Selección de Jobbers',
        },
      },
    },
    tabs: {
      sendNotifications: 'Envio de notificaciones',
      sentNotifications: 'Estado de notificaciones enviadas',
    },
    actions: {
      button: 'Enviar notificación',
    },
    table: {
      title: 'Título',
      staff: 'Staff responsable',
      quantity: 'N° de Jobbers',
      date: 'Fecha de envío',
      type: 'Tipo de envío',
      actions: {
        unique: 'Enviar notificación',
        generate: 'Generar reporte de Jobbers',
      },
      tag: {
        byTemplate: 'Vía plantilla',
        normal: 'Selección de Jobbers',
      },
    },
    drawer: {
      title: 'Notificación',
      actions: 'Acciones',
      date: 'Fecha',
      quantity: 'Cantidad de Jobbers',
      notificationTitle: 'Título',
      notificationBody: 'Cuerpo',
      jobbers: 'Jobbers',
      withoutEmail: 'jobber{{num}}@gmail.com',
    },
    reportModal: {
      title: 'Generar reporte de Jobbers',
      subtitle:
        'Al confirmar la generación del reporte, enviaremos un email a <b>{{email}}</b>, con el listado completo de Jobbers a los que fue enviada la notificación.',
      cancel: 'Volver',
      submit: 'Generar reporte',
      alerts: {
        error: 'No se logró completar la solicitud de envío.',
        success: 'El reporte de Jobbers ha sido enviado exitosamente a {{email}}',
      },
    },
    modal: {
      title: {
        massive: 'Enviar notificación masiva',
        selected: 'Enviar notificación',
        unique: 'Enviar notificación',
        confirm: 'Confirmación de envío',
      },
      subtitle: {
        massive:
          'Haz seleccionado todos los Jobbers filtrados en la búsqueda, <b>por lo que la notificación será enviada a {{qty}} Jobbers.</b>',
        selected: 'Ingresa el mensaje que recibirá el o los Jobbers seleccionados.',
        unique: 'Ingresa el mensaje que será enviado al Jobber <b>{{jobber}}.</b>',
        confirm: {
          massive:
            'Al confirmar el envío de la notificación, esta será enviada a <b>{{qty}} Jobbers.</b>',
          selected:
            'Al confirmar el envío de la notificación, esta será enviada a <b>{{qty}} Jobbers.</b>',
          unique: 'Confirma el envío de la notificación al Jobber <b>{{jobber}}</b>.',
        },
      },
      paginate: {
        info: {
          title: 'Actualmente hay {{total}} jobbers filtrados',
          desc1: 'Realiza el envío a los',
          desc2: 'usuarios',
        },
        subtitle:
          'Como el número de usuarios supera los 100 registros, separamos el envío de notificaciones en {{partials}} proceso(s) con un máximo de {{limit}} notificaciones por cada envío.',
        description:
          'Estás enviando la notificación para los usuarios {{counter}} de un total de {{total}}.',
        button: 'Continuar',
      },
      alert: {
        info: '{{qty}} Jobbers seleccionados.',
        success: 'El mensaje se envió correctamente a los jobbers seleccionados.',
        error: 'No se logró enviar el mensaje.',
      },
      form: {
        title: {
          label: 'Título de la notificación',
          placeholder: 'Ingresa un título',
          required: 'El título es requerido',
          length: 'La cantidad máxima de caracteres son 40.',
        },
        body: {
          label: 'Cuerpo de la notificación',
          placeholder: 'Ingresa un mensaje',
          required: 'El mensaje es requerido',
          length: 'La cantidad máxima de caracteres son 135.',
        },
        next: 'Confirmar envío',
        submit: 'Enviar notificación',
        cancel: 'Cancelar',
        return: 'Volver',
      },
    },
  },
};
