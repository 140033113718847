export default {
  auth: {
    login: {
      title: 'Iniciar sesión',
      otpButton: 'Solicitar código',
      otpSent: 'Hemos enviado el código de ingreso al correo',
      otpReSentButton: 'Reenviar código',
      signInOtherEmail: 'Ingresar con otro correo',
      text: 'Ingresa tu email para recibir el código de ingreso.',
      alertNotFound: 'Email incorrecto. Por favor intenta nuevamente',
      alertBlocked: 'La cuenta se encuentra bloqueada.',
      alertOtpNotFound:
        'El código no es correcto. Revisa que esté bien escrito e intenta nuevamente.',
      alertMaxLoginRetries:
        'Has superado el número máximo de intentos. Por favor, intenta nuevamente en 5 minutos',
      button: 'Iniciar sesión',
      psw: 'Olvidé mi contraseña',
      activeUser:
        'Tu cuenta ya se encuentra activa.  Si no recuerdas tu contraseña, puedes recuperarla en cualquier momento',
    },
    newOtp: {
      alert: {
        success: 'Código reenviado con éxito. Revisa tu correo.',
      },
    },
  },
};
