import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import server from '@services/api';

import { requirementsRoute } from '@features/settings/ts/routes';

export const settingsAdapter = createEntityAdapter();

export const initialState = settingsAdapter.getInitialState({
  requirements: { docs: [], loading: false },
  error: null,
});

export const getAllRequirements = createAsyncThunk(
  'settings/getAllRequirements',
  async (params?: any) => {
    const response = await server.get(requirementsRoute(), { params: { ...params, limit: 10e9 } });
    return response.data;
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getAllRequirements.pending)]: (state) => {
      state.requirements.loading = true;
    },
    [String(getAllRequirements.rejected)]: (state) => {
      state.requirements.loading = false;
    },
    [String(getAllRequirements.fulfilled)]: (state, action) => {
      state.requirements = { docs: action.payload?.data?.docs ?? [], loading: false };
    },
  },
});

export default settingsSlice.reducer;
