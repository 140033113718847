import { configureStore, Action } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistReducer,
  createTransform,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import applicantsSlice from '@features/applicants/applicantsSlice';
import authReducer from '@features/auth/authSlice';
import componentsSlice from '@features/components/componentsSlice';
import coordinatorSlice from '@features/maintainers/coordinatorSlice';
import coursesSlice from '@features/courses/coursesSlice';
import enterprisesSlice from '@features/enterprises/enterprisesSlice';
import jobTypesSlice from '@features/maintainers/jobTypesSlice';
import locationsSlice from '@features/locations/locationsSlice';
import reportSlice from '@features/finance/report/reportSlice';
import settingsSlice from '@features/settings/settingsSlice';
import skillsSlice from '@features/skills/skillsSlice';
import staffsSlice from '@features/staffs/staffsSlice';
import storesSlice from '@features/enterprises/storesSlice';
import finesAndDiscountsSlice from '@features/finesAndDiscounts/finesAndDiscountsSlice';
import tasksSlice from '@features/tasks/tasksSlice';
import templatesSlice from '@features/templates/templatesSlice';
import schemesSlice from '@features/schemes/schemesSlice';
import blocklistSlice from '@features/blocklist/blocklistSlice';
import deleteListSlice from '@features/deleteList/deleteListSlice';
import paymentsSlice from '@features/payments/paymentsSlice';
import deliverySlice from '@features/delivery/deliverySlice';
import dashboardSlice from '@features/dashboard/dashboardSlice';
import jobberRequestsSlice from '@features/jobberRequests/jobberRequestsSlice';
import notificationsSlice from '@features/notifications/notificationsSlice';
import applicationsSlice from '@features/applications/applicationsSlice';
import clientsEnterpriseSlice from '@features/clientsEnterprise/clientsEnterpriseSlice';
import requirementsSlice from '@features/requirements/requirementsSlice';

const persistedStores = ['auth', 'tasks', 'applications'];
const persistedKeys = { auth: ['user'], tasks: ['listFilter'], applications: ['listFilter'] };

const reducers = combineReducers({
  applicants: applicantsSlice,
  applications: applicationsSlice,
  auth: authReducer,
  business: enterprisesSlice,
  blocklist: blocklistSlice,
  deletelist: deleteListSlice,
  components: componentsSlice,
  coordinator: coordinatorSlice,
  courses: coursesSlice,
  enterprises: enterprisesSlice,
  jobTypes: jobTypesSlice,
  locations: locationsSlice,
  report: reportSlice,
  settings: settingsSlice,
  skills: skillsSlice,
  staffs: staffsSlice,
  stores: storesSlice,
  finesAndDiscounts: finesAndDiscountsSlice,
  tasks: tasksSlice,
  templates: templatesSlice,
  schemes: schemesSlice,
  payments: paymentsSlice,
  delivery: deliverySlice,
  dashboard: dashboardSlice,
  jobberRequests: jobberRequestsSlice,
  notifications: notificationsSlice,
  clientsEnterprise: clientsEnterpriseSlice,
  requirements: requirementsSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') return reducers(undefined, action);
  return reducers(state, action);
};

const customTransform = createTransform(
  (state, key) => {
    const stateAux = Object.fromEntries(
      persistedKeys[key].map((subkey) => [subkey, state[subkey]])
    );
    if (persistedStores.includes(String(key)) && !stateAux) {
      //In case stateAux is undefined it defines a default state
      const newState = {};
      persistedKeys[key].forEach((elem) => {
        newState[elem] = {};
      });
      return newState;
    }
    return stateAux;
  },
  (state) => state,
  { whitelist: persistedStores }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: persistedStores,
  transforms: [customTransform],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const storeOnly = configureStore({
  reducer: rootReducer,
});

export default store;

export type RootState = ReturnType<typeof storeOnly.getState>;
export type AppDispatch = typeof storeOnly.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type ThunkAPI = {
  state: ReturnType<typeof storeOnly.getState>;
};
