export default {
  skills: {
    nameWArticle: 'la Skill',
    enums: {
      skillStatus: {
        finalized: 'Finalizada',
        inProgress: '{{progress}}% progreso',
        no_progress: 'Sin progreso',
        signed_up: 'Inscrita',
        pending_staff: 'Pendiente',
        onSiteSigned_up: 'Agendada',
        finalizedByStaff: 'Finalizada por staff',
      },
    },
    main: {
      title: 'Skills',
      subtitle: 'Revisa el listado de skills creadas y su estado.',
      buttons: {
        createSkill: 'Crear Skill',
      },
      actionsDrawer: {
        new: {
          title: 'Crear Skill',
          subtitle: 'Ingresa los datos para crear la Skill',
          submitButton: 'Crear skill',
          cancelModal: {
            title: 'Cancelar creación skill',
            text: 'Al confirmar la cancelación, perderás los datos ingresados ',
            okText: 'Cancelar creación',
          },
          successMessage: 'Skill ha sido creada exitosamente.',
        },
        finalizeSkill: {
          alertFinalized:
            'La skill cambiará a estado finalizado y notificaremos al Jobber. Recuerda que después de finalizarla puedes volver al estado que se encuentra actualmente.',
          titleFinalize: 'Finalizar skill',
          alertRecalculate: 'La skill cambiará a su estado anterior y notificaremos al Jobber.',
          titleRecalculate: 'Volver al estado anterior',
          alertSuccessFinalize: 'Skill finalizada exitosamente',
          alertSuccessRecalculate: 'Skill ha cambiado a su estado anterior exitosamente',
          button: {
            finalize: 'Finalizar Skill',
            recalculate: 'Volver al estado anterior',
          },
          infoMessage: {
            finalize:
              'La skill cambiará a estado finalizado y notificaremos al Jobber. Recuerda que después de finalizarla puedes volver al estado que se encuentra actualmente.',
            recalculate: 'La skill cambiará a su estado anterior y notificaremos al Jobber.',
          },
          history: {
            finalizedBy: 'Finalizada por {{staffName}}',
            revertBy: 'Vuelta a {{status}} por {{staffName}}',
            showHistory: 'Ver historial',
          },
        },
        edit: {
          title: 'Editar Skill',
          subtitle:
            'Ingresa los datos para editar la Skill. Puedes navegar a través del paso a paso o haciendo click en el botón “siguiente”',
          submitButton: 'Guardar cambios',
          cancelModal: {
            title: 'Cancelar edición skill',
            text: 'Se han editado uno o más cambios. Al salir perderás todos los cambios realizados.',
            okText: 'Cancelar edición',
          },
          successMessage: 'Skill ha sido editada exitosamente.',
          manualSkill: 'Selecciona las skills que agregarás al perfil del Jobber',
        },
        steps: [
          {
            stepperTitle: 'Tipo tarea',
            onlySkillDesc: 'Sólo puedes editar la descripción de Skill',
            inputs: {
              type: {
                title: 'Tipo de tarea',
                label: 'Tipo *',
                message: 'Ingresa el tipo de tarea',
                existSkillThatHasType: 'Ya existe la skill para este tipo de tarea.',
                existSkillThatHasTypeAndClient:
                  'Ya existe la skill para este tipo de tarea y cliente.',
                existForJobTypeWithEnterpriseAndStore:
                  'Ya existe la skill para este tipo de tarea, cliente y sede.',
              },
              enterprise: {
                title: 'Cliente',
                subtitle:
                  'Selecciona el cliente comercial. Si no es necesario, puedes dejar este campo vacío.',
                label: 'Cliente comercial',
              },
              store: {
                title: 'Sede',
                subtitle:
                  'Selecciona la sede en caso de que la inducción sea específica por sede. Si no es necesario, puedes dejar este campo vacío.',
                label: 'Sede',
              },
              description: {
                title: 'Descripción Skill',
                placeholder: 'Ingresa una descripción de la skill.',
                label: 'Descripción *',
                message: 'Ingresa la descripcion del skill',
              },
            },
          },
          {
            stepperTitle: 'Ubicación preferente',
            inputs: {
              allRegions: {
                title:
                  'Aplicar para todas $t(global.regionWArticle_plural, lowercase) de $t(global.settings.country.name) *',
                applyToAll: 'Al seleccionar esta opción, la Skill aplicará para todo el país',
              },
              communes: {
                add: 'Agregar otra $t(global.region, lowercase)',
                incompleteError: 'Tienes un error en alguno de los forms anteriores',
                emptyError: 'Debes añadir información en esta sección',
              },
            },
          },
          {
            stepperTitle: 'Cursos',
            inputs: {
              virtual: {
                title: 'Cursos e-learning/meets',
                subtitle: 'Agrega cursos e-learning o meets.',
                label: 'Cursos e-learning/meets',
                alert: 'Agrega los cursos en el orden que deseas que sean vistos por el Jobber',
              },
              onSite: {
                onSiteCheckbox: 'Requiere de curso presencial',
                title: 'Horario disponibles',
                startHour: 'Hora inicio *',
                endHour: 'Hora término *',
                add: 'Agregar otro horario',
                alert:
                  'Recuerda incorporar los días que la sede tendrá coordinador en sala para realizar el curso presencial. Esto se debe gestionar directamente al editar una sede desde la sección de Clientes.',
              },
            },
          },
          {
            stepperTitle: 'Otros requisitos',
            inputs: {
              documents: {
                title: 'Documentos',
                subtitle: 'Selecciona los documentos que debe tener el Jobber',
                label: 'Documentos',
              },
              vehicles: {
                title: 'Vehículo',
                subtitle: 'Selecciona el vehículo y los documentos que debe cargar el Jobber',
                checkedAll: 'Seleccionar todos',
                delete: 'Borrar selección',
                selectSubtitle: 'Selecciona los documentos requeridos',
              },
              specificRequirements: {
                title: 'Requisitos específicos',
                subtitle: 'Selecciona los requisitos que debe cumplir el Jobber',
                label: 'Requisitos',
              },
              os: {
                title: 'Recomendaciones del Skill (no excluyentes)',
                subtitle:
                  'Selecciona dispositivo y elementos de protección personal deseables para el Jobber',
                inputTitle: 'Dispositivos *',
                message: 'Ingresa el tipo de dispositivo',
              },
              personalProtection: {
                title: 'Elementos de protección personal',
                label: 'Elementos de protección personal',
              },
            },
          },
          {
            alert:
              'Revisa que todos los datos estén correctos, antes de confirmar la creación de skill',
            confirmSteps: [
              {
                title: 'Tipo tarea',
                type: 'Tipo',
                client: 'Cliente comercial',
                store: 'Sede',
                description: { title: 'Descripción Skill', subtitle: 'Descripción' },
              },
              {
                title: 'Ubicación preferente',
                allRegions:
                  'Aplica para $t(global.allRegionWArticle_plural, lowercase) de $t(global.settings.country.name)',
              },
              {
                title: 'Cursos',
                noCourses: 'No has agregado cursos',
              },
              {
                title: 'Otros requisitos',
                documents: 'Documentos',
                noDocs: 'No has agregado documentos',
                os: 'Dispositivo',
                specificRequirements: 'Requisitos específicos',
                noSR: 'No has agregado requisitos específicos',
                ppe: 'Elementos de protección personal',
                noPpe: 'No has agregado elementos de protección personal',
              },
            ],
            inputs: {
              isActive: {
                activate: 'Activar skill',
                deactivate: 'Desactivar skill',
                text: 'Al activar esta opción, tu skill quedará activa.',
                alert:
                  'Puedes activar o desactivar la skill en cualquier momento desde la columna acciones, en la tabla Skill del Gestor de inducciones',
              },
            },
          },
        ],
        saveSkillCourses: {
          successMessage: 'Curso finalizado exitosamente',
          successMessageRemove: 'Curso removido exitosamente',
          text: 'Los cursos seleccionados cambiarán a estado finalizado y notificaremos al Jobber.',
          textRemove:
            'Los cursos seleccionados serán desinscritos. En caso de que el jobber cuente con la skill, la perderá.',
        },
      },
      delete: {
        modal: {
          title: 'Eliminar skill',
          subtitle: 'Al eliminar la skill <b>{{name}},</b>',
          cancel: 'Cancelar',
          submit: 'Eliminar Skill',
          success: 'La Skill <b>{{name}}</b>, fue eliminada exitosamente.',
          error: 'No se logró eliminar la Skill.',
          tasks: {
            status: {
              start: ' se afectará a',
              task: 'tarea',
              task_plural: 'tareas',
              scheduled: 'programada',
              scheduled_plural: 'programadas',
              published: 'publicada',
              published_plural: 'publicadas',
              ongoing: 'en curso',
              ongoing_plural: 'en curso',
              templates: 'plantilla para crear tareas.',
              templates_plural: 'plantillas para crear tareas.',
              empty: ' no podrás recuperar esta información.',
            },
          },
        },
      },
      filterClear: { title: 'Limpiar filtros' },
      filterDatePicker: { title: 'Fecha de creación' },
      filterSearch: { title: 'Buscar', placeholder: 'Buscar por skill' },
      filters: {
        enabled: {
          title: 'Estado',
          placeholder: 'Selecciona',
          options: {
            true: 'Activada',
            false: 'Desactivada',
          },
        },
        errorPattern: 'Caracter inválido',
      },
      columns: [
        { title: 'Nombre', courses: '{{count}} curso', courses_plural: '{{count}} cursos' },
        {
          title: 'Ubicación',
          region: '{{count}} $t(global.region, lowercase)',
          region_plural: '{{count}} $t(global.region_plural, lowercase)',
        },
        { title: 'Creado por' },
        { title: 'Última modificación' },
        { title: 'Estado' },
        { title: 'Acciones' },
      ],
      kpi: {
        total: { title: 'Total skills creadas' },
        isEnabled: { title: 'Activas' },
        notEnabled: { title: 'Inactivas' },
      },
      options: {
        edit: 'Editar',
        disable: 'Desactivar',
        enable: 'Activar',
        delete: 'Eliminar Skill',
      },
      summary: {
        title: 'Detalle Skill',
        subtitle: 'Ingresa los datos para crear la Skill',
      },
    },
    components: {
      ppes: { label: 'Elementos *' },
      specificsRequirements: {
        label: 'Requisitos *',
      },
      inputSkills: { label: 'Skills *' },
      card: {
        staffOwner: 'Staff responsable: {{staffName}}, {{date}}',
        signedUp: 'Inscripción: {{date}}',
        titleCourses: 'Cursos',
        titleELearning: 'E-learning',
        titleDocument: 'Documentos',
        additionalRequirements: 'Requisitos Adicionales',
        additional: 'Adicionales',
        noRequirement: 'No requiere',
        tooltip: {
          expired: 'Jobber debe actualizar documento para volver a tener la Skill.',
        },
      },
    },
  },
};
