export default {
  report: {
    title: 'Reportería',
    subtitle: 'Descarga y envia reportes a tu email o realiza una carga masiva de pagos.',
    tabs: {
      title: 'Solicitar Reporte',
    },
    alerts: {
      preLoading: 'Generando y enviando reporte',
      preAlert: 'Esta es una previzualisación de tu búsqueda.',
      success: 'Su reporte se ha enviado a su correo con éxito.',
      error: 'No se encontraron datos relacionados a los filtros, intente nuevamente.',
      empty: 'Selecciona el tipo de reporte que quieres generar, para continuar.',
    },
    form: {
      label: {
        dates: 'Fecha ejecución tarea',
        deliveryDate: 'Fecha compromiso/despacho pedido',
        singleDate: 'Rango de fechas',
        rangePicker: ['Inicio', 'Fin'],
        typeTask: 'Tipo de tarea',
        client: 'Cliente',
        store: 'Sede',
        jobber: 'ID Jobber',
        applicationStatus: {
          title: 'Estados Applications',
          options: {
            applied: 'Postulados',
            assigned: 'Asignados',
            cancelled: 'Cancelados',
            deallocated: 'Desasignados',
            present: 'Presentes',
            absent: 'Ausentes',
            finished: 'Finalizados',
          },
        },
        filterRequired: 'Debes aplicar al menos 1 filtro de fecha para enviar el reporte.',
        documentCode: {
          options: {
            finalized: 'Finalizados',
            not_finalized: 'No finalizados',
            applied: 'Postulados',
            tariff: 'Tarifario',
            job_list: 'Tareas',
            payment_upload: 'Carga masiva de cantidades Labour',
            payment_upload_shopper: 'Carga masiva de cantidades (Shopper)',
            invoice: 'Reporte de cobro',
            invoice_hours: 'Reporte de Cobro - Variable horas',
            tariff_applications: 'Tarifario Applications',
            shopper_invoice: 'Prefactura (delivery orders)',
            shopper_invoice_no_delivery: 'Prefactura Shopper',
            load_picker_finished: 'Carga de pedidos Picker no creados en Backoffice',
            transform_shopper_driver: 'Transformar pedidos Shopper-Driver',
            assigned_payroll_rappi: 'Nómina de asignados Rappi',
          },
          title: 'Tipo de reporte',
        },
      },
      placeholder: {
        typeTask: 'Selecciona',
        client: 'Selecciona',
        store: 'Selecciona',
        jobber: 'Busca por ID',
      },
      rules: {
        messages: {
          dates: 'La fechas son requeridas.',
          jobber: 'Conformado por números.',
        },
      },
    },
    actions: {
      generateReport: 'Enviar Reporte',
      clear: 'Limpiar filtros',
      generateBankReport: 'Descargar información bancaria de Jobbers',
      generateProfesionalProfile: 'Descargar perfil profesional del Jobbers',
      generateVehicles: 'Descarga registro de vehículos',
    },
    paymentUpload: {
      loading: 'Cargando planilla de cantidades',
      button: 'Cargar plantilla cantidades',
      file: {
        title: 'Carga masiva de cantidades',
        button: 'Cargar cantidades',
        download: 'Descargar plantilla base',
        downloadLink: {
          develop: `https://tj-public-assets-dev.s3.amazonaws.com/quantity.csv?${new Date().getTime()}`,
          staging: `https://tj-public-assets-stg.s3.amazonaws.com/quantity.csv?${new Date().getTime()}`,
          production: `https://tj-public-assets.s3.amazonaws.com/quantity.csv?${new Date().getTime()}`,
        },
        invalidFile: 'Documento inválido',
      },
      alert: {
        textInfo: 'Recuerda completar todos los datos del Jobber y tarea en el documento.',
        textAmounts: 'En la columna CANTIDAD se de ingresar un número válido.',
        staggered: {
          textInfo: 'Completa todos los datos del Jobber y tarea en el documento.',
          textAmounts1: 'La columna CANTIDAD corresponde a la magnitud de la variable',
          textAmounts2: 'ejecutada en la application y Q_ESCALONADO, corresponde a la',
          textAmounts3: 'cantidad de veces que se ejecuta la CANTIDAD asociada al rango',
          textAmounts4: 'escalonado. Si la variable no es escalonada, Q_ESCALONADO debe ir',
          textAmounts5: 'vacío.',
        },
        textVariables: 'Se pueden ingresar las siguientes variables de pago: ',
        success: 'Planilla cargada exitosamente.',
        error: 'El documento contiene errores. Por favor vuelve a revisar e intenta nuevamente.',
      },
      quantityUpdate: {
        textOne: '<strong>En caso de cometer un error en las cantidades cargadas,</strong> podrás',
        textTwo: 'corregir cargando nuevamente el documento con las modificaciones.',
        textThree: 'Aplica para Applications que no hayan gatillado orden de pago, o',
        textFour: 'tengan una orden de pago que esté en estado por pagar/cancelada.',
      },
    },
    shopperReport: {
      title: 'Prefactura Shopper',
      subtitle: 'Genera la prefactura de pagos Shoppers.',
      form: {
        generate: {
          label: 'Generar *',
          placeholder: 'Selecciona',
          options: {
            prefecture: 'Prefactura',
            payment_report: 'Reporte de pagos',
          },
        },
        enterprise: { label: 'Cliente *', placeholder: 'Selecciona' },
        store: { label: 'Sede', placeholder: 'Selecciona' },
        dates: { label: 'Fecha de ejecución tarea *', start: 'inicio', end: 'fin' },
        actions: { clear: 'Limpiar filtros', submit: 'Generar Reporte' },
      },
      alert: {
        successTemplate: 'El reporte ha sido enviado al correo <b>{{email}}</b> con éxito.',
      },
    },
    paymentUploadShopper: {
      title: 'Carga masiva de cantidades (Shopper)',
      subtitle: 'Selecciona los filtros para generar la plantilla que cargarás.',
      loading: 'Cargando planilla de cantidades (Shopper)',
      button: 'Cargar plantilla cantidades (Shopper)',
      form: {
        enterprise: { label: 'Cliente', placeholder: 'Selecciona' },
        store: { label: 'Sede', placeholder: 'Selecciona' },
        dates: { label: 'Fecha de ejecución tarea *', start: 'inicio', end: 'fin' },
        actions: { clear: 'Limpiar filtros', submit: 'Generar plantilla' },
      },
      file: {
        title: 'Carga plantilla de cantidades',
        button: 'Cargar plantilla',
        download: 'Descargar plantilla base',
        downloadLink: {
          develop: `https://tj-public-assets-dev.s3.amazonaws.com/quantityShopperOrder.csv?${new Date().getTime()}`,
          staging: `https://tj-public-assets-stg.s3.amazonaws.com/quantityShopperOrder.csv?${new Date().getTime()}`,
          production: `https://tj-public-assets.s3.amazonaws.com/quantityShopperOrder.csv?${new Date().getTime()}`,
        },
        invalidFile: 'Documento inválido',
      },
      alert: {
        info: 'Recuerda completar todos los datos de acuerdo a lo establecido en la plantilla. En la columna CANTIDAD, se debe ingresar un número entero mayor o igual a cero.<br>Se pueden ingresar las siguientes variables de pago:',
        success:
          'Planilla cargada exitosamente. Hemos enviado un correo con el detalle de la carga',
        error: 'El documento contiene errores. Por favor vuelve a revisar e intenta nuevamente.',
        successTemplate:
          'Hemos enviado la plantilla a tu correo, para que puedas ingresar las cantidades.',
        errorTemplate: 'No se logró generar la plantilla.',
      },
      secondAlert: {
        part1: 'En caso de cometer un error en las cantidades cargadas, ',
        part2:
          'podrás corregir cargando nuevamente el documento con las modificaciones. Aplica para Pedidos de Shopper que no hayan gatillado orden de pago, o  tengan una orden de pago que esté en estado por pagar/cancelada.',
      },
    },
    paymentUploadLabour: {
      title: 'Carga masiva de cantidades Labour',
      subtitle: 'Aplica el filtro para generar la plantilla que cargarás.',
      loading: 'Cargando planilla de cantidades Labour',
      button: 'Cargar plantilla cantidades Labour',
      form: {
        enterprise: { label: 'Cliente', placeholder: 'Selecciona' },
        store: { label: 'Sede', placeholder: 'Selecciona' },
        dates: { label: 'Fecha de ejecución tarea *', start: 'inicio', end: 'fin' },
        actions: { clear: 'Limpiar filtros', submit: 'Generar plantilla' },
      },
      file: {
        title: 'Carga plantilla de cantidades',
        button: 'Cargar plantilla',
        invalidFile: 'Documento inválido',
      },
      alert: {
        info: 'Recuerda completar todos los datos del Jobber y tarea en el documento. En la columna CANTIDAD se de ingresar un número válido.<br>Se pueden ingresar las siguientes variables de pago:',
        success:
          'Planilla cargada exitosamente. Hemos enviado un correo con el detalle de la carga',
        error: 'El documento contiene errores. Por favor vuelve a revisar e intenta nuevamente.',
        successTemplate:
          'Hemos enviado la plantilla a tu correo, para que puedas ingresar las cantidades.',
        errorTemplate: 'No se logró generar la plantilla.',
      },
      secondAlert: {
        part1: 'En caso de cometer un error en las cantidades cargadas, ',
        part2:
          'podrás corregir cargando nuevamente el documento con las modificaciones.  Aplica para Applications que no hayan gatillado orden de pago, o tengan una orden de pago que esté en estado por pagar/cancelada.',
      },
    },
    createPickerFinished: {
      title: 'Carga de pedidos Picker no creados en Backoffice',
      subtitle: 'Descarga la plantilla base e ingresa los datos solicitados.',
      button: 'Cargar plantilla de pedidos',
      file: {
        title: 'Carga de plantilla',
        button: 'Cargar plantilla',
        invalidFile: 'Documento inválido',
        download: 'Descargar plantilla base',
        downloadLink: {
          develop: `https://tj-public-assets-dev.s3.amazonaws.com/Carga+Masiva+de+pedidos+Picker.csv?${new Date().getTime()}`,
          staging: `https://tj-public-assets-stg.s3.amazonaws.com/Carga+Masiva+de+pedidos+Picker.csv?${new Date().getTime()}`,
          production: `https://tj-public-assets.s3.amazonaws.com/Carga+Masiva+de+pedidos+Picker.csv?${new Date().getTime()}`,
        },
      },
      alert: {
        info: 'Al finalizar la carga, te enviaremos un correo con el resultado de la misma.',
        success:
          'Planilla cargada exitosamente. Hemos enviado un correo con el detalle de la carga',
        error: 'No hemos podido cargar la plantilla. Intenta nuevamente.',
      },
    },
    transformShopperDriver: {
      title: 'Transformar pedidos Shopper-Driver',
      subtitle: 'Transforma pedidos de tag Shopper a pedidos de tag Driver y viceversa.',
      button: 'Cargar plantilla de pedidos',
      file: {
        title: 'Carga de plantilla',
        button: 'Cargar plantilla',
        invalidFile: 'Documento inválido',
        download: 'Descargar plantilla base',
        downloadLink: {
          develop: `https://tj-public-assets-dev.s3.amazonaws.com/transformacion_shopper_driver.csv?${new Date().getTime()}`,
          staging: `https://tj-public-assets-stg.s3.amazonaws.com/transformacion_shopper_driver.csv?${new Date().getTime()}`,
          production: `https://tj-public-assets.s3.amazonaws.com/transformacion_shopper_driver.csv?${new Date().getTime()}`,
        },
      },
      alert: {
        info: 'Al finalizar la carga, te enviaremos un correo con el resultado de la misma.',
        success:
          'Planilla cargada exitosamente. Hemos enviado un correo con el detalle de la carga',
        error: 'No hemos podido cargar la plantilla. Intenta nuevamente.',
      },
    },
    assignedPayroll: {
      form: {
        dates: { label: 'Fecha de ejecución tarea *', start: 'inicio', end: 'fin' },
      },
      alert: {
        success: 'El reporte ha sido enviado al correo <strong>{{email}}</strong> con éxito.',
        error: 'No hemos podido generar y enviar el reporte. Intenta nuevamente.',
      },
    },
  },
};
