export enum BlockKindEnum {
  GENERAL = 'general',
  SPECIFIC = 'specific',
}

export enum RestrictionTypeEnum {
  CLIENT = 'client',
  CLIENT_PREMISES = 'client_premises',
  CLIENT_TASK = 'client_task',
  TASK = 'task',
  TASK_PREMISES = 'task_premises',
}

export enum ReasonsBlockEnum {
  OTHER_REASON = 'other_reason',
  BAD_BEHAVIOR = 'bad_behavior',
  STEAL_ACCUSATION = 'steal_accusation',
}

export enum ActionStatusCollapseEnum {
  CREATED = 'created',
  BLOCKED = 'blocked',
  UNBLOCKED = 'unblocked',
  EDIT = 'edit',
  DELETE = 'delete',
}

export enum StatusTagsCollapseEnum {
  NEW = 'new',
  UNBLOCKED = 'unBlocked',
  EDIT = 'edit',
  NEW_RESTRICTION = 'newRestriction',
  DELETE_RESTRICTION = 'deleteRestriction',
  EDIT_RESTRICTION = 'editRestriction',
}
