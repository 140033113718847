export default {
  delivery: {
    page: {
      title: 'Delivery orders',
      subtitle: 'Revisa el listado de órdenes creadas y sus estados.',
      deliveryOrdersTab: 'Pedidos activos',
      eventsReportTab: 'Reporte de eventos',
      nEvents: '{{quantity}} reportes',
    },
    shopperOrdersList: {
      filterSearch: { title: 'Buscar', placeholder: 'N° Pedido, nombre o apellido Jobber' },
      filterClear: { title: 'Limpiar filtros' },
      filterDatePicker: { title: 'Fecha de creación' },
      filters: {
        status: 'Estado',
        select: 'Seleccione',
        date: 'Fecha compromiso',
        createdAt: 'Fecha de creación',
        subStatus: 'Subestados',
        type: 'Rol del Jobber',
        extraType: 'Pedido especial',
        windowsStart: {
          title: 'Inicio ventana',
          from: 'Desde',
          to: 'Hasta',
        },
      },
      columns: {
        code: 'N° Pedido',
        jobber: {
          title: 'Jobber',
          without: 'Sin jobber asignado',
          tags: {
            picker: 'Picker',
            driver: 'Driver',
            shopper: 'Shopper',
            completeBase: 'Base completa',
          },
        },
        store: 'Sede/Cliente',
        0: {
          title: 'Fecha creación',
          popover: 'Fecha en la que se creó el pedido.',
        },
        1: {
          title: 'Km',
          popover: 'Distancia entre la sede y el cliente final (entregada por google/ waze).',
        },
        2: {
          title: 'Fecha compromiso',
          popover: 'Fecha y ventana comprometida en la que se debe realizar la entrega del pedido.',
        },
        status: 'Estado',
        actions: 'acciones',
      },
      tooltip: {
        status: {
          ready:
            'Este pedido aún no ha sido asignada a un Jobber. Procura que sea asignada antes de completar su ventana.',
          reserved: '',
          ongoing: '',
          arrived: '',
          finished: '',
          cancelled_by_staff: '',
          cancelled_by_company: '',
        },
        ignore_integration: 'Pedido creado manualmente y sin integración con Beetrack o Janis.',
      },
      status: {
        ready: 'Lista',
        reserved: 'Reservado',
        ongoing: 'En curso',
        arrived: 'Jobber llega a la dirección',
        finished: 'Finalizada',
        cancelled_by_staff: 'Cancelada por staff',
        cancelled_by_company: 'Cancelada por cliente',
      },
      tag: {
        ready: 'No asignado',
        beetrack_error: 'Error Beetrack',
        ignore_integration: 'Sin integración',
      },
      actions: {
        close: 'Cerrar pedido',
        disassociate: 'Desasociar pedido',
        cancel: 'Cancelar pedido',
        kilometers: 'Actualizar dirección',
        updateAddress: 'Actualizar información',
        difference: 'Diferencia en Kilómetros',
        restore: 'Cambiar estado a Lista',
        store: 'Cambiar local',
        picker: 'Asignar Picker',
        overweight: 'Crear clon por sobrepeso',
        close_picker: 'Cerrar pedido',
        payCompleteBase: 'Pagar base completa',
        payOriginalBase: 'Pagar base original',
      },
    },
    listFilter: {},
    drawer: {
      title: 'N° Pedido {{code}}',
      subTitle: 'ID {{id}}',
      jobber: {
        title: 'Jobber asignado',
        without: 'Sin Jobber asignado',
        withoutMsg:
          'Este pedido aún no ha sido asignada a un Jobber. Procura que sea asignada antes de completar su ventana.',
      },
      beetrack: {
        error:
          'Pedido no actualizado. Por favor ingresa a Beetrack y actualiza su estado para continuar.',
      },
      ingoreIntegration: 'Pedido sin integración.',

      details: {
        title: 'Detalles del pedido',
        headquarter: 'Sede',
        business: 'Empresa',
        address: 'Dirección',
        date: 'Despacho',
        window: 'Ventana',
        dateInfo: 'Fecha comprometida en la que se debe realizar la entrega del pedido.',
      },
      payment: {
        title: 'Esquema de pago',
        amount: '$t(global.currencySymbol) {{amount}}',
        units: { title: 'Valores unitarios', prefix: 'Pago por {{name}}' },
        paymentCode: 'Orden de pago {{code}}',
        paymentType: {
          kms_difference: 'Ajuste Diferencia KMS',
          sku_difference: 'Ajuste Diferencia SKU',
          units_difference: 'Ajuste Diferencia Unidades',
          secure_difference: 'Ajuste Diferencia Asegurado',
          incentive_difference: 'Ajuste Diferencia Incentivo',
          extra_payment: 'Pago extra especial',
        },
        toInvoice: '(con cobro a cliente)',
        payments: { title: 'Pago Jobber', total: 'Pago total' },
      },
      history: {
        title: 'Historial',
        subtitle: 'Revisa el historial de movimientos del pedido y el despacho',
        status: 'Cambio estado pedido',
        differenceKm: 'Reporte diferencia en Km ─ {{extra}}',
        picker: 'Asignación de Picker',
      },
      dispatch: {
        title: 'Despacho',
        destiny: 'Destino',
        phone: 'Teléfono',
        details: 'Observaciones',
        distance: 'Distancia Km',
        items: 'SKU / Items',
        lumps: 'Bultos',
        note: 'Nota',
        distanceInfo: 'Distancia entre la sede y el cliente final (entregada por google/waze).',
        empty: 'Sin información.',
        position: 'Posición en ruta',
      },
      deliveryProof: {
        title: 'Prueba de entrega',
        receiver: 'Recibe',
        phone: 'Teléfono',
        evidences: 'Prueba de entrega',
        evidenceText: 'foto_de_la_entrega.jpg',
      },
      charge: {
        title: 'Carga de pedido',
        pre: 'Fotos pre-packing',
        post: 'Fotos post-packing',
        pending: 'Pendiente',
      },
      errors: {
        title: 'Reporte de problemas',
        evidence: 'Evidencia',
        file: 'foto_del_{{enum}}°_problema.jpg',
        reason: 'Motivo',
        comment: 'Comentario',
        km: 'Dirección errónea, {{km}} Km. Solicitud de actualización de kilómetros.',
        types: {
          client_reason: 'Motivo del cliente',
          missing_items: 'Productos perdidos',
          damaged_items: 'Productos dañados',
          items_not_applicable: 'Productos no aplicables',
          partial_delivery: 'Entrega parcial',
        },
      },
      associated: {
        title: 'Pedidos asociados a la ruta',
        order: 'Pedido {{code}}',
        empty: 'Sin pedidos asociados',
      },
      closeOrder: {
        firstTitle: 'Cerrar pedido',
        secondTitle: 'Pedido cerrado por Staff',
        subtitleWithJobber: 'Indica el estado en que quedó el delivery.',
        subtitleWithoutJobber:
          'Selecciona el Jobber que tomó el pedido e indica el estado en que quedó el delivery.',
        inputs: {
          jobber: 'El campo jobber es obligatorio',
        },
        order: 'Pedido N° {{code}}',
        empty: 'Sin pedidos asociados',
        select: 'Busca por RUT o email *',
        vehicle: 'Patente {{patent}}',
        withoutVehicle: 'El jobber no tiene un vehículo asociado',
        withoutVehicleAlert: 'No se puede hacer el cierre adminsitrativo, ya que el Jobber <strong>{{jobberName}}</strong> no tiene un vehículo asociado. Para agregarlo, el Jobber debe ingresar a la App, en la sección <strong>“Shopper” del menú y añadirlo.</strong>',
        form: {
          alerts: {
            success: 'Cierre de pedido realizado con éxito.',
            error: 'Ocurrió un error al cerrar el pedido.',
            info: 'Al seleccionar esta opción, la vuelta será considerada en el pago del Jobber.',
            hasApplication: {
              title: 'Para cerrar el pedido, debes considerar lo siguiente:',
              list: [
                'La tarea debe existir y ser de Personal Shopper o Home Delivery.',
                'La tarea debe coincidir con el local asociado al pedido.',
                'El Jobber debe tener una Application Asignada/Presente/Finalizada/Ausente, asociada a la tarea ingresada.',
              ],
            },
            hasError:
              'El pedido no tiene una dirección de destino válida, por lo que pasó a la sección de <b>Reporte de eventos</b> como pedido <b>"Creado con errores"</b>.<br />Actualiza la dirección de destino a una válida, para poder cerrar el pedido administrativamente.',
            withoutApplication:
              'El Jobber <strong>{{jobberName}},</strong> no tiene una Application en estado Asignada, Presente, Ausente o Finalizada en esa tarea, por lo que no puede ser agregada.',
          },
          details: {
            title: 'Detalles cierre',
          },
          status: {
            label: 'Estado del delivery *',
            required: 'El estado del delivery es requerido.',
          },
          position: {
            label: 'Posición en ruta *',
          },
          reason: {
            label: 'Motivo del cierre *',
          },
          receiver: {
            title: 'Datos del receptor',
            subtitle: 'Evidencia *',
            description: 'Máx. 3 imágenes - Peso: 10Mb - Formato: jpg',
            checkbox: 'Enviar datos del receptor',
            name: {
              label: 'Nombre *',
            },
            phone: {
              label: 'Teléfono *',
              required: 'El número de teléfono es requerido',
              pattern: 'Ingresé un número de teléfono válido',
            },
            btnValue: 'Cargar evidencia',
          },
          date: {
            label: 'Fecha de cierre',
            msg: 'La fecha de cierre es requerida',
            placeholder: 'día / mes / año',
          },
          time: {
            label: 'Hora de cierre',
            required: 'La hora de cierre es requerida.',
          },
          bags: {
            label: 'Número de bolsas',
            pattern: 'Solo se aceptan números',
            freeBags: {
              label: 'Productos sueltos (opcional)',
              pattern: 'Solo se aceptan números',
            },
          },
          evidence: {
            title: 'Prueba de retiro',
            withdrawTitle: 'Formulario de retiro',
            deliverTitle: 'Formulario de entrega',
            receiverData: 'Datos del receptor',
            receiverName: { label: 'Nombre' },
            receiverPhone: {
              label: 'Teléfono (opcional)',
              pattern: 'Ingrese un número de teléfono válido',
            },
            receiverFile: {
              title: 'Foto de productos',
            },
            receiverHome: {
              title: 'Foto de numeración de domicilio',
            },
            buttonUpload: 'Cargar evidencia',
            reasonDetail: 'Motivo',
            reasonError: 'Error',
          },
          schemas: {
            title: 'Esquema de pago y cobro de la tarea',
            subtitle:
              'Ingresa el código de la tarea de la cual se heredará el esquema de pago y cobro.',
            label: 'Código de la tarea *',
            error: {
              exist: 'El código ingresado no existe. Ingresa un válido para continuar.',
              lastmille:
                'El código ingresado no corresponde a una tarea Personal Shopper o Home Delivery. Ingresa uno válido para continuar.',
              store:
                'La tarea ingresada, no coincide con el local asociado al pedido. Ingresa uno válido para continuar.',
            },
            paymentSchema: 'Esquema de pago: ',
            invoiceSchema: 'Esquema de cobro: ',
          },
          uploadOrder: {
            title: 'Carga del pedido',
            prePacking: {
              title: 'Fotos pre-packing *',
              helper: 'Máx. 1 imagen - Peso 10Mb - Formato: jpg',
              button: 'Cargar foto',
            },
            postPacking: {
              title: 'Fotos post-packing *',
              helper: 'Máx. 1 imagen - Peso 10Mb - Formato: jpg',
              button: 'Cargar foto',
            },
          },
          job: {
            title: 'Esquema de pago y cobro de la tarea',
            subtitle:
              'Ingresa el código de la tarea de la cual se heredará el esquema de pago y cobro.',
            label: 'Código de la tarea *',
            error: {
              exist: 'El código ingresado no existe. Ingresa un válido para continuar.',
              notPicker:
                'El Jobber debe tener una <strong>Application Asignada, Presente o Finalizada</strong> a una tarea con tipo de tarea <strong>Picker, Picker Apoyo, Personal Shopper o Home Deliver.<strong>',
            },
            paymentSchema: 'Esquema de pago: ',
            invoiceSchema: 'Esquema de cobro: ',
          },
          return: {
            label: 'Pagar vuelta al local',
          },
          buttons: {
            cancel: 'Cancelar',
            ok: 'Cerrar pedido',
          },
        },
        closed: {
          title: 'Pedido cerrado por Staff',
          subtitle: 'Staff {{staff}}, {{date}}',
          status: 'Estado final del pedido',
          reason: 'Motivo del cierre',
          paymentSchema: 'Esquema de pago',
          invoiceSchema: 'Esquema de cobro',
          paidReturn: 'Pago de vuelta al local',
          position: 'Posición en ruta',
        },
      },

      disassociate: {
        title: 'Desasociar pedido',
        description:
          'Al desasociar el pedido, este será cerrado y se creará uno nuevo para que otro shopper lo pueda tomar.',
        detail:
          'El número del pedido será el mismo, pero podrás identificarlos con un "+1" en la tabla de pedidos.',
        cancel: 'Cancelar',
        submit: 'Desasociar pedido',
        success: 'Pedido desasociado con éxito.',
        fail: 'No se pudo desasociar el pedido.',
        jobber: 'Jobber desasociado',
        jobberMsg: '{{action}} por {{name}}',
        oldKm: 'Distancia del pedido: {{km}}Km',
        newKm: 'Distancia indicada por el Jobber: {{km}}Km',
        reason: 'Motivo: {{value}}',
        form: {
          options: {
            wrong_order_entered: 'Pedido mal ingresado',
            external_fact_to_jobber: 'Hecho externo al jobber',
          },
          reason: {
            label: 'Motivo para desasociar *',
            required: 'El motivo de desasociación es requerido',
          },
        },
      },
      cancel: {
        title: 'Cancelar pedido',
        description:
          'Al cancelar el pedido <b>{{description}}</b>, este será cerrado y no podrá ser tomado por ningún Jobber.',
        cancel: 'Cancelar',
        submit: 'Cancelar pedido',
        success: 'Pedido {{description}} cancelado exitosamente.',
        fail: 'No se pudo cancelar el pedido.',
        form: {
          options: {
            no_stock: 'Falta stock',
            client_doesnt_receive: 'Cliente no quiere recibir el pedido',
          },
          reason: {
            label: 'Motivo de la cancelación *',
            required: 'El motivo de la cancelación es requerido.',
          },
          jobber: {
            label: 'Jobber asignado (opcional)',
          },
        },
        collapse: {
          title: 'Pedido cancelado',
          subtitle: 'Staff {{staff}}, {{hour}} hrs.',
          reason: 'Motivo de la cancelación',
          jobber: 'Jobber asignado',
        },
      },
      payCompleteBase: {
        title: 'Pagar base completa',
        orderCode: 'Pedido {{code}}',
        description:
          'El pedido cambiará a <b>pago de base completa (1)</b> y se recalculará el monto de la orden de pago, en caso de que exista y esté en estado Por Pagar o Bloqueada.',
        cancel: 'Cancelar',
        okButton: 'Pagar base completa',
      },
      payOriginalBase: {
        title: 'Pagar base original',
        description:
          'El pedido cambiará a <b>pago de base original (0,5)</b> y se recalculará el monto de la orden de pago, en caso de que exista y esté en estado Por Pagar o Bloqueada.',
        okButton: 'Pagar base original',
      },
      changeBaseNotAllowed: {
        title: 'No es posible editar el pago base',
        description:
          'El clon por sobrepeso tiene una <b>orden de pago en estado Liquidada o Pagada,</b> por lo que no es posible realizar este cambio',
        okButton: 'Entendido',
      },
      alerts: {
        originalBase: 'Se ha cambiado el pago a base original exitosamente.',
        completeBase: 'Se ha cambiado el pago a base completa exitosamente.',
      },
      restore: {
        title: 'Cambiar estado del pedido a Lista',
        subtitle: 'Al cambiar el pedido quedará disponible y podrá ser tomado por un Shopper.',
        submit: 'Cambiar a Lista',
        cancel: 'Cancelar',
        alerts: {
          success: 'El pedido ha cambiado su estado a "Lista" exitosamente.',
          error: 'No se logró cambiar el estado del pedido.',
        },
      },
      changeStore: {
        title: 'Cambiar sede del pedido',
        subtitle:
          'Selecciona la sede desde donde será enviado el pedido a la entrega en [{{address}}].',
        label: 'Sede',
        alerts: {
          info: 'Procura seleccionar la opción más cercana a la entrega.',
          success: 'Sede cambiada con éxito.',
          error: 'No se logró cambiar la sede.',
          warning: 'NO HAY INFORMACIÓN DEL CLIENTE y/o DESTINO',
          select: 'No se encontró sedes cercanas...',
        },
        cancel: 'Cancelar',
        submit: 'Cambiar sede',
      },
      assignPicker: {
        title: 'Asignar Picker',
        close_title: 'Cerrar pedido',
        helper: 'Pedido {{code}}',
        subtitle: 'Selecciona el Jobber que solo realizó el picking de los productos.',
        select: {
          label: 'Jobber *',
          placeholder: 'Buscar por RUT o email del Jobber',
          helper: 'Si buscas por RUT, ingrésalo sin puntos y con guión.',
        },
        schemas: {
          title: 'Esquema de pago y cobro de la tarea',
          subtitle: 'Ingresa el código de la tarea de la cual se heredarán los esquemas.',
          label: 'Código de la tarea *',
          placeholder: 'Ingresa el código y presiona enter para buscar',
        },
        alerts: {
          info: 'Al confirmar, se creará un clon del pedido en estado <b>"Finalizada"</b> y subestado <b>"Entregada"</b>. Jobber identificará con la etiqueta <b>"Picker"</b>.',
          success: 'El Jobber <b>{{fullname}}</b> ha sido asignado como Picker exitosamente.',
          error: 'No se logró realizar la asignación',
          close_info: 'Al confirmar, el pedido quedará en estado <b>“Finalizada”</b>.',
          close_success: 'Pedido cerrado exitosamente.',
        },
        submit: 'Asignar Picker',
        close_submit: 'Cerrar pedido',
        close: 'Salir',
        cancel: 'Cancelar',
      },
      overweight: {
        title: 'Crear clon por sobrepeso',
        subtitle: 'Pedido {{code}}',
        description:
          'Para crear el clon por motivo de sobrepeso, debes seleccionar el estado en que se creará.',
        buttons: {
          title: 'Estado de creación del pedido',
          finished: 'Finalizado',
          ready: 'Lista',
        },
        message: {
          finished:
            'El pedido quedará en estado <strong>“Finalizado”</strong> con el mismo Jobber asociado.',
          ready:
            'El pedido quedará en estado <strong>“Lista”</strong> para que un Jobber pueda tomarlo.',
        },
        infoAlert:
          'El equipo de facturación realizará la validación de que este pedido sea efectivamente por sobrepeso.',
        submit: 'Crear clon por sobrepeso',
        cancel: 'Cancelar',
        alerts: {
          success: {
            finished:
              'El pedido <b>{{code}}</b> se ha creado en estado <b>Finalizado</b> exitosamente.',
            ready: 'El pedido <b>{{code}}</b> se ha creado en estado <b>Lista</b> exitosamente.',
          },
          error: 'No se logró clonar el pedido.',
        },
        tagLabel: 'Clon x sobrepeso',
        filter: {
          title: 'Tipo de clon',
          options: {
            normal: 'Clon normal',
            overweight: 'Clon por sobrepeso',
          },
        },
      },
    },
    menu: ['Cerrar pedido', 'Desasociar pedido', 'Cancelar pedido', 'Ver'],
    manualOrder: {
      buttonCreate: 'Agregar pedido manual',
      drawer: {
        title: 'Agregar pedido manual',
        subtitle: 'Ingresa los datos obligatorios (*) para crear el pedido.',
        place: {
          title: 'Información del pedido',
          origin: {
            'walmart/beetrack': 'Pedido walmart',
            smu: 'Pedido SMU',
          },
          local_origen: 'Código del local de origen *',
        },
        dispatch: {
          title: 'Información del despacho',
          date: {
            label: 'Fecha de despacho * ',
            popover: 'Fecha comprometida en la que se debe realizar la entrega del pedido.',
          },
          store: 'Local de origen *',
          carrierId: 'CarrierId del pedido SMU *',
          windowStart: 'Inicio ventana *',
          windowEnd: 'Término ventana *',
          commune: 'Comuna *',
          streetName: 'Dirección *',
          streetNumber: 'Número de calle *',
          depto: 'Departamento (opcional)',
          mapHelper: 'Desplazate en el mapa y marca el punto donde se encuentra la sede.',
          observations: 'Observación (opcional)',
          placeholder: 'Ingresa detalles que ayuden al Jobber a encontrar la dirección.',
          maxLength: '{{count}}/300 caracteres',
          beetrack: {
            sg: 'SG del pedido *',
            validate: 'Validar SG',
            manual: 'Crear pedido manualmente',
            alerts: {
              success: 'Pedido Walmart agregado con éxito.',
              warning:
                'El pedido ingresado ya se encuentra activo en delivery. Intenta con otro SG o revisa que este esté bien escrito.',
              manual:
                'El pedido no ha sido encontrado. Puedes crearlo manualmente pero este pedido no estará integrado con Beetrack.',
              store:
                'El pedido ingresado, es parte del local <b>“{{name}}”</b>. Presiona “Agregar pedido” para crearlo en el local seleccionado.',
              info: 'Recuerda que al crear el pedido manualmente, este no estará integrado con Beetrack.',
            },
            origin: 'Local de origen *',
            subtitle: 'Información del despacho',
            store: 'Sede',
            client: 'Empresa',
            address: 'Dirección',
            dispatch: 'Despacho',
            window: 'Ventana',
            destiny: 'Destino',
            note: 'Observaciones',
            distance: 'Distancia Km',
            close: 'Salir',
            submit: 'Agregar pedido',
          },
        },
        order: {
          title: 'Información del pedido',
          number: {
            label: 'N° del pedido *',
            popover: 'Número con el que podrás identificar el pedido.',
          },
          items: {
            label: 'Cantidad de items *',
            popover: 'Cantidad de paquetes que llevará el Shopper en su entrega.',
          },
        },
        errors: {
          local_origen: 'El código de local de origen es requerido',
          date: 'La fecha de espacho es requerida',
          windowStart: 'El inicio de venta es requerido',
          windowEnd: 'El término de venta es requerido',
          commune: 'La comuna es requerida',
          street: 'La dirección es requerida',
          number: 'El número de la calle es requerida',
          code: 'El código es requerido',
          qtyItems: 'La cantidad de items es requerida',
        },
        cancel: 'Salir',
        create: 'Crear pedido',
      },
      msgSuccess: 'Pedido SMU creado con éxito',
      msgError: 'No se logró crear el pedido.',
    },
  },
  orderIncidents: {
    filterSearch: { title: 'Buscar', placeholder: 'N° Pedido o Jobber' },
    filterClear: { title: 'Limpiar filtros' },
    enterprise: {
      label: 'Cliente',
      placeholder: 'Selecciona',
    },
    store: {
      label: 'Sede',
      placeholder: 'Selecciona',
    },
    dates: {
      label: 'Fecha compromiso',
      placeholder: 'Selecciona',
    },
    status: {
      label: 'Estados',
      placeholder: 'Todos',
    },
    table: ['N° pedido', 'Jobber', 'Sede/Cliente', 'Fecha compromiso', 'Estado', 'Acciones'],
    tableEmpty: 'No tienes eventos pendientes',
    kilometersError: {
      message: 'Pedido no pudo ser creado porque tiene más de 100 kilómetros. Se calcularon {{km}}',
      modal: {
        title: 'Actualizar dirección del pedido',
        subTitle: 'Ingresa la dirección donde se debe realizar el despacho.',
        number: 'Número de calle *',
        references: 'Referencia (opcional)',
        note: 'Nota (opcional)',
        mapHelper: 'Desplazate en el mapa y marca el punto donde debe ser entregado el pedido.',
        successMsg:
          'Actualización de dirección realizada con éxito. Pedido pasado a la sección de pedidos activos.',
        errorMsg: 'No se logró actualizar la dirección del pedido.',
        onOk: 'Actualizar dirección',
        cancel: '{{value}}',
      },
    },
    differenceKM: {
      title: 'Reporte de diferencia en Km',
      jobberKM: 'Distancia indicada por Jobber',
      orderKM: 'Distancia del pedido',
      evidence: 'Evidencia',
      comment: 'Comentario Jobber',
      cancel: 'Rechazar kilómetros',
      success: 'Confirmar kilómetros',
      confirm: {
        title: 'Confirmar reporte de kilómetros',
        subtitle: 'Kilómetros indicados por el Jobber, serán considerados en el pago del mismo.',
        input: 'Km indicados por el Jobber *',
        helper: 'Distancia del pedido: {{km}} Km',
        cancel: 'Cancelar',
        submit: 'Confirmar kilómetros',
      },
      decline: {
        title: 'Rechazar reporte de kilómetros',
        subtitle:
          'Reporte será rechazado y solo se considerará el pago de kilómetros indicados en el pedido.',
        cancel: 'Cancelar',
        submit: 'Rechazar kilómetros',
      },
      msgs: {
        modified: 'Reporte de Km confirmado con éxito.',
        notModified: 'Reporte de Km rechazado con éxito.',
        errorModified: 'No se logró confirmar los kilómetros.',
        errorNotModified: 'No se logró rechazar la confirmación de kilómetros.',
      },
    },
  },
};
