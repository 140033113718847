import { GetStaffsFiltersInterfaces } from './staffs.interfaces';

export const getStaffsRoute = (filters: GetStaffsFiltersInterfaces): string => {
  let endpoint = '/account/staffs';
  if (Object.keys(filters).some((item) => typeof filters[item] !== 'undefined')) endpoint += '?';
  Object.keys(filters).forEach((item) => {
    if (typeof filters[item] !== 'undefined') endpoint += `${item}=${filters[item]}&`;
  });
  return endpoint.slice(0, -1);
};

export const getSearchRoute = ({
  limit,
  next,
  previous,
  pendingBy,
  wildcard,
  role,
}: GetStaffsFiltersInterfaces): string =>
  getStaffsRoute({
    limit,
    next,
    previous,
    pendingBy,
    role,
    wildcard,
  });

export const createStaffRoute = (): string => 'account/staffs/create';

export const editStaffRoute = (id: string): string => `account/staffs/${id}`;

export const disableRoute = (): string => `account/staffs/block`;
