export default {
  requirements: {
    title: 'Requerimientos clientes',
    subtitle: 'Aprueba o rechaza los requerimientos enviados desde el Portal Empresas.',
    requirementsList: {
      filters: {
        filterClear: {
          title: 'Limpiar filtros',
        },
        filterDatePicker: {
          title: 'Fecha de ejecución',
        },
        enterprise: 'Cliente',
        store: 'Sede',
        jobType: 'Tipo tarea',
        status: {
          title: 'Estado requerimiento',
          options: {
            scheduled: 'Programado',
            rejected: 'Rechazado',
            in_assignment: 'En asignación',
            ongoing: 'En curso',
            finished: 'Finalizado',
            cancelled: 'Cancelado',
          },
        },
        factibility: {
          title: 'Factibilidad',
          options: {
            feasibility: 'Factibles',
            notFeasible: 'No factibles',
          },
        },
        typeTurn: {
          title: 'Tipo de turnos',
          options: {
            regular: 'Regular',
            lastMinute: 'Último minuto',
            diurnal: 'Diurno',
            nocturnal: 'Nocturno',
            dawn: 'Madrugada',
            sporadic: 'Esporádico',
            recurring: 'Recurrente',
          },
        },
        placeholder: {
          select: 'Selecciona',
          dateFrom: 'Fecha inicial',
          dateTo: 'Fecha final',
        },
      },
      columns: {
        code: {
          title: 'ID Requerimiento',
        },
        jobCode: {
          title: 'ID Tarea',
        },
        enterprise: {
          title: 'Cliente/Sede',
        },
        jobType: {
          title: 'Tipo tarea',
          requiredQuotas: '{{quota}} cupos',
        },
        date: {
          title: 'Fecha de ejecución',
        },
        type: {
          title: 'Tipo de turno',
          options: {
            regular: 'Regular',
            lastMinute: 'Último minuto',
            diurnal: 'Diurno',
            nocturnal: 'Nocturno',
            dawn: 'Madrugada',
            sporadic: 'Esporádico',
            recurring: 'Recurrente',
          },
        },
        status: {
          title: 'Estado',
          options: {
            scheduled: 'Programado',
            rejected: 'Rechazado',
            in_assignment: 'En asignación',
            ongoing: 'En curso',
            finished: 'Finalizado',
            cancelled: 'Cancelado',
          },
          gap: {
            feasibility: 'Factible',
            notFeasible: 'No factible',
          },
        },
        actions: {
          title: 'Acciones',
          options: {
            accept: 'Aceptar requerimiento',
            reject: 'Rechazar requerimiento',
          },
        },
      },
    },
    rejected: {
      title: 'Rechazar requerimiento',
      subtitle:
        'Al rechazar, el requerimiento pasará a estado "Rechazado" y el cliente recibirá un correo informando el motivo.',
      form: {
        textArea: {
          title: 'Motivo',
          placeholder: 'El cliente podrá ver este comentario.',
        },
      },
      alert: {
        message:
          'Te enviaremos un correo con el detalle del rechazo. El cliente también recibirá un correo informando el rechazo.',
      },
      error: {
        message: 'Este campo es obligatorio.',
      },
      actions: {
        reject: 'Rechazar requerimiento',
        cancel: 'Cancelar',
      },
    },
    approved: {
      title: 'Aprobar requerimiento',
      subtitle:
        'Una vez aprobado, el requerimiento pasará a estado <strong>“En asignación”</strong> y se creará una tarea en la sección <strong>“Tareas”.</strong> A continuación puedes revisar el listado de jobbers que se consideraron disponibles a la hora de crear el requerimiento.',
      jobbers: 'Jobbers disponibles ({{jobbers}})',
      alert: {
        message:
          'Te enviaremos un correo con el detalle de la aprobación. El cliente también recibirá un correo informando la aprobación.',
      },
    },
    alerts: {
      rejected: {
        success:
          'Requerimiento rechazado con éxito. Hemos enviado un correo al cliente para informar el rechazo',
      },
      approved: {
        success:
          'Requerimiento aprobado con éxito. Puedes revisar la <strong>tarea {{code}},</strong> desde la tabla o el detalle del requerimiento.',
      },
    },
    requirementDetails: {
      title: 'Requerimiento',
      requirementId: 'ID Requerimiento',
      taskId: 'ID Tarea',
      type: {
        title: 'Turno',
        options: {
          regular: 'Regular',
          lastMinute: 'Último minuto',
          diurnal: 'Diurno',
          nocturnal: 'Nocturno',
          dawn: 'Madrugada',
          sporadic: 'Esporádico',
          recurring: 'Recurrente',
        },
      },
      status: {
        title: 'Estado',
        options: {
          scheduled: 'Programado',
          rejected: 'Rechazado',
          in_assignment: 'En asignación',
          ongoing: 'En curso',
          finished: 'Finalizado',
          cancelled: 'Cancelado',
        },
        alertMessage: {
          rejected: {
            title: 'Requerimiento rechazado',
            reason: 'Motivo del rechazo',
          },
          approved: {
            title: 'Requerimiento aprobado',
            taskPublication: 'Publicación de la tarea:',
          },
        },
        extraLabels: {
          staffName: 'Staff {{fullName}}, {{date}}',
        },
      },
      gap: {
        feasibility: 'Factible',
        notFeasible: 'No factible',
        alertMessage: {
          notFeasible: {
            title: 'Requerimiento sin factibilidad',
            description:
              'La cantidad de <strong>Jobbers solicitados ({{quantity}})</strong>, supera la disponibilidad de la fecha seleccionada.',
            detailsSubTitle: 'Se debe aplicar uno de estos puntos para dar factibilidad:',
            jobberQuantity:
              'La cantidad de <strong>Jobbers debe ser igual o menor a {{quantity}}</strong>, para mantener la fecha indicada por el cliente.',
            jobSchedule:
              'La fecha de la tarea <strong>debe ser igual o posterior al <span>{{date}}</span></strong>, para mantener la cantidad de Jobbers solicitados por cliente.',
          },
          feasibility: {
            gap: {
              title: 'Requerimiento factible por GAP',
              description:
                'El requerimiento cuenta con los Jobbers disponibles para su realización.',
            },
            no_recruitment: {
              title: 'Requerimiento factible por anticipación',
              description:
                'El requerimiento no necesita reclutamiento adicional de Jobbers, ya que cuenta con una anticipación de días superior a los necesarios.',
            },
            with_recruitment: {
              title: 'Requerimiento factible por anticipación con reclutamiento',
              description:
                'El requerimiento necesita reclutamiento adicional de Jobbers, pero cuenta con la anticipación de días necesarios para que esto se lleve a cabo.',
            },
          },
        },
      },
      actions: {
        title: 'Acciones',
        options: {
          accept: 'Aceptar requerimiento',
          reject: 'Rechazar requerimiento',
        },
      },
      displayJobbers: {
        true: 'Ver Jobbers disponibles',
        false: 'Ocultar Jobbers disponibles',
      },
      sections: {
        job: {
          title: 'Tarea',
          dateInfo: 'Ejecución tareas',
          fields: {
            businessClient: 'Cliente comercial',
            stores: 'Sede',
            jobType: 'Tipo de tarea',
            responsibleStaff: 'Staff responsable',
            responsibleKAM: 'KAM responsable',
          },
          subSections: {
            jobOrderDetail: {
              title: 'Detalles orden de trabajo',
              fields: {
                description: 'Descripción:',
              },
            },
            jobOrderLocation: {
              title: 'Ubicación orden de trabajo',
              fields: {
                assistanceRadius: 'Radio de asistencia',
                locationPicture: {
                  label: 'Fotografía lugar de ingreso',
                  action: {
                    reference: 'Ver referencia',
                  },
                },
                locationReference: 'Referencias de lugar de ingreso',
              },
            },
          },
        },
        jobberDetail: {
          title: 'Jobber',
          fields: {
            inductionUsed: {
              label: 'Inducción utilizada',
              isGeneral: '(Skill general)',
            },
            onSiteCourse: 'Curso en terreno',
            inductionVisualization: {
              label: 'Visualización inducción',
              options: {
                true: 'Visualizar tarea con inducción pendiente.',
                false: 'Visualizar tarea con inducción completada.',
              },
            },
            device: {
              label: 'Dispositivo',
              options: {
                android: 'Android',
                ios: 'iOS',
                both: 'Cualquiera',
              },
            },
            jobberGender: {
              label: 'Sexo Jobber',
              options: {
                male: 'Hombre',
                female: 'Mujer',
                both: 'Mixto',
              },
            },
            ppes: 'Elementos de Protección Personal (EPP)',
            language: {
              label: 'Idioma',
              options: {
                true: 'Español',
                false: 'Cualquiera',
              },
            },
          },
        },
        jobAndPayment: {
          title: 'Tarea y pago',
          fields: {
            jobCoordinator: 'Coordinador tarea',
            quotaRequested: 'Cupos solicitados',
            overQuota: 'Sobrecupos',
            taskSchedule: 'Horario tarea',
            lunchSchedule: 'Horario almuerzo',
          },
          subSections: {
            customerInvoice: {
              title: 'Cobro cliente',
              fields: {
                invoiceSchema: 'Esquema de cobro',
                currency: 'Moneda',
                variables: 'Variable(s)',
              },
            },
            jobberPayment: {
              title: 'Pago Jobber',
              fields: {
                paymentSchema: 'Esquema de pago',
                variables: {
                  hour: 'Pago por hora',
                  sku: 'Pago por SKU',
                },
                lunchPayable: {
                  title: 'Pago horario de almuerzo',
                  options: {
                    true: 'Hora de almuerzo será pagada',
                    false: 'Hora de almuerzo no será pagada',
                  },
                },
              },
            },
          },
        },
        aditionalInfo: {
          title: 'Información adicional',
          fields: {
            links: 'Links',
            files: 'Archivos',
          },
        },
      },
      cancelledAlert: {
        cancelledBy: 'Requerimiento cancelado por <strong>{{fullName}} el {{date}}.</strong>',
        penalty: {
          title: 'Cancelación con cobro asociado',
          full: 'Se cobrará el 100% del total de cupos solicitados de acuerdo a las horas requeridas.',
          half: 'Se cobrará el 50% del total de cupos solicitados de acuerdo a las horas requeridas.',
        },
      },
    },
  },
};
