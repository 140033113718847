export enum JobStatusEnum {
  PUBLISHED = 'published',
  SCHEDULED = 'scheduled',
  ASSIGNED = 'assigned',
  ONGOING = 'ongoing',
  FINISHED = 'finished',
  CANCELLED = 'cancelled',
}

export enum JobApplicationStatusEnum {
  APPLIED = 'applied',
  ASSIGNED = 'assigned',
  PRESENT = 'present',
  CANCELLED = 'cancelled',
  DEALLOCATED = 'deallocated',
  FINISHED = 'finished',
  ABSENT = 'absent',
  ABSENT_TO_FINISHED = 'absentToFinished',
}

export enum DelayReasonEnum {
  EXTRA_HOURS = 'extra_hours',
  FORGOT_TO_MARK = 'forgot_to_mark',
  NO_BATTERY = 'no_battery',
  OTHER = 'other',
  NONE = 'none',
}

export enum JobApplicationStatusListsEnum {
  APPLIED = 'applied',
  ASSIGNED = 'assigned',
  PRESENT = 'presents',
  DEALLOCATED = 'deallocated',
  FINISHED = 'finalized',
  ABSENT = 'absents',
  CANCELLED_PRE = 'preAssignmentCancellations',
  CANCELLED_POST = 'postAssignmentCancellations',
}

export enum JobAttendanceStatusEnum {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  LATE = 'late',
}

export enum RelevanceTaskEnum {
  FEATURED = 'featured',
  NOTFEATURED = 'notFeatured',
}

export enum AssignationTypeEnum {
  MASSIVE = 'massive',
  NO_APPLICATION = 'no_application',
  APPLICATION = 'application',
  AUTOMATIC = 'automatic',
}

export enum InsuredStatusEnum {
  ASSIGN_INSURED = 'assign_insured',
  DENIED = 'denied',
}