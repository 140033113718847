import auth from './modules/auth';
import applicants from './modules/applicants';
import tasks from './modules/tasks';
import businessClient from './modules/businessClient';

export default {
  ...auth,
  ...applicants,
  ...tasks,
  ...businessClient,
  global: {
    region: 'Región',
    region_plural: 'Regiones',
    regionWArticle: 'La región',
    regionWArticle_plural: 'Las regiones',
    commune: 'Comuna',
    communeWArticle: 'La comuna',
    commune_plural: 'Comunas',
    communeWArticle_plural: 'Las comunas',
    drivingLicence: 'Licencia de conducir',
    inputs: {
      documentNumber: {
        title: 'RUT',
        lower: 'rut',
        pattern: /^([1-9][0-9]?)?.?([0-9]{1,3})?.?([0-9]{1,3})-([0-9|k|K]{1})$/,
        message: 'Ingresa un rut válido',
        maximumDocumentLength: { rut: 12 },
        alreadyTaken:
          'Este RUT ya se encuentra registrado, para más información comunícate con $t(global.supportEmail)',
      },
      phoneNumber: {
        pattern: /^(\d){9}$/,
        message: 'Ingresa un teléfono de 9 dígitos',
      },
    },
    settings: {
      country: {
        phonePrefix: '+56',
        name: 'Chile',
        accountType: ['cc', 'cv', 'ca'],
        phoneLengthWithoutPrefix: 9,
        vehicles: ['auto'],
      },
    },
  },
};
