import React from 'react';
import axios, { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import server from '@services/api';
import store from '@app/store';
import i18n from 'i18next';
import { camelCase, cloneDeep, isEqual, snakeCase } from 'lodash';

import { message, Upload } from 'antd';

import { getSignedUrlS3Route } from '@features/files/ts/routes';
import { SortOrder } from 'antd/lib/table/interface';
import {
  CountryEnum,
  DocumentSubstatusEnum,
  LoadingSelectEnum,
  MoneyOrderCreationType,
  MoneyOrderTypes,
  RequestStatusEnum,
  VariableKindEnum,
  VehicleTypes,
} from './enum';
import { Vehicle } from '@features/applicants/ts/applicants.interfaces';
import {
  fileTypesExtensions,
  links,
  alertExpiredDocuments,
  enterpriseCodeNumber,
  DOCUMENT_CODES,
  SPECIAL_CODE_MAP,
} from './consts';
import { DetailBlockInfo, getBlocklistPayload } from '@features/blocklist/ts/blocklist.interface';
import { RolesEnum } from '../features/auth/ts/enums';
import { CoursesTypes } from '../features/courses/ts/enums';
import { BlockKindEnum } from '@features/blocklist/ts/enums';
// TODO: comentar estas funciones para saber su uso

export const buildUrl = (key: string): string => {
  if (!key) return '';
  const accessToken = localStorage.getItem('access_token');
  return `${process.env.NEXT_PUBLIC_BASE_URL_API}/${getSignedUrlS3Route(key, accessToken)}`;
};

export const getExtension = (name: string): string => {
  if (name) {
    const str = name.split('.');
    return str.slice(-1)[0];
  }
  return '';
};

export const uploadFiles = async (params: {
  file: File;
  fileType: string;
  urlToSave?: string;
  saveKey?: boolean;
  isPublic?: boolean;
}): Promise<AxiosResponse<any>> => {
  const { file, fileType, urlToSave, saveKey = true, isPublic = false } = params;

  const signedUrlResponse = await server.post(
    getSignedUrlS3Route(),
    cleanObjects({ ext: fileType, isPublic })
  );
  const { url, key } = signedUrlResponse.data.data;
  let response = await axios.put(url, file, {
    headers: { 'Content-Type': file.type },
  });
  response.data = key;
  if (saveKey) {
    response = await server.put(urlToSave, { key });
  }
  return response;
};

export const getFiles = async (urlToResource: string): Promise<AxiosResponse<any>> => {
  const getFileStatus = await server.get(urlToResource);
  const { key } = getFileStatus.data.data;
  if (!key) {
    Object.keys(getFileStatus.data.data).forEach((index) => {
      if (getFileStatus.data.data[index].key) {
        getFileStatus.data.data[index].src = buildUrl(key);
        getFileStatus.data.data[index].ext = getExtension(getFileStatus.data.data[index].key);
      }
    });
    return getFileStatus;
  }
  getFileStatus.data.data.src = buildUrl(key);
  getFileStatus.data.data.ext = getExtension(key);
  return getFileStatus;
};

export const chooseRandom = (array): any => array[Math.floor(Math.random() * array.length)];

export const getNested = (obj: Record<string, unknown>, path: string, separator = '.'): unknown => {
  try {
    return path
      .replace('[', separator)
      .replace(']', '')
      .split(separator)
      .reduce(function (obj, property) {
        return obj[property];
      }, obj);
  } catch (err) {
    return undefined;
  }
};

export const defaultApplicantTablePayload = (
  isJobber: boolean,
  pendingBy: string,
  limit = 10
): Record<string, any> => ({
  pendingBy: pendingBy,
  limit: limit,
  role: isJobber ? 'jobber' : 'jobber_applicant',
  isMigrated: !isJobber && pendingBy === 'jobber' ? 'false' : undefined,
  isRejected: 'false',
  sortAscending: false,
  sortField: isJobber ? 'jobberAt' : '',
  skillIds: [],
  isBlocked: 'false',
  ppes: [],
});

export const defaultBlocklistTablePayload = (limit = 10): Record<string, any> => ({
  limit: limit,
  sortField: 'updatedAt',
  sortAscending: false,
});

export const defaultDeletelistTablePayload = (limit = 10): Record<string, any> => ({
  limit: limit,
  sortField: 'updatedAt',
  sortAscending: false,
  isDeleted: true,
});

export const defaultCoursesTablePayload = (limit = 10): Record<string, any> => ({
  limit: limit,
  sortField: 'updatedAt',
  sortAscending: true,
  skipTypes: [CoursesTypes.ON_SITE],
});

export const defaultSkillsTablePayload = (limit = 10): Record<string, any> => ({
  limit: limit,
  sortField: 'updatedAt',
  sortAscending: false,
});

export const defaultJobAssignedTablePayload = (job, limit = 10): Record<string, any> => ({
  job,
  limit,
});

export const defaultNameOrderedPayload = (extraParams): Record<string, any> => ({
  ...extraParams,
  sortField: 'name',
  sortAscending: true,
});

export const storeStateHandler = (state, action, callback = () => null): void => {
  const { requestId } = action.meta;
  if (state.loading === 'pending' && state.currentRequestId === requestId) {
    state.loading = 'idle';
    state.currentRequestId = undefined;
  }
  callback();
};

export const pendingStateHandler = (state, action, callback = () => null): void => {
  if (state.loading === RequestStatusEnum.IDLE) {
    state.loading = RequestStatusEnum.PENDING;
    state.currentRequestId = action.meta.requestId;
  }
  callback();
};

export const validateAccess = (userRoles: Array<string>, accessRoles: Array<string>): boolean => {
  if (!accessRoles?.length) return true;
  return userRoles?.some((role) => accessRoles?.includes(role));
};

export const linksByRoles = (userRoles: Array<string>, countryIso: string): any => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') delete links.requirements;

  const { payments, paymentsLaboursShopper, ...restOfLinks } = links;
  const response = Object.keys(restOfLinks).map((link) => restOfLinks[link]);

  if (userRoles?.includes(RolesEnum.STAFF_FINANCE)) {
    if (countryIso !== CountryEnum.CL) {
      response.splice(4, 0, payments);
    } else {
      response.splice(4, 0, paymentsLaboursShopper);
    }
  }

  return response;
};

export const tablePagination = (
  pagination,
  paginationAux,
  setPagination,
  previous,
  next,
  sorterAux,
  sorter: any,
  setSorter: React.Dispatch<any>,
  payload: any,
  cleanStates: () => null,
  pageSize: number,
  setPageSize: React.Dispatch<React.SetStateAction<number>>,
  setPayload,
  setCurrentPayload,
  dispatchFunction
): void => {
  delete pagination.total;
  const aux = { ...payload };
  if (cleanStates) cleanStates();
  if (!isEqual(pagination, paginationAux)) {
    if (pagination.pageSize !== pageSize) {
      setPageSize(pagination.pageSize);
      setPayload({ ...payload, limit: pagination.pageSize });
      setCurrentPayload({ ...payload, limit: pagination.pageSize });
      setPagination({ ...pagination, current: 1 });
    } else {
      if (pagination.current < paginationAux.current) {
        if (pagination.current !== 1) aux.previous = previous;
        store.dispatch(dispatchFunction(aux));
        setPagination({
          ...pagination,
          current: pagination.current !== 1 ? paginationAux.current - 1 : 1,
        });
      }
      if (pagination.current > paginationAux.current) {
        aux.next = next;
        store.dispatch(dispatchFunction(aux));
        setPagination({ ...pagination, current: paginationAux.current + 1 });
      }
      setCurrentPayload(aux);
    }
  } else if (!isEqual(sorter, sorterAux)) {
    setSorter(sorter);
    setPagination({ ...paginationAux, current: 1 });
    const { field, order } = sorter;
    let orderAux;
    if (typeof order !== 'undefined') orderAux = order === 'ascend';
    const fieldAux = typeof field === 'object' ? Object.values(field).join('.') : field;
    if (payload.sortField !== fieldAux || payload.sortAscending !== orderAux) {
      const aux = { ...payload };
      if (typeof orderAux === 'undefined') {
        delete aux.sortAscending;
        delete aux.sortField;
        if (!isEqual(aux, payload)) setPayload(aux);
      } else {
        setPayload({ ...payload, sortAscending: orderAux, sortField: fieldAux });
        setCurrentPayload({ ...payload, sortAscending: orderAux, sortField: fieldAux });
      }
    }
  }
};

export const validateRut = (rut: string): boolean => {
  const aux = rut.replace(/\./g, '');
  const rutFormat = /^([1-9][0-9]?)?([0-9]{3})?([0-9]{3})?-([0-9|k|K]{1})$/;
  if (!aux.match(rutFormat)) return true;

  const reversedRut = aux.split('').reverse();
  const validDigit =
    11 -
    (reversedRut
      .slice(2)
      .reduce((acc, item, index) => acc + parseInt(item, 10) * ((index % 6) + 2), 0) %
      11);

  if (validDigit === 11) return reversedRut[0] === '0';
  if (validDigit === 10) return reversedRut[0].toLowerCase() === 'k';

  return String(validDigit) === reversedRut[0];
};

export const validateRutSpecific = (value: string): boolean => {
  if (!/^[0-9]+-[0-9kK]{1}$/.test(value)) return false;
  const tmp = value.split('-');
  let digv = tmp[1];
  const rut = tmp[0];
  if (digv == 'K') digv = 'k';

  // VERIFIER DIGIT
  let sum = 0;
  let initial = 2;
  const rutReverse = rut.toString().split('').reverse().join('');
  for (const item of rutReverse as any) {
    sum += item * initial;
    initial++;
    if (initial > 7) initial = 2;
  }
  let response: string | number = 11 - (sum % 11);
  if (response === 10) response = 'k';
  if (response === 11) response = 0;
  return response == digv;
};

export const onlyLetters = (word: string): boolean => {
  const wordFormat = /^[ÑñA-Za-záéíóúÁÉÍÓÚÜü]+[ ÑñA-Za-záéíóúÁÉÍÓÚÜü´`'&-]*$/;
  if (!word.match(wordFormat)) return true;

  return false;
};

export const onlyNumbers = (number: string): boolean => {
  const numberFormat = /^\d+$/;
  if (!number.match(numberFormat)) return true;

  return false;
};

export const firstLetterUppercase = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const placeMaskRut = (val: string): string => {
  if (!val) return '';
  const value = val.replace(/\.|-|(?![k|K])\D/g, '');
  if (value.length > 7) {
    return value.replace(/^([1-9][0-9]?)([0-9]{3})([0-9]{3})([0-9|k|K]{1})$/g, '$1.$2.$3-$4');
  }
  if (value.length > 4) {
    return value.replace(/^([0-9]{1,3})([0-9]{3})([0-9|k|K]{1})$/g, '$1.$2-$3');
  }
  if (value.length > 1) {
    return value.replace(/([0-9]{1,3})([0-9|k|K]{1})$/g, '$1-$2');
  }
  return value.replace(/^(\d)/, '$1');
};

export const placeMaskDate = (val: string): string => {
  if (!val) return '';
  const value = val.replace(/\/|\D/g, '');
  if (value.length > 4) {
    return value.replace(/^([0-9]{2})([0-9]{2})([0-9]{1,4})$/g, '$1/$2/$3');
  }
  if (value.length > 2) {
    return value.replace(/([0-9]{2})([0-9]+)$/g, '$1/$2');
  }
  return value.replace(/^(\d)/, '$1');
};

export const validateEmail = (val) =>
  typeof val === 'string' &&
  !!val.match(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const cleanObjects = (params, onlyNull = false) => {
  Object.keys(params).map((key) => {
    if (onlyNull) {
      if (params[key] === null) {
        delete params[key];
      }
    } else {
      if (!params[key]) {
        delete params[key];
      }
    }
  });
  return params;
};

export const transformShiftsToSend = (shiftsOrig) => {
  const shifts = cloneDeep(shiftsOrig);

  for (let i = 0; i < shifts?.length; i++) {
    if (shifts[i]) {
      const year = shifts[i]?.publishAt?.year();
      const month = shifts[i]?.publishAt?.month();
      const date = shifts[i]?.publishAt?.date();
      Object.assign(shifts[i], {
        startAt: shifts[i]?.publishAt
          ? shifts[i]?.startAt?.date(date)?.month(month).year(year).toDate().toISOString()
          : shifts[i]?.startAt,
        endAt: shifts[i]?.publishAt
          ? shifts[i]?.endAt?.date(date)?.month(month).year(year).toDate().toISOString()
          : shifts[i]?.endAt,
        publishAt: shifts[i]?.publishAt ? shifts[i]?.publishAt?.toDate().toISOString() : undefined,
        quotas: parseInt(shifts[i]?.quotas, 10),
      });
    }
  }
  return shifts;
};

export const disabledFutureDates: any = (current) => current && current > moment().endOf('day');

export const disableOldDates: any = (current, countryIso) => {
  if (countryIso === CountryEnum.CO)
    return current && current < moment().subtract(3, 'months').startOf('day');

  return current && current < moment().subtract(7, 'days').startOf('day');
};

export const calculateHours: any = (startDate: Moment, endDate: Moment) =>
  endDate.diff(startDate, 'hours');

export const requiredField = (message: string): { required: boolean; message: string } => ({
  required: true,
  message,
});

export const isAValidUrl: any = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!pattern.test(str);
};

export const scrollTo = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
export const getSortOrder = (dataIndex, payload): SortOrder => {
  if (dataIndex === payload.sortField) return payload.sortAscending ? 'ascend' : 'descend';
  return undefined;
};

export const buildRegionsAndCommunes = (localities, regionOrCity) =>
  localities.reduce((acc, val) => {
    const index = acc.findIndex((elem) => elem?.region === val?.[regionOrCity]);
    if (index !== -1) {
      acc[index].communes = [
        ...acc[index].communes,
        {
          _id: val?.locality,
          name: val?.localityName,
          region: { _id: val?.region, name: val?.regionName },
          city: { _id: val?.city, name: val?.cityName },
        },
      ];
      return acc;
    }
    return [
      ...acc,
      {
        region: val?.[regionOrCity],
        communes: [
          {
            _id: val?.locality,
            name: val?.localityName,
            region: { _id: val?.region, name: val?.regionName },
            city: { _id: val?.city, name: val?.cityName },
          },
        ],
      },
    ];
  }, []);

export const validateFileType = (file, validTypes) => {
  if (
    ![
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/vnd.ms-excel',
      'text/csv',
    ].includes(file.type) ||
    (validTypes && !validTypes.some((value) => fileTypesExtensions[value]?.includes(file.type)))
  ) {
    message.error(
      i18n.t(`applicants.review.upload.formatError`, {
        formats: validTypes ? validTypes.join(', ') : 'png, jpg o pdf',
      })
    );
    return Upload.LIST_IGNORE;
  }
  if (file.size / 2 ** 20 > 10) {
    message.error(i18n.t(`applicants.review.upload.weightError`));
    return Upload.LIST_IGNORE;
  }
  return true;
};

export const compareMinutes = (date1, date2) => {
  if (!date1 || !date2) return null;
  const today = moment();
  const date = today.date();
  const month = today.month();
  const year = today.year();
  return moment(date1)
    .clone()
    .date(date)
    .month(month)
    .year(year)
    .seconds(0)
    .milliseconds(0)
    .diff(
      moment(date2).clone().date(date).month(month).year(year).seconds(0).milliseconds(0),
      'minutes'
    );
};

export const mergeDateTime = (date, dateTime) => {
  if (!dateTime) return null;
  if (!date) return dateTime;
  return date.clone().set('hours', dateTime.get('hours')).set('minutes', dateTime.get('minutes'));
};

export const clearState = (
  state,
  b: { payload: { key: string; subkey?: string; base?: string } }
) => {
  const { key, subkey, base } = b.payload ?? {};
  const originalState = base ?? RequestStatusEnum.IDLE;
  if (subkey && state[key]?.[subkey]) state[key][subkey] = originalState;
  else if (key && state[key]) state[key] = originalState;
};

export const formatNumber = (number) =>
  !number && number !== 0 ? '' : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const isElectricBiciMoto = (vehiclesInfo: Vehicle[]): boolean =>
  vehiclesInfo?.find((elemAux) => elemAux.kind === VehicleTypes.BICIMOTO)?.isElectric;

export const deleteDuplicateSkill = (info) => {
  const objAux = {};
  return info?.skills?.filter((item) => (objAux[item._id] ? false : (objAux[item._id] = true)));
};

export const orderSortDate = (objs: DetailBlockInfo[]): DetailBlockInfo[] => {
  const objsOriginal = [...objs];
  return objsOriginal?.sort((a, b) => (b.blockedAt > a.blockedAt ? 1 : -1));
};
export const switchIDOrWildcard = (value: string, payload: any): any => {
  const switchBoolean = /^[a-f\d]{24}$/g.test(value);
  if (payload?.id) delete payload.id;
  else delete payload.wildcard;
  const dataAux = Object.assign(payload, switchBoolean ? { id: value } : { wildcard: value });
  return dataAux;
};

export const capitalLetterUpperCase = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const payloadReducerFunction = (
  mainObject: getBlocklistPayload,
  filterFunction: (mainO: any) => void
): any => {
  return Object.keys(mainObject)
    .filter(function (ObjectKey) {
      return filterFunction(mainObject[ObjectKey]);
    })
    .reduce(function (result, ObjectKey) {
      result[ObjectKey] = mainObject[ObjectKey];
      return result;
    }, {});
};

export const removeAccents = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const filterOptions = (inputValue: string, option: any) => {
  let text = '';
  if (typeof option.children === 'object') {
    text = removeAccents(option?.children?.join()?.toLowerCase());
  } else {
    text = removeAccents(option?.children?.toLowerCase());
  }
  if (text === LoadingSelectEnum.LOADING) return true;
  return text?.includes(inputValue.toLowerCase());
};

export const selectPagination = (
  event: any,
  loading: boolean,
  next?: string,
  paginateAction?: VoidFunction
) => {
  event.persist();
  const target = event.target as HTMLDivElement;
  if (target.scrollTop + target.offsetHeight === target.scrollHeight && !loading && next) {
    target.scrollTo(0, target.scrollHeight);
    paginateAction();
  }
};

export const converMtToKm = (value: number | undefined) => {
  if (!value || !Number(value)) return 0;
  return Number(parseFloat(`${value / 1000}`).toFixed(2));
};

export const converKmToMt = (value: number | undefined) => {
  if (!value || !Number(value)) return 0;
  return Number(parseFloat(`${Number(value) * 1000}`).toFixed(2));
};

export const getNavigator = () => {
  let sBrowser = '',
    sUsrAg = window.navigator.userAgent;
  if (sUsrAg.indexOf('Chrome') > -1) sBrowser = 'Chrome';
  else if (sUsrAg.indexOf('Safari') > -1) sBrowser = 'Safari';
  else if (sUsrAg.indexOf('Opera') > -1) sBrowser = 'Opera';
  else if (sUsrAg.indexOf('Firefox') > -1) sBrowser = 'Mozilla';
  else if (sUsrAg.indexOf('MSIE') > -1) sBrowser = 'Internet Explorer';

  return sBrowser;
};

export const generateLandingTaskURL = (
  countryIso: string,
  vertical: string,
  idTarea: string
): void => {
  const urlBase = process.env.NEXT_PUBLIC_LANDING_BASE_URL;
  const country = countryIso.toLowerCase();
  const link = `${urlBase}/${country}/vacantes/${vertical}?codigo=${idTarea}`;

  navigator.clipboard.writeText(link);
};

export const generateTrackingInfo = (lat: string, lng: string): void => {
  navigator.clipboard.writeText(`${lat},${lng}`);
};

export const validateDate = ({ expirationDate, issueDate }: any): boolean => {
  if (
    expirationDate &&
    moment(expirationDate, 'DD/MM/YYYY').isBefore(moment(new Date(), 'DD/MM/YYYY'))
  ) {
    return true;
  }

  if (
    issueDate &&
    (moment(issueDate, 'DD/MM/YYYY').isAfter(moment(new Date(), 'DD/MM/YYYY')) ||
      moment(issueDate, 'DD/MM/YYYY').isBefore(moment(new Date(), 'DD/MM/YYYY').subtract(3, 'y')))
  ) {
    return true;
  }

  return false;
};

export const convertString = (value: string, reverse?: boolean) => {
  if (reverse) {
    if (value === 'id_card_reverse') value = 'identity_card_reverse ';
    if (value === 'id_card_front') value = 'identity_card_front';
    return camelCase(value);
  }

  if (value === 'identityCardFront') value = 'idCardFront';
  if (value === 'identityCardReverse') value = 'idCardReverse';
  return snakeCase(value);
};

export const calculateExpired = (files: any, isAlert?: boolean) => {
  const arrayFiles = Array.isArray(files) ? files : [files];
  const type = isAlert ? 'table' : 'message';

  let expiredCount = 0;
  let toExpireCount = 0;

  expiredCount = arrayFiles.reduce(
    (count, file) => (file?.subStatus === DocumentSubstatusEnum.EXPIRED ? count + 1 : count),
    0
  );
  toExpireCount = arrayFiles.reduce(
    (count, file) => (file?.subStatus === DocumentSubstatusEnum.TO_EXPIRE ? count + 1 : count),
    0
  );

  if (expiredCount && toExpireCount)
    return alertExpiredDocuments(type, 'multipleCases', expiredCount, toExpireCount);

  if (expiredCount) return alertExpiredDocuments(type, 'expired', expiredCount);

  if (toExpireCount) return alertExpiredDocuments(type, 'toExpire', toExpireCount);
};

export const getIdentifyDocumentLabel = (documentKey: string, kind: string): string | null => {
  const isID = ['identityCardFront', 'identityCardReverse'].includes(documentKey);
  if (!isID || !kind) return null;

  const side = documentKey === 'identityCardFront' ? 'front' : 'reverse';
  const basePath = 'applicants.review.visualizer.otherDocuments.kindIdentifyDocuments';

  return i18n.t(`${basePath}.${kind}.${side}`);
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const typeStore = (storeId: string) => {
  switch (storeId) {
    case enterpriseCodeNumber.SMU:
      return 'unimarc';
    case '61e049816f4c8f00241e5eba':
      return 'walmart';
    default:
      return 'default';
  }
};

export const sortVariablesByWeigth = (variables: Array<any>): Array<any> => {
  const weightVariables = {
    [VariableKindEnum.SIMPLE]: 1,
    [VariableKindEnum.STAGGERED]: 0,
  };

  return [...variables].sort((a, b) => {
    const aWeight = weightVariables[a.kind || VariableKindEnum.SIMPLE];
    const bWeight = weightVariables[b.kind || VariableKindEnum.SIMPLE];
    return bWeight - aWeight;
  });
};

const isOperatorOrderValid = (order: any) =>
  order?.application && order?.type !== MoneyOrderTypes.INCENTIVE;

const isShopperOrderValid = (order) =>
  order?.shopperOrder?.startAt && order?.type !== MoneyOrderTypes.INCENTIVE;

export const formatTimeHour = (dateTime: string): string =>
  dateTime && moment(dateTime).format('HH:mm') + ' hrs';

export function getSettlements(
  isShopper: boolean,
  settlement: any
): { groupedOrders: any[]; others: any[] } {
  let groupedOrders = [];
  const others = [];

  if (!isShopper) {
    const { moneyOrders } = settlement;

    const filteredOrders = [];

    moneyOrders.forEach((moneyOrder) => {
      if (
        moneyOrder?.creationType === MoneyOrderCreationType.AUTOMATIC &&
        isOperatorOrderValid(moneyOrder)
      ) {
        filteredOrders.push(moneyOrder);
      } else {
        others.push(moneyOrder);
      }
    });

    const groupedByDate = filteredOrders
      .sort((a, b) => new Date(a.job.startAt).getTime() - new Date(b.job.startAt).getTime())
      .reduce((acc, moneyOrder) => {
        const date = moment(moneyOrder.job.startAt).format('dddd DD [de] MMMM [de] YYYY');

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(moneyOrder);
        return acc;
      }, {});

    groupedOrders = Object.entries(groupedByDate).map(([startAt, items]) => ({
      title: startAt,
      items,
    }));
  } else {
    const { moneyOrdersShopper } = settlement;

    const filteredOrders = [];

    moneyOrdersShopper?.forEach((moneyOrder) => {
      if (
        moneyOrder.creationType === MoneyOrderCreationType.AUTOMATIC &&
        isShopperOrderValid(moneyOrder)
      ) {
        filteredOrders.push(moneyOrder);
      } else {
        others.push(moneyOrder);
      }
    });

    const groupedByDate = filteredOrders
      .sort(
        (a, b) =>
          new Date(a.shopperOrder.startAt).getTime() - new Date(b.shopperOrder.startAt).getTime()
      )
      .reduce((acc, moneyOrder) => {
        const date = moment(moneyOrder.shopperOrder.startAt).format('dddd DD [de] MMMM [de] YYYY');

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(moneyOrder);
        return acc;
      }, {});

    groupedOrders = Object.entries(groupedByDate).map(([startAt, items]) => ({
      title: startAt,
      items,
    }));
  }

  return {
    groupedOrders,
    others,
  };
}
export const numberWithDotsAndCommas = (x = 0, countryIso: string): string => {
  const fixedNumber = countryIso === 'CL' || countryIso === 'CO' ? x.toFixed(0) : x.toFixed(2);

  const str = fixedNumber.split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return str.join(',');
};

// Date formatter to ->  "jueves 5 de octubre de 2023"
export const customDateFormat = (isoDate: string, withYear = false) => {
  moment.locale('es');
  const date = moment(isoDate);

  const formattedDateLongString = 'dddd D [de] MMMM [de] YYYY';
  const formattedDateString = 'dddd D [de] MMMM';

  const formattedDate = date.format(withYear ? formattedDateLongString : formattedDateString);

  return formattedDate.charAt(0) + formattedDate.slice(1);
};

// Check if the jobber has a specific block for the job.
export const isJobberBlockedForJob = (blockList, job) => {
  const { enterprise, type, store } = job;

  return blockList.some((blockInfo) => {
    if (blockInfo.kind !== BlockKindEnum.SPECIFIC) {
      return false;
    }

    const { restriction } = blockInfo;

    if (restriction.enterprise && restriction.enterprise._id !== enterprise?._id) {
      return false;
    }

    if (restriction.jobType && restriction.jobType._id !== type?._id) {
      return false;
    }

    if (restriction.store && restriction.store._id !== store?._id) {
      return false;
    }

    return true;
  });
};

export const getOptionsDocumentsByCountry = (countryIso: string) => {
  const records = DOCUMENT_CODES[countryIso]?.records || [];
  const otherDocuments = DOCUMENT_CODES[countryIso]?.otherDocuments || [];
  const additionalInformation = DOCUMENT_CODES[countryIso]?.additionalInformation || [];

  return [...records, ...otherDocuments, ...additionalInformation];
};

const getMappedCode = (documentName: string) => {
  return SPECIAL_CODE_MAP[documentName] || documentName;
};

const getDocument = (documentName: string, country: string, type: string) => {
  const documents = DOCUMENT_CODES[country]?.[type] || [];
  const mappedName = getMappedCode(documentName);

  return documents.includes(documentName) ? mappedName : null;
};

export const findDocument = (documentName: string, jobber: any, countryIso: string) => {
  const types = ['records', 'otherDocuments', 'additionalInformation'];

  for (const type of types) {
    const documentCode = getDocument(documentName, countryIso, type);

    if (documentCode) {
      switch (type) {
        case 'records':
          return jobber.records.file;
        case 'otherDocuments': {
          const kind = documentName.replace(/_[^_]*$/, '');

          return jobber.otherDocuments?.[documentCode].kind === kind
            ? jobber.otherDocuments?.[documentCode]
            : null;
        }
        case 'additionalInformation':
          return (
            jobber.additionalInformation?.documents?.find((doc) => doc.code === documentCode) ??
            null
          );
        default:
          return null;
      }
    }
  }

  return null;
};

export const filterCounter = (filters: Record<string, any>) => {
  const values = Object.entries(filters).reduce((acc, [key, value]) => {
    // Fields to omit
    if (['ppesNames', 'skillFinalized'].includes(key)) return acc;

    if (key.endsWith('From')) {
      // Group 'From' and 'To' pairs into a single filter if either has a value
      const baseKey = key.replace('From', '');
      const toValue = filters[`${baseKey}To`];
      const filteredValues = [value, toValue].filter(Boolean);

      if (filteredValues.length > 0) {
        acc.push({ [baseKey]: filteredValues });
      }
    } else if (!key.endsWith('To') && (Array.isArray(value) ? value.length > 0 : Boolean(value))) {
      // Add non-range filters if they have a value
      acc.push({ [key]: value });
    }

    return acc;
  }, []);

  return values.length; // Return the total number of valid filters
};
