export default {
  courses: {
    mainTitle: 'Gestor de inducciones',
    global: {
      on_site: 'Terreno',
      meets: 'Meet',
      e_learning: 'E-learning',
    },
    main: {
      title: 'Cursos',
      subtitle: 'Revisa el listado de cursos creados y su descripción.',
      buttons: { createCourse: 'Crear curso' },
      options: { view: 'Ver', edit: '$t(global.edit)', delete: '$t(global.delete)' },
      deleteModal: {
        title: 'Eliminar curso',
        text: 'Al eliminar el curso, no podrás recuperar esta información y desaparecerá de la tabla.',
        successMessage: 'El curso ha sido eliminado exitosamente. ',
      },
      kpi: {
        total: { title: 'Total cursos creados' },
        meets: { title: 'Meets' },
        onSite: { title: 'Terreno' },
        eLearning: { title: 'E-learning' },
      },
      columns: [
        { title: 'ID' },
        { title: 'Última modificación' },
        { title: 'Nombre' },
        { title: 'Turnos' },
        { title: 'Tipo', options: { on_site: 'Terreno', e_learning: 'E-learning', meets: 'Meet' } },
        { title: 'Creado por' },
        { title: 'Acciones' },
      ],
      filters: [
        {
          title: 'Tipo curso',
          placeholder: 'Selecciona',
          options: { on_site: 'Terreno', e_learning: 'E-learning', meets: 'Meet' },
        },
        { title: 'Creado por' },
      ],
      filterSearch: { title: 'Buscar', placeholder: 'Busca por nombre de curso' },
      filterDatePicker: { title: 'Fecha de creación' },
      filterClear: { title: 'Limpiar filtros' },
      actionsDrawer: {
        new: {
          title: 'Creación de curso',
          subtitle: 'Ingresa los datos obligatorios (*) para crear el curso',
          submitButton: 'Crear curso',
          successMessage:
            'El curso ha sido creado exitosamente. En la tabla podrás revisar cada curso de manera independiente.',
          cancelModal: {
            title: 'Cancelar creación curso',
            text: 'Al confirmar la cancelación, perderás los datos ingresados ',
            okText: 'Cancelar creación',
            cancelText: '$t(global.getBack)',
          },
        },
        edit: {
          title: 'Edición curso',
          subtitle: 'Realiza los cambios necesarios en el curso',
          submitButton: 'Actualizar curso',
          successMessage: 'El curso ha sido actualizado exitosamente.',
          cancelModal: {
            title: 'Cambios sin guardar',
            text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
            okText: 'Salir sin guardar',
            cancelText: 'Guardar cambios',
          },
        },
        finished: {
          title: 'Finalizar curso',
          titleRemove: 'Remover curso',
          subtitle: 'Finalizado por',
        },
        courseType: {
          title: 'Escoge el curso',
          subtitle: 'Selecciona el tipo de curso que quieres crear',
          terreno: 'Terreno',
          elearning: 'E-learning',
          meets: 'Meet',
        },
        inputs: {
          type: {
            label: 'Tipo *',
            message: 'Selecciona el tipo de curso',
          },
        },
        information: {
          title: 'Información',
          subtitle: 'Ingresa los datos que serán utilizados en el detalle del curso',
          inputs: {
            name: { label: 'Nombre del curso *', message: 'Ingresa el nombre del curso' },
            enterprise: { label: 'Cliente *', message: 'Ingresa el cliente' },
            store: { label: 'Sede *', message: 'Ingresa la sede' },
          },
        },
        description: {
          title: 'Descripción del curso',
          inputs: {
            description: {
              label: 'Descripción *',
              placeholder: 'Agrega una descripción del curso',
              message: 'Ingresa la descripción',
            },
          },
        },
        url: {
          title: 'Url del curso ',
          subtitle: 'Pega aquí el link del meet/zoom',
          label: 'Link *',
          placeholder: 'Ej: www.meets.cl',
          message: 'Ingresa el link',
          formatError: 'Ingresa un link válido',
        },
        thinkific: {
          title: 'Asocia el curso de Thinkific',
          inputs: {
            category: {
              label: 'Categoría *',
              placeholder: 'Selecciona una categoría *',
              message: 'Seleccióna la categoría',
            },
            course: {
              label: 'Curso *',
              placeholder: 'Selecciona un curso *',
              message: 'Seleccióna el curso',
            },
          },
        },
      },
      shifts: {
        title: 'Crear turno',
        subtitle: 'Ingresa los siguientes datos para crear el turno',
        add: 'Agregar otro turno',
        errors: {
          empty: 'Debe ingresar al menos 1 turno',
          incomplete: 'Completa los datos de los turnos',
        },
        inputs: {
          publishAt: { label: 'Fecha realización curso *', required: 'Ingresa la fecha del turno' },
          quotas: {
            label: 'Cupos turno *',
            required: 'Ingresa el número de cupos',
            onlyNumbers: 'Ingresa solo números',
            between1and999: 'Número debe ser mayor a 0',
          },
          startAt: { label: 'Hora inicio *', required: 'Ingresa la hora de inicio' },
          endAt: {
            label: 'Hora término *',
            required: 'Ingresa la hora de término',
            notAllowed: 'La hora de término debe ser posterior a la de inicio',
          },
        },
        aproxTime: 'Tiempo estimado: ',
        schedule: 'Horario turno: ',
        publishAt: 'Fecha: ',
      },
      summaryDrawer: {
        type: 'Tipo de Curso',
        enterprise: 'Cliente',
        store: 'Sede',
        access: 'Url del curso',
        thinkific: 'ID Curso Thinkific',
        about: 'Acerca del curso',
        description: 'Descripción:',
        shifts: {
          title: 'Turnos',
          publishAt: 'Fecha realización curso',
          quotas: 'Cupos turno',
          schedule: 'Horario estimado',
          duration: 'Duración',
        },
      },
    },
    components: {
      CourseFormItemCard: {
        title: 'Nombre curso',
        shiftsAmount: '({{count}} turno)',
        shiftsAmount_plural: '({{count}} turnos)',
        type: 'Tipo',
        url: 'URL curso ',
        parent: 'Curso padre: {{parent}}',
        noParent: 'no seleccionado',
        body: {
          parent: { title: 'Curso padre', label: 'Curso padre (opcional)' },
          description: 'Description',
          shifts: {
            title: 'Turno',
            date: 'Fecha realización curso',
            quotas: 'Cupos turno',
            time: 'Horario estimado',
            length: 'Duración',
          },
        },
      },
    },
  },
};
