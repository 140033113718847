export default {
  tasks: {
    list: {
      title: 'Tareas',
      buttonActions: {
        title: 'Crear orden de trabajo',
        options: ['Nueva', 'Usar plantilla'],
      },
      filters: {
        placeholderById: 'Busca por ID',
        search: 'Buscar',
        select: 'selecciona',
        client: 'Cliente',
        campus: 'Sede',
        typeTask: 'Tipo tarea',
        date: 'Fecha ejecución tarea',
        status: 'Estados',
        clean: 'Limpiar filtros',
      },
      columns: [
        'ID Tarea',
        'Sede Cliente',
        'Tipo tarea',
        'Fecha publicación',
        'Fecha/Hora ejecución',
        'Estadisticas',
        'Estado',
        {
          title: 'Acciones',
          options: ['Editar Tarea', 'Cancelar Tarea', 'Editar Orden de trabajo'],
        },
      ],
    },
  },
};
