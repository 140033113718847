import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import server from '@services/api';
import { RequestStatusEnum } from '../../utils/enum';
import { SendNotificationsBodyProps, SendNotificationsTemplateBodyProps } from './ts/notifications';
import {
  getHistoryNotificationsRoute,
  historyNotificationsReportRoute,
  sendNotificationsRoute,
  sendNotificationsTemplateRoute,
} from './ts/routes';

export const notificationsAdapter = createEntityAdapter();

export const initialState = notificationsAdapter.getInitialState({
  loading: RequestStatusEnum.IDLE,
  sendNotificationsState: {
    loading: RequestStatusEnum.IDLE,
    status: null, // success | error
    msgVisible: false,
  },
  sendNotificationsTemplateState: {
    loading: RequestStatusEnum.IDLE,
    status: null, // success | error
    msgVisible: false,
  },
  notificationsHistoryState: {
    loading: RequestStatusEnum.IDLE,
    docs: [],
    previous: null,
    next: null,
    totalDocs: 0,
  },
  sendNotificationReportState: {
    loading: RequestStatusEnum.IDLE,
    status: null, // success | error
  },
});

export const sendNotifications = createAsyncThunk(
  'delivery/sendNotifications',
  async (body: SendNotificationsBodyProps, { rejectWithValue }: any) => {
    try {
      const response = await server.post(sendNotificationsRoute(), body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const sendNotificationsTemplate = createAsyncThunk(
  'delivery/sendNotificationsTemplate',
  async (body: SendNotificationsTemplateBodyProps, { rejectWithValue }: any) => {
    try {
      const response = await server.post(sendNotificationsTemplateRoute(), body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getHistoryNotifications = createAsyncThunk(
  'delivery/getHistoryNotifications',
  async (params: Record<string, unknown>, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'get',
        url: getHistoryNotificationsRoute(),
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const historyNotificationsReport = createAsyncThunk(
  'delivery/historyNotificationsReport',
  async (params: { notification: string }, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'get',
        url: historyNotificationsReportRoute(),
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    finishSendNotifications: (state) => {
      state.sendNotificationsState.status = 'success';
      state.sendNotificationsState.msgVisible = true;
    },
    resetSendNotificationsTemplate: (state) => {
      state.sendNotificationsTemplateState.status = null;
      state.sendNotificationsTemplateState.msgVisible = true;
    },
    resetSendNotificationsState: (state) => {
      state.sendNotificationsState.status = null;
      state.sendNotificationsState.msgVisible = false;
    },
    resetSendNotificationReportState: (state) => {
      state.sendNotificationReportState.status = null;
    },
  },
  extraReducers: {
    [String(sendNotifications.pending)]: (state) => {
      state.loading = RequestStatusEnum.PENDING;
      state.sendNotificationsState.loading = RequestStatusEnum.PENDING;
    },
    [String(sendNotifications.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.sendNotificationsState.loading = RequestStatusEnum.IDLE;
    },
    [String(sendNotifications.rejected)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.sendNotificationsState.loading = RequestStatusEnum.IDLE;
      state.sendNotificationsState.status = 'error';
      state.sendNotificationsState.msgVisible = true;
    },

    [String(sendNotificationsTemplate.pending)]: (state) => {
      state.loading = RequestStatusEnum.PENDING;
      state.sendNotificationsTemplateState.loading = RequestStatusEnum.PENDING;
    },
    [String(sendNotificationsTemplate.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.sendNotificationsTemplateState.loading = RequestStatusEnum.IDLE;
      state.sendNotificationsTemplateState.status = 'success';
      state.sendNotificationsTemplateState.msgVisible = true;
    },
    [String(sendNotificationsTemplate.rejected)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.sendNotificationsTemplateState.loading = RequestStatusEnum.IDLE;
      state.sendNotificationsTemplateState.status = 'error';
      state.sendNotificationsTemplateState.msgVisible = true;
    },

    [String(getHistoryNotifications.pending)]: (state) => {
      state.loading = RequestStatusEnum.PENDING;
      state.notificationsHistoryState.loading = RequestStatusEnum.PENDING;
    },
    [String(getHistoryNotifications.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.notificationsHistoryState = {
        loading: RequestStatusEnum.IDLE,
        ...action?.payload?.data?.data,
      };
    },
    [String(getHistoryNotifications.rejected)]: (state) => {
      state.loading = RequestStatusEnum.ERROR;
      state.notificationsHistoryState.loading = RequestStatusEnum.IDLE;
    },

    [String(historyNotificationsReport.pending)]: (state) => {
      state.loading = RequestStatusEnum.PENDING;
      state.sendNotificationReportState.loading = RequestStatusEnum.PENDING;
      state.sendNotificationReportState.status = null;
    },
    [String(historyNotificationsReport.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.sendNotificationReportState.loading = RequestStatusEnum.IDLE;
      state.sendNotificationReportState.status = 'success';
    },
    [String(historyNotificationsReport.rejected)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.sendNotificationReportState.loading = RequestStatusEnum.IDLE;
      state.sendNotificationReportState.status = 'error';
    },
  },
});

export const {
  resetSendNotificationsState,
  resetSendNotificationReportState,
  finishSendNotifications,
  resetSendNotificationsTemplate,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
