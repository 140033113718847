export enum NpsAtributeEnum {
  REGISTRATION_PROCESS = 'REGISTRATION_PROCESS',
  INDUCTIONS = 'INDUCTIONS',
  APPLICATION_FOR_THE_FIRST_JOB = 'APPLICATION_FOR_THE_FIRST_JOB',
  JOB_ASSIGNMENT = 'JOB_ASSIGNMENT',
  OPERATION_OF_THE_APP = 'OPERATION_OF_THE_APP',
  MISTREATMENT_DURING_JOB = 'MISTREATMENT_DURING_JOB',
  JOB_PLACE = 'JOB_PLACE',
  PAYMENTS = 'PAYMENTS',
  OTHER = 'OTHER',
  INCOMES = 'INCOMES',
  SHOPPER = 'SHOPPER',
}

export enum NpsLegendEnum {
  ONE_SIX = '1_6',
  SEVEN_EIGHT = '7_8',
  NINE_TEN = '9_10',
  NPS = 'nps',
}

export enum qualificationTasksEnum {
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
  FOUR = 'four',
  FIVE = 'five',
  AVG = 'avg',
}

export enum qualificationStarsEnum {
  ACCESS_OR_SITE_REFERENCE = 'ACCESS_OR_SITE_REFERENCE',
  ACCESS_OR_SITE_REFERENCE_AND_LOCATION_IN_APP = 'ACCESS_OR_SITE_REFERENCE_AND_LOCATION_IN_APP',
  APP_OPERATION = 'APP_OPERATION',
  AVAILABLE_ORDERS = 'AVAILABLE_ORDERS',
  AVAILABLE_PRODUCTS = 'AVAILABLE_PRODUCTS',
  AVAILABLE_SUPPLIES = 'AVAILABLE_SUPPLIES',
  BATHROOM = 'BATHROOM',
  COORDINATORS_TREATMENT = 'COORDINATORS_TREATMENT',
  DELIVERY_DISTANCE = 'DELIVERY_DISTANCE',
  DISPATCH_APP = 'DISPATCH_APP',
  EXPLANATION_OF_TASK = 'EXPLANATION_OF_TASK',
  LOCATION = 'LOCATION',
  NEARBY_BUS = 'NEARBY_BUS',
  ORDER_QUANTITY = 'ORDER_QUANTITY',
  OTHER = 'OTHER',
  PARKING = 'PARKING',
  PHYSICAL_DEMAND = 'PHYSICAL_DEMAND',
  PICKING_APP = 'PICKING_APP',
  PLACE_FOR_LUNCH = 'PLACE_FOR_LUNCH',
  REST_AREA = 'REST_AREA',
  SCHEDULE_ADHERENCE = 'SCHEDULE_ADHERENCE',
  STORAGE = 'STORAGE',
  TASK_MATCHES_APP_DESCRIPTION = 'TASK_MATCHES_APP_DESCRIPTION',
  WORKING_HOURS = 'WORKING_HOURS',
  WORK_ENVIRONMENT = 'WORK_ENVIRONMENT',
}
