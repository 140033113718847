export enum CoursesTypes {
  MEETS = 'meets',
  ON_SITE = 'on_site',
  E_LEARNING = 'e_learning',
}

export enum CoursesTypesForSelect {
  MEETS = 'meets',
  E_LEARNING = 'e_learning',
}

export enum CoursesTypesNames {
  VIRTUAL = 'virtual',
  ONSITE = 'onSite'
}
