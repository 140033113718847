export const coursesRoute = (): string => '/academy/courses';

export const courseRoute = (id: string): string => `/academy/courses/${id}`;

export const getCoursesCountSummaryRoute = (): string => '/academy/courses/summary-counters';

export const getThinkificCategoriesRoute = (): string => '/academy/thinkific/categories';

export const getThinkificCoursesRoute = (categoryId: string): string =>
  `/academy/thinkific/categories/${categoryId}/courses`;
