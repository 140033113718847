export default {
  localities: {
    title: 'Gestor Localidades',
    subtitle:
      'Revisa y edita las $t(global.commune_plural, lowercase) activas por cada $t(global.region, lowercase)',
    initial:
      'Selecciona una $t(global.region, lowercase) para ver sus $t(global.commune_plural, lowercase) activas y agregar otras',
    firstLevelLabel: 'Selecciona $t(global.region, lowercase)',
    activeLocalities: {
      title: '$t(global.commune_plural) activas $t(global.region)',
      subtitle:
        'Si quieres DESACTIVAR $t(global.commune_plural, uppercase) debes seleccionar la opción',
      activeAmount: '{{count}} $t(global.commune, lowercase) activa',
      activeAmount_plural: '{{count}} $t(global.commune_plural, lowercase) activas',
      actionActivateOthers: 'Activar otras $t(global.commune_plural, lowercase)',
      actionDeactivate: 'Desactivar $t(global.commune, lowercase)',
      actionDeactivate_plural: 'Desactivar $t(global.commune_plural, lowercase)',
      empty:
        'Actualmente no tienes $t(global.commune_plural, lowercase) activadas. Desactiva $t(global.regionWArticle, lowercase) o activa la $t(global.commune, lowercase) que necesites en el botón ACTIVAR OTRAS $t(global.commune_plural, uppercase)',
      emptyDisabled:
        'Actualmente tu $t(global.region, lowercase) está DESACTIVADA. Debes activarla para poder activar la o las $t(global.commune_plural, lowercase) que necesites',
    },
    disabledLocalities: {
      title: '$t(global.commune_plural) desactivadas $t(global.region)',
      subtitle:
        'Listado de $t(global.commune_plural, lowercase) desactivadas de la $t(global.region, lowercase)',
      disabledAmount: '{{count}} $t(global.commune, lowercase) desactivada',
      disabledAmount_plural: '{{count}} $t(global.commune_plural, lowercase) desactivadas',
    },
    commonActionMsg: {
      changeDemand: 'Demanda cambiada con éxito.',
      changeNeeds: 'Necesidad cambiada con éxito.',
      changeBoth: 'Demanda y necesidad cambiada con éxito.',
    },
    selection: {
      selectAll: 'Seleccionar todas',
      unselectAll: 'Borrar selección',
    },
    demand: {
      options: {
        mid: 'Demanda normal',
        high: 'Demanda baja',
      },
      bulkLabel: 'Selecciona la demanda de la selección',
      bulkPlaceholder: 'Selecciona',
    },
    needOfJobbers: {
      options: {
        low: 'Necesidad de Jobbers baja',
        medium: 'Necesidad de Jobbers media',
        high: 'Necesidad de Jobbers alta',
      },
      bulkLabel: 'Selecciona la necesidad de la selección',
      bulkPlaceholder: 'Selecciona',
    },
    commonActionApply: 'Aplicar',
    upperLevel: {
      active: {
        true: {
          button: 'Desactivar $t(global.region, lowercase)',
          successMsg: '$t(global.region) desactivada exitosamente',
        },
        false: {
          button: 'Activar $t(global.region, lowercase)',
          successMsg: '$t(global.region) activada exitosamente',
        },
      },
    },
    enableSecondLevel: {
      title: 'Activar otras $t(global.commune, {"count": 0 })',
      select: {
        placeholder: 'Elije $t($t(global.communeWArticle, {"count": 0 }), lowercase) ha activar',
        all: 'Activar todas $t($t(global.communeWArticle, {"count": 0 }), lowercase) de $t($t(global.regionWArticle), lowercase)',
        any: 'Deseleccionar $t($t(global.communeWArticle, {"count": 0 }), lowercase)',
      },
      close: '$t(global.cancel)',
      enable: 'Activar $t($t(global.commune, {"count": {{count}} }), lowercase)',
      demand: {
        title: 'Selecciona la demanda de cada $t(global.commune, lowercase)',
        subtitle:
          'Acá podrás cambiar el flujo de demanda de $t(global.communeWArticle_plural, lowercase) que quieres activar seleccionando una opción o todas ellas al mismo tiempo',
      },
    },
    toggleActiveSecondLevel: {
      activatedMsg:
        'Has agregado {{count}} nueva $t(global.commune, lowercase) activa a la $t(global.region)',
      activatedMsg_plural:
        'Has agregado {{count}} nuevas $t(global.commune_plural, lowercase) activas a la $t(global.region)',
      deactivatedMsg:
        'Has desactivado {{count}} $t(global.commune, lowercase) de la $t(global.region)',
      deactivatedMsg_plural:
        'Has desactivado {{count}} $t(global.commune_plural, lowercase) de la $t(global.region)',
      deactivatedAllMsg:
        'Has desactivado todas las $t(global.commune_plural, lowercase) de la $t(global.region)',
    },
    components: {
      CommunesFormItemCard: {
        subtitle: 'Selecciona $t(global.regionWArticle, lowercase) en que aplicará {{name}}',
        communesTitle: 'Selecciona la o las comunas para esta región',
        communesError: 'Selecciona al menos una $t(global.commune, lowercase)',
      },
    },
  },
};
