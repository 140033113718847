export default {
  businessClient: {
    table: {
      filters: [
        {
          name: 'Buscar',
          placeholder: 'Busca por razón social, giro o RUT',
        },
        {
          name: 'País',
          placeholder: 'Selecciona',
        },
        {
          name: 'Región',
          placeholder: 'Selecciona',
        },
        {
          name: 'Comuna',
          placeholder: 'Selecciona',
        },
        {
          name: 'Fecha de creación',
          placeholder: 'Selecciona',
        },
      ],
    },
  },
};
