import i18n from 'i18next';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { snakeCase } from 'lodash';

import server from '@services/api';
import {
  getApplicantsRoute,
  logBooksRoute,
  getSearchRoute,
  editUserRoute,
  getCheckEmailRoute,
  putSpeaksSpanishRoute,
  putAdditionalInformationReviewRoute,
  requirementsRoute,
  jobbersReportRoute,
  deleteApplicantUserRoute,
  getJobbersContractRoute,
  finishContractRoute,
  reactivateContractRoute,
  getFinishContractClausesRoute,
  metricsReportRoute,
  getCheckRappiIdRoute,
  editJobberRappiIdRoute,
} from '@features/applicants/ts/routes';

import {
  GetApplicantsFiltersInterfaces,
  GetApplicantsLists,
  ApplicantsDocs,
  SetRecordsReview,
  SetOtherDocsReview,
  GetInfo,
  GetLogBook,
  PostLogbook,
  PostAssignment,
  EditUserPayload,
  PutSpeaksSpanish,
  RequirementSkills,
  SpecificRequirement,
  SetOtherDataReview,
} from './ts/applicants.interfaces';
import { Skill } from '@features/skills/ts/skills.interfaces';
import {
  putRecordsReviewRoute,
  putOtherDocsReviewRoute,
  putFrameworkAgreementReviewRoute,
  assignmentRoute,
} from './ts/routes';

import { otherDocsCodes } from '@utils/consts';
import { RequestStatusEnum } from '@utils/enum';
import { Documents, Ppe } from '@features/components/ts/components.interfaces';
import { JobberContractsEnum } from './ts/enum';

export const applicantsAdapter = createEntityAdapter();

export const initialState = applicantsAdapter.getInitialState({
  applicants: { docs: [] as [] | ApplicantsDocs[], loading: false },
  projectionApplicants: {
    docs: [],
    loading: RequestStatusEnum.IDLE,
  },
  records: { loading: false, file: undefined },
  applicantInfo: {
    loading: false,
    personalData: {},
    additionalInformation: { ppes: [], documents: [], vehicleDocuments: [] },
    specifics: [],
    skills: [],
    assignedStaff: {},
    blockedInfo: [],
    contract: {},
    rappiId: '',
  } as {
    loading: boolean;
    personalData?: Record<string, any>;
    language?: Record<string, any>;
    additionalInformation?: { ppes: Ppe[]; documents: Documents[]; vehicleDocuments: Documents[] };
    specifics?: SpecificRequirement[];
    skills?: Skill[];
    assignedStaff?: Record<string, any>;
    contract: {};
    rappiId: string;
  },
  reviewer: { loading: false, opened: false },
  logbook: { logs: [], loading: false },
  loading: RequestStatusEnum.IDLE,
  currentRequestId: undefined,
  error: null,
  errorInfo: null,
  search: {
    searchLoading: false,
    searchList: null,
  },
  drawerMessage: {
    type: '',
    message: '',
  },
  assignment: { state: RequestStatusEnum.IDLE, individualState: RequestStatusEnum.IDLE },
  editUser: {
    status: RequestStatusEnum.IDLE,
  },
  editJobberRapiId: {
    status: RequestStatusEnum.IDLE,
  },
  checkRappiIdExists: {
    isTaken: RequestStatusEnum.IDLE,
    loading: false,
  },
  deleteApplicant: {
    status: RequestStatusEnum.IDLE,
  },
  speaksSpanishLoading: false,
  specificsRequirementsStatus: RequestStatusEnum.IDLE,
  blockingStatus: RequestStatusEnum.IDLE,
  unblockingStatus: RequestStatusEnum.IDLE,
  jobberReport: RequestStatusEnum.IDLE,
  metricsReport: RequestStatusEnum.IDLE,
  finishContractState: {
    loading: RequestStatusEnum.IDLE,
    type: null,
    message: '',
  },
  contractClauses: {
    loading: RequestStatusEnum.IDLE,
    clauses: [],
  },
});

export const getApplicantInfo = createAsyncThunk(
  'applicants/getInfo',
  async (id: string, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: GetInfo = await server({
      method: 'get',
      url: `/account/jobbers/${id}/details-staff`,
    });

    return response?.data;
  }
);

export const getApplicants = createAsyncThunk(
  'applicants/getApplicants',
  async (params: GetApplicantsFiltersInterfaces, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: GetApplicantsLists = await server({
      method: 'get',
      url: getApplicantsRoute(),
      params,
    });
    return response?.data;
  }
);

export const getProjectionApplicants = createAsyncThunk(
  'applicants/getProjectionApplicants',
  async (params: GetApplicantsFiltersInterfaces) => {
    const response: GetApplicantsLists = await server({
      method: 'get',
      url: getApplicantsRoute(),
      params,
    });
    return response?.data;
  }
);

export const getSearch = createAsyncThunk(
  'applicants/getSearch',
  async (params: GetApplicantsFiltersInterfaces, { getState, requestId }: any) => {
    const { currentRequestId } = getState().applicants;
    if (requestId !== currentRequestId) {
      return;
    }
    const response: GetApplicantsLists = await server({
      method: 'get',
      url: getSearchRoute(params),
    });
    return response?.data;
  }
);

export const setRecordsReview = createAsyncThunk(
  'applicants/setRecordsReview',
  async (payload: SetRecordsReview, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.put(putRecordsReviewRoute(payload.jobberId), payload);
    return response;
  }
);

export const setOtherDocsReview = createAsyncThunk(
  'applicants/setOtherDocsReview',
  async (payload: SetOtherDocsReview, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.put(
      putOtherDocsReviewRoute(otherDocsCodes[payload.code], payload.jobberId),
      payload
    );
    return response;
  }
);

export const setFrameworkAgreementReview = createAsyncThunk(
  'applicants/setFrameworkAgreementReview',
  async (payload: SetOtherDocsReview, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.put(putFrameworkAgreementReviewRoute(payload.jobberId), payload);
    return response;
  }
);

export const getLogBook = createAsyncThunk(
  'applicants/getLogBook',
  async (payload: GetLogBook, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.get(logBooksRoute(payload.jobberId));
    return response;
  }
);

export const postLogbook = createAsyncThunk(
  'applicants/postLogBook',
  async (payload: PostLogbook, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.post(logBooksRoute(payload.jobberId), payload);
    return response;
  }
);

export const postAssignment = createAsyncThunk(
  'applicants/postAssignment',
  async (payload: PostAssignment, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().applicants;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.put(assignmentRoute(), payload);
    response.data.individualAssignation = payload.individualAssignation;
    return response.data;
  }
);

export const editUser = createAsyncThunk(
  'applicants/edituser',
  async (payload: EditUserPayload, thunkApi) => {
    try {
      const { originalEmail, email, _id } = payload;
      if (originalEmail !== email) {
        const isTaken = await server.get(getCheckEmailRoute(), {
          params: { email: payload?.email },
        });
        if (isTaken?.data?.data) return thunkApi.rejectWithValue({ status: 409, type: 'email' });
      }
      const response = await server.put(editUserRoute(_id), payload);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue({ status: error?.response?.status });
    }
  }
);

export const checkRappiIdExists = createAsyncThunk(
  'applicants/checkRappiIdExists',
  async (payload: EditUserPayload) => {
    if (payload) {
      const isTaken = await server.get(getCheckRappiIdRoute(), {
        params: { rappiId: payload.toString() },
      });

      return isTaken?.data?.data;
    }
  }
);

export const editJobberRappiId = createAsyncThunk(
  'applicants/editJobberRappiId',
  async (payload: { _id: string; rappiId: string }, thunkApi) => {
    try {
      const { _id } = payload;

      const response = await server.put(editJobberRappiIdRoute(_id), payload);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue({ status: error?.response?.status });
    }
  }
);

export const putSpeaksSpanish = createAsyncThunk(
  'applicants/putSpeaksSpanish',
  async ({ speakSpanish, _id }: PutSpeaksSpanish) => {
    const response = await server.put(putSpeaksSpanishRoute(_id), {
      speakSpanish,
    });
    return response.data;
  }
);

export const setAdditionalDocsReview = createAsyncThunk(
  'applicants/setVehicleDocsReview',
  async (payload: SetOtherDocsReview) => {
    const response = server.put(
      putAdditionalInformationReviewRoute(
        payload.jobberId,
        snakeCase(payload.code),
        payload.category
      ),
      payload
    );
    return response;
  }
);

export const setOtherDataReview = createAsyncThunk(
  'applicants/setVehicleDocsReview',
  async (payload: SetOtherDataReview) => {
    const response = server.put(
      putAdditionalInformationReviewRoute(
        payload.jobberId,
        snakeCase(payload.code),
        payload.category
      ),
      payload
    );
    return response;
  }
);

export const putRequirementSkills = createAsyncThunk(
  'applicants/requirementsSkills',
  async ({ _id, specifics }: RequirementSkills) => {
    const response = await server.put(requirementsRoute(_id), {
      specifics,
    });
    return response.data;
  }
);

export const getJobbersReport = createAsyncThunk(
  'applicants/getJobbersReport',
  async (params: GetApplicantsFiltersInterfaces) => {
    const response = await server({
      method: 'get',
      url: jobbersReportRoute(),
      params,
    });
    return response;
  }
);

export const getMetricsReport = createAsyncThunk(
  'applicants/getMetricsReport',
  async (params: any) => {
    const response = await server({
      method: 'get',
      url: metricsReportRoute(),
      params,
    });

    return response;
  }
);

export const deleteApplicantUser = createAsyncThunk(
  'applicants/deleteApplicantUser',
  async (id: string) => {
    const response = await server({
      method: 'delete',
      url: deleteApplicantUserRoute(id),
    });
    return response;
  }
);

export const getJobbersContract = createAsyncThunk(
  'applicants/getJobbersContract',
  async (params: object) => {
    const response = await server({
      method: 'get',
      url: getJobbersContractRoute(),
      params,
    });
    return response;
  }
);

export const finishContract = createAsyncThunk(
  'applicants/finishContract',
  async ({ contractId, body }: { contractId: string; body: Record<string, any> }) => {
    const response = await server.put(finishContractRoute(contractId), body);
    return response;
  }
);

export const reactivateContract = createAsyncThunk(
  'applicants/reactivateContract',
  async ({ contractId, body }: { contractId: string; body: Record<string, any> }) => {
    const response = await server.put(reactivateContractRoute(contractId), body);
    return response;
  }
);

export const getFinishContractClauses = createAsyncThunk(
  'applicants/getFinishContractClauses',
  async (params: { contractType: JobberContractsEnum }) => {
    const response = await server({
      method: 'get',
      url: getFinishContractClausesRoute(),
      params,
    });

    return response.data;
  }
);

const applicantsSlice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    openReviewer: (state) => {
      state.reviewer.opened = true;
    },
    closeReviewer: (state) => {
      state.reviewer.opened = false;
    },
    clearState: (state, b: { payload: { key: string; subkey?: string; base?: any } }) => {
      const { key, subkey, base } = b.payload ?? {};
      const originalState = base ?? RequestStatusEnum.IDLE;
      if (subkey && state[key]?.[subkey]) state[key][subkey] = originalState;
      else if (key && state[key]) state[key] = originalState;
    },
    clearApplicantInfo: (state) => {
      state.applicantInfo = initialState.applicantInfo;
    },
    setStaffLocalyToApplicant: (
      state,
      b: { payload: { staffId: string; staffName: string; jobberId: string } }
    ) => {
      const { staffId, staffName, jobberId } = b.payload ?? {};
      const index = state.applicants.docs.findIndex(
        (item: Record<string, string>) => item._id === jobberId
      );
      if (index >= 0)
        state.applicants.docs[index].assignedStaff = { _id: staffId, id: staffId, name: staffName };
    },
    setApplicantPpes: (state, b: { payload: { ppes: Ppe[] } }) => {
      const { ppes } = b.payload ?? {};
      state.applicantInfo.additionalInformation.ppes = ppes;
    },
    setApplicantSkills: (state, action: { payload: Skill[] }) => {
      const skills = action.payload ?? ([] as Skill[]);
      state.applicantInfo.skills = skills;
    },
    resetFinishContractState: (state) => {
      state.finishContractState = {
        loading: RequestStatusEnum.IDLE,
        type: null,
        message: '',
      };
    },
  },
  extraReducers: {
    [String(getApplicants.pending)]: (state, action) => {
      state.applicants.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getApplicants.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.applicants = { loading: false, ...action.payload.data };
        state.currentRequestId = undefined;
      }
    },
    [String(getApplicants.rejected)]: (state, action) => {
      state.applicants.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getProjectionApplicants.pending)]: (state, action) => {
      state.projectionApplicants.loading = RequestStatusEnum.PENDING;
      state.loading = RequestStatusEnum.PENDING;
    },
    [String(getProjectionApplicants.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.projectionApplicants = { loading: false, ...action.payload?.data };
    },
    [String(getProjectionApplicants.rejected)]: (state, action) => {
      state.projectionApplicants.loading = RequestStatusEnum.IDLE;
      state.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
    },

    [String(getSearch.pending)]: (state, action) => {
      state.search.searchLoading = true;
      state.currentRequestId = action.meta.requestId;
      state.search.searchList = null;
    },
    [String(getSearch.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.search.searchLoading === true && state.currentRequestId === requestId) {
        state.search.searchList = action.payload.data.docs;
        state.search.searchLoading = false;
        state.currentRequestId = undefined;
      }
    },
    [String(getSearch.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.search.searchLoading === true && state.currentRequestId === requestId) {
        state.search.searchLoading = false;
        state.currentRequestId = undefined;
        state.search.searchList = null;
      }
    },

    [String(setRecordsReview.pending)]: (state, action) => {
      state.reviewer.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(setRecordsReview.fulfilled)]: (state, action) => {
      state.reviewer.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(setRecordsReview.rejected)]: (state, action) => {
      state.reviewer.loading = false;
      state.applicantInfo.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(setOtherDocsReview.pending)]: (state, action) => {
      state.reviewer.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(setOtherDocsReview.fulfilled)]: (state, action) => {
      state.reviewer.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(setOtherDocsReview.rejected)]: (state, action) => {
      state.reviewer.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(setFrameworkAgreementReview.pending)]: (state, action) => {
      state.reviewer.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(setFrameworkAgreementReview.fulfilled)]: (state, action) => {
      state.reviewer.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(setFrameworkAgreementReview.rejected)]: (state, action) => {
      state.reviewer.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },

    [String(getApplicantInfo.pending)]: (state, action) => {
      state.applicantInfo.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getApplicantInfo.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.applicantInfo = {
          ...state.applicantInfo,
          ...action.payload?.data,
          loading: false,
        };
        state.applicantInfo.personalData.ruc = action?.payload?.data?.otherDocuments?.ruc?.code;
        state.currentRequestId = undefined;
      }
    },
    [String(getApplicantInfo.rejected)]: (state, action) => {
      state.applicantInfo.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.errorInfo = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getLogBook.pending)]: (state, action) => {
      state.logbook.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getLogBook.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.logbook = {
          loading: false,
          logs: action.payload?.data?.data ?? [],
        };
        state.currentRequestId = undefined;
      }
    },
    [String(getLogBook.rejected)]: (state, action) => {
      state.logbook.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.errorInfo = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(postLogbook.pending)]: (state, action) => {
      state.logbook.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(postLogbook.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(postLogbook.rejected)]: (state, action) => {
      state.logbook.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.errorInfo = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(postAssignment.pending)]: (state, action) => {
      const key = action.meta?.arg?.individualAssignation ? 'individualState' : 'state';
      state.assignment[key] = RequestStatusEnum.PENDING;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(postAssignment.fulfilled)]: (state, action) => {
      const key = action.meta?.arg?.individualAssignation ? 'individualState' : 'state';
      state.assignment[key] = RequestStatusEnum.SUCCESS;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(postAssignment.rejected)]: (state, action) => {
      const key = action.meta?.arg?.individualAssignation ? 'individualState' : 'state';
      state.assignment[key] = RequestStatusEnum.ERROR;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.errorInfo = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(editUser.pending)]: (state) => {
      state.editUser.status = RequestStatusEnum.PENDING;
    },
    [String(editUser.fulfilled)]: (state, action) => {
      const { documentNumber, documentType, email, names, surnames, ruc } = action.meta.arg;
      state.editUser.status = RequestStatusEnum.SUCCESS;
      state.drawerMessage = {
        type: RequestStatusEnum.SUCCESS,
        message: i18n.t('applicants.jobberDetails.editUser.success'),
      };
      state.applicantInfo.personalData = {
        ...state.applicantInfo.personalData,
        documentNumber,
        documentType,
        email,
        names,
        surnames,
        ruc,
      };
    },
    [String(editUser.rejected)]: (state, action) => {
      state.editUser.status = RequestStatusEnum.ERROR;
      const { status, type } = action?.payload ?? {};
      if (status === 409 && type === 'email')
        state.drawerMessage = {
          type: RequestStatusEnum.ERROR,
          message: i18n.t('applicants.jobberDetails.editUser.email409'),
        };
      else if (status === 409)
        state.drawerMessage = {
          type: RequestStatusEnum.ERROR,
          message: i18n.t('applicants.jobberDetails.editUser.docNum409'),
        };
      else
        state.drawerMessage = {
          type: RequestStatusEnum.ERROR,
          message: i18n.t('applicants.jobberDetails.editUser.genericError'),
        };
    },

    [String(editJobberRappiId.pending)]: (state) => {
      state.editJobberRapiId.status = RequestStatusEnum.PENDING;
    },
    [String(editJobberRappiId.fulfilled)]: (state, action) => {
      const { rappiId } = action.meta.arg;
      state.editJobberRapiId.status = RequestStatusEnum.SUCCESS;
      state.drawerMessage = {
        type: RequestStatusEnum.SUCCESS,
        message: i18n.t('applicants.jobberDetails.personalInfo.rappiId.modal.successAlert'),
      };
      state.applicantInfo = {
        ...state.applicantInfo,
        rappiId,
      };
    },
    [String(editJobberRappiId.rejected)]: (state, action) => {
      state.editJobberRapiId.status = RequestStatusEnum.ERROR;
      const { status } = action?.payload ?? {};
      if (status === 409)
        state.drawerMessage = {
          type: RequestStatusEnum.ERROR,
          message: i18n.t('applicants.jobberDetails.editUser.docNum409'),
        };
      else
        state.drawerMessage = {
          type: RequestStatusEnum.ERROR,
          message: i18n.t('applicants.jobberDetails.editUser.genericError'),
        };
    },

    [String(checkRappiIdExists.pending)]: (state) => {
      state.checkRappiIdExists.loading = true;
      state.editJobberRapiId.status = RequestStatusEnum.PENDING;
    },
    [String(checkRappiIdExists.fulfilled)]: (state, action) => {
      const isTaken = action.payload;
      state.editJobberRapiId.status = RequestStatusEnum.SUCCESS;

      state.checkRappiIdExists = {
        isTaken: isTaken ?? RequestStatusEnum.IDLE,
        loading: false,
      };
    },
    [String(checkRappiIdExists.rejected)]: (state) => {
      state.checkRappiIdExists.loading = false;
      state.checkRappiIdExists.isTaken = RequestStatusEnum.ERROR;
    },

    [String(putSpeaksSpanish.pending)]: (state) => {
      state.speaksSpanishLoading = true;
    },
    [String(putSpeaksSpanish.fulfilled)]: (state, action) => {
      state.speaksSpanishLoading = false;
      state.applicantInfo.language.speakSpanish = action.meta.arg.speakSpanish;
    },
    [String(putSpeaksSpanish.rejected)]: (state, action) => {
      state.speaksSpanishLoading = false;
      state.error = action.error;
    },

    [String(setAdditionalDocsReview.pending)]: (state) => {
      state.reviewer.loading = true;
    },
    [String(setAdditionalDocsReview.fulfilled)]: (state) => {
      state.reviewer.loading = false;
    },
    [String(setAdditionalDocsReview.rejected)]: (state, action) => {
      state.reviewer.loading = false;
      state.error = action.error;
    },

    [String(putRequirementSkills.pending)]: (state) => {
      state.specificsRequirementsStatus = RequestStatusEnum.PENDING;
    },
    [String(putRequirementSkills.fulfilled)]: (state, action) => {
      const { specifics } = action?.meta?.arg ?? [];
      state.specificsRequirementsStatus = RequestStatusEnum.SUCCESS;
      state.applicantInfo.specifics = specifics;
    },
    [String(putRequirementSkills.rejected)]: (state, action) => {
      state.specificsRequirementsStatus = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(getJobbersReport.pending)]: (state) => {
      state.jobberReport = RequestStatusEnum.PENDING;
    },
    [String(getJobbersReport.fulfilled)]: (state) => {
      state.jobberReport = RequestStatusEnum.SUCCESS;
    },
    [String(getJobbersReport.rejected)]: (state, action) => {
      state.jobberReport = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(getMetricsReport.pending)]: (state) => {
      state.metricsReport = RequestStatusEnum.PENDING;
    },
    [String(getMetricsReport.fulfilled)]: (state) => {
      state.metricsReport = RequestStatusEnum.SUCCESS;
    },
    [String(getMetricsReport.rejected)]: (state, action) => {
      state.metricsReport = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(deleteApplicantUser.pending)]: (state) => {
      state.deleteApplicant.status = RequestStatusEnum.PENDING;
    },
    [String(deleteApplicantUser.fulfilled)]: (state) => {
      state.deleteApplicant.status = RequestStatusEnum.SUCCESS;
    },
    [String(deleteApplicantUser.rejected)]: (state, action) => {
      state.deleteApplicant.status = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(getJobbersContract.pending)]: (state, action) => {
      state.applicantInfo.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getJobbersContract.fulfilled)]: (state, action) => {
      state.applicantInfo.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.applicantInfo.contract = action?.payload?.data?.data || [];
        state.currentRequestId = undefined;
      }
    },
    [String(getJobbersContract.rejected)]: (state, action) => {
      state.applicantInfo.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.errorInfo = action.error;
        state.currentRequestId = undefined;
      }
    },

    /* FINISH CONTRACT ACTIONS */

    [String(finishContract.pending)]: (state) => {
      state.finishContractState = {
        loading: RequestStatusEnum.PENDING,
        type: null,
        message: '',
      };
    },
    [String(finishContract.fulfilled)]: (state) => {
      state.finishContractState = {
        loading: RequestStatusEnum.IDLE,
        type: 'success',
        message: i18n.t(
          `applicants.jobberDetails.personalInfo.documents.contracts.modal.alerts.success`
        ),
      };
    },
    [String(finishContract.rejected)]: (state) => {
      state.finishContractState = {
        loading: RequestStatusEnum.IDLE,
        type: 'error',
        message: i18n.t(
          `applicants.jobberDetails.personalInfo.documents.contracts.modal.alerts.error`
        ),
      };
    },

    /* REACTIVATE CONTRACT ACTIONS */

    [String(reactivateContract.pending)]: (state) => {
      state.finishContractState = {
        loading: RequestStatusEnum.PENDING,
        type: null,
        message: '',
      };
    },
    [String(reactivateContract.fulfilled)]: (state) => {
      state.finishContractState = {
        loading: RequestStatusEnum.IDLE,
        type: 'success',
        message: i18n.t(
          `applicants.jobberDetails.personalInfo.documents.contracts.reactivate.alerts.success`
        ),
      };
    },
    [String(reactivateContract.rejected)]: (state) => {
      state.finishContractState = {
        loading: RequestStatusEnum.IDLE,
        type: 'error',
        message: i18n.t(
          `applicants.jobberDetails.personalInfo.documents.contracts.reactivate.alerts.error`
        ),
      };
    },

    [String(getFinishContractClauses.pending)]: (state) => {
      state.contractClauses = { loading: RequestStatusEnum.PENDING, clauses: [] };
    },
    [String(getFinishContractClauses.fulfilled)]: (state, action) => {
      state.contractClauses = { loading: RequestStatusEnum.IDLE, ...action?.payload?.data?.[0] };
    },
    [String(getFinishContractClauses.rejected)]: (state) => {
      state.contractClauses = { loading: RequestStatusEnum.IDLE, clauses: [] };
    },
  },
});

export const {
  openReviewer,
  closeReviewer,
  clearState,
  clearApplicantInfo,
  setStaffLocalyToApplicant,
  setApplicantPpes,
  setApplicantSkills,
  resetFinishContractState,
} = applicantsSlice.actions;
export default applicantsSlice.reducer;
