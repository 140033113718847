import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  getLocationFirstLevel,
  getLocationSecondLevel,
  editDemandRoute,
  editNeedOfJobbersRoute,
  editFirstLevelStatusRoute,
  toggleSecondLevelActiveRoute,
} from '@features/locations/ts/routes';
import {
  SecondLevelPayloadInterface,
  FirstLevelPayloadInterface,
  EditDemandInfo,
  EditNeedOfJobbersInfo,
  EditFirstLevelStatus,
  ToggleSecondLevelActive,
} from './ts/locations.interfaces';
import { cleanObjects, pendingStateHandler, storeStateHandler } from '@utils/functions';

import server from '@services/api';

export const locationsAdapter = createEntityAdapter();

const setLocations = (state, action, type) => {
  state[type].data = action.payload?.data ?? [];
};

const updateSecondLevelItems = (state, b: { localities: Array<any> }) => {
  const { localities } = b ?? {};

  for (let i = 0; i < localities.length; i++) {
    const index = state.secondLevel.data.findIndex(
      (item: Record<string, string>) => item._id === localities[i].locality
    );
    if (index >= 0) {
      let { isActive, selected, demand, needOfJobbers } = localities[i];

      if (isActive === undefined) isActive = !!state.secondLevel.data[index].isActive;
      if (selected === undefined) selected = !!state.secondLevel.data[index].selected;

      state.secondLevel.data[index] = {
        ...state.secondLevel.data[index],
        demand: demand || state.secondLevel.data[index].demand,
        needOfJobbers: needOfJobbers || state.secondLevel.data[index].needOfJobbers,
        isActive,
        selected,
      };
    }
  }
};

export const initialState = locationsAdapter.getInitialState({
  firstLevel: { data: [], loading: false },
  secondLevel: { data: [], loading: false },
  editDemand: { loading: false, bulkLoading: false },
  editNeedOfJobbers: { loading: false, bulkLoading: false },
  firstLevelEnabled: { loading: false },
  deactivateSecondLevel: { loading: false, localities: [], message: '', showAll: false },
  successMsg: '',
  commonActionSuccessMsg: '',
  currentRequestId: undefined,
  error: null,
});

export const getFirstLevel = createAsyncThunk(
  'location/getFirstLevel',
  async ({ countryIso }: FirstLevelPayloadInterface, thunkAPI) => {
    const response = await server({
      method: 'get',
      url: getLocationFirstLevel(countryIso),
      params: {
        countryIso,
      },
    });
    return response.data;
  }
);

export const getSecondLevel = createAsyncThunk(
  'location/getSecondLevel',
  async (params: SecondLevelPayloadInterface) => {
    cleanObjects(params);
    const response = await server({
      method: 'get',
      url: getLocationSecondLevel(),
      params,
    });
    return response.data;
  }
);

export const editDemand = createAsyncThunk(
  'location/editDemand',
  async (payload: EditDemandInfo, thunkApi: any) => {
    try {
      const { currentRequestId } = thunkApi.getState().locations;
      if (thunkApi.requestId !== currentRequestId) {
        return;
      }
      const response = await server.put(editDemandRoute(), payload);
      response.data.isIndividual = payload.isIndividual;
      return response?.data;
    } catch (error) {
      const { status } = error.request;
      return thunkApi.rejectWithValue({ status });
    }
  }
);

export const editNeedOfJobbers = createAsyncThunk(
  'location/editNeedOfJobbers',
  async (payload: EditNeedOfJobbersInfo, thunkApi: any) => {
    try {
      const { currentRequestId } = thunkApi.getState().locations;
      if (thunkApi.requestId !== currentRequestId) {
        return;
      }
      const response = await server.put(editNeedOfJobbersRoute(), payload);
      response.data.isIndividual = payload.isIndividual;
      
      return response?.data;
    } catch (error) {
      const { status } = error.request;
      return thunkApi.rejectWithValue({ status });
    }
  }
);

export const editFirstLevelStatus = createAsyncThunk(
  'location/editFirstLevelStatus',
  async (payload: EditFirstLevelStatus) => {
    const { enabling, id, city } = payload;
    const response = await server.patch(editFirstLevelStatusRoute(id, city), {
      isActive: enabling,
    });
    return response.data;
  }
);

export const deactivateSecondLevel = createAsyncThunk(
  'location/deactivateSecondLevel',
  async (payload: ToggleSecondLevelActive, thunkApi: any) => {
    try {
      const { currentRequestId } = thunkApi.getState().locations;
      if (thunkApi.requestId !== currentRequestId) {
        return;
      }
      const response = await server.put(toggleSecondLevelActiveRoute(), payload);
      return response?.data;
    } catch (error) {
      const { status } = error.request;
      return thunkApi.rejectWithValue({ status });
    }
  }
);

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    updateSecondLevelItemsAction: (state, b: { payload: { localities: Array<any> } }) => {
      updateSecondLevelItems(state, b.payload);
    },
    clearSuccessMsg: (state) => {
      state.successMsg = '';
    },
    clearCommonActionSuccessMsg: (state) => {
      state.commonActionSuccessMsg = '';
    },
    cleanSecondLevelData: (state) => {
      state.secondLevel.data = [];
    },
    toggleDeactivatedShowAll: (state) => {
      state.deactivateSecondLevel.showAll = !state.deactivateSecondLevel.showAll;
    },
    clearDeactivatedSecondLevel: (state) => {
      state.deactivateSecondLevel.message = '';
      state.deactivateSecondLevel.showAll = false;
      state.deactivateSecondLevel.localities = [];
    },
  },
  extraReducers: {
    [String(getFirstLevel.pending)]: (state) => {
      state.firstLevel.loading = true;
    },
    [String(getFirstLevel.rejected)]: (state) => {
      state.firstLevel.loading = false;
    },
    [String(getFirstLevel.fulfilled)]: (state, action) => {
      setLocations(state, action, 'firstLevel');
      state.firstLevel.loading = false;
    },

    [String(getSecondLevel.pending)]: (state) => {
      state.secondLevel.loading = true;
    },
    [String(getSecondLevel.rejected)]: (state) => {
      state.secondLevel.loading = false;
    },
    [String(getSecondLevel.fulfilled)]: (state, action) => {
      setLocations(state, action, 'secondLevel');
      state.secondLevel.loading = false;
    },

    [String(editDemand.pending)]: (state, action) => {
      const key = action.meta?.arg?.isIndividual ? 'loading' : 'bulkLoading';
      state.editDemand[key] = true;
      state.currentRequestId = action.meta.requestId;
    },
    [String(editDemand.rejected)]: (state, action) => {
      const key = action.meta?.arg?.isIndividual ? 'loading' : 'bulkLoading';
      state.editDemand[key] = false;
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.currentRequestId = undefined;
      }
    },
    [String(editDemand.fulfilled)]: (state, action) => {
      const key = action.meta?.arg?.isIndividual ? 'loading' : 'bulkLoading';
      state.editDemand[key] = false;
      state.commonActionSuccessMsg === '' ? state.commonActionSuccessMsg = 'changeDemand' : state.commonActionSuccessMsg = 'changeBoth'
      state.secondLevel.data.filter((obj) => obj.selected).map((obj) => {obj.selected = false});
      updateSecondLevelItems(state, action.meta?.arg);
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.currentRequestId = undefined;
      }
    },

    [String(editNeedOfJobbers.pending)]: (state, action) => {
      const key = action.meta?.arg?.isIndividual ? 'loading' : 'bulkLoading';
      state.editNeedOfJobbers[key] = true;
      state.currentRequestId = action.meta.requestId;
    },
    [String(editNeedOfJobbers.rejected)]: (state, action) => {
      const key = action.meta?.arg?.isIndividual ? 'loading' : 'bulkLoading';
      state.editNeedOfJobbers[key] = false;
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.currentRequestId = undefined;
      }
    },
    [String(editNeedOfJobbers.fulfilled)]: (state, action) => {
      const key = action.meta?.arg?.isIndividual ? 'loading' : 'bulkLoading';
      state.editNeedOfJobbers[key] = false;
      state.commonActionSuccessMsg === '' ? state.commonActionSuccessMsg = 'changeNeeds' : state.commonActionSuccessMsg = 'changeBoth'
      state.secondLevel.data.filter((obj) => obj.selected).map((obj) => {obj.selected = false});
      updateSecondLevelItems(state, action.meta?.arg);
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.currentRequestId = undefined;
      }
    },

    [String(editFirstLevelStatus.pending)]: (state, action) => {
      state.firstLevelEnabled.loading = true;
      pendingStateHandler(state, action);
    },
    [String(editFirstLevelStatus.rejected)]: (state, action) => {
      state.firstLevelEnabled.loading = false;
      storeStateHandler(state, action);
    },
    [String(editFirstLevelStatus.fulfilled)]: (state, action) => {
      const index = state.firstLevel.data.findIndex((elem) => elem._id === action.meta?.arg?.id);
      if (index > -1) state.firstLevel.data[index].isActive = action.meta?.arg?.enabling;
      state.firstLevelEnabled.loading = false;
      state.successMsg = i18n.t(
        `localities.upperLevel.active.${action.meta?.arg?.enabling ? 'false' : 'true'}.successMsg`
      );
      storeStateHandler(state, action);
    },

    [String(deactivateSecondLevel.pending)]: (state, action) => {
      state.deactivateSecondLevel.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [String(deactivateSecondLevel.rejected)]: (state, action) => {
      state.deactivateSecondLevel.loading = false;
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.currentRequestId = undefined;
      }
    },
    [String(deactivateSecondLevel.fulfilled)]: (state, action) => {
      state.deactivateSecondLevel.loading = false;
      state.deactivateSecondLevel.showAll = false;
      if (!action.meta?.arg?.localities[0]?.isActive)
        state.deactivateSecondLevel.message =
          action.meta?.arg?.localities?.length <
          state.secondLevel.data.filter((obj) => obj.isActive).length
            ? i18n.t('localities.toggleActiveSecondLevel.deactivatedMsg', {
                count: action.meta?.arg?.localities?.length,
              })
            : i18n.t('localities.toggleActiveSecondLevel.deactivatedAllMsg');
      else
        state.deactivateSecondLevel.message = i18n.t(
          'localities.toggleActiveSecondLevel.activatedMsg',
          {
            count: action.meta?.arg?.localities?.length,
          }
        );
      state.deactivateSecondLevel.localities = action.meta?.arg?.localities || [];
      updateSecondLevelItems(state, {
        localities: action.meta?.arg?.localities?.map((item) => ({ ...item, selected: false })),
      });
      const { requestId } = action.meta;
      if (state.currentRequestId === requestId) {
        state.currentRequestId = undefined;
      }
    },
  },
});

export const {
  updateSecondLevelItemsAction,
  clearSuccessMsg,
  clearCommonActionSuccessMsg,
  cleanSecondLevelData,
  toggleDeactivatedShowAll,
  clearDeactivatedSecondLevel,
} = locationsSlice.actions;
export default locationsSlice.reducer;
