import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import i18n from 'i18next';
import server from '@services/api';

import {
  skillRoute,
  skillsRoute,
  skillExistsRoute,
  getSkillsCountSummaryRoute,
  putUserSkillsRoute,
  postFinalizeCourseInSkills,
  finalizeSkillInProgressRoute,
  recalculateSkillFinalizedRoute,
  postRemoveCourseInSkills,
} from '@features/skills/ts/routes';
import { RequestStatusEnum } from '@utils/enum';
import {
  CreateSkillPayload,
  GetSkillsPayload,
  GetIfExistsPayload,
  GetSkillPayload,
  EditSkillPayload,
  GetSkillsForJobPayload,
  PutUserSkills,
  PostCourseInSkill,
  SkillOnSiteCourses,
} from './ts/skills.interfaces';
import { sendLog } from '@utils/SentryError';
import { setApplicantSkills } from '@features/applicants/applicantsSlice';

export const skillsAdapter = createEntityAdapter();

export const initialState = skillsAdapter.getInitialState({
  skills: { docs: [], loading: false },
  skillCreation: {
    status: RequestStatusEnum.IDLE,
    existForJobType: undefined,
    existForJobTypeStatus: RequestStatusEnum.IDLE,
    existForJobTypeAndEnterprise: undefined,
    existForJobTypeAndEnterpriseStatus: RequestStatusEnum.IDLE,
    existForJobTypeWithEnterpriseAndStore: undefined,
    existForJobTypeWithEnterpriseAndStoreStatus: RequestStatusEnum.IDLE,
  },
  skillSummary: { loading: false, data: {} },
  editSkill: { loading: false, status: RequestStatusEnum.IDLE, data: {} },
  deleteSkill: { loading: false, status: RequestStatusEnum.IDLE },
  skillsCounter: { loading: false, isEnabled: 0, notEnabled: 0 },
  skillsForJob: { docs: [], loading: false },
  userSkills: { status: RequestStatusEnum.IDLE },
  alertMessages: { type: undefined, text: '' },
  createSkillStatus: RequestStatusEnum.IDLE,
  error: null,
});

export const getSkillsForJob = createAsyncThunk(
  'skills/getSkillsForJob',
  async (payload: GetSkillsForJobPayload) => {
    const response = await server.get(skillsRoute(), { params: payload });
    return response.data;
  }
);

export const getSkills = createAsyncThunk('skills/getSkills', async (payload: GetSkillsPayload) => {
  const response = await server.get(skillsRoute(), { params: payload });
  return response.data;
});

export const postSkill = createAsyncThunk(
  'skills/postSkill',
  async (payload: { skill: CreateSkillPayload; shifts?: Array<SkillOnSiteCourses> }) => {
    const response = await server.post(skillsRoute(), payload);
    return response.data;
  }
);

export const deleteSkill = createAsyncThunk(
  'skills/deleteSkill',
  async (params: { id: string; name: string }) => {
    const response = await server.delete(skillRoute(params.id));
    return response.data;
  }
);

export const getSkill = createAsyncThunk('skills/getSkill', async (payload: GetSkillPayload) => {
  const { id } = payload;
  const response = await server.get(skillRoute(id));
  return response.data;
});

export const putSkill = createAsyncThunk('skills/putSkill', async (payload: EditSkillPayload) => {
  const { _id } = payload;
  const response = await server.put(skillRoute(_id), payload);
  return response.data;
});

export const getIfSkillExists = createAsyncThunk(
  'skills/getIfSkillExists',
  async (payload: GetIfExistsPayload) => {
    const response = await server.get(skillExistsRoute(), { params: payload });
    return response.data;
  }
);

export const getSkillsSummaryCounter = createAsyncThunk(
  'skills/getSkillsSummaryCounter',
  async () => {
    const response = await server.get(getSkillsCountSummaryRoute());
    return response.data;
  }
);

export const putUserSkills = createAsyncThunk(
  'skills/putUserSkills',
  async (payload: PutUserSkills, { dispatch }) => {
    const { skills, userId, ...payloadAux } = payload;
    const response = await server.put(putUserSkillsRoute(userId), payloadAux);
    if (response?.data?.data) dispatch(setApplicantSkills(skills));
    return response.data;
  }
);

export const postFinishedCourse = createAsyncThunk(
  'skills/postFinishedCourse',
  async (payload: PostCourseInSkill) => {
    const { _id, courses } = payload;
    const response = await server.post(postFinalizeCourseInSkills(_id), { courses });
    return response?.data;
  }
);

export const postRemoveCourse = createAsyncThunk(
  'skills/postRemoveCourse',
  async (payload: PostCourseInSkill) => {
    const { _id, courses } = payload;
    const response = await server.post(postRemoveCourseInSkills(_id), { courses });
    return response?.data;
  }
);

export const finalizeSkill = createAsyncThunk('skills/finalizeSkill', async (payload: any) => {
  const { _id, finalizedInProgressSkill } = payload;
  const response = await server.put(finalizeSkillInProgressRoute(_id), finalizedInProgressSkill);
  return response;
});

export const recalculateSkill = createAsyncThunk(
  'skills/recalculateSkill',
  async (payload: any) => {
    const { _id, finalizedInProgressSkill } = payload;
    const response = await server.put(
      recalculateSkillFinalizedRoute(_id),
      finalizedInProgressSkill
    );
    return response;
  }
);

const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    clearAlertMsg: (state) => {
      state.alertMessages = { type: undefined, text: '' };
    },
    clearState: (state, b: { payload: { key: string; subkey?: string; base?: any } }) => {
      const { key, subkey, base } = b.payload ?? {};
      const originalState = base ?? 'idle';
      if (subkey && state[key]?.[subkey]) state[key][subkey] = originalState;
      else if (key && state[key]) state[key] = originalState;
    },
    clearSkillCreationExists: (state) => {
      state.skillCreation = {
        ...state.skillCreation,
        existForJobType: undefined,
        existForJobTypeAndEnterprise: undefined,
        existForJobTypeWithEnterpriseAndStore: undefined,
        existForJobTypeStatus: RequestStatusEnum.IDLE,
        existForJobTypeAndEnterpriseStatus: RequestStatusEnum.IDLE,
        existForJobTypeWithEnterpriseAndStoreStatus: RequestStatusEnum.IDLE,
      };
    },
  },
  extraReducers: {
    [String(getSkillsForJob.pending)]: (state) => {
      state.skillsForJob.loading = true;
    },
    [String(getSkillsForJob.fulfilled)]: (state, action) => {
      state.skillsForJob = { loading: false, ...action.payload.data };
    },
    [String(getSkillsForJob.rejected)]: (state, action) => {
      state.skillsForJob.loading = false;
      state.error = action.error;
    },

    [String(getSkills.pending)]: (state) => {
      state.skills.loading = true;
    },
    [String(getSkills.fulfilled)]: (state, action) => {
      state.skills = { loading: false, ...action.payload.data };
    },
    [String(getSkills.rejected)]: (state, action) => {
      state.skills.loading = false;
      state.error = action.error;
    },

    [String(postSkill.pending)]: (state) => {
      state.skillCreation.status = RequestStatusEnum.PENDING;
    },
    [String(postSkill.fulfilled)]: (state) => {
      state.skillCreation.status = RequestStatusEnum.SUCCESS;
      state.alertMessages = {
        text: i18n.t('skills.main.actionsDrawer.new.successMessage'),
        type: 'success',
      };
    },
    [String(postSkill.rejected)]: (state, action) => {
      state.skillCreation.status = RequestStatusEnum.ERROR;
      state.error = action.error;
      sendLog('Store - Skills', action?.meta?.arg, 'Fatal', action.error, 'BO-SKILLS_SLICE-84');
    },

    [String(deleteSkill.pending)]: (state) => {
      state.deleteSkill.status = RequestStatusEnum.PENDING;
    },
    [String(deleteSkill.fulfilled)]: (state, action) => {
      state.deleteSkill.status = RequestStatusEnum.SUCCESS;
      state.alertMessages = {
        text: i18n.t('skills.main.delete.modal.success', {
          name: action?.meta?.arg?.name || ' - ',
        }),
        type: 'success',
      };
    },
    [String(deleteSkill.rejected)]: (state, action) => {
      state.deleteSkill.status = RequestStatusEnum.ERROR;
      state.error = action.error;
      state.alertMessages = {
        text: i18n.t('skills.main.delete.modal.error'),
        type: 'error',
      };
      sendLog('Store - Skills', action?.meta?.arg, 'Critical', action.error, 'BO-SKILLS_SLICE-137');
    },

    [String(getSkill.pending)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'editSkill' : 'skillSummary';
      state[field].loading = true;
    },
    [String(getSkill.fulfilled)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'editSkill' : 'skillSummary';
      state[field].loading = false;
      state[field].data = action.payload.data;
    },
    [String(getSkill.rejected)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'editSkill' : 'skillSummary';
      state[field].loading = false;
      state.error = action.error;
      sendLog('Store - Skills', action?.meta?.arg, 'fatal', action.error, 'BO-SKILLS_SLICE-142');
    },

    [String(putSkill.pending)]: (state) => {
      state.editSkill.status = RequestStatusEnum.PENDING;
    },
    [String(putSkill.fulfilled)]: (state) => {
      state.editSkill.status = RequestStatusEnum.SUCCESS;
      state.alertMessages = {
        text: i18n.t('skills.main.actionsDrawer.edit.successMessage'),
        type: 'success',
      };
    },
    [String(putSkill.rejected)]: (state, action) => {
      state.editSkill.status = RequestStatusEnum.ERROR;
      state.error = action.error;
      sendLog('Store - Skills', action?.meta?.arg, 'fatal', action.error, 'BO-SKILLS_SLICE-157');
    },

    [String(getIfSkillExists.pending)]: (state, action) => {
      const { enterprise, store } = action.meta?.arg ?? {};
      if (!enterprise) {
        state.skillCreation.existForJobTypeStatus = RequestStatusEnum.PENDING;
      } else if (enterprise && !store) {
        state.skillCreation.existForJobTypeAndEnterpriseStatus = RequestStatusEnum.PENDING;
      } else {
        state.skillCreation.existForJobTypeWithEnterpriseAndStoreStatus = RequestStatusEnum.PENDING;
      }
    },
    [String(getIfSkillExists.fulfilled)]: (state, action) => {
      const { enterprise, store } = action.meta?.arg ?? {};

      if (!enterprise) {
        state.skillCreation.existForJobType = action.payload.data;
        state.skillCreation.existForJobTypeStatus = RequestStatusEnum.IDLE;
      } else if (enterprise && !store) {
        state.skillCreation.existForJobTypeAndEnterprise = action.payload.data;
        state.skillCreation.existForJobTypeAndEnterpriseStatus = RequestStatusEnum.IDLE;
        state.skillCreation.existForJobTypeWithEnterpriseAndStore = undefined;
      } else {
        state.skillCreation.existForJobTypeWithEnterpriseAndStore = action.payload.data;
        state.skillCreation.existForJobTypeWithEnterpriseAndStoreStatus = RequestStatusEnum.IDLE;
      }
    },
    [String(getIfSkillExists.rejected)]: (state, action) => {
      const { enterprise, store } = action.meta?.arg ?? {};
      if (!enterprise) {
        state.skillCreation.existForJobTypeStatus = RequestStatusEnum.ERROR;
      } else if (enterprise && !store) {
        state.skillCreation.existForJobTypeAndEnterpriseStatus = RequestStatusEnum.ERROR;
      } else {
        state.skillCreation.existForJobTypeWithEnterpriseAndStoreStatus = RequestStatusEnum.ERROR;
      }
      state.error = action.error;
    },

    [String(getSkillsSummaryCounter.pending)]: (state) => {
      state.skillsCounter.loading = true;
    },
    [String(getSkillsSummaryCounter.fulfilled)]: (state, action) => {
      state.skillsCounter = { loading: false, ...action.payload.data };
    },
    [String(getSkillsSummaryCounter.rejected)]: (state, action) => {
      state.skillsCounter.loading = false;
      state.error = action.error;
    },

    [String(putUserSkills.pending)]: (state) => {
      state.userSkills.status = RequestStatusEnum.PENDING;
    },
    [String(putUserSkills.fulfilled)]: (state) => {
      state.userSkills.status = RequestStatusEnum.SUCCESS;
    },
    [String(putUserSkills.rejected)]: (state, action) => {
      state.userSkills.status = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(postFinishedCourse.pending)]: (state) => {
      state.userSkills.status = RequestStatusEnum.PENDING;
    },
    [String(postFinishedCourse.fulfilled)]: (state) => {
      state.userSkills.status = RequestStatusEnum.SUCCESS;
      state.alertMessages = {
        text: i18n.t('skills.main.actionsDrawer.saveSkillCourses.successMessage'),
        type: 'success',
      };
    },
    [String(postFinishedCourse.rejected)]: (state, action) => {
      state.userSkills.status = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(postRemoveCourse.pending)]: (state) => {
      state.userSkills.status = RequestStatusEnum.PENDING;
    },
    [String(postRemoveCourse.fulfilled)]: (state) => {
      state.userSkills.status = RequestStatusEnum.SUCCESS;
      state.alertMessages = {
        text: i18n.t('skills.main.actionsDrawer.saveSkillCourses.successMessageRemove'),
        type: 'success',
      };
    },
    [String(postRemoveCourse.rejected)]: (state, action) => {
      state.userSkills.status = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(finalizeSkill.pending)]: (state) => {
      state.userSkills.status = RequestStatusEnum.PENDING;
    },
    [String(finalizeSkill.fulfilled)]: (state) => {
      state.userSkills.status = RequestStatusEnum.SUCCESS;
    },
    [String(finalizeSkill.rejected)]: (state, action) => {
      state.userSkills.status = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(recalculateSkill.pending)]: (state) => {
      state.userSkills.status = RequestStatusEnum.PENDING;
    },
    [String(recalculateSkill.fulfilled)]: (state) => {
      state.userSkills.status = RequestStatusEnum.SUCCESS;
    },
    [String(recalculateSkill.rejected)]: (state, action) => {
      state.userSkills.status = RequestStatusEnum.ERROR;
      state.error = action.error;
    },
  },
});

export const { clearAlertMsg, clearState, clearSkillCreationExists } = skillsSlice.actions;
export default skillsSlice.reducer;
