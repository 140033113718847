// Enterprises
export const fetchEnterprisesRoute = (): string => '/business/enterprises';
export const fetchEnterpriseRoute = (id: string): string => `/business/enterprises/${id}`;
export const updateEnterpriseIncentiveRoute = (enterpriseId: string): string =>
  `/business/enterprises/${enterpriseId}/incentive`;

// Stores
export const getStoresRoute = (): string => '/business/stores';
export const getStoreRoute = (id: string): string => `/business/stores/${id}`;
export const getEnterprisesRoute = (): string => '/business/enterprises';
export const getStoresShopperRoute = (): string => '/business/stores/shopper';
export const createStoreIncentiveRoute = (storeId: string): string =>
  `/business/stores/${storeId}/incentive`;
export const updateStoreIncentiveRoute = (storeId: string, incentiveId: string): string =>
  `/business/stores/${storeId}/incentive/${incentiveId}`;
export const updateStoreIncentiveStatusRoute = (storeId: string, incentiveId: string): string =>
  `/business/stores/${storeId}/incentive/${incentiveId}`;
export const blockStoreIncentiveRoute = (storeId: string): string =>
  `/business/stores/${storeId}/incentive-global`;
export const existsRappiIdRoute = (): string => '/business/stores/rappi-id-exists';
