import { GetJobbersFilters, GetApplicantsFiltersInterfaces } from './applicants.interfaces';

export const getJobbers = (filters: GetJobbersFilters): string => {
  let endpoint = '/account/jobbers';
  if (Object.keys(filters).some((item) => typeof filters[item] !== 'undefined')) endpoint += '?';
  Object.keys(filters).forEach((item) => {
    if (typeof filters[item] !== 'undefined') {
      if (item === 'locality') endpoint += filters[item].map((obj) => `${item}=${obj}&`).join('');
      else endpoint += `${item}=${filters[item]}&`;
    }
  });
  return endpoint.slice(0, -1);
};

export const recordsRoute = (): string => 'account/jobbers/records';

export const putRecordsReviewRoute = (jobberId: string): string =>
  `account/jobbers/${jobberId}/records/review`;

export const getApplicantsRoute = (): string => '/account/jobbers';

export const getSearchRoute = ({
  limit,
  next,
  previous,
  pendingBy,
  wildcard,
  role,
  isBlocked,
  historyWildcard,
  isRejected,
  skillIds,
  sortAscending,
}: GetApplicantsFiltersInterfaces): string =>
  getJobbers({
    limit,
    next,
    previous,
    pendingBy,
    role,
    wildcard,
    isBlocked,
    historyWildcard,
    isRejected,
    skillIds,
    sortAscending,
  });

export const putOtherDocsReviewRoute = (doc: string, jobberId: string): string =>
  `account/jobbers/${jobberId}/other-documents/review?code=${doc}`;

export const putFrameworkAgreementReviewRoute = (jobberId: string): string =>
  `account/jobbers/${jobberId}/framework-agreement/review`;

export const logBooksRoute = (jobberId: string): string => `account/jobbers/${jobberId}/log-books`;

export const assignmentRoute = (): string => `account/jobbers/assign-profiles`;

export const getCheckEmailRoute = (): string => 'account/jobbers/email-exists';

export const getCheckRappiIdRoute = (): string => 'account/jobbers/rappi-id-exists';

export const editUserRoute = (id: string): string => `account/jobbers/${id}/partial-personal-data`;

export const editJobberRappiIdRoute = (id: string): string => `account/jobbers/${id}/rappi-id`;

export const putSpeaksSpanishRoute = (id: string): string => `/account/jobbers/${id}/language`;

export const putAdditionalInformationReviewRoute = (
  id: string,
  doc: string,
  category: string
): string => {
  if (!category) return `/account/jobbers/${id}/additional-documents/review?code=${doc}`;
  return `/account/jobbers/${id}/additional-documents/review?code=${doc}&category=${category}`;
};

export const requirementsRoute = (_id: string): string => `/account/jobbers/${_id}/specifics`;

export const jobbersReportRoute = (): string => '/reports/jobbers/jobbers-info/full_data';

export const metricsReportRoute = (): string => '/reports/jobbers/metrics';

export const deleteApplicantUserRoute = (id: string): string => `/account/jobbers/${id}`;

export const getJobbersContractRoute = (): string => `/account/contracts`;

export const finishContractRoute = (contractId: string): string =>
  `/account/contracts/terminate/${contractId}`;

export const reactivateContractRoute = (contractId: string): string =>
  `/account/contracts/${contractId}/reactivation`;

export const getFinishContractClausesRoute = (): string => `/account/contracts/clauses`;
