import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import {
  blockJobberRoute,
  blockListReportRoute,
  deleteBlocklistRoute,
  getBlocklistRoute,
  postBlocklistRoute,
  putBlocklistGeneralRoute,
  putBlocklistRoute,
  unblockJobberRoute,
} from '@features/blocklist/ts/routes';

import server from '@services/api';
import {
  BlockedInfo,
  DeleteInfo,
  getBlocklistPayload,
} from '@features/blocklist/ts/blocklist.interface';
import { RequestStatusEnum } from '../../utils/enum';

export const blockListAdapter = createEntityAdapter();

const initialState = blockListAdapter.getInitialState({
  blocklist: { docs: [] as [], loading: false },
  error: null,
  blockListReport: RequestStatusEnum.IDLE,
});

export const getBlocklist = createAsyncThunk(
  'blocklist/getBlocklist',
  async (payload: getBlocklistPayload) => {
    const response = await server.get(getBlocklistRoute(), { params: { ...payload } });
    return response.data.data;
  }
);

export const postBlocklist = createAsyncThunk(
  'blocklist/postBlocklist',
  async (payload: getBlocklistPayload) => {
    const response = await server.post(postBlocklistRoute(payload._id), payload);
    return response;
  }
);

export const putBlocklist = createAsyncThunk(
  'blocklist/putBloclist',
  async ({
    _id,
    restrictionId,
    description,
    reason,
    evidence,
    url,
    restriction,
    viewJobs,
  }: any) => {
    const response = await server.put(putBlocklistRoute(_id, restrictionId), {
      description,
      reason,
      evidence,
      url,
      restriction,
      viewJobs,
    });
    return response;
  }
);

export const deleteBlocklist = createAsyncThunk(
  'blocklist/deleteBlocklist',
  async ({ _id, blockId }: DeleteInfo) => {
    const response = await server.delete(deleteBlocklistRoute(_id, blockId));
    return response;
  }
);

export const blockGeneral = createAsyncThunk(
  'applicants/blockJobber',
  async ({ _id, reason, restrictionKind, description, evidence }: BlockedInfo) => {
    const response = await server.put(blockJobberRoute(_id), {
      reason,
      restrictionKind,
      description,
      evidence,
    });
    return response.data;
  }
);

export const unBlockGeneral = createAsyncThunk('applicants/unblockJobber', async (_id: string) => {
  const response = await server.put(unblockJobberRoute(_id));
  return response;
});

export const putBlocklistGeneral = createAsyncThunk(
  'applicats/putBlocklistGeneral',
  async ({ _id, blockId, reason, description, evidence }: any) => {
    const response = await server.put(putBlocklistGeneralRoute(_id, blockId), {
      reason,
      description,
      evidence,
    });
    return response;
  }
);

export const getBlockListReport = createAsyncThunk(
  'applicants/getBlockListReport',
  async (params: getBlocklistPayload) => {
    const response = await server({
      method: 'get',
      url: blockListReportRoute(),
      params,
    });
    return response;
  }
);

const blocklistSlice = createSlice({
  name: 'blocklist',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getBlocklist.pending)]: (state) => {
      state.blocklist.loading = true;
    },
    [String(getBlocklist.fulfilled)]: (state, action) => {
      state.blocklist = { loading: false, ...action.payload };
    },
    [String(getBlocklist.rejected)]: (state, action) => {
      state.blocklist.loading = false;
      state.error = action.error;
    },

    [String(postBlocklist.pending)]: (state) => {
      state.blocklist.loading = true;
    },
    [String(postBlocklist.fulfilled)]: (state) => {
      state.blocklist.loading = false;
    },
    [String(postBlocklist.rejected)]: (state, action) => {
      state.blocklist.loading = false;
      state.error = action.error;
    },

    [String(putBlocklist.pending)]: (state) => {
      state.blocklist.loading = true;
    },
    [String(putBlocklist.fulfilled)]: (state) => {
      state.blocklist.loading = false;
    },
    [String(putBlocklist.rejected)]: (state, action) => {
      state.blocklist.loading = false;
      state.error = action.error;
    },

    [String(deleteBlocklist.pending)]: (state) => {
      state.blocklist.loading = true;
    },
    [String(deleteBlocklist.fulfilled)]: (state) => {
      state.blocklist.loading = false;
    },
    [String(deleteBlocklist.rejected)]: (state, action) => {
      state.blocklist.loading = false;
      state.error = action.error;
    },

    [String(blockGeneral.pending)]: (state) => {
      state.blocklist.loading = true;
    },
    [String(blockGeneral.fulfilled)]: (state) => {
      state.blocklist.loading = false;
    },
    [String(blockGeneral.rejected)]: (state, action) => {
      state.blocklist.loading = false;
      state.error = action.error;
    },

    [String(unBlockGeneral.pending)]: (state) => {
      state.blocklist.loading = true;
    },
    [String(unBlockGeneral.fulfilled)]: (state) => {
      state.blocklist.loading = false;
    },
    [String(unBlockGeneral.rejected)]: (state, action) => {
      state.blocklist.loading = false;
      state.error = action.error;
    },

    [String(putBlocklistGeneral.pending)]: (state) => {
      state.blocklist.loading = true;
    },
    [String(putBlocklistGeneral.fulfilled)]: (state) => {
      state.blocklist.loading = false;
    },
    [String(putBlocklistGeneral.rejected)]: (state, action) => {
      state.blocklist.loading = false;
      state.error = action.error;
    },

    [String(getBlockListReport.pending)]: (state) => {
      state.blockListReport = RequestStatusEnum.PENDING;
    },
    [String(getBlockListReport.fulfilled)]: (state) => {
      state.blockListReport = RequestStatusEnum.SUCCESS;
    },
    [String(getBlockListReport.rejected)]: (state, action) => {
      state.blockListReport = RequestStatusEnum.ERROR;
      state.error = action.error;
    },
  },
});

export default blocklistSlice.reducer;
