import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import server from '@services/api';
import { RequestStatusEnum } from '../../utils/enum';
import { IResponseData } from './ts/delivery.interfaces';
import {
  assignPickerToShopOrderRoute,
  calculateKmsRoute,
  changeStoreShopOrderRoute,
  closePickerOrderRoute,
  closeShopperOrderRoute,
  createSMUShopOrderRoute,
  fetchShopperOrdersRoute,
  fixFaliedCreationRoute,
  getBeetrackOrderRoute,
  getDeliveryVehiclesRoute,
  overweightCloneShopOrderRoute,
  restoreShopOrderRoute,
  updatePayCompleteOrderRoute,
  updateShopOrderKilometerRoute,
  updateStatusShopperOrderRoute,
} from './ts/routes';
import i18n from 'i18next';

export const deliveryAdapter = createEntityAdapter();

export const initialState: any = deliveryAdapter.getInitialState({
  shopperOrders: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  shopperOrder: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  updateShopOrderStatusSubmit: {
    loading: RequestStatusEnum.IDLE,
    success: false,
    error: false,
    type: '',
    description: '',
  },
  showImages: {
    open: false,
    images: [],
  },
  closeShopperOrder: {
    message: '',
    error: '',
    loading: RequestStatusEnum.IDLE,
  },
  faliedCreation: {
    loading: false,
    success: false,
    error: false,
    msgVisible: false,
  },
  updateKilometer: {
    loading: false,
    success: false,
    error: false,
    msgVisible: false,
    accept: false,
  },
  createShopOrder: {
    loading: false,
    success: false,
    error: false,
    msgVisible: false,
    response: {},
  },
  restoreOrderState: {
    loading: false,
    type: '',
    msgVisible: false,
  },
  changeStoreState: {
    loading: false,
    state: '',
    msgVisible: false,
  },
  jobberVehicles: {
    loading: RequestStatusEnum.IDLE,
    docs: [],
  },
  beetrackOrder: {
    loading: RequestStatusEnum.IDLE,
    dataOrder: null,
    substatus: null,
    substatus_code: null,
    requestState: null,
  },
  assignPicker: {
    loading: RequestStatusEnum.IDLE,
    type: null, // success | error
    message: '',
  },
  closePickerOrder: {
    loading: RequestStatusEnum.IDLE,
    type: null, // success | error
  },
  validateKMS: {
    status: RequestStatusEnum.IDLE,
  },
  overweightState: {
    loading: RequestStatusEnum.IDLE,
    message: '',
    type: null,
  },
  loading: RequestStatusEnum.IDLE,
  updatePayCompleteOrder: {
    loading: false,
    error: false,
  },
});

export const getShopperOrders = createAsyncThunk(
  'delivery/getShopperOrders',
  async (params: any, { getState }: any) => {
    const { shopperOrders } = getState().delivery;

    if (shopperOrders?.loading !== RequestStatusEnum.PENDING) return;

    const response: IResponseData = await server({
      method: 'get',
      url: fetchShopperOrdersRoute(),
      params,
    });

    return response.data;
  }
);

export const getSingleShopperOrder = createAsyncThunk(
  'delivery/getSingleShopperOrder',
  async (params: any, { getState }: any) => {
    const { shopperOrder } = getState().delivery;

    if (shopperOrder?.loading !== RequestStatusEnum.PENDING) return;

    const response: IResponseData = await server({
      method: 'get',
      url: fetchShopperOrdersRoute(),
      params,
    });

    return response.data;
  }
);

export const updateShopOrderStatus = createAsyncThunk(
  'delivery/updateShopOrderStatus',
  async (payload: Record<string, string | Record<string, string>>, { rejectWithValue }: any) => {
    try {
      const response = await server.put(
        updateStatusShopperOrderRoute(payload.status),
        payload.body
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const closeShopperOrder = createAsyncThunk(
  'delivery/closeShopperOrder',
  async (body: any, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId, closeShopperOrder } = getState().delivery;

      if (
        closeShopperOrder?.loading !== RequestStatusEnum.PENDING ||
        requestId !== currentRequestId
      )
        return;

      const response: IResponseData = await server({
        method: 'put',
        url: closeShopperOrderRoute(body._id),
        data: body,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const fixFaliedCreation = createAsyncThunk(
  'delivery/fixFaliedCreation',
  async (payload: any, { rejectWithValue }: any) => {
    try {
      const response = await server.put(fixFaliedCreationRoute(payload._id), payload.body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateShopOrderKilometer = createAsyncThunk(
  'delivery/updateShopOrderKilometer',
  async (payload: any, { rejectWithValue }: any) => {
    try {
      const response = await server.put(updateShopOrderKilometerRoute(payload._id), payload.body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createManualShopperOrder = createAsyncThunk(
  'delivery/createManualShopperOrder',
  async (payload: object, { rejectWithValue }: any) => {
    try {
      const response = await server.post(createSMUShopOrderRoute(), payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const restoreShopOrder = createAsyncThunk(
  'delivery/restoreShopOrder',
  async (payload: object, { rejectWithValue }: any) => {
    try {
      const response = await server.put(restoreShopOrderRoute(), payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const changeStoreShopOrder = createAsyncThunk(
  'delivery/changeStoreShopOrder',
  async (payload: { store: string; code: string }, { rejectWithValue }: any) => {
    try {
      const response = await server.put(changeStoreShopOrderRoute(payload.code), {
        store: payload.store,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getDeliveryVehicles = createAsyncThunk(
  'delivery/getDeliveryVehicles',
  async (params: Record<string, string>, { getState }: any) => {
    const { jobberVehicles } = getState().delivery;
    if (jobberVehicles?.loading !== RequestStatusEnum.PENDING) return;
    const response: IResponseData = await server({
      method: 'get',
      url: getDeliveryVehiclesRoute(),
      params,
    });

    return response.data;
  }
);

export const getBeetrackOrder = createAsyncThunk(
  'delivery/getBeetrackOrder',
  async (params: Record<string, string>, { getState }: any) => {
    const { beetrackOrder } = getState().delivery;
    if (beetrackOrder?.loading !== RequestStatusEnum.PENDING) return;

    const response = await server({
      method: 'get',
      url: getBeetrackOrderRoute(),
      params,
    });

    const orderSearch = await server({
      method: 'get',
      url: fetchShopperOrdersRoute(),
      params: { code: params.identifier },
    });

    return {
      beetrack: response?.data?.data,
      backoffice: orderSearch?.data?.data,
    };
  }
);

export const assignPickerToShopOrder = createAsyncThunk(
  'delivery/assignPickerToShopOrder',
  async (body: Record<string, any>, { rejectWithValue }: any) => {
    try {
      const response = await server.post(assignPickerToShopOrderRoute(), body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const closePickerOrder = createAsyncThunk(
  'delivery/closePickerOrder',
  async (body: Record<string, any>, { rejectWithValue }: any) => {
    try {
      const response = await server.put(closePickerOrderRoute(body.shopperOrder), body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const calculateKmsOnCloseOrder = createAsyncThunk(
  'delivery/calculateKmsOnCloseOrder',
  async (shopperOrderId: string, { rejectWithValue }: any) => {
    try {
      const response = await server.put(calculateKmsRoute(shopperOrderId));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const overweightCloneShopOrder = createAsyncThunk(
  'delivery/overweightCloneShopOrder',
  async (payload: object, { rejectWithValue }: any) => {
    try {
      const response = await server.post(overweightCloneShopOrderRoute(), payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updatePayCompleteOrder = createAsyncThunk(
  'delivery/updatePayCompleteOrder',
  async (payload: { shopperOrder: string; payComplete: boolean }, { rejectWithValue }: any) => {
    try {
      const response = await server.put(updatePayCompleteOrderRoute(payload.shopperOrder), {
        payComplete: payload.payComplete,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    clearField: (state, b) => {
      const { field } = b.payload ?? {};
      state[field] = {
        message: '',
        loading: RequestStatusEnum.IDLE,
      };
    },
    resetShopOrderStatus: (state) => {
      state.updateShopOrderStatusSubmit.loading = RequestStatusEnum.IDLE;
      state.updateShopOrderStatusSubmit.success = false;
      state.updateShopOrderStatusSubmit.error = false;
      state.updateShopOrderStatusSubmit.type = '';
      state.updateShopOrderStatusSubmit.description = '';
    },
    toggleShowImages: (state, action) => {
      state.showImages.open = !state.showImages.open;
      state.showImages.images = action.payload;
    },
    resetFailedCreation: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.faliedCreation.loading = false;
      state.faliedCreation.success = false;
      state.faliedCreation.error = false;
      state.faliedCreation.msgVisible = false;
    },
    resetUpdateKilometer: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.updateKilometer.loading = false;
      state.updateKilometer.success = false;
      state.updateKilometer.error = false;
      state.updateKilometer.msgVisible = false;
      state.updateKilometer.accept = false;
    },
    resetCreateShopOrder: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.createShopOrder.loading = false;
      state.createShopOrder.success = false;
      state.createShopOrder.error = false;
      state.createShopOrder.msgVisible = false;
      state.createShopOrder.response = {};
    },
    resetRestoreOrder: (state) => {
      state.restoreOrderState.loading = false;
      state.restoreOrderState.type = '';
      state.restoreOrderState.msgVisible = false;
    },
    resetChangeStoreState: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.changeStoreState.loading = false;
      state.changeStoreState.state = '';
      state.changeStoreState.msgVisible = false;
    },
    clearBeetrackOrder: (state) => {
      state.beetrackOrder = {
        loading: RequestStatusEnum.IDLE,
        dataOrder: null,
        substatus: null,
        substatus_code: null,
        requestState: null,
      };
      state.shopperOrder = {
        loading: RequestStatusEnum.IDLE,
        refresh: false,
        docs: [],
        previous: null,
        next: null,
        totalDocs: null,
      };
    },
    resetAssignPickerState: (state) => {
      state.assignPicker = { loading: RequestStatusEnum.IDLE, type: null, message: '' };
    },
    resetValidateKMS: (state) => {
      state.validateKMS.status = RequestStatusEnum.IDLE;
    },
    resetOverweightState: (state) => {
      state.overweightState = {
        loading: RequestStatusEnum.IDLE,
        message: '',
        type: null,
      };
    },
  },
  extraReducers: {
    [String(getShopperOrders.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.shopperOrders.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(getShopperOrders.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.shopperOrders = { loading: RequestStatusEnum.IDLE, ...action.payload.data };
      state.currentRequestId = undefined;
    },
    [String(getShopperOrders.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.shopperOrders.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(getSingleShopperOrder.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.shopperOrder.loading = RequestStatusEnum.PENDING; // asbdjhadjsadasd
      state.currentRequestId = action.meta.requestId;
    },
    [String(getSingleShopperOrder.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.shopperOrder = { loading: RequestStatusEnum.IDLE, ...action.payload.data };
      state.currentRequestId = undefined;
    },
    [String(getSingleShopperOrder.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.shopperOrder.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    // UPDATE SHOP ORDER STATUS
    [String(updateShopOrderStatus.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.updateShopOrderStatusSubmit.loading = RequestStatusEnum.PENDING;
      state.updateShopOrderStatusSubmit.type = action.meta.arg.type;
      state.updateShopOrderStatusSubmit.description = action.meta.arg.description;
      state.updateShopOrderStatusSubmit.success = false;
      state.updateShopOrderStatusSubmit.error = false;
    },
    [String(updateShopOrderStatus.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.updateShopOrderStatusSubmit.loading = RequestStatusEnum.SUCCESS;
      state.updateShopOrderStatusSubmit.success = true;
      state.updateShopOrderStatusSubmit.error = false;
    },
    [String(updateShopOrderStatus.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.updateShopOrderStatusSubmit.loading = RequestStatusEnum.SUCCESS;
      state.updateShopOrderStatusSubmit.success = false;
      state.updateShopOrderStatusSubmit.error = true;
    },

    // CLOSE SHOPPER ORDER
    [String(closeShopperOrder.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.closeShopperOrder.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.closeShopperOrder.error = '';
      }
    },
    [String(closeShopperOrder.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.closeShopperOrder = {
          message: 'Cierre de orden realizado con éxito.',
          loading: RequestStatusEnum.SUCCESS,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(closeShopperOrder.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.closeShopperOrder = {
          error: action?.payload?.status,
          loading: RequestStatusEnum.IDLE,
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    // FIX FAILED CREATION - SHOPPER ORDER
    [String(fixFaliedCreation.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.faliedCreation.loading = true;
      state.faliedCreation.success = false;
      state.faliedCreation.error = false;
      state.faliedCreation.msgVisible = false;
    },
    [String(fixFaliedCreation.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.SUCCESS;
      state.faliedCreation.loading = false;
      state.faliedCreation.success = true;
      state.faliedCreation.error = false;
      state.faliedCreation.msgVisible = true;
    },
    [String(fixFaliedCreation.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.ERROR;
      state.faliedCreation.loading = false;
      state.faliedCreation.success = false;
      state.faliedCreation.error = true;
      state.faliedCreation.msgVisible = true;
    },

    // UPDATE KILOMETERS - SHOPPER ORDER
    [String(updateShopOrderKilometer.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.updateKilometer.loading = true;
      state.updateKilometer.success = false;
      state.updateKilometer.error = false;
      state.updateKilometer.msgVisible = false;
      state.updateKilometer.accept = action.meta?.arg?.body?.response ?? false;
    },
    [String(updateShopOrderKilometer.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.SUCCESS;
      state.updateKilometer.loading = false;
      state.updateKilometer.success = true;
      state.updateKilometer.error = false;
      state.updateKilometer.msgVisible = true;
    },
    [String(updateShopOrderKilometer.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.ERROR;
      state.updateKilometer.loading = false;
      state.updateKilometer.success = false;
      state.updateKilometer.error = true;
      state.updateKilometer.msgVisible = true;
    },

    // CREATE SMU - SHOPPER ORDER
    [String(createManualShopperOrder.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.createShopOrder.loading = true;
      state.createShopOrder.success = false;
      state.createShopOrder.error = false;
      state.createShopOrder.msgVisible = false;
    },
    [String(createManualShopperOrder.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.SUCCESS;
      state.createShopOrder.loading = false;
      state.createShopOrder.success = true;
      state.createShopOrder.error = false;
      state.createShopOrder.msgVisible = true;
      state.createShopOrder.response = action?.payload?.data?.data;
    },
    [String(createManualShopperOrder.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.ERROR;
      state.createShopOrder.loading = false;
      state.createShopOrder.success = false;
      state.createShopOrder.error = true;
      state.createShopOrder.msgVisible = true;
    },

    // RESTORE SHOP ORDER

    [String(restoreShopOrder.pending)]: (state) => {
      state.loading = RequestStatusEnum.PENDING;
      state.restoreOrderState.loading = true;
      state.restoreOrderState.type = '';
      state.restoreOrderState.msgVisible = false;
    },
    [String(restoreShopOrder.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.SUCCESS;
      state.restoreOrderState.loading = false;
      state.restoreOrderState.type = 'success';
      state.restoreOrderState.msgVisible = true;
    },
    [String(restoreShopOrder.rejected)]: (state) => {
      state.loading = RequestStatusEnum.ERROR;
      state.restoreOrderState.loading = false;
      state.restoreOrderState.type = 'error';
      state.restoreOrderState.msgVisible = true;
    },

    // CHANGE STORE --- SHOPPER ORDER
    [String(changeStoreShopOrder.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.changeStoreState.loading = true;
      state.changeStoreState.state = '';
      state.changeStoreState.msgVisible = false;
    },
    [String(changeStoreShopOrder.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.SUCCESS;
      state.changeStoreState.loading = false;
      state.changeStoreState.state = 'success';
      state.changeStoreState.msgVisible = true;
    },
    [String(changeStoreShopOrder.rejected)]: (state) => {
      state.loading = RequestStatusEnum.ERROR;
      state.changeStoreState.loading = false;
      state.changeStoreState.state = 'error';
      state.changeStoreState.msgVisible = true;
    },

    // DELIVERY VEHICLES
    [String(getDeliveryVehicles.pending)]: (state) => {
      state.jobberVehicles.loading = RequestStatusEnum.PENDING;
    },
    [String(getDeliveryVehicles.fulfilled)]: (state, action) => {
      state.jobberVehicles = { loading: RequestStatusEnum.IDLE, ...action.payload.data };
    },
    [String(getDeliveryVehicles.rejected)]: (state, action) => {
      state.jobberVehicles.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
    },

    // DELIVERY VEHICLES
    [String(getBeetrackOrder.pending)]: (state) => {
      state.shopperOrder.loading = RequestStatusEnum.PENDING;
      state.beetrackOrder = {
        loading: RequestStatusEnum.PENDING,
        dataOrder: null,
        substatus: null,
        substatus_code: null,
        requestState: null,
      };
    },
    [String(getBeetrackOrder.fulfilled)]: (state, action) => {
      state.shopperOrder = { loading: RequestStatusEnum.IDLE, ...action?.payload?.backoffice };

      const existOrder = action?.payload?.backoffice?.totalDocs > 0;

      state.beetrackOrder = {
        code: action.meta.arg.identifier,
        loading: RequestStatusEnum.IDLE,
        requestState: existOrder ? 'warning' : 'success',
        dataOrder: existOrder ? null : action?.payload?.beetrack.dataOrder,
        substatus: existOrder ? null : action?.payload?.beetrack.substatus,
        substatus_code: existOrder ? null : action?.payload?.beetrack.substatus_code,
        isSameStore: existOrder ? false : action?.payload?.beetrack.isSameStore,
        storeName: existOrder ? null : action?.payload?.beetrack.originalStoreName,
        existsInBeetrack: existOrder ? null : action?.payload?.beetrack.existsInBeetrack,
      };
    },
    [String(getBeetrackOrder.rejected)]: (state, action) => {
      state.shopperOrder.loading = RequestStatusEnum.IDLE;
      state.beetrackOrder.loading = RequestStatusEnum.IDLE;
      state.beetrackOrder.requestState = 'error';
      state.error = action.error;
    },

    // ASSIGN PICKER
    [String(assignPickerToShopOrder.pending)]: (state) => {
      state.assignPicker = { loading: RequestStatusEnum.PENDING, type: null, message: '' };
    },
    [String(assignPickerToShopOrder.fulfilled)]: (state, action) => {
      state.assignPicker = {
        loading: RequestStatusEnum.IDLE,
        type: 'success',
        message: i18n.t(`delivery.drawer.assignPicker.alerts.success`, {
          fullname: `${action?.meta?.arg?.jobber?.personalData?.names} ${action?.meta?.arg?.jobber?.personalData?.surnames}`,
        }),
      };
    },
    [String(assignPickerToShopOrder.rejected)]: (state) => {
      state.assignPicker = {
        loading: RequestStatusEnum.IDLE,
        type: 'error',
        message: i18n.t(`delivery.drawer.assignPicker.alerts.error`),
      };
    },

    // CLOSE PICKER ORDER
    [String(closePickerOrder.pending)]: (state) => {
      state.closePickerOrder = { loading: RequestStatusEnum.PENDING, type: null };
    },
    [String(closePickerOrder.fulfilled)]: (state) => {
      state.closePickerOrder = {
        loading: RequestStatusEnum.IDLE,
        type: 'success',
      };
    },
    [String(closePickerOrder.rejected)]: (state) => {
      state.closePickerOrder = {
        loading: RequestStatusEnum.IDLE,
        type: 'error',
      };
    },

    // VALIDATE KMS ON CLOSE ORDER
    [String(calculateKmsOnCloseOrder.pending)]: (state) => {
      state.validateKMS.status = RequestStatusEnum.PENDING;
    },
    [String(calculateKmsOnCloseOrder.fulfilled)]: (state) => {
      state.validateKMS.status = RequestStatusEnum.SUCCESS;
    },
    [String(calculateKmsOnCloseOrder.rejected)]: (state) => {
      state.validateKMS.status = RequestStatusEnum.ERROR;
    },

    // OVERWEIGHT CLONE ORDER
    [String(overweightCloneShopOrder.pending)]: (state) => {
      state.overweightState = {
        loading: RequestStatusEnum.PENDING,
        message: '',
        type: null,
      };
    },
    [String(overweightCloneShopOrder.fulfilled)]: (state, action) => {
      state.overweightState = {
        loading: RequestStatusEnum.IDLE,
        message: i18n.t(`delivery.drawer.overweight.alerts.success.${action?.meta?.arg?.status}`, {
          code: action?.meta?.arg?.code,
        }),
        type: 'success',
      };
    },
    [String(overweightCloneShopOrder.rejected)]: (state) => {
      state.overweightState = {
        loading: RequestStatusEnum.IDLE,
        message: i18n.t('delivery.drawer.overweight.alerts.error'),
        type: 'error',
      };
    },

    // UPDATE PAY COMPLETE ORDER
    [String(updatePayCompleteOrder.pending)]: (state) => {
      state.updatePayCompleteOrder = {
        loading: true,
        error: false,
      };
    },
    [String(updatePayCompleteOrder.fulfilled)]: (state) => {
      state.updatePayCompleteOrder = {
        loading: false,
        error: false,
      };
    },
    [String(updatePayCompleteOrder.rejected)]: (state) => {
      state.updatePayCompleteOrder = {
        loading: false,
        error: true,
      };
    },
  },
});

export const {
  clearField,
  resetShopOrderStatus,
  toggleShowImages,
  resetFailedCreation,
  resetUpdateKilometer,
  resetCreateShopOrder,
  resetRestoreOrder,
  resetChangeStoreState,
  clearBeetrackOrder,
  resetAssignPickerState,
  resetValidateKMS,
  resetOverweightState,
} = deliverySlice.actions;

export default deliverySlice.reducer;
