export const fetchMoneyOrdersRoute = (): string => '/payment/money-orders';
export const fetchMoneyOrdersShopperRoute = (): string => '/payment/money-orders-shopper';
export const fetchMoneyOrderRoute = (_id: string): string => `/payment/money-orders/${_id}`;
export const fetchMoneyOrderShopperRoute = (_id?: string): string =>
  `/payment/money-orders-shopper/${_id}`;
export const fetchMoneyOrderShopperByArrRoute = (): string => `/payment/money-orders-shopper`;
export const updateMoneyOrderStatusRoute = (_id: string): string => `/payment/money-orders/${_id}`;
export const updateMoneyOrderShopperStatusRoute = (_id: string): string =>
  `/payment/money-orders-shopper/${_id}`;

export const createManualMoneyOrderRoute = (): string => '/payment/money-orders/manual-create';
export const fetchMoneyOrdersShopperNyShopperCode = (): string =>
  '/payment/money-orders-shopper/to-shopper-code';

export const fetchSettlementsRoute = (): string => '/payment/settlements';
export const fetchShopperSettlementsRoute = (): string => '/payment/shopper-settlements';

export const fetchSettlementRoute = (_id: string): string => `/payment/settlements/${_id}`;
export const fetchShopperSettlementRoute = (_id: string): string =>
  `/payment/shopper-settlements/${_id}`;
export const deleteSettlementRoute = (_id: string): string => `/payment/settlements/${_id}`;
export const deleteShopperSettlementRoute = (_id: string): string =>
  `/payment/shopper-settlements/${_id}`;

export const createSettlementProcessRoute = (): string => '/payment/processes';
export const createSettlementShopperProcessRoute = (): string => '/payment/shopper-processes';
export const createIncentiveShopperProcessRoute = (): string =>
  '/reports/staffs/create-incentive-process';

export const downloadSettlementsFileRoute = (): string => '/reports/jobbers/settlements';
export const downloadShopperSettlementsFileRoute = (): string =>
  '/reports/jobbers/shopper-settlements';
export const loadSettlementPaymentsRoute = (): string => '/reports/staffs/load-payment';
export const loadShopperSettlementPaymentsRoute = (): string =>
  '/reports/staffs/load-payment-shopper';
export const loadMoneyOrdersMassiveRoute = (): string => '/reports/staffs/load-money-orders';
export const spreadsheetAssignRoute = (): string => '/reports/staffs/spreadsheet-assign';
export const downloadShopperSettlementPaymentsRoute = (): string =>
  '/reports/staffs/shopper-settlements-info';
export const downloadLabourSettlementPaymentsRoute = (): string =>
  '/reports/staffs/settlements-labour-info';

export const fetchProcessesRoute = (): string => '/payment/processes';
export const fetchShopperProcessesRoute = (): string => '/payment/shopper-processes';
export const fetchIncentiveProcessesRoute = (): string => '/payment/incentive-processes';
export const fetchIncentiveProcessRoute = (_id: string): string =>
  `/payment/incentive-processes/${_id}`;

export const deleteProcessRoute = (_id: string): string => `/payment/processes/${_id}`;
export const deleteShopperProcessRoute = (_id: string): string =>
  `/payment/shopper-processes/${_id}`;
export const deleteIncentiveProcessRoute = (_id: string): string =>
  `/payment/incentive-processes/${_id}`;

export const createManualShopperMoneyOrderRoute = (): string =>
  '/payment/money-orders-shopper/manual-create';

export const loadMassiveShopperRoute = (): string => '/reports/staffs/load-money-orders-shopper';
