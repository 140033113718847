import React from 'react';

interface Props {
  style?: React.CSSProperties;
}
const ErrorOutlined: React.FC<Props> = (props: Props) => (
  <img src="/icons/errorOutlined.svg" alt="errorOutlined" {...props} />
);

export default ErrorOutlined;
