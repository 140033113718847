export const fetchJobsRoute = (): string => '/assignment/jobs';
export const deleteJobRoute = (id: string): string => `assignment/jobs/${id}`;
export const acceptJobRoute = (id: string): string => `assignment/jobs/${id}/accept`;
export const updateJobRoute = (jobId: string): string => `/assignment/jobs/${jobId}`;
export const updateStatusJobRoute = (id: string): string => `assignment/jobs/status/${id}`;
export const getJobDetailsRoute = (taskId: string): string => `/assignment/jobs/${taskId}`;
export const getJobOverlappedJobbersRoute = (): string =>
  '/assignment/applications/overlapped-jobbers';
export const getJobApplicationsDataRoute = (): string =>
  '/account/jobbers/personal-data-and-metrics';
export const getJobApplicationsRoute = (): string => '/assignment/applications';
export const updateJobApplicationRoute = (taskId: string): string =>
  `/assignment/applications/${taskId}`;
export const bulkAssignJobApplicationRoute = (): string =>
  '/assignment/applications/bulk-assignment';
export const bulkCancelJobApplicationRoute = (): string =>
  '/assignment/applications/bulk-deallocation';
export const bulkPresentJobApplicationRoute = (): string => '/assignment/applications/bulk-present';
export const bulkAbsentJobApplicationRoute = (): string => '/assignment/applications/bulk-absent';
export const bulkFinishedJobApplicationsRoute = (): string =>
  '/assignment/applications/bulk-finish';
export const multipleJobApplicationsRoute = (): string => '/assignment/applications/multiple';
export const updateRecordHoursRoute = (applicationId: string, recordId: string): string =>
  `assignment/applications/${applicationId}/records/${recordId}`;
export const applicationCreateCommentRoute = (applicationId: string): string =>
  `assignment/applications/${applicationId}/comments/`;
export const applicationUpdateDeleteCommentRoute = (commentId: string): string =>
  `assignment/applications/comments/${commentId}`;
export const rosterEmailRoute = (): string => 'reports/jobs/job-payroll';
export const getExtraHoursRoute = (): string => 'assignment/applications/listExtraHours';
export const updateAuthorizedOvertimeRoute = (): string =>
  'assignment/applications/updateAutorizedOvertime';
export const bulkCancelJobsRoute = (): string => '/assignment/jobs/bulk-cancel';
export const bulkDeleteJobsRoute = (): string => '/assignment/jobs/bulk-delete';
export const updateCarouselStarRoute = (taskId: string): string =>
  `/assignment/jobs/carrousel-star/${taskId}`;
export const updateTaskEntrySchemaRoute = (taskId: string): string =>
  `/assignment/jobs/${taskId}/invoice-schema`;
export const getJobsWithSkillRoute = (skillId: string): string =>
  `/assignment/jobs/skills/${skillId}`;

export const fetchStatusInsuredRoute = (): string =>
  '/assignment/jobs/applications-with-executed-insured';
export const updateInsuredRoute = (_id: string): string => `/assignment/jobs/${_id}/update-insured`;
export const massiveInsuredActionRoute = (): string => '/assignment/applications/massive-assign-insured';
export const updateJobPaymentSchemaVariablesRoute = (_id: string): string => `/assignment/jobs/variables-value/${_id}`;
