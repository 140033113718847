export enum ContactTypesEnum {
  FINANCE = 'finance',
  OPERATIONS = 'operations',
  COMMERCIAL = 'commercial',
  MARKETING = 'marketing',
}

export enum SexEnum {
  FEMALE = 'female',
  MALE = 'male',
  ANY = 'any',
}

export enum OperativeSystemEnum {
  ANDROID = 'android',
  IOS = 'ios',
  ANY = 'any',
}

export enum EPPEnum {
  T_SHIRT = 't_shirt',
  FACE_MASK = 'face_mask',
  HELMET = 'helmet',
  ANTI_CUT_GLOVES = 'anti_cut_gloves',
  REFLECTIVE_VEST = 'reflective_vest',
  SAFETY_SHOES = 'safety_shoes',
  GOOGLE_GLASSES = 'google_glasses',
}

export enum daysWeekEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum WorkDaysEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
}

export enum VerticalsEnum {
  LABOUR = 'labour',
  SELLER = 'seller',
  PLATFORM = 'platform',
  STORAGE = 'storage',
  RETAIL = 'retail',
  SUPERVISOR = 'supervisor',
  LAST_MILLE = 'last_mille',
  CUSTOMER_SERVICE = 'customer_service',
  RESTAURANT = 'restaurant',
  INTERN = 'intern',
  EST = 'est',
}

export enum EnterpriseTypeEnum {
  BUSINESS_CLIENT = 'business_client',
  TIMEJOBS = 'timejobs',
}

export enum JobTypesEnum {
  PRESENTIAL = 'presential',
  REMOTE = 'remote',
}

export enum WorkDayTypeEnum {
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
}

export enum RequestStatusEnum {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum TypeCoordinatesEnum {
  POINT = 'Point',
}

export enum StaffRoles {
  ADMIN = 'admin',
  STAFF = 'staff',
  STAFF_ONBOARDING = 'staff_onboarding',
  STAFF_LEGAL = 'staff_legal',
  STAFF_FINANCE = 'staff_finance',
  STAFF_COMMERCIAL = 'staff_commercial',
  STAFF_MARKETING = 'staff_marketing',
  STAFF_SENIOR = 'staff_senior',
}

export enum CountryEnum {
  CL = 'CL',
  CO = 'CO',
  MX = 'MX',
  PE = 'PE',
}

export enum ActionsDrawerActionsEnum {
  NEW = 'new',
  EDIT = 'edit',
}

export enum LanguagesEnum {
  SPANISH = 'spanish',
  ANY = 'any',
}

export enum PaymentTypeEnum {
  LIQUID = 'liquid',
  GROSS = 'gross',
}

export enum TypeAssignmentEnum {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum PaymentRuleEnum {
  DAY_AND_WEEK = 'day_and_week',
  FORTNIGHT = 'fortnight',
}

export enum PublicationDatesEnum {
  NOW = 'now',
  ONE_DAY = '1|day',
  TWO_DAYS = '2|days',
  ONE_WEEK = '1|week',
}

export enum InputSettingsEnum {
  TEXTAREA_MIN_LENGTH = 20,
  TEXTAREA_MAX_LENGTH = 300,
  INPUT_MIN_LENGTH = 5,
  INPUT_MAX_LENGTH = 1000,
  RICH_TEXTAREA_MAX_LENGTH = 10000,
}

export enum DocumentTypeEnum {
  CE_PE = 'ce',
  RUT = 'rut',
  RUC = 'ruc',
  DNI = 'dni',
  PTP = 'ptp',
  PPT = 'ppt',
  CPP = 'cpp',
  CURP = 'curp',
  PASSPORT = 'pasaporte',
  CI = 'cedula_de_identidad',
  CC = 'cedula_de_ciudadania',
  CE_CO = 'cedula_de_extranjeria',
  PEP = 'pep',
}
export enum JobValidationFields {
  withLunch = 'requiredQuotas,startAtField,endAtField,paymentSchema,lunchStartAt,lunchEndAt',
  withoutLunch = 'requiredQuotas,startAtField,endAtField,paymentSchema',
}

export enum VehicleTypes {
  AUTO = 'auto',
  VAN = 'van',
  MOTO = 'moto',
  BICIMOTO = 'bicimoto',
  FURGON = 'furgon',
  NONE = 'none',
}

export enum VehicleTypesSwitch {
  AUTO = 'car',
  VAN = 'van',
  MOTO = 'motorcycle',
  BICIMOTO = 'moped',
}

export enum TabSelected {
  JOBBER = 'jobber',
  DOCUMENTSTAFF = 'documentStaff',
  STAFF = 'staff',
}

export enum SpecialFilesEnum {
  RECORDS = 'records',
  ID_CARD_FRONT = 'id_card_front',
  ID_CARD_REVERSE = 'id_card_reverse',
  REGISTRATION_CERTIFICATE = 'registration_certificate', // car or motorcycle
  DRIVER_LICENSE = 'driver_license', // car or motorcycle
  VISA_APPLICATION = 'visa_application', // sub category ID_CARD_FRONT
}

export enum OnSitesStatusEnum {
  SCHEDULED = 'scheduled',
  CANCELLED = 'cancelled',
  ABSENT = 'absent',
  PRESENT = 'present',
  FINISHED = 'finished',
}

export enum SpecialFilesStateEnum {
  OBJECTED = 'objected',
  NOT_OBJECTED = 'not_objected',
}

export enum VehicleTypesSwitchTranslatedOption {
  CAR = 'auto',
  VAN = 'van',
  MOTORCYCLE = 'moto',
  MOPED = 'bicimoto',
}

export enum CategoryTypeVehicleEnum {
  CAR = 'car',
  MOPED = 'moped',
  MOTORCYCLE = 'motorcycle',
  VAN = 'van',
  CERTIFICATE = 'certificates',
}

export enum VehicleDocumentKeyCodeMatchEnum {
  'car_registration_certificate' = 'carRegistrationCertificate',
  'motorcycle_registration_certificate' = 'motorcycleRegistrationCertificate',
  'moped_registration_certificate' = 'mopedRegistrationCertificate',
  'van_registration_certificate' = 'vanRegistrationCertificate',
  'car_driver_license' = 'carDriverLicense',
  'car_insurance' = 'carInsurance',
  'motorcycle_driver_license' = 'motorcycleDriverLicense',
  'moped_driver_license' = 'mopedDriverLicense',
  'van_driver_license' = 'vanDriverLicense',
  'car_ownership_card' = 'carOwnershipCard',
  'motorcycle_ownership_card' = 'motorcycleOwnershipCard',
  'moped_ownership_card' = 'mopedOwnershipCard',
  'van_ownership_card' = 'vanOwnershipCard',
  'car_soat' = 'carSoat',
  'motorcycle_soat' = 'motorcycleSoat',
  'moped_soat' = 'mopedSoat',
  'van_soat' = 'vanSoat',
  'motorcycle_road_worthiness_test' = 'motorcycleRoadWorthinessTest',
  'van_road_worthiness_test' = 'vanRoadWorthinessTest',
  'car_road_worthiness_test' = 'carRoadWorthinessTest',
  'furgon_driver_license' = 'furgonDriverLicense',
  'furgon_registration_certificate' = 'furgonRegistrationCertificate',
}

export enum VehicleDocumentKeyCodeSwitchEnum {
  CARREGISTRATIONCERTIFICATE = 'car',
  MOTORCYCLEREGISTRATIONCERTIFICATE = 'motorcycle',
  MOPEDREGISTRATIONCERTIFICATE = 'moped',
  VANREGISTRATIONCERTIFICATE = 'van',
  CARDRIVERLICENSE = 'car',
  MOTORCYCLEDRIVERLICENSE = 'motorcycle',
  MOPEDDRIVERLICENSE = 'moped',
  VANDRIVERLICENSE = 'van',
  CAROWNERSHIPCARD = 'car',
  MOTORCYCLEOWNERSHIPCARD = 'motorcycle',
  MOPEDOWNERSHIPCARD = 'moped',
  VANOWNERSHIPCARD = 'van',
  CARSOAT = 'car',
  MOTORCYCLESOAT = 'motorcycle',
  MOPEDSOAT = 'moped',
  VANSOAT = 'van',
  MOTORCYCLEROADWORTHINESSTEST = 'motorcycle',
  VANROADWORTHINESSTEST = 'van',
  CARROADWORTHINESSTEST = 'car',
  CARINSURANCE = 'car',
  FURGONREGISTRATIONCERTIFICATE = 'furgon',
  FURGONDRIVERLICENSE = 'furgon',
}

export enum ExtraDocsEnum {
  'vaccination_card' = 'vaccinationCard',
  'food_handling_certificate' = 'foodHandlingCertificate',
  'eps_certificate' = 'epsCertificate',
  'pension_certificate' = 'pensionCertificate',
  'health_card_with_food_handling' = 'healthCardWithFoodHandling',
}

export enum JobOrderFieldsEnum {
  DATE = 'date',
  STORE = 'store',
  ENTERPRISE = 'enterprise',
  RESPONSIBLE_OPERATOR = 'responsibleOperator',
  KAM = 'kam',
  JOB_TYPE = 'jobType',
  JOBS = 'jobs',
  JOB_TYPE_SUBNAME = 'subName',
  SCHEMAS = 'schemas',
}

export enum SkillRequirementEnum {
  SPECIFIC_STORE = 'specific_store',
  SPECIFIC = 'specific',
  GENERAL = 'general',
  BOTH = 'both',
}

export enum CommentTypeEnum {
  JOBBER_CALL = 'JOBBER_CALL',
  JOBBER_MESSAGE = 'JOBBER_MESSAGE',
  STORE_COORDINATOR_CONTACT = 'STORE_COORDINATOR_CONTACT',
  CLIENT_CONTACT = 'CLIENT_CONTACT',
  OTHER = 'OTHER',
}

export enum AdditionalPaymentEnum {
  REGULARIZATION = 'regularitation',
  INCENTIVES = 'incentives',
  MISCALCULATION = 'miscalculation',
  EXTRA_HOURS = 'extra_hours',
  LACK_OF_JOB_CREATION = 'lack_of_job_creation',
  MARKING_ERROR = 'marking_error',
}

export enum ManualPaymentOrderEnum {
  PAYMENT = 'payment',
  DISCOUNT = 'discount',
}

export enum ManualPaymentOrderShopperEnum {
  PAYMENT = 'payment',
  DISCOUNT = 'discount',
}

export enum LoadingSelectEnum {
  LOADING = 'Cargando...',
}

export enum ShopOrderFieldsEnum {
  FORM_NAME = 'create-shop-order',
  ORIGIN = 'origin',
  LOCAL_ORIGEN = 'local_origen',
  DATE = 'date',
  WINDOW_START = 'windowStart',
  WINDOW_END = 'windowEnd',
  COMMUNE = 'commune',
  COMMUNE_NAME = 'communeName',
  STREET_NAME = 'street',
  STREET_NUMBER = 'number',
  DEPARTAMENT = 'depto',
  OBSERVATIONS = 'observations',
  CODE = 'code',
  QUANTITY_ITEMS = 'qtyItems',
  SHOPPER_CODE = 'shopperCode',
  STORE = 'store',
  SG = 'sg',
}

export enum currenciesEnum {
  USD = 'USD',
  CLP = 'CLP',
  COP = 'COP',
  PEN = 'PEN',
  MXN = 'MXN',
  UF = 'UF',
}

export enum TypeOrdersToCreateEnum {
  WALMART = 'walmart',
  SMU = 'smu',
  WALMART_BEETRACK = 'walmart/beetrack',
}

export enum DocumentSubstatusEnum {
  TO_EXPIRE = 'toExpire',
  EXPIRED = 'expired',
  IN_FORCE = 'inForce',
}

export enum DocumentKeyCodeEnum {
  ID_CARD_FRONT = 'id_card_front',
  ID_CARD_REVERSE = 'id_card_reverse',
  PENSION_CERTIFICATE = 'pension_certificate',
  EPS_CERTIFICATE = 'eps_certificate',
  RUC_CERTIFICATE = 'ruc_certificate',
  SUSPENSION_CERTIFICATE = 'suspension_certificate',
}

export enum DocumentsSubStatusFilterEnum {
  WO_DATE = 'woDate',
  TO_EXPIRE = 'toExpire',
  EXPIRED = 'expired',
}

export enum ContactTypeEnum {
  CALL = 'call',
  MESSAGE = 'message',
  EMAIL = 'email',
}

export enum ContactStatusEnum {
  NOT_ANSWER = 'no-answer',
  INTERESTED = 'interested',
  NOT_INTERESTED = 'not-interested',
  FUTURE_INTEREST = 'future-interest',
  TO_BE_RECOVERED = 'to-be-recovered',
  NOT_CONTACTED = 'not-contacted',
  CLOSED = 'closed',
}

export enum FileStatusEnum {
  PENDING = 'pending',
  IN_REVIEW = 'in_review',
  APPROVED = 'approved',
  OBJECTED = 'objected',
  REJECTED = 'rejected',
}

export enum NeedOfJobbersEnum {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum ManualShopperOrderReasonEnum {
  KMS_DIFFERENCE = 'kms_difference',
  SKU_DIFFERENCE = 'sku_difference',
  UNITS_DIFFERENCE = 'units_difference',
  SECURE_DIFFERENCE = 'secure_difference',
  INCENTIVE_DIFFERENCE = 'incentive_difference',
  EXTRA_PAYMENT = 'extra_payment',
}

export enum InvoiceSchemaDayTypeEnum {
  MONDAY_SATURDAY = 'monday_saturday',
  SUNDAY_HOLIDAY = 'sunday_holiday',
}

export enum ManualShopperAdditionalExpensesEnum {
  TOLL = 'toll',
  PARKING = 'parking',
  OTHER = 'other',
}

export enum VariableKindEnum {
  SIMPLE = 'simple',
  STAGGERED = 'staggered',
}

export enum MoneyOrderTypes {
  INCENTIVE = 'incentive',
  JOBBER_PACK = 'jobber_pack',
  ADJUSTMENT = 'adjustment',
}

export enum MoneyOrderCreationType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
  MASSIVE = 'massive',
}

export enum ActionsUpdateClient {
  ADD = 'add',
  REMOVE = 'remove',
  BLOCK = 'block',
  UNBLOCK = 'unblock',
}

export enum TemplateViewStatusEnum {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export enum StatusOverweightCloneEnum {
  READY = 'ready',
  FINISHED = 'finished',
}

export enum MoneyOrderStatusEnum {
  PAYABLE = 'payable',
  PAID_OUT = 'paid_out',
  SETTLED = 'settled',
  REPROCESS = 'reprocess',
  BLOCKED = 'blocked',
}

export enum MetricsFilterCriteriaEnum {
  HAS = 'has',
  HAS_NOT = 'hasNot',
}

export enum PenaltyRequirementEnum {
  FULL = 'full',
  HALF = 'half',
  FREE = 'free',
}

export enum JobApprovalActionEnum {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export enum PuntualityEnum {
  NO_INFO = 'no_info',
  VERY_PUNTUAL = 'very_puntual',
  PUNTUAL = 'puntual',
  NOT_VERY_PUNTUAL = 'not_very_puntual',
  NOT_PUNTUAL = 'not_puntual',
}
