import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import server from '@services/api';
import { RequestStatusEnum } from '@utils/enum';
import {
  editPresentAndFinishMassiveRoute,
  fetchApplicationsRoute,
  updateStatusInsuredRoute,
} from './ts/routes';
import { Applications } from './ts/applications.interfaces';
import i18n from '@i18n/index';
import { IResponseData } from '@features/payments/ts/payments.interfaces';

export const applicationsAdapter = createEntityAdapter();

export const initialState = applicationsAdapter.getInitialState({
  applications: {
    docs: [],
    totalDocs: null,
  },
  loading: RequestStatusEnum.IDLE,
  currentRequestId: undefined,
  error: null,
  listFilter: {},
  alertMessages: { type: undefined, text: '', extraLines: [] },
  updateStatusIndured: RequestStatusEnum.IDLE,
});

export const getApplications = createAsyncThunk(
  'applications/getApplications',
  async (params: any, { getState, requestId }: any) => {
    const { loading, currentRequestId } = getState().applications;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) return;
    const response: Applications = await server({
      method: 'get',
      url: fetchApplicationsRoute(),
      params,
    });

    return response.data;
  }
);

export const updateAssignInsured = createAsyncThunk(
  'applications/updateAssignInsured',
  async ({ application, insentiveStatus }: any, { getState, requestId }: any) => {
    const { loading, currentRequestId } = getState().applications;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) return;

    const response = await server({
      method: 'put',
      url: updateStatusInsuredRoute(application),
      data: { insentiveStatus },
    });

    return { response, insentiveStatus };
  }
);

export const updatePresentAndFinish = createAsyncThunk(
  'payments/updatePresentAndFinish',
  async (body: any, { rejectWithValue }) => {
    try {
      const response: IResponseData = await server({
        method: 'post',
        url: editPresentAndFinishMassiveRoute(),
        data: body,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    clearAlertMsg: (state) => {
      state.alertMessages = { type: '', text: '', extraLines: [] };
    },
  },
  extraReducers: {
    [String(getApplications.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.listFilter = {};
      }
    },
    [String(getApplications.fulfilled)]: (state, action) => {
      const { arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.applications = action?.payload?.data;
        state.currentRequestId = undefined;
        state.listFilter = arg;
      }
    },
    [String(getApplications.rejected)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
        state.listFilter = {};
      }
    },

    [String(updateAssignInsured.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.updateStatusIndured = RequestStatusEnum.PENDING;
      }
    },
    [String(updateAssignInsured.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.alertMessages = {
          type: 'success',
          text: i18n.t(
            `tasks.jobs.dashboard.tabs.applications.${action.payload?.insentiveStatus}.successToast`
          ),
          extraLines: [],
        };
        state.updateStatusIndured = RequestStatusEnum.SUCCESS;
        state.currentRequestId = undefined;
      }
    },
    [String(updateAssignInsured.rejected)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.updateStatusIndured = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(updatePresentAndFinish.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updatePresentAndFinish.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.alertMessages = {
          type: 'success',
          text: i18n.t(`tasks.jobs.dashboard.tabs.applications.massiveStatus.success`),
          extraLines: [],
        };
        state.currentRequestId = undefined;
      }
    },
    [String(updatePresentAndFinish.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.alertMessages = {
          type: 'error',
          text: i18n.t(`tasks.jobs.dashboard.tabs.applications.massiveStatus.error`),
          extraLines: [],
        };
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const { clearAlertMsg } = applicationsSlice.actions;
export default applicationsSlice.reducer;
