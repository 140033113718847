export default {
  stores: {
    stores: {
      drawer: {
        edit: {
          cancellationModal: {
            title: 'Edición de sede',
            text: 'Los cambios se verán reflejados en las órdenes de tarea futuras. No afectará a las pasadas ya creadas o en curso.',
            actions: ['Cancelar', 'Salir sin guardar'],
          },
        },
        delete: {
          description:
            'Al eliminar la sede {{input}}, no estará disponible para crear nuevas órdenes de tarea. No afectarán a las pasadas ya creadas o en curso.',
        },
      },
    },
  },
};
