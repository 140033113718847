export const getBlocklistRoute = (): string => 'account/jobbers/blocked';

export const postBlocklistRoute = (id: string): string => `account/jobbers/${id}/restrictions`;

export const putBlocklistRoute = (_id: string, restriction: string): string =>
  `account/jobbers/${_id}/restrictions/${restriction}`;

export const deleteBlocklistRoute = (id: string, blockId: string): string =>
  `account/jobbers/${id}/restrictions/${blockId}`;

export const blockJobberRoute = (_id: string): string => `/account/jobbers/block/${_id}`;

export const unblockJobberRoute = (_id: string): string => `/account/jobbers/unblock/${_id}`;

export const putBlocklistGeneralRoute = (_id: string, blockId: string): string =>
  `/account/jobbers/${_id}/blocks/${blockId}`;

export const blockListReportRoute = (): string => '/reports/jobbers/blocked';
