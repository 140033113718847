import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import i18n from 'i18next';
import server from '@services/api';
import { coordinatorsRoute, coordinatorRoute } from './ts/routes';
import {
  CoordinatorLists,
  CoordinatorParams,
  CoordinatorResources,
  CoordinatorFind,
  DeleteCoordinator,
} from './ts/coordinator.interface';

export const coordinatorAdapter = createEntityAdapter();

export const initialState = coordinatorAdapter.getInitialState({
  coordinators: {},
  coordinator: {},
  components: {
    coordinatorsSelect: { docs: [], loading: false, defaultValue: undefined },
  },
  loading: 'idle',
  message: undefined,
  currentRequestId: undefined,
  error: null,
});

export const getCoordinators = createAsyncThunk(
  'coordinator/getAll',
  async (params: CoordinatorParams, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().coordinator;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response: CoordinatorLists = await server({
      method: 'get',
      url: coordinatorsRoute(),
      params,
    });

    return response.data;
  }
);

export const createCoordinator = createAsyncThunk(
  'coordinator/create',
  async (data: CoordinatorResources, thunkApi: any) => {
    try {
      const { getState, requestId } = thunkApi;
      const { currentRequestId, loading } = getState().coordinator;
      if (loading !== 'pending' || requestId !== currentRequestId) {
        return;
      }
      const response: CoordinatorFind = await server({
        method: 'post',
        url: coordinatorsRoute(),
        data,
      });

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response, null);
    }
  }
);

export const getCoordinator = createAsyncThunk(
  'coordinator/getOne',
  async (id: string, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().coordinator;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response: CoordinatorFind = await server({
      method: 'get',
      url: coordinatorRoute(id),
    });

    return response.data;
  }
);

export const updateCoordinator = createAsyncThunk(
  'coordinator/update',
  async (data: CoordinatorResources, thunkApi: any) => {
    try {
      const { getState, requestId } = thunkApi;
      const { currentRequestId, loading } = getState().coordinator;
      if (loading !== 'pending' || requestId !== currentRequestId) {
        return;
      }
      const response: CoordinatorFind = await server({
        method: 'put',
        url: coordinatorRoute(data._id),
        data,
      });

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response, null);
    }
  }
);

export const deleteCoordinator = createAsyncThunk(
  'coordinator/delete',
  async (id: string, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().coordinator;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    const response: DeleteCoordinator = await server({
      method: 'delete',
      url: coordinatorRoute(id),
    });

    return response;
  }
);

export const getCoordinatorForSelect = createAsyncThunk(
  'coordinator/getCoordinatorForSelect',
  async (params: any) => {
    const response = await server.get(coordinatorsRoute(), {
      params,
    });
    return response.data;
  }
);

const coordinatorSlice = createSlice({
  name: 'coordinator',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload;
    },
  },
  extraReducers: {
    [String(getCoordinators.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(getCoordinators.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.coordinators = action.payload?.data;
        state.currentRequestId = undefined;
      }
    },
    [String(getCoordinators.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(createCoordinator.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(createCoordinator.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.message = i18n.t(`stores.coordinator.alerts.newSuccess`, {
          name: `${action.payload.data.data.firstName} ${action.payload.data.data.lastName}`,
        });
      }
    },
    [String(createCoordinator.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action?.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getCoordinator.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(getCoordinator.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.coordinator = action.payload?.data;
        state.currentRequestId = undefined;
      }
    },
    [String(getCoordinator.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(updateCoordinator.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(updateCoordinator.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.message = i18n.t(`stores.coordinator.alerts.editSuccess`);
      }
    },
    [String(updateCoordinator.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(deleteCoordinator.pending)]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [String(deleteCoordinator.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.message = i18n.t(`stores.coordinator.alerts.deleteSuccess`);
      }
    },
    [String(deleteCoordinator.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getCoordinatorForSelect.pending)]: (state) => {
      state.components.coordinatorsSelect.loading = true;
    },
    [String(getCoordinatorForSelect.fulfilled)]: (state, action) => {
      const { next, id, firstLoad } = action.meta?.arg;

      const newDocs =
        next || firstLoad
          ? [
              ...state.components.coordinatorsSelect?.docs,
              ...action.payload.data?.docs?.filter(
                (obj) => obj._id !== state.components.coordinatorsSelect?.defaultValue
              ),
            ]
          : action.payload.data?.docs;

      state.components.coordinatorsSelect = {
        ...action.payload.data,
        loading: false,
        docs: newDocs,
        defaultValue: next || firstLoad ? state.components.coordinatorsSelect?.defaultValue : id,
      };
    },
    [String(getCoordinatorForSelect.rejected)]: (state, action) => {
      state.components.coordinatorsSelect.loading = false;
      state.error = action.error;
    },
  },
});

export const { setMessage } = coordinatorSlice.actions;

export default coordinatorSlice.reducer;
