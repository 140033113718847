import auth from './modules/auth';
import applicants from './modules/applicants';
import localities from './modules/localities';
import skills from '../es/modules/skills';

export default {
  ...auth,
  ...applicants,
  ...localities,
  ...skills,
  global: {
    region: 'Departamento',
    region_plural: 'Departamentos',
    regionWArticle: 'El departamento',
    regionWArticle_plural: 'Los departamentos',
    allRegionWArticle: 'Todo $t(global.regionWArticle, lowercase)',
    allRegionWArticle_plural: 'Todos $t(global.regionWArticle_plural, lowercase)',
    commune: 'Localidad',
    communeWArticle: 'La localidad',
    commune_plural: 'Localidades',
    communeWArticle_plural: 'Las localidades',
    vehicles: {
      moto: 'Motocicleta',
    },
    inputs: {
      documentNumber: {
        alreadyTaken:
          'Este número de documento ya se encuentra registrado, para más información comunícate con contacto.co@timejobs.work',
        pattern: {
          cedula_de_ciudadania: /^(\d){6,12}$/,
          cedula_de_extranjeria: /^([a-zA-Z]|\d)+$/,
          pep: /^([a-zA-Z]|\d){0,10}$/,
          numero_de_identificacion_tributaria: /^(\d){8,15}$/,
          ppt: /^(\d){4,10}$/,
        },
        message: {
          cedula_de_ciudadania: 'Ingresa entre 6 y 12 dígitos',
          cedula_de_extranjeria: 'Ingresa dígitos o letras',
          pep: 'Ingresa hasta 10 dígitos o letras',
          numero_de_identificacion_tributaria: 'Ingresaentre 8 y 15 digitos',
          ppt: 'Ingresa de 4 a 10 dígitos.',
        },
        maximumDocumentLength: {
          cedula_de_ciudadania: 12,
          cedula_de_extranjeria: 12,
          numero_de_identificacion_tributaria: 15,
          pep: 10,
          ppt: 10,
        },
      },
      phoneNumber: {
        pattern: /^(\d){10}$/,
        message: 'Ingresa un teléfono de 10 dígitos',
      },
    },
    settings: {
      country: {
        phonePrefix: '+57',
        name: 'Colombia',
        phoneLengthWithoutPrefix: 10,
      },
    },
    enums: {
      paymentType: {
        liquid: 'Neto',
      },
    },
  },
};
