export default {
  enterprises: {
    enterprisesProfile: {
      tabs: {
        paymentSchemes: {
          actions: {
            delete: {
              description:
                'Al eliminar esquema de pago {{input}}, no estará disponible para crear nuevas órdenes de tarea. No afectarán a las pasadas ya creadas o en curso.',
              actions: ['Cancelar', 'Eliminar esquema de pago'],
            },
          },
          form: {
            actions: {
              edit: {
                title: 'Guardar Cambios',
                cancellationModal: {
                  text: 'Los cambios se verán reflejados en órdenes de tarea futuras, en estado Programada y no afectará ordenes pasadas o en curso.',
                },
              },
            },
          },
        },
        entriesSchemes: {
          delete: {
            title: 'Eliminar esquema de cobro',
            okBtn: 'Eliminar esquema de cobro',
            cancel: 'Cancelar',
            description:
              'Al eliminar el esquema de cobro {{name}}, no podrá ser utilizado para crear órdenes de tarea. Su eliminación no afectará órdenes ya creadas o en curso.',
          },
        },
      },
    },

    drawer: {
      buttonsActions: ['Agregar otro contacto'],
      delete: {
        title: 'Eliminar cliente',
        okText: 'Eliminar cliente',
        message: 'El cliente comercial se ha eliminado exitosamente.',
        error: 'No se pudo eliminar el cliente porque tiene tareas creadas.',
        text: 'Al eliminar el cliente comercial {{input}}, no estará disponible para crear nuevas órdenes de tarea.',
      },
    },
  },
};
