export default {
  sidebar: {
    menu: {
      home: 'Inicio',
      dashboard: 'Estadísticas',
      jobbers: {
        title: 'Jobbers',
        submenu: ['Pendientes applicant', 'Jobbers activos', 'Jobbers bloqueados y eliminados'],
      },
      task: {
        title: 'Tareas',
        submenu: ['Listado de tareas'],
      },
      delivery: {
        title: 'Delivery',
      },
      courses: {
        title: 'Inducciones',
        submenu: ['Gestor de inducciones'],
      },
      payments: {
        title: 'Pagos',
        submenu: ['Pagos Labours', 'Pagos Shoppers'],
      },
      finance: {
        title: 'Reportería',
        submenu: ['Reportes'],
      },
      enterprise: {
        title: 'Clientes',
        submenu: ['Listado de clientes'],
      },
      settings: {
        title: 'Configuración',
        submenu: [
          'Roles y perfiles',
          'Gestor localidades',
          'Tipo de tareas',
          'Coordinadores sedes',
        ],
      },
      notification: {
        title: 'Notificaciones',
      },
      requirements: {
        title: 'Requerimientos',
      },
    },
  },
  home: {
    links: {
      dashboard: {
        title: 'Estadísticas',
        indicators: 'Indicadores',
      },
      jobbers: {
        title: 'Jobbers',
        pendingApplicants: 'Pendientes applicant',
        activeJobbers: 'Jobbers activos',
        blocklist_safelist: 'Jobbers bloqueados y eliminados',
      },
      notifications: {
        title: 'Notificationes',
        main: 'Envío de notificaciones',
      },
      inductions: {
        title: 'Inducciones',
        inductionManager: 'Gestor de inducciones',
      },
      tasks: {
        title: 'Tareas y asistencia',
        tasks: 'Tareas',
      },
      delivery: {
        title: 'Delivery',
        delivery: 'Delivery orders',
      },
      payments: {
        title: 'Pagos',
        paymentPage: 'Pagos Jobbers',
      },
      reports: {
        title: 'Reportería',
        paymentState: 'Estado de pagos',
        paymentReport: 'Reporte de pagos',
        invoiceGeneration: 'Generación de boletas',
      },
      commercialCustomer: {
        title: 'Cliente comercial',
        customers: 'Clientes',
        billingsAndCollections: 'Facturación y cobranza',
      },
      configuration: {
        title: 'Configuraciones',
        taskTypeMaintainer: 'Tipo de tareas',
        maintainerCoordinatorsHeadquarters: 'Coordinadores sedes',
        localitiesManager: 'Gestor localidades',
        paymentVariables: 'Variables de pago',
        ratingAttributes: 'Atributos calificación',
        staffs: 'Roles y perfiles',
      },
      requirements: {
        title: 'Portal Empresas',
        requirementsPage: 'Requerimientos clientes',
      },
    },
  },
};
