export default {
  tasks: {
    jobTypes: {
      title: 'Tipo de tareas',
      subtitle: 'Revisa el listado de tareas creadas',
      kpi: ['Tareas', 'Verticales', 'Tareas nuevas'],
      buttonsActions: {
        create: 'Crear tipo de tarea',
        delete: 'Eliminar tipo de tarea',
      },
      filters: {
        search: { label: 'Buscar', placeholder: 'Buscar por tarea' },
        vertical: { label: 'Vertical', placeholder: 'Selecciona' },
        date: 'Fecha de creación',
        clean: 'Limpiar filtros',
      },
      table: { columns: ['Tarea/Vertical', 'Descripción', 'Fecha creación', 'Acciones'] },
      deleteModal: {
        title: 'Eliminar tipo de tarea',
        text: 'Al eliminar el tipo de tarea {{input}}, no podrás recuperar esta información.',
        okText: '$t(tasks.jobTypes.deleteModal.title)',
      },
      cancelModal: {
        new: {
          title: 'Descartar creación tipo de tarea',
          text: 'Al salir, se perderán todos los datos ingresados.',
          okText: 'Descartar tipo de tarea',
          cancelText: 'Cancelar',
        },
      },
      alerts: {
        newSuccess:
          'El tipo de tarea se ha creado exitosamente. Puedes revisarlo en la tabla y editar posteriormente.',
        editSuccess: 'Los cambios se han guardado con éxito.',
        deleteSuccess: 'El tipo de tarea se ha eliminado con éxito',
      },
      newDrawer: {
        new: {
          title: 'Creación tipo de tarea',
          subtitle: 'Ingresa los datos obligatorios (*) para crear nuevo tipo de tarea',
          submit: 'Crear tipo de tarea',
        },
        edit: {
          title: 'Edición tipo de tarea',
          subtitle: 'Realiza los cambios necesarios en la tarea',
          submit: 'Guardar cambios',
        },
        formTitle: 'Información',
        inputs: {
          name: {
            label: 'Nombre de tarea *',
            required: 'Ingresa un nombre a la tarea',
            patterError: 'Ingresa un nombre válido',
            min: 'Debe ingresar un mínimo de {{input}} caracteres',
          },
          description: {
            label: 'Definición *',
            placeholder: 'Describe la o las funciones que tendrá este tipo de tarea',
            required: 'Ingresa una definición para el tipo de tarea',
            min: 'Debe ingresar un mínimo de {{input}} caracteres',
            max: 'Límite de caracteres alcanzado',
          },
        },
      },
      profile: {
        title: 'Detalle tipo de tarea',
        sections: ['Fecha creación', 'Vertical', 'Descripción'],
      },
    },
    jobs: {
      dashboard: {
        title: 'Tareas',
        subtitle: 'Revisa el listado de tareas programadas y plantillas de orden de tarea',
        actionButtons: {
          title: 'Programar orden de tarea',
        },
        bulkUpdateButtons: {
          title: 'Acciones',
          cancel: {
            okButton: 'Cancelar tareas',
            text: 'Tareas seleccionadas ({{quantity}}) quedarán en estado “Cancelada”, quedando inactivas y ubicandose al final de la tabla.',
            title: 'Cancelar tareas',
            option: 'Cancelar tareas',
            success: 'Tareas ({{quantity}}) canceladas con éxito.',
            error: 'Error al cancelar tareas ({{quantity}}). Codigo: {{code}}',
          },
          delete: {
            okButton: 'Eliminar tareas',
            text: 'Tareas seleccionadas ({{quantity}}) desaparecerán de la tabla y no podrás recuperar esta información.',
            title: 'Eliminar tareas',
            option: 'Eliminar tareas',
            success: 'Tareas ({{quantity}}) eliminadas con éxito.',
            error: 'Error al eliminar tareas ({{quantity}}). Codigo: {{code}}',
          },
        },
        tabs: {
          jobs: {
            title: 'Tareas programadas',
            filters: {
              client: 'Cliente',
              stores: 'Sede',
              jobType: 'Tipo tarea',
              date: 'Fecha ejecución tarea',
              status: 'Estados',
              statusTooltip:
                'Al filtrar por “Estado”, podrás seleccionar varias tareas y realizar acciones de manera masiva.',
              relevance: {
                title: 'Relevancia tarea',
                tooltip:
                  'Al filtrar por “Relevancia tarea”, podrás seleccionar las tareas que han sido destacadas.',
                featured: 'Destacadas',
                notFeatured: 'No destacadas',
              },
              clean: 'Limpiar filtros',
            },
            columns: [
              {
                title: 'ID Tarea',
                tooltip: 'Relevancia tarea',
                detailToltip:
                  'Al destacar la tarea, esta aparecerá en el carrusel de inicio de la App del Jobber.',
              },
              'Estado',
              'Sede/Cliente',
              'Tipo tarea',
              'Fecha publicación',
              'Fecha ejecución',
              {
                title: 'Estadísticas',
                tooltips: {
                  presents: '{{count}} presente',
                  presents_plural: '{{count}} presentes',
                  finalized: '{{count}} finalizado',
                  finalized_plural: '{{count}} finalizados',
                  assignedQuotas: '{{count}} cupo asignado',
                  assignedQuotas_plural: '{{count}} cupos asignados',
                  requestedQuotas: '{{count}} cupo total',
                  requestedQuotas_plural: '{{count}} cupos totales',
                  applied: '{{count}} postulación',
                  applied_plural: '{{count}} postulaciones',
                  missing: '{{count}} ausente',
                  missing_plural: '{{count}} ausentes',
                  canceledPostAssigned: '{{count}} cancelado post asig.',
                  canceledPostAssigned_plural: '{{count}} cancelados post asig.',
                },
              },
              {
                options: [
                  'Editar Tarea',
                  'Editar Orden de tarea',
                  'Cancelar Tarea',
                  'Eliminar Tarea',
                  'Publicar Tarea',
                  'Editar esquema de cobro',
                ],
              },
            ],
          },
          templates: {
            title: 'Plantillas de orden de tarea',
            kpis: ['Plantillas creadas', 'Plantillas en uso', 'Plantillas nuevas'],
            columns: [
              {
                title: 'Plantilla',
                job: '{{count}} tarea',
                job_plural: '{{count}} tareas',
              },
              'Descripción',
              'Último uso',
              {
                title: 'Acciones',
                options: ['Utilizar plantilla', 'Editar', 'Eliminar'],
              },
            ],
            disabledAttributes: {
              store: {
                tag: 'Sede eliminada',
                popover: 'Esta sede fue eliminada por lo que esta plantilla no puede ser utilizada',
              },
              enterprise: {
                tag: 'Cliente eliminado',
                popover:
                  'Este cliente fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              job_type: {
                tag: 'Tipo de tarea eliminado',
                popover:
                  'Este tipo de tarea fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              coordinator: {
                tag: 'Coordinador eliminado',
                popover:
                  'Este coordinador fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              payment_schema: {
                tag: 'Esquema de pagos eliminado',
                popover:
                  'Este esquema de pagos fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              invoice_schema: {
                tag: 'Esquema de cobro eliminado',
                popover:
                  'Este esquema de cobro fue eliminado por lo que esta plantilla no puede ser utilizada',
              },
              many: {
                text: 'Los siguientes atributos también fueron eliminados:',
                attributes: {
                  store: 'Sede',
                  enterprise: 'Cliente',
                  job_type: 'Tipo de tarea',
                  coordinator: 'Coordinador',
                  payment_schema: 'Esquema de pago',
                  invoice_schema: 'Esquema de cobro',
                },
              },
            },
          },
          extraHours: {
            title: 'Validación horas extras',
            subtitle: 'Revisar el listado de Jobbers que solicitan pago de horas extras.',
            button: 'Acciones',
            filters: {
              select: 'Seleccione',
              client: 'Cliente',
              stores: 'Sede',
              jobType: 'Tipo tarea',
            },
            filterSearch: { title: 'Buscar', placeholder: 'Busca por ID de tarea' },
            filterClear: { title: 'Limpiar filtros' },
            filterDatePicker: { title: 'Fecha ejecución tarea' },
            columns: {
              jobId: 'ID Tarea',
              jobber: 'Jobber',
              jobEnterprise: 'Sede/Cliente',
              jobType: 'Tipo de tarea',
              jobDate: 'Fecha Ejecución',
              presentAt: 'Hora entrada',
              finishAt: 'Hora salida',
              actions: 'Acciones',
            },
            actions: {
              title: 'Acciones',
              accept: 'Aceptar horas extras',
              modify: 'Modificar horas extras',
              reject: 'Rechazar horas extras',
            },
            accept: {
              title: 'Aceptar horas extras',
              okText: 'Aceptar horas extras',
              text: 'Jobbers seleccionados quedarán con el horario de salida marcado y se pagarán sus horas extras.',
              successToast: 'Horas extras aceptadas',
            },
            modify: {
              title: 'Modificar horas extras',
              okText: 'Modificar horas extras',
              text: 'Jobbers quedarán con el mismo horario de salida que ingreses más abajo.',
              jobTime: 'Ejecución: {{- date}}  -  {{start}} - {{end}} hrs.',
              successToast: 'Horas extras modificadas',
            },
            reject: {
              title: 'Rechazar horas extras',
              okText: 'Rechazar horas extras',
              text: 'Jobbers quedarán con el horario de salida definido en la tarea y le pago de horas extras no será considerado.',
              successToast: 'Horas extras rechazadas',
            },
            modal: {
              select: {
                label: 'Jobbers seleccionados *',
                placeholder: 'Selecciona o busca por Jobber, RUT o email',
              },
              floatInputDate: 'Fecha de salida',
              floatInputHour: 'Hora de salida',
              dateError: 'Hora fuera del rango permitido',
            },
          },
        },
      },
      modalActions: {
        deleteTask: {
          title: 'Eliminar tarea',
          text: 'Al eliminar la tarea {{job}} - {{client}} - {{store}}, no podrás recuperar esta información y esta desaparecerá de la tabla.',
          okText: 'Eliminar tarea',
          message: 'La tarea ha sido eliminada exitosamente',
        },
        cancelTask: {
          title: 'Cancelar tarea',
          text: 'Al cancelar la tarea {{job}} - {{client}} - {{store}}, esta quedará inactiva y se verá al final de la tabla. ',
          okText: 'Cancelar tarea',
          message: 'La tarea ha sido cancelada exitosamente, ahora puede ser eliminada',
        },
        publishTask: {
          title: 'Publicar tarea',
          text: 'Al realizar esta acción la tarea {{job}} - {{client}} - {{store}}, quedará publicada y será visible en la app.',
          okText: 'Publicar tarea',
          message: 'La tarea ha sido publicada exitosamente',
        },
        deleteTemplate: {
          title: 'Eliminar plantilla de orden de tarea',
        },
        editEntrySchema: {
          title: 'Editar esquema de cobro cliente',
          subtitle: 'Edita el esquema de cobro que se aplicará a la tarea {{job}}.',
          okText: 'Editar esquema',
          success: 'Esquema de cobro editado con éxito.',
          repeatHelper:
            'Cambio del esquema de cobro, solo afectará esta tarea y no sus repeticiones.',
          schema: {
            label: 'Esquema de cobro',
          },
          info: {
            comment: 'Descripción esquema de cobro',
            variables: 'Variable(s)',
            simpleVariable: '{{name}} (variable fija)',
            staggeredVariable: '{{name}} (escalonada)',
            staggeredValue: 'De {{min}} a {{max}} {{name}}', 
            staggeredValueInf: 'De {{min}} {{name}} en adelante',
          },
        },
        approveJob: {
          title: 'Crear tarea',
          text: 'Al confirmar, la tarea <strong>{{code}}</strong> será creada y publicada en caso de que corresponda.',
          alert: 'Podrás visualizar esta tarea en la sección de <strong>Tareas programadas.</strong>',
          okText: 'Confirmar creación',
        }
      },
      drawer: {
        new: {
          title: 'Programar orden de tarea',
          subtitle: 'Ingresa los datos obligatorios (*) para programar la orden de tarea',
          message:
            'Orden de tarea ha sido programada exitosamente. En la tabla podrás revisar cada tarea de manera independiente.',
          submitButton: 'Programar orden de tarea',
          cancelModalOptions: {
            title: 'Descartar orden de tarea',
            okText: 'Descartar orden de tarea',
          },
          error: 'Completa los campos obligatorios para poder avanzar',
          overlappedJobsError: 'Existe conflicto con el horario de una tarea ya existente',
          selectTimesPublish: [
            'Publicar ahora',
            '1 día antes de la ejecución',
            '2 días antes de la ejecución',
            '1 semana antes de la ejecución',
          ],
          entrySchema: {
            title: 'Cobro cliente',
            schema: {
              label: 'Esquema de cobro *',
            },
            info: {
              comment: 'Descripción esquema de cobro (opcional)',
              variables: 'Variable(s)',
            },
            recommended: {
              matchToJobType:
                'De acuerdo al día y hora de inicio de la tarea, el esquema de cobro recomendado para este <strong>Tipo de tarea</strong>, es el esquema <strong>“{{invoiceSchema}}”</strong>. Puedes cambiarlo si lo deseas.',
              matchToStore:
                'De acuerdo al día y hora de inicio de la tarea, el esquema de cobro recomendado para esta <strong>Sede y Tipo de tarea</strong>, es el esquema <strong>“{{invoiceSchema}}”</strong>. Puedes cambiarlo si lo deseas.',
              unmatched:
                '<strong>Ningún esquema de cobro coincide</strong> con el día y hora de inicio de la tarea. Selecciona manualmente el esquema que aplicará.',
            },
          },
          paymentSchema: {
            title: 'Pago Jobber',
            subtitle:
              'Elige el esquema de pagos e ingresa un valor en las variables previamente asignadas.',
            lunchHelper: {
              true: 'Esquema de pago SI considera el pago de la hora de almuerzo (horario definido o no).',
              false:
                'Esquema de pago NO considera el pago de la hora de almuerzo (horario definido o no).',
            },
            inputs: [
              {
                label: 'Esquema de pagos *',
                min: 'Ingresa un valor mayor a $t(global.currencySymbol){{min}}',
                max: 'Ingresa un valor menor a $t(global.currencySymbol){{max}}',
                range:
                  'Ingresa un valor entre $t(global.currencySymbol){{min}} y $t(global.currencySymbol){{max}}',
              },
            ],
          },
          extraInfo: {
            title: 'Información adicional (opcional)',
            subtitle:
              'Puedes agregar un link con los horarios y paradas, un documento, una fotografía o lo que necesites comunicar adicionalmente para esta tarea. Puedes agregar más de un archivo/link.',
            addFile: {
              content: 'Adjuntar archivo',
              helper: 'Peso máx. 10 mb - Formato: jpg, pdf, png',
            },
            addLink: {
              content: 'Insertar Link',
              helper: 'URL del contenido que verá el Jobber',
            },
            inputs: [
              {
                label: 'Texto para mostrar *',
                placeholder: 'Ej: Ver horarios y paradas',
              },
              {
                label: 'URL *',
                placeholder: 'Ej: www.horariosyparadasjobber.cl',
              },
            ],
          },
          steps: [
            {
              title: 'Orden tarea',
              missingSkillMessage:
                'Para este cliente y tipo de tarea, no existe una Skill creada. Dirígete al Gestor de inducciones para crear una.',
              missingSchemaMessage:
                'Para este cliente, sede y tipo de tarea, no existe un Esquema de pago creado. Dirígete al perfil del cliente para crear uno.',
              schemaWithoutRuleMessage:
                'No podrá continuar con el flujo hasta que se arregle la regla.',
              withoutEntrySchemaMessage:
                'Para este cliente, sede y tipo de tarea, no existe un Esquema de cobro creado. Dirígete al perfil del cliente para crear uno.',
              template: {
                title: 'Plantilla orden de tarea',
                subtitle: 'Selecciona la plantilla que utilizarás como base',
                label: 'Plantilla',
                placeholder: 'Plantilla orden de tarea',
                required: 'Selecciona una plantilla',
              },
              responsableInfo:
                'En caso de no seleccionar un staff, el creador de la OT quedará como responsable.',
              sections: [
                {
                  title: 'Orden de tarea',
                  inputs: [
                    {
                      label: 'Fecha ejecución tareas',
                      required: 'Ingresa la fecha de ejecución de la(s) tarea(s)',
                    },
                    {
                      label: 'Cliente comercial',
                      required: 'Selecciona un cliente',
                    },
                    {
                      label: 'Sede',
                      required: 'Selecciona una sede',
                    },
                    {
                      label: 'Tipo de tarea',
                      required: 'Selecciona un tipo de tarea',
                    },
                    {
                      label: 'Staff responsable (opcional)',
                    },
                    {
                      label: 'KAM responsable (opcional)',
                    },
                    {
                      label: 'Subnombre tipo de tarea (opcional)',
                    },
                  ],
                },
                {
                  title: 'Detalles orden de tarea',
                  inputs: [
                    {
                      label: 'Descripción',
                      required: 'Ingresa una descripción para la orden de tarea',
                      placeholder:
                        'Ingresa una descripción de la o las tareas que deberá realizar el Jobber.    Omite información respecto a pagos, horarios y transporte.',
                      max: 'Límite de caracteres alcanzado',
                    },
                  ],
                },
                {
                  title: 'Ubicación orden de tarea',
                  labels: [
                    'Radio de asistencia',
                    'Referencia lugar de ingreso',
                    'URL Google Maps',
                    'Fotografía lugar de ingreso',
                  ],
                  actions: {
                    map: 'Ver mapa',
                  },
                },
              ],
            },
            {
              title: 'Jobber',
              inputs: [
                {
                  required: 'Selecciona al menos una inducción',
                },
                {
                  required: 'Selecciona el dispositivo a utilizar',
                },
                {
                  label: 'Elementos',
                  required: 'Selecciona los Elementos de Protección Personal',
                },
                {
                  options: {
                    spanish: 'Si',
                    any: 'No',
                  },
                  required: 'Selecciona el idioma del Jobber',
                },
                {
                  required: 'Selecciona el sexo del Jobber',
                },
              ],
              sections: [
                {
                  title: 'Inducción utilizada (skill)',
                  subtitle: 'Para este cliente y tipo de tarea, se está utilizando la inducción ',
                  subtitleEnd: ' como requisito necesario para postular a la tarea.',
                  subtitleBoth:
                    'Para este cliente y tipo de tarea, se están utilizando las inducciones ',
                  checkbox: 'Utilizar inducción general',
                  radio: {
                    specific: 'Utilizar inducción específica ',
                    general: 'Utilizar inducción general ',
                    both: 'Utilizar inducción general y/o específica.',
                  },
                  alert:
                    'Al seleccionar esta opción, más Jobbers podrán visualizar la(s) tarea(s).',
                  alertBoth:
                    'Al seleccionar esta opción, más Jobbers podrán visualizar la(s) tarea(s). Jobbers sólo verán los requisitos de la inducción general al postular.',
                },
                {
                  label: 'Requiere curso en terreno',
                  subtitleStart: 'Para este cliente y sede, se está utilizando el curso ',
                  subtitleEnd: ' como requisito necesario para postular a la tarea.',
                },
                {
                  title: 'Jobbers con inducción pendiente',
                  subtitle:
                    'Selecciona como verán la tarea aquellos Jobbers que NO aplican con la o las inducciones seleccionadas.',
                  radio: {
                    true: 'Visualizar tarea con inducción pendiente.',
                    false: 'No visualizar tarea.',
                  },
                },
                'Dispositivos',
                'Elementos de Protección Personal (EPP)',
                '¿El jobber necesita hablar español?',
                'Sexo Jobber',
              ],
            },
            {
              title: 'Tareas y pagos',
              messageInfo: 'Recuerda que cada tarea, se verá de manera independiente en el listado',
              messageNextDay: 'Esta tarea finaliza el día siguiente a la ejecución',
              messageQuotas:
                'Se ha reducido el número de cupos solicitados de la tarea, por lo que debes desasignar a 1 Jobber.',
              messageQuotas_plural:
                'Se ha reducido el número de cupos solicitados de la tarea, por lo que debes desasignar a {{count}} Jobbers.',
              messageHasConflict: 'Existe conflicto en el horario de las tareas',
              messageJobFinishedDate:
                'Esta fecha ya transcurrió {{day}}/{{month}}/{{year}}, por lo que los Jobbers asignados posteriormente, quedarán en estado finalizado, con la hora de ingreso y salida definida en la(s) tarea(s).',
              messageJobFinishedDateRequiringApproval:
                'Tareas creadas posterior a la fecha de ejecución deberán ser validadas por staff senior',
              messageJobFinishedHour:
                'Acabas de seleccionar una hora y fecha ya transcurridas, por lo que los Jobbers asignados posteriormente, quedarán en estado Finalizado, con la hora de ingreso y salida definida en la tarea.',
              messageJobFinishedHourSameDay:
                'Acabas de seleccionar una hora ya transcurrida, por lo que los Jobbers asignados posteriormente, quedarán en estado Finalizado, con la hora de ingreso y salida definida en la tarea.',
              messageJobOngoing:
                'Acabas de seleccionar una hora y fecha en curso, por lo que los Jobbers asignados posteriormente, quedarán en estado Presente, con la hora de ingreso definida en la tarea.',
              empty: {
                message: 'Faltan datos por completar',
                field: 'sin datos',
              },
              actions: ['Agregar otra tarea'],
              sections: ['Cupos tarea (Jobbers)', 'Horario tarea'],
              labels: {
                headerPanel: ['Horario tarea', 'Cupos solicitados'],
              },
              inputs: [
                {
                  label: 'Coordinador tarea (opcional)',
                },
                {
                  label: 'Solicitados',
                  required: 'Ingresa la cantidad de Jobbers',
                },
                {
                  label: 'Sobrecupos (opcional)',
                },
                {
                  label: 'Hora inicio',
                  required: 'Ingresa una hora de inicio',
                  sameTime: 'Ya existe una tarea que posee este horario',
                  notAllowed: 'Hora fuera del rango permitido',
                },
                {
                  label: 'Hora término',
                  required: 'Ingresa una hora de término',
                  notAllowed: 'Hora fuera del rango permitido',
                },
                {
                  label: 'Horario de almuerzo',
                },
                {
                  label: 'Sin horario definido',
                  required: 'sdsd',
                },
                {
                  title: 'Tiempo de almuerzo *',
                  options: '{{option}} minutos',
                  text: 'Selecciona el tiempo que tendrá el Jobber para almorzar. Estos minutos pueden ser utilizados en el horario que estime el local el día de la tarea.',
                },
                {
                  label: 'Comentario específico de la tarea (opcional)',
                  placeholder: 'Ingresa los detalles específicos para esta tarea',
                  max: 'Límite de caracteres alcanzado',
                },
              ],
            },
            {
              title: 'Publicación',
              subtitle: 'Publicación y repetición de tareas',
              label: 'Publicación de tareas',
              executionTasks: 'Ejecución tareas: {{input}}',
              titleCheck: 'Definir una publicación para todas las tareas',
              resumenEndDates: 'Resumen repetición tareas',
              resumenEndDatesValue: 'Desde: {{startDate}} Hasta: {{endDate}}',
              helpTexts: ['Publicación'],
              description:
                'Define la anticipación con la que quieres que el Jobber vea las tareas en la App. Por ejemplo, 2 días antes de la fecha de ejecución a las 10:00 hrs.',
              inputs: [
                {
                  label: 'Publicación en la App',
                  required: 'Este campo es obligatorio',
                  invalidOption: 'Esa fecha ya paso, te recomendamos publicar ahora mismo',
                },
                {
                  label: 'Hora de publicación',
                  required: 'Este campo es obligatorio',
                },
                {
                  label: 'Repetir tarea(s)',
                  textInfo: 'Aplica para todos las tareas creadas en el paso anterior',
                  title: 'Se repite(n) días:',
                  subtitle: 'Selecciona los días que quieres que se repita la o las tareas.',
                  selection: {
                    selectAll: 'Seleccionar todos',
                    unselectAll: 'Borrar selección',
                  },
                  schemasAlert:
                    'Esquemas de “cobro cliente” y “pago Jobber”, serán los mismos que los definidos en la creación de cada tarea del paso 3.',
                  warning:
                    'Una vez creada la orden de tarea, no podrás modificar la repetición de la(s) tarea(s).',
                },
              ],
            },
            [
              {
                title: 'Orden de tarea',
                dateInfo: 'Ejecución tareas',
                message:
                  'Revisa que todos los datos estén correctos, antes de confirmar la programación de la orden',
                fields: [
                  'Cliente comercial',
                  'Sede',
                  'Tipo de tarea',
                  'OPS responsable',
                  'KAM responsable',
                  'Detalles orden de tarea',
                  'Descripción',
                  'Ubicación orden de tarea',
                  'Radio de asistencia',
                  'Referencias de lugar de ingreso',
                  'Fotografía lugar de ingreso',
                  'URL Google Maps',
                  'Subnombre tipo de tarea',
                ],
                actions: {
                  reference: 'Ver imagen',
                  map: 'Ver mapa',
                },
              },
              {
                title: 'Jobber',
                fields: [
                  {
                    title: 'Inducción',
                    isGeneral: ' (Inducción general)',
                  },
                  'Curso en terreno',
                  'Visualización inducción',
                  'Dispositivo',
                  'Sexo Jobber',
                  'Elementos de Protección Personal (EPP)',
                  'Idioma',
                ],
              },
              {
                title: 'Tareas y pagos',
                fields: [
                  'Coordinador tarea',
                  'Cupos solicitados',
                  'Sobrecupos',
                  'Horario tarea',
                  'Horario almuerzo',
                  'Si (sin horario definido)',
                  'Descripción',
                  {
                    title: 'Cobro cliente',
                    schema: 'Esquema de cobro',
                    store: 'Sede',
                    currency: 'Moneda',
                    jobtype: 'Tarea',
                    variables: 'Variable(s)',
                  },
                  'Esquema de pago',
                  {
                    title: 'Pago horario de almuerzo',
                    options: {
                      true: 'Hora de almuerzo será pagada',
                      false: 'No pagado',
                    },
                  },
                  'Información adicional',
                  'Archivos',
                  'Links',
                ],
              },
              {
                title: 'Publicación y repetición de tareas',
                publication: 'Publicación',
                repeatTasks: 'Repetición de tareas',
                days: 'Días',
              },
              {
                checkbox: 'Guardar orden de tarea como plantilla',
                subtitle:
                  'Cuando programas una orden de tarea y también la guardas como plantilla, puedes utilizarla posteriormente como base para futuras ordenes de tarea.',

                update: {
                  checkbox: 'Actualizar plantilla',
                  subtitle:
                    'Cuando programas una orden de tarea y también la guardas como plantilla, puedes utilizarla posteriormente como base para futuras ordenes de tarea.',
                },
              },
            ],
          ],
        },
        edit: {
          title: 'Edición tarea',
          subtitle: 'Realiza los cambios necesarios en la tarea',
          jobTitle: 'Tarea',
          dateInfo: 'Fecha de ejecución tarea',
          message: 'Tarea modificada exitosamente',
          submitButton: 'Actualizar tarea',
          cancelModalOptions: {
            title: 'Cambios sin guardar',
            cancelText: '$t(global.saveChanges)',
            okText: '$t(global.exitWithoutSaving)',
            text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          },
          error: 'Completa los campos obligatorios para poder avanzar',
          negativeQuotasError:
            'No puedes disminuir la cantidad de Jobbers solicitados. Para disminuir los cupos solicitados o sobrecupos, primero desasigna la misma cantidad de Jobbers.',
          selectTimesPublish: [
            'Publicar ahora',
            '1 día antes de la ejecución',
            '2 días antes de la ejecución',
            '1 semana antes de la ejecución',
          ],
          jobHoursEditError: {
            before:
              'El horario ingresado modifica el estado de la tarea.\
              Ingresa un horario anterior a las {{hours}} hrs para no modificarlo.',
            after:
              'El horario ingresado modifica el estado de la tarea.\
              Ingresa un horario posterior a las {{hours}} hrs para no modificarlo.',
            bothBefore:
              'El horario ingresado modifica el estado de la tarea.\
              Ingresa un horario de inicio anterior a las {{start}} hrs y una hora de termino anterior a las {{end}} hrs para no modificar.',
            bothAfter:
              'El horario ingresado modifica el estado de la tarea.\
              Ingresa un horario de inicio posterior a las {{start}} hrs y una hora de termino posterior a las {{end}} hrs para no modificar.',

            start: {
              before:
                'El horario ingresado modifica el estado de la tarea. Ingresa una hora de inicio anterior a las {{start}} hrs. para no modificarlo.',
              after:
                'El horario ingresado modifica el estado de la tarea. Ingresa una hora de inicio posterior a las {{start}} hrs. para no modificarlo.',
            },
            end: {
              before:
                'El horario ingresado modifica el estado de la tarea. Ingresa una hora de término anterior a las {{end}} hrs. para no modificarlo.',
              after:
                'El horario ingresado modifica el estado de la tarea. Ingresa una hora de término posterior a las {{end}} hrs. para no modificarlo.',
            },
            both: {
              before:
                'El horario ingresado modifica el estado de la tarea.\
                Ingresa una hora de inicio anterior a las {{start}} hrs. y una hora de término anterior a las {{end}} hrs',
              after:
                'El horario ingresado modifica el estado de la tarea.\
                Ingresa una hora de inicio posterior a las {{start}} hrs. y una hora de término posterior a las {{end}} hrs',
              mix: 'El horario ingresado modifica el estado de la tarea.\
                Ingresa una hora de inicio anterior a las {{start}} hrs. y una hora de término posterior a las {{end}} hrs',
            },
          },
          sections: ['Cupos tarea (Jobbers)', 'Horario tarea'],
          inputs: [
            {
              label: 'Coordinador tarea (opcional)',
            },
            {
              label: 'Solicitados',
              required: 'Ingresa la cantidad de Jobbers',
            },
            {
              label: 'Sobrecupos (opcional)',
            },
            {
              label: 'Hora inicio',
              required: 'Ingresa una hora de inicio',
              sameTime: 'Ya existe una tarea que posee este horario',
            },
            {
              label: 'Hora término',
              required: 'Ingresa una hora de término',
              helper: 'Tiempo de almuerzo',
            },
            {
              label: 'Horario de almuerzo',
            },
            {
              label: 'Sin horario definido',
            },
            {
              label: 'Comentario específico de la tarea (opcional)',
              placeholder: 'Ingresa los detalles específicos para esta tarea',
            },
            {
              label: 'Entrega más información del porqué del cambio (opcional)',
              placeholder: 'Entrega más información del porqué del cambio',
            },
          ],
          extraInfo: {
            title: 'Información adicional (opcional)',
            subtitle:
              'Puedes agregar un link con los horarios y paradas, un documento, una fotografía o lo que necesites comunicar adicionalmente para esta tarea.',
            addFile: {
              content: 'Adjuntar archivo',
              helper: 'Peso máx. 10 mb - Formato: jpg, pdf, png',
            },
            addLink: { content: 'Insertar Link', helper: 'URL del contenido que verá el Jobber' },
            inputs: [
              {
                label: 'Texto para mostrar *',
                placeholder: 'Ej: Ver horarios y paradas',
              },
              {
                label: 'URL *',
                placeholder: 'Ej: www.horariosyparadasjobber.cl',
              },
            ],
          },
          changesComment: {
            title: 'Agregar un comentario sobre el o los cambios',
            subtitle:
              'Antes de guardar, deja un comentario para que tu equipo esté informado, sobre los cambios en:',
          },
          touchedFields: {
            coordinator: 'Coordinador',
            requiredQuotas: 'Cupos tarea solicitados',
            extraQuotas: 'Sobrecupos',
            startAt: 'Hora inicio tarea',
            endAt: 'Hora término tarea',
            lunchStartAt: 'Hora inicio almuerzo',
            lunchEndAt: 'Hora término almuerzo',
            lunch: 'Horario de almuerzo',
            noDefinedLunchTime: 'Almuerzo definido',
            comments: 'Comentario específico de la tarea',
            urls: 'Información adicional',
          },
        },
        edit_template: {
          title: 'Edición plantilla orden de tarea',
          subtitle: 'Realiza los cambios necesarios en la plantilla',
          submitButton: 'Guardar cambios',
          cancelModalOptions: {
            title: 'Cambios sin guardar',
            cancelText: '$t(global.saveChanges)',
            okText: '$t(global.exitWithoutSaving)',
            text: 'Se han editado uno o más campos. Al salir perderás todos los cambios realizados.',
          },
          inputs: [
            {
              label: 'Nombre plantilla *',
              placeholder: 'Ingresa un nombre para identificar esta plantilla',
              required: 'Ingresa un nombre para la plantilla',
              repeated: 'El nombre ingresado ya existe. Intenta con uno distinto',
              min: 'Debe ingresar un mínimo de {{input}} caracteres',
            },
          ],
        },
      },
    },
    bulkCreate: {
      title: 'Creación masiva de tareas',
      subtitle: 'Crea tareas masivas',
      titleEdit: 'Edición masiva de tareas',
      detailModal: {
        openDescription: 'Ver detalle',
        openStore: 'Ver ubicación',
        okText: 'Aceptar',
        description: {
          title: 'Detalles orden de tarea',
          comments: 'Comentario específico de la tarea',
        },
      },
      invalidModal: {
        title: 'Creación masiva de tareas',
        text: '1 o más tareas no tienen los campos completos',
        okText: 'Aceptar',
      },
      submitModal: {
        title: 'Creación masiva de tareas',
        emptyText: 'No hay tareas nuevas para crear, agregue alguna para continuar',
        text: 'Al dar click en Confirmar se creará {{count}} tarea (Sin contar repeticiones)',
        text_plural:
          'Al dar click en Confirmar se crearán {{count}} tareas (Sin contar repeticiones)',
        failedText:
          'Al dar click en Confirmar se creará {{count}} tarea (Solo se creará la tarea que falló en el intento anterior, sin contar repeticiones)',
        failedText_plural:
          'Al dar click en Confirmar se crearán {{count}} tareas (Solo se crearán las tareas que fallaron en el intento anterior, sin contar repeticiones)',
        repeatPublishText:
          'La publicación de las tareas repetidas será asignada según la diferencia entre las fechas de ejecución y publicación de la tarea principal, si la publicación es inmediata las repeticiones también se publicarán de forma inmediata.',
        pending: 'Creando tareas...',
        counter: 'Progreso: {{count}} de {{total}} tareas',
        failed: '{{count}} tarea no pudo ser creada',
        failed_plural: '{{count}} tareas no pudieron ser creadas',
        success: '{{count}} tarea creada',
        success_plural: '{{count}} tareas creadas',
        resultSuccess: 'Fue creada {{count}} tarea (Sin contar repeticiones)',
        resultSuccess_plural: 'Fueron creadas {{count}} tareas (Sin contar repeticiones)',
        resultFailed: 'No se pudo crear {{count}} tarea (Sin contar repeticiones)',
        resultFailed_plural: 'No se pudieron crear {{count}} tareas (Sin contar repeticiones)',
        okText: 'Confirmar',
      },
      submitEditModal: {
        title: 'Edición masiva de tareas',
        emptyText: 'No hay tareas para editar, agregue alguna para continuar',
        text: 'Al dar click en Confirmar se editará {{count}} tarea(s)',
        failedText:
          'Al dar click en Confirmar se editará {{count}} tarea (Solo se editará la tarea que falló en el intento anterior)',
        failedText_plural:
          'Al dar click en Confirmar se crearán {{count}} tareas (Solo se crearán las tareas que fallaron en el intento anterior)',
        failed: '{{count}} tarea no pudo ser editada',
        failed_plural: '{{count}} tareas no pudieron ser editadas',
        success: '{{count}} tarea editada',
        success_plural: '{{count}} tareas editadas',
        resultSuccess: 'Fue editada {{count}} tarea',
        resultSuccess_plural: 'Fueron editadas {{count}} tareas',
        resultFailed: 'No se pudo editar {{count}} tarea',
        resultFailed_plural: 'No se pudieron editar {{count}} tareas',
        message: 'Edición masiva finalizada',
        descriptionSuccess: 'Todas las tareas fueron editadas correctamente.',
        descriptionInfo: 'Una o mas tareas no fueron editadas correctamente.',
      },
      actions: {
        add: {
          input: 'Ingresa ID de la tarea',
          button: 'Agregar tarea',
          success: 'Tarea agregada al final de la lista',
          error: 'No existe una tarea con el código ingresado',
        },
        submit: 'Crear tareas masivas',
        submitEdit: 'Editar tareas masivas',
      },
      columns: {
        validation: {
          title: 'Estado',
          tags: {
            valid: 'Esta tarea tiene todos los campos completos y válidos',
            invalid: 'Esta tarea no tiene todos los campos completos y válidos',
            new: 'Esta tarea acaba de ser agregada',
            publishDateError:
              'La fecha de publicación debe ser por lo menos 1 día anterior a la fecha de ejecución',
            statusError: {
              finished:
                'La hora elegida modifica el estado de la tarea a Finalizada. Ingrese una hora apropiada para evitar ese cambio.',
              ongoing:
                'La hora elegida modifica el estado de la tarea a En curso. Ingrese una hora apropiada para evitar ese cambio.',
              published:
                'La hora elegida modifica el estado de la tarea a Publicada. Ingrese una hora apropiada para evitar ese cambio.',
            },
            repeatError: 'Datos para repetir tarea incompletos',
            repeat: 'La tarea se creará para el día {{- dates}}',
            repeat_plural: 'La tarea se creará para los días {{- dates}}',
            failed: 'Esta tarea no pudo ser creada (Código: {{code}})',
            success:
              'Esta tarea fue creada correctamente (Código: {{code}}), no se tomará en cuenta en la siguiente creación masiva',
          },
        },
        code: 'ID Tarea Original',
        job: 'Sede / Cliente / Tipo de Tarea',
        jobber: {
          title: 'Perfil Jobber',
          epps: 'Elementos de Protección Personal',
          cellphoneOS: 'Dispositivo: ',
          language: 'Idioma: ',
          genderAllowed: 'Sexo Jobber: ',
        },
        responsible: 'Responsables',
        quotas: 'Cupos',
        startAt: 'Fecha ejecución',
        lunch: {
          title: 'Horario de almuerzo',
          switchText: '¿Tiene hora de almuerzo?',
          inputs: [
            {
              label: 'Hora inicio',
              required: 'Ingresar una hora de inicio',
            },
            {
              label: 'Hora término',
              required: 'Ingresar una hora de término',
            },
            {
              label: 'Hora término',
              required: 'Ingresar una hora de término',
            },
            {
              label: 'Sin horario definido',
            },
          ],
        },
        publishAt: 'Fecha publicación',
        repeat: 'Repetir tarea (Opcional)',
        entrySchema: 'Esquema de cobro',
        paymentSchema: 'Esquema de pagos',
        actions: {
          title: 'Acciones',
          menu: {
            clone: 'Clonar',
            delete: 'Eliminar',
          },
        },
      },
      editable: {
        responsible: {
          responsibleOperator: 'Staff responsable (opcional)',
          kam: 'KAM responsable (opcional)',
        },
        quotas: {
          requiredQuotas: 'Solicitados',
          extraQuotas: 'Sobrecupos (Opcional)',
          errorTag: 'Cupos muy bajos',
          errorMessage:
            'Se ha reducido el número de cupos solicitados de la tarea, por lo que debes desasignar a 1 Jobber.',
          errorMessage_plural:
            'Se ha reducido el número de cupos solicitados de la tarea, por lo que debes desasignar a {{count}} Jobbers.',
        },
        startAt: {
          date: 'Fecha de ejecución',
          startHour: 'Hora inicio',
          endHour: 'Hora término',
        },
        publishAt: {
          date: 'Fecha de publicación',
          hour: 'Hora de publicación',
          alerts: {
            finished: {
              tag: 'Tarea finalizada',
              popover:
                'La fecha de ejecución ya transcurrió, por lo que los Jobbers asignados posteriormente, quedarán en estado Finalizado, con la hora de ingreso y salida definida en la tarea.',
            },
            ongoing: {
              tag: 'Tarea en curso',
              popover:
                'Esta tarea se creará en curso, por lo que los Jobbers asignados posteriormente, quedarán en estado Presente, con la hora de ingreso definida en la tarea.',
            },
          },
        },
        repeat: {
          days: 'Días',
          range: 'Rango',
        },
        entrySchema: {
          schema: 'Esquema de cobro',
          variables: {
            title: 'Variable(s)',
            popoverTitle: 'Descripción esquema',
            popoverText:
              'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
          },
        },
        paymentSchema: {
          schema: 'Esquema de pagos',
          variables: {
            min: 'Valor mayor a $t(global.currencySymbol){{min}}',
            max: 'Valor menor a $t(global.currencySymbol){{max}}',
            range:
              'Valor entre $t(global.currencySymbol){{min}} y $t(global.currencySymbol){{max}}',
          },
        },
      },
    },
    jobDetails: {
      subtitle: 'ID de tarea: {{code}}',
      actions: {
        back: 'Volver al listado de tareas',
        edit: 'Editar tarea',
        showDetails: {
          true: 'Ver menos detalles',
          false: 'Ver más detalles',
        },
        downloadReport: 'Descargar reporte de Jobbers',
        assignJobber: 'Asignar Jobbers no postulados',
      },
      cancelledAlert: 'La tarea fue cancelada por lo que no puedes realizar cambios en ella.',
      reportError: 'Ocurrió un error al descargar el archivo, inténtelo de nuevo',
      modal: {
        alertModal: {
          title: 'Jobber en período de desconexión',
          okText: 'Entendido',
        },
        assigned: {
          title: 'Asignar Jobber',
          okText: 'Asignar Jobber',
          text: 'Al asignar al Jobber {{jobber}}, quedará asignado/a a la tarea {{job}}',
          successMessage: 'El Jobber seleccionado fue asignado exitosamente.',
          successMessage_plural: 'Los Jobbers seleccionados fueron asignados exitosamente.',
          alertMessage:
            'El jobber {{jobbers}}, ya se encuentra asignado a otra tarea, por lo que no fue asignado a esta.',
          alertMessage_plural:
            'Los Jobbers {{jobbers}}, ya se encuentran asignados a otra tarea, por lo que no fueron asignados a esta.',
          disconnectedMessage:
            'El Jobber {{jobbers}} no puede ser asignado, ya que se encuentra en su período de desconexión (12 horas), según la nueva “Ley de plataformas digitales”.',
          disconnectedMessage_plural:
            'Los Jobbers {{jobbers}} se encuentran en su período de desconexión, por lo que no pueden ser asignados. Para continuar, quítalos(as) y selecciona otros Jobbers.',
          withOutContractMessage:
            'El Jobber {{jobbers}} no cuenta con todos los contratos firmados. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
          withOutContractMessage_plural:
            'Los Jobbers {{jobbers}} no cuentan con con todos los contratos firmados. Para continuar, quitalos(as) y selecciona otros Jobbers.',
          bulk: {
            title: 'Asignar Jobbers',
            okText: 'Asignar Jobbers',
            text: 'Selecciona el o los Jobber que quieres asignar a esta tarea. Una vez asignados, podrás verlos en la sección “Jobbers asignados”.',
          },
          manual: {
            title: 'Asignar Jobbers no postulados',
            okText: 'Asignar Jobbers',
            text: 'Acá podrás agregar aquellos Jobbers que no hayan postulado a la tarea, para que asistan a ella. Una vez asignados, podrás verlos en la sección “Jobbers asignados”.',
            overlapped: {
              same: 'El Jobber {{jobbers}} ya fue asignado a esta tarea. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
              same_plural:
                'Los Jobbers {{jobbers}} ya se encuentran asignados(as) a esta tarea. Para continuar, quitalos(as) y selecciona otros Jobbers.',
              other:
                'El Jobber {{jobbers}} se encuentra asignado a una tarea en el mismo horario. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
              other_plural:
                'Los Jobbers {{jobbers}} se encuentran asignados(as) a una tarea en el mismo horario. Para continuar, quitalos(as) y selecciona otros Jobbers.',
            },
            blockedMessage:
              'El Jobber {{jobbers}} se encuentra bloqueado. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
            blockedMessage_plural:
              'Los Jobbers {{jobbers}} se encuentran bloqueados. Para continuar, quitalos(as) y selecciona otros Jobbers.',
            disconnectedMessage:
              'El Jobber {{jobbers}} se encuentra en su período de desconexión, por lo que no puede ser asignado. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
            disconnectedMessage_plural:
              'Los Jobbers {{jobbers}} se encuentran en su período de desconexión, por lo que no pueden ser asignados. Para continuar, quítalos(as) y selecciona otros Jobbers.',
            withOutContractMessage:
              'El Jobber {{jobbers}} no cuenta con todos los contratos firmados. Para continuar, quita a {{jobberName}} y selecciona otro Jobber.',
            withOutContractMessage_plural:
              'Los Jobbers {{jobbers}} no cuentan con con todos los contratos firmados. Para continuar, quitalos(as) y selecciona otros Jobbers.',
            select: {
              label: 'Jobbers *',
              placeholder: 'Selecciona o busca por RUT o email',
              searchText: 'Ingresa 3 caracteres para buscar',
              emptyText: 'No hay datos',
            },
          },
        },
        cancelled: {
          title: 'Descartar Jobber',
          okText: 'Descartar Jobber',
          text: 'Al descartar la postulación del Jobber {{jobber}}, este/a será eliminado de la tabla.',
          successMessage: 'El Jobber seleccionado fue descartado exitosamente.',
          bulk: {
            title: 'Descartar Jobbers',
            okText: 'Descartar Jobbers',
            text: 'Selecciona el o los Jobber que quieres descartar de esta tarea. Una vez descartados, serán eliminados de la tabla.',
            successMessage: 'Los Jobbers seleccionados fueron descartados exitosamente.',
          },
        },
        deallocated: {
          title: 'Desasignar Jobber',
          okText: 'Desasignar Jobber',
          text: 'Al desasignar al Jobber {{jobber}}, este/a será transladado a la lista de postulaciones.',
          successMessage: 'El Jobber seleccionado fue desasignado exitosamente.',
          successMessage_plural: 'Los Jobbers seleccionados fueron desasignados exitosamente.',
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Desasignado" por un staff',
              default: 'No pudimos desasignar al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Desasignar Jobbers',
            okText: 'Desasignar Jobbers',
            text: 'Selecciona el o los Jobber que quieres desasignar de esta tarea. Una vez desasignados, serán transladados a la lista de postulaciones.',
          },
        },
        absent: {
          title: 'Marcar ausente',
          okText: 'Marcar ausente',
          text: 'Jobber quedará en estado Ausente, independiente si la tarea ha terminado o no.',
          successMessage: 'El Jobber quedó marcado como Ausente exitosamente.',
          successMessage_plural: 'Los Jobbers quedaron marcados como Ausente exitosamente.',
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Ausente" por un staff',
              default: 'No pudimos marcar como ausente al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Marcar ausente',
            okText: 'Marcar ausente',
            text: 'Jobbers',
            textBold: ' quedarán ausentes',
            textEnd: ', independiente de si la tarea ha terminado o no.',
            failedMessage: 'El Jobber {{jobbers}} ya fue marcado como "Ausente" por un staff',
            failedMessage_plural:
              'Los Jobbers {{jobbers}} ya fueron marcados como "Ausente" por un staff',
          },
        },
        present: {
          title: 'Marcar presente',
          okText: 'Marcar presente',
          text: 'Selecciona el horario en que el Jobber ingresó a la tarea. El Jobber quedará presente el mismo día de ejecución de la tarea ({{- date}})',
          successMessage: 'El Jobber quedó marcado como Presente exitosamente.',
          successMessage_plural: 'Los Jobbers quedaron marcados como Presente exitosamente.',
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Presente" por un staff',
              default: 'No pudimos marcar como presente al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Marcar presente',
            okText: 'Marcar presente',
            text: 'Jobbers quedarán',
            textBold: ' presentes el mismo día y hora de entrada a la tarea ',
            textEnd: '{{- value}}',
            extraText:
              'Para modificar esta hora/fecha, puedes hacerlo desde el detalle de cada Jobber.',
            failedMessage: 'El Jobber {{jobbers}} ya fue marcado como "Presente" por un staff',
            failedMessage_plural:
              'Los Jobbers {{jobbers}} ya fueron marcados como "Presente" por un staff',
          },
        },
        finished: {
          title: 'Marcar salida',
          okText: 'Marcar salida',
          text: 'Selecciona el horario en que el Jobber terminó a la tarea. Se marcará la salida el mismo día de ejecución de la tarea ({{- date}})',
          bulk: {
            title: 'Marcar salida',
            okText: 'Marcar salida',
            text: 'Jobbers quedarán',
            textBold: ' finalizados el mismo día y hora de salida a la tarea ',
            textEnd: '{{- value}}',
            extraText:
              'Para modificar esta hora/fecha, puedes hacerlo desde el detalle de cada Jobber.',
            failedMessage: 'El Jobber {{jobbers}} ya fue marcado como "Finalizado" por un staff',
            failedMessage_plural:
              'Los Jobbers {{jobbers}} ya fueron marcados como "Finalizado" por un staff',
          },
        },
        absentToFinished: {
          title: 'Marcar asistencia',
          okText: 'Marcar Asistencia',
          text: 'Se marcará la hora de entrada y salida automaticamente. La asistencia se marcará el mismo día de ejecución de la tarea.',
          successMessage: 'El Jobber quedó marcado como Finalizado exitosamente.',
          errors: {
            code: {
              409: 'El Jobber ya fue marcado como "Finalizado" por un staff',
              default: 'No pudimos marcar como finalizado al jobber. Intenta nuevamente',
            },
          },
          bulk: {
            title: 'Marcar asistencia',
            okText: 'Marcar asistencia',
            text: 'Se marcará la hora de entrada y salida automaticamente. La asistencia se marcará el mismo día de ejecución de la tarea.',
            extraText:
              'Para modificar esta hora/fecha, puedes hacerlo desde el detalle de cada Jobber.',
          },
        },
        sendRoster: {
          button: 'Enviar nómina de Jobbers',
          title: 'Enviar nómina de asignados, presentes y finalizados',
          okText: 'Enviar nómina',
          text: 'Acá podrás revisar la nómina con los distintos estados del Jobber en la tarea. KAM y Staff responsables recibirán una copia del email.',
        },
      },
      details: {
        jobTitle: 'Detalles Orden de tarea',
        enterprise: 'Cliente comercial: ',
        store: 'Sede: ',
        type: 'Tipo de tarea: ',
        typeDescription: 'Descripción tipo de tarea: ',
        publishAt: 'Fecha de ejecución: ',
        responsibleOperator: 'Staff responsable: ',
        kam: 'KAM responsable: ',
        taskTitle: 'Detalles Tarea',
        coordinator: 'Coordinador tarea: ',
        requiredQuotas: 'Cupos solicitados: ',
        extraQuotas: 'Sobrecupos: ',
        schedule: 'Horario tarea: ',
        lunchTime: 'Almuerzo: ',
        hourlyRate: 'Pago por hora: ',
        skuRate: 'Pago por SKU: ',
        entryReference: 'Referencias de lugar de ingreso',
        map: {
          title: 'URL Google Maps',
          action: 'Ver mapa',
        },
        entryPhoto: 'Fotografía lugar de ingreso',
        details: 'Detalles orden de tarea',
        epps: 'Elementos de Protección Personal (EPP)',
        cellphoneOS: 'Dispositivo: ',
        language: 'Idioma: ',
        genderAllowed: 'Sexo Jobber: ',
        comments: 'Comentario específico de la tarea',
        paymentSchema: 'Esquema de pago',
        paymentSchemaPrefix: 'Pago por',
        invoiceSchema: 'Esquema de cobro',
        invoiceSchemaPrefix: 'Cobro por',
        extraInfo: 'Información adicional',
        additionalLinks: 'Links',
        additionalFiles: 'Archivos',
      },
      applications: {
        title: 'Postulaciones a la tarea',
        subtitle: 'Revisa el listado de Jobbers que están postulando a la tarea',
        filterSearch: { title: 'Buscar', placeholder: 'Busca por Jobber' },
        filterClear: { title: 'Limpiar filtros' },
        maxQuotasAlert: 'Puedes seleccionar un máximo de 1 Jobber para ser asignado.',
        maxQuotasAlert_plural:
          'Puedes seleccionar un máximo de {{count}} Jobbers para ser asignados.',
        filledQuotasAlert:
          'Se han asignados todos los cupos solicitados para esta tarea. Revisa el seguimiento de los Jobbers en la sección "Jobbers asignados".',
        asignFinishedAlert:
          'Aquellos Jobbers que sean asignados finalizada la tarea, quedarán por defecto con horario de ingreso y salida, de acuerdo al horario fijado de la tarea. Si deseas modificar este horario, debes hacerlo en el detalle de cada Jobber.',
        table: {
          columns: [
            'Jobber',
            {
              CL: 'Región/Comuna',
              PE: 'Depto./Provincia',
              CO: 'Depto./Municipio',
              MX: 'Estado/Municipio',
            },
            {
              title: 'Rotación',
              popover: 'Tareas asignadas en igual cliente esta semana (lunes a domingo)',
            },
            {
              title: 'Riesgo',
              popover:
                'Porcentaje de riesgo que tiene el Jobber de cancelar la tarea. Cálculo en base a cancelaciones y ausentismo anterior.',
            },
            {
              title: 'Ocupación',
              popover:
                'Número de días en que el jobber ha sido asignados / Número de días en que el jobber ha postulado',
            },
            'Estado',
            'Acciones',
          ],
          stats: {
            job: '{{count}} tarea',
            job_plural: '{{count}} tareas',
            assigned: '{{count}} asignación',
            assigned_plural: '{{count}} asignaciones',
            applied: 'de {{count}} postulación',
            applied_plural: 'de {{count}} postulaciones',
            allJobs: 'en todas las tareas',
            sameJob: 'en igual tarea',
          },
          emptyText: 'No hay postulaciones',
        },
        newJobbers: {
          tooltip: {
            tag: {
              text: 'Jobber con menos de 3 tareas realizadas en verticales de retail y labour',
            },
            switch: {
              text: 'Jobbers con menos de 3 tareas realizadas en verticales de retail y labour',
              label: 'Ver sólo Jobbers nuevos',
            },
          },
          new: 'Nuevo',
        },
      },
      assigned: {
        title: 'Jobbers Asignados',
        subtitle: 'Revisa los Jobbers asignados a la tarea',
        table: {
          columns: [
            'Jobber',
            'Asistencia',
            'Hora entrada',
            'Hora salida',
            'Horas a pagar',
            'Estado',
            'Acciones',
          ],
          expandable: ['$t(global.email)', 'Ubicación'],
          emptyText: 'No hay Jobber asignados',
          actions: {
            present: 'Marcar presente',
            absent: 'Marcar ausente',
            deallocated: 'Desasignar Jobber',
            comment: 'Dejar un comentario',
            finished: 'Marcar salida',
            absentToFinished: 'Marcar asistencia',
          },
        },
      },
      applied: {
        table: {
          actions: {
            present: 'Marcar presente',
            absent: 'Marcar ausente',
            deallocated: 'Desasignar Jobber',
            comment: 'Dejar un comentario',
            finished: 'Marcar salida',
            absentToFinished: 'Marcar asistencia',
          },
        },
      },
      history: {
        header: {
          title: 'Datos Jobber',
          nationality: 'Nacionalidad',
          phone: 'Teléfono',
          address: 'Dirección',
          email: '$t(global.email)',
          region: '$t(global.region)',
          commune: '$t(global.commune)',
          documentType: {
            ce: 'CE',
            rut: 'RUT',
            ruc: 'RUC',
            dni: 'DNI',
            ptp: 'PTP',
            ppt: 'PPT',
            cpp: 'CPP',
            curp: 'CURP',
            pasaporte: 'Pasaporte',
            cedula_de_identidad: 'CI',
            cedula_de_ciudadania: 'CC',
            cedula_de_extranjeria: 'CE',
            pep: 'PEP',
            numero_de_identificacion_tributaria: 'NIT',
          },
          gender: {
            title: 'Género',
            male: 'Masculino',
            female: 'Femenino',
          },
        },
        records: {
          title: 'Historial del Jobber en la tarea',
          actions: {
            jobber: {
              applied: 'Jobber postula a la tarea',
              present: 'Jobber marca como presente',
              preAssignmentCancellation: 'Jobber cancela postulacion',
              postAssignmentCancellation: 'Jobber cancela tarea asignada',
              finished: 'Jobber marca salida',
              delayedCompletion: 'Jobber marca salida con atraso',
              reasonType: {
                late_assignment: 'Me asignaron tarde a la tarea',
                same_day_assignment: 'Fui asignado a una tarea el mismo día',
                automatic: '3 horas posterior al término de la tarea',
              },
            },
            staff: {
              applied: 'Staff postula a la tarea',
              assigned: 'Staff asigna jobber a la tarea',
              present: 'Staff marca como presente',
              absent: 'Staff marca como ausente',
              deallocated: 'Staff desasigna Jobber',
              finished: 'Staff marca salida',
              delayedCompletion: 'Salida marcada por el sistema',
              reasonType: {
                automatic: '3 horas posterior al término de la tarea',
              },
            },
            notification: {
              assigned: 'Envío de notificación “Asignación”',
              deallocated: 'Envío de notificación "Desasignado"',
              job_started: 'Envío de notificación “Comienzo tarea”',
              job_finished: 'Envío de notificación “Finalización tarea”',
              readed: 'Jobber revisa la notificación',
              create_finished_application: 'Envío notificación "Asistencia a tarea finalizada"',
            },
          },
          card: {
            present: {
              title: 'Editar hora y fecha de ingreso',
              floatInputDate: 'Fecha de ingreso',
              floatInputHour: 'Hora de ingreso',
            },
            finished: {
              title: 'Editar hora y fecha de salida',
              floatInputDate: 'Fecha de salida',
              floatInputHour: 'Hora de salida',
            },
            verifyUpdate: {
              title: 'Edición impactará orden de pago',
              message: 'Al confirmar la edición, esta impactará el cálculo de la orden de pago.',
              button: 'Confirmar edición',
            },
            text: 'Puedes modificar la hora, seleccionando un horario anterior o posterior a lo marcado por el Jobber. También puedes modificar la fecha si el turno abarca 2 días.',
            error: 'Hora fuera del rango permitido',
            cancelButton: 'Cancelar',
            updateButton: 'Guardar Cambios',
          },
          extraHours: {
            accepted: 'Aceptadas por',
            modified: 'Rechazadas por',
            rejected: 'Modificadas por',
            automaticallyRejected: 'Rechazadas automáticamente',
            hoursAndMinutes: 'Horas extras: {{hours}} hora(s) y {{minutes}} minuto(s).',
            minutes: 'Horas extras: {{minutes}} minuto(s).',
          },
        },
        parallelJobs: {
          title: 'Tareas postuladas en el mismo horario por el Jobber',
        },
        comments: {
          title: 'Comentarios',
          subtitle: 'Revisa el historial de comunicación del Jobber y la tarea.',
          form: {
            create: {
              title: 'Agregar comentario',
              body: 'Aquí puedes dejar registro de llamadas y mensajes enviados al Jobber. También puedes agregar feedback del cliente o coordinador de sala en relación al Jobber.',
            },
            update: {
              title: 'Editar comentario',
              body: 'Al editar el comentario, este actualizará la hora y fecha en que fue agregado.',
            },
            commentType: 'Tipo de comentario *',
            message: {
              label: 'Escribe un comentario *',
              placeholder: 'Ingresa más detalles del comentario',
            },
          },
        },
      },
      hourInfoModal: {
        button: 'Entendido',
        present: {
          title: 'Criterios en Hora de entrada',
          item1:
            'Si Staff marca o modifica la entrada, esta queda <strong>registrada de acuerdo a lo que staff indique.</strong>',
          item2:
            'Si Jobber <strong>marca entrada hasta 30 min. antes del inicio de tarea,</strong> se registra como entrada el inicio de la tarea.',
          item3:
            'Si Jobber marca <strong>entrada entre el inicio de tarea y el final de la tarea,</strong> se registra como entrada la hora de marcación del Jobber.',
        },
        finish: {
          title: 'Criterios en Hora de salida',
          item1:
            'Si Staff marca o modifica la salida, esta queda registrada de acuerdo a lo que staff indique.',
          item2:
            'Si Jobber <strong>marca salida antes de la hora de término de la tarea,</strong> se registra como salida lo marcado por el Jobber.',
          item3:
            'Si Jobber <strong>marca salida hasta 30 min. después del término</strong> de la tarea, se registra el término de la tarea.',
          item4:
            'Si Jobber <strong>marca salida entre 30 min. después del término y antes de 3 horas después del término de la tarea</strong> y NO se autorizaron horas extras, se registra el término de la tarea. Si estas se autorizan, se registra lo marcado por el Jobber.',
          item5:
            'Si pasan <strong>3 horas desde el término de la tarea y el jobber no ha marcado salida,</strong> esta se realiza de forma automática, quedando como hora de finalización la hora de término de la tarea.',
        },
      },
    },
  },
};
