import axios from 'axios';
import { stringify } from 'qs';

import LocalStorageService from '@utils/localStorage';
import Router from 'next/router';

import { registerApp } from '@features/auth/authSlice';
import store from '@app/store';
import { version } from '../../package.json';

const BASE_URL_API = process.env.NEXT_PUBLIC_BASE_URL_API;

const localStorageService = LocalStorageService.getService();

const server = axios.create({
  baseURL: BASE_URL_API,
  headers: { 'Content-type': 'application/json', 'x-version': version },
});

server.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorageService.getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

server.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === '/auth/oath/token') {
      Router.push('/auth/log-in');
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const response = await store.dispatch(registerApp());
      if (response.payload.data.accessToken)
        return server(originalRequest, {
          headers: { Authorization: `Bearer ${response.payload.data.accessToken}` },
        });
    }

    return Promise.reject(error);
  }
);

server.defaults.paramsSerializer = (params) => stringify(params, { indices: false });
export default server;
