import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestStatusEnum } from '@utils/enum';
import {
  EditClientInfo,
  GetClientLists,
  GetClientsParamsInterfaces,
  blockClientInfo,
  createClientPayload,
  unblockClientInfo,
} from './ts/clientsEnterprise.interfaces';
import server from '@services/api';
import {
  blockClientRoute,
  createClientRoute,
  editClientRoute,
  getClientsRoute,
  unblockClientRoute,
} from './ts/routes';
import { pendingStateHandler, storeStateHandler } from '@utils/functions';
import i18n from '@i18n/index';

export const clientsAdapter = createEntityAdapter();

export const initialState: any = clientsAdapter.getInitialState({
  clients: {
    loading: RequestStatusEnum.IDLE,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  createClientResponse: {
    status: null,
    loading: RequestStatusEnum.IDLE,
    message: null,
  },
  editClientResponse: {
    status: null,
    loading: RequestStatusEnum.IDLE,
  },
  blockClientResponse: {
    status: null,
    loading: RequestStatusEnum.IDLE,
    message: null,
  },
  unblockClientResponse: {
    status: null,
    loading: RequestStatusEnum.IDLE,
    message: null,
  },
  updateClientResponseAlert: {
    status: null,
    message: null,
  },
  loading: RequestStatusEnum.IDLE,
  currentRequestId: undefined,
});

export const getClientsByEnterprise = createAsyncThunk(
  'clientsEnterprise/getClientsByEnterprise',
  async (params: GetClientsParamsInterfaces) => {
    const response: GetClientLists = await server({
      method: 'get',
      url: getClientsRoute(),
      params,
    });
    return response?.data;
  }
);

export const createClient = createAsyncThunk(
  'clientsEnterprise/createClient',
  async (params: createClientPayload, { getState, requestId, rejectWithValue }: any) => {
    try {
      const { currentRequestId } = getState().clientsEnterprise;
      if (requestId !== currentRequestId) {
        return;
      }
      const response: GetClientLists = await server.post(createClientRoute(), params);
      return response?.data;
    } catch (error) {
      const { status } = error.request;
      return rejectWithValue({ status });
    }
  }
);

export const editClient = createAsyncThunk(
  'clientEnterprise/editClient',
  async (params: EditClientInfo, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId } = getState().clientsEnterprise;
      if (requestId !== currentRequestId) {
        return;
      }

      const { roles } = params;

      const response = await server.put(editClientRoute(params.id), {
        roles,
      });
      return response?.data;
    } catch (error) {
      const { status } = error.request;
      return rejectWithValue({ status });
    }
  }
);

export const blockClient = createAsyncThunk(
  'clientEnterprise/blockClient',
  async (payload: blockClientInfo, { getState, requestId }: any) => {
    const { currentRequestId } = getState().clientsEnterprise;
    if (requestId !== currentRequestId) {
      return;
    }
    const response = await server.put(blockClientRoute(), payload);
    return response.data;
  }
);

export const unblockClient = createAsyncThunk(
  'clientEnterprise/unblockClient',
  async (payload: unblockClientInfo, { getState, requestId }: any) => {
    const { currentRequestId } = getState().clientsEnterprise;
    if (requestId !== currentRequestId) {
      return;
    }
    const response = await server.put(unblockClientRoute(), payload);
    return response.data;
  }
);

const clientsEnterpriseSlice = createSlice({
  name: 'clientsEnterprise',
  initialState,
  reducers: {
    clearState: (state, b: { payload: { key: string; subkey?: string; base?: string } }) => {
      const { key, subkey, base } = b.payload ?? {};
      const originalState = base ?? RequestStatusEnum.IDLE;
      if (subkey && state[key]?.[subkey]) state[key][subkey] = originalState;
      else if (key && state[key]) state[key] = originalState;
    },
    clearCreateClient: (state) => {
      state.createClientResponse = initialState.createClientResponse;
    },
    clearEditClient: (state) => {
      state.editClientResponse = initialState.editClientResponse;
    },
    clearBlockClient: (state) => {
      state.blockClientResponse = initialState.blockClientResponse;
    },
    clearUnblockClient: (state) => {
      state.unblockClientResponse = initialState.unblockClientResponse;
    },
    clearUpdateClientResponseAlert: (state) => {
      state.updateClientResponseAlert = initialState.updateClientResponseAlert;
    },
  },
  extraReducers: {
    [String(getClientsByEnterprise.pending)]: (state, action) => {
      state.clients.loading = RequestStatusEnum.PENDING;
      pendingStateHandler(state, action);
    },
    [String(getClientsByEnterprise.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.clients = {
          ...state.clients,
          loading: RequestStatusEnum.IDLE,
          ...action.payload?.data,
        };
        storeStateHandler(state, action);
      }
    },
    [String(getClientsByEnterprise.rejected)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.clients.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        storeStateHandler(state, action);
      }
    },

    [String(createClient.pending)]: (state, action) => {
      state.createClientResponse.loading = RequestStatusEnum.PENDING;
      pendingStateHandler(state, action);
    },
    [String(createClient.fulfilled)]: (state, action) => {
      state.createClientResponse = {
        message: i18n.t('enterprises.enterprisesProfile.tabs.clientsProfiles.alert.create.success'),
        status: 'success',
        loading: RequestStatusEnum.IDLE,
      };

      state.updateClientResponseAlert = {
        status: 'success',
        message: i18n.t('enterprises.enterprisesProfile.tabs.clientsProfiles.alert.create.success'),
      };
      storeStateHandler(state, action);
    },
    [String(createClient.rejected)]: (state, action) => {
      state.createClientResponse.loading = RequestStatusEnum.IDLE;
      if (action.payload?.status === 409) state.createClientResponse.status = 'alreadyTaken';
      else {
        state.createClientResponse.status = 'error';
        state.createClientResponse.message = i18n.t(
          'enterprises.enterprisesProfile.tabs.clientsProfiles.alert.create.error'
        );

        state.updateClientResponseAlert = {
          status: 'error',
          message: i18n.t('enterprises.enterprisesProfile.tabs.clientsProfiles.alert.create.error'),
        };
      }
      storeStateHandler(state, action);
    },

    [String(editClient.pending)]: (state, action) => {
      state.editClientResponse.loading = RequestStatusEnum.PENDING;
      pendingStateHandler(state, action);
    },
    [String(editClient.fulfilled)]: (state, action) => {
      storeStateHandler(state, action);
      state.editClientResponse = {
        status: 'success',
        loading: RequestStatusEnum.IDLE,
        message: i18n.t(
          `enterprises.enterprisesProfile.tabs.clientsProfiles.alert.edit.${action.meta.arg.type}.success`
        ),
      };

      state.updateClientResponseAlert = {
        status: 'success',
        message: i18n.t(
          `enterprises.enterprisesProfile.tabs.clientsProfiles.alert.edit.${action.meta.arg.type}.success`
        ),
      };
    },
    [String(editClient.rejected)]: (state, action) => {
      state.editClientResponse = {
        status: 'error',
        loading: RequestStatusEnum.IDLE,
        message: i18n.t(
          `enterprises.enterprisesProfile.tabs.clientsProfiles.alert.edit.${action.meta.arg.type}.error`
        ),
      };

      state.updateClientResponseAlert = {
        status: 'error',
        message: i18n.t(
          `enterprises.enterprisesProfile.tabs.clientsProfiles.alert.edit.${action.meta.arg.type}.error`
        ),
      };

      storeStateHandler(state, action);
    },

    [String(blockClient.pending)]: (state, action) => {
      state.blockClientResponse.loading = RequestStatusEnum.PENDING;
      pendingStateHandler(state, action);
    },
    [String(blockClient.fulfilled)]: (state, action) => {
      state.blockClientResponse = {
        message: i18n.t(
          'enterprises.enterprisesProfile.tabs.clientsProfiles.alert.blocked.success',
          {
            name: action.meta.arg.fullName,
          }
        ),
        status: 'success',
        loading: RequestStatusEnum.IDLE,
      };

      state.updateClientResponseAlert = {
        status: 'success',
        message: i18n.t(
          'enterprises.enterprisesProfile.tabs.clientsProfiles.alert.blocked.success',
          {
            name: action.meta.arg.fullName,
          }
        ),
      };
      storeStateHandler(state, action);
    },
    [String(blockClient.rejected)]: (state, action) => {
      state.blockClientResponse = {
        message: i18n.t('enterprises.enterprisesProfile.tabs.clientsProfiles.alert.blocked.error', {
          name: action.meta.arg.fullName,
        }),
        status: 'error',
        loading: RequestStatusEnum.IDLE,
      };

      state.updateClientResponseAlert = {
        status: 'error',
        message: i18n.t('enterprises.enterprisesProfile.tabs.clientsProfiles.alert.blocked.error', {
          name: action.meta.arg.fullName,
        }),
      };

      storeStateHandler(state, action);
    },

    [String(unblockClient.pending)]: (state, action) => {
      state.unblockClientResponse.loading = RequestStatusEnum.PENDING;
      pendingStateHandler(state, action);
    },
    [String(unblockClient.fulfilled)]: (state, action) => {
      state.unblockClientResponse = {
        message: i18n.t(
          'enterprises.enterprisesProfile.tabs.clientsProfiles.alert.unblocked.success',
          {
            name: action.meta.arg.fullName,
          }
        ),
        status: 'success',
        loading: RequestStatusEnum.IDLE,
      };

      state.updateClientResponseAlert = {
        status: 'success',
        message: i18n.t(
          `enterprises.enterprisesProfile.tabs.clientsProfiles.alert.unblocked.success`,
          {
            name: action.meta.arg.fullName,
          }
        ),
      };
      storeStateHandler(state, action);
    },
    [String(unblockClient.rejected)]: (state, action) => {
      state.unblockClientResponse = {
        message: i18n.t(
          'enterprises.enterprisesProfile.tabs.clientsProfiles.alert.unblocked.error',
          {
            name: action.meta.arg.fullName,
          }
        ),
        status: 'error',
        loading: RequestStatusEnum.IDLE,
      };

      state.unblockClientResponse = {
        status: 'error',
        message: i18n.t(
          `enterprises.enterprisesProfile.tabs.clientsProfiles.alert.unblock.${action.meta.arg.type}.error`,
          {
            name: action.meta.arg.fullName,
          }
        ),
      };

      storeStateHandler(state, action);
    },
  },
});

export const {
  clearState,
  clearCreateClient,
  clearBlockClient,
  clearEditClient,
  clearUnblockClient,
  clearUpdateClientResponseAlert,
} = clientsEnterpriseSlice.actions;

export default clientsEnterpriseSlice.reducer;
