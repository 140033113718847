export const skillsRoute = (): string => '/academy/skills';

export const skillRoute = (id: string): string => `/academy/skills/${id}`;

export const getSkillsCountSummaryRoute = (): string => 'academy/skills/summary-counters';

export const skillExistsRoute = (): string => '/academy/skills/exists';

export const putUserSkillsRoute = (id: string): string => `/account/jobbers/${id}/skills`;

export const postFinalizeCourseInSkills = (id: string): string =>
  `/account/jobbers/${id}/finalize-courses`;

export const postRemoveCourseInSkills = (id: string): string =>
  `/account/jobbers/${id}/remove-courses`;

export const finalizeSkillInProgressRoute = (id: string): string =>
  `/account/jobbers/${id}/skills/finalize`;

export const recalculateSkillFinalizedRoute = (id: string): string =>
  `/account/jobbers/${id}/skills/recalculate-progress`;
