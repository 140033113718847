import { CountryEnum } from '@utils/enum';

export const getLocationFirstLevel = (countryIso: CountryEnum | '') =>
  countryIso === 'PE' ? 'locations/cities' : 'locations/regions';

export const getLocationSecondLevel = () => {
  return 'locations/localities';
};

export const editDemandRoute = (): string => 'locations/localities/demand';

export const editNeedOfJobbersRoute = (): string => 'locations/localities/need-of-jobbers';

export const editFirstLevelStatusRoute = (id: string, city: boolean): string =>
  `/locations/${city ? 'cities' : 'regions'}/${id}/is-active`;

export const toggleSecondLevelActiveRoute = (): string => 'locations/localities/is-active';
