export default {
  localities: {
    subtitle:
      'Revisa y edita los $t(global.commune_plural, lowercase) activos por cada $t(global.region, lowercase)',
    activeLocalities: {
      title: '$t(global.commune_plural) activos $t(global.region)',
      activeAmount: '{{count}} $t(global.commune, lowercase) activo',
      activeAmount_plural: '{{count}} $t(global.commune_plural, lowercase) activos',
      actionActivateOthers: 'Activar otros $t(global.commune_plural, lowercase)',
      empty:
        'Actualmente no tienes $t(global.commune_plural, lowercase) activados. Desactiva la $t(global.region, lowercase) o activa el $t(global.commune, lowercase) que necesites en el botón ACTIVAR OTROS $t(global.commune_plural, uppercase)',
      emptyDisabled:
        'Actualmente tu $t(global.region, lowercase) está DESACTIVADA. Debes activarla para poder activar el o los $t(global.commune_plural, lowercase) que necesites',
    },
    disabledLocalities: {
      title: '$t(global.commune_plural) desactivados $t(global.region)',
      subtitle:
        'Listado de $t(global.commune_plural, lowercase) desactivados de la $t(global.region, lowercase)',
      disabledAmount: '{{count}} $t(global.commune, lowercase) desactivado',
      disabledAmount_plural: '{{count}} $t(global.commune_plural, lowercase) desactivados',
    },
    selection: {
      selectAll: 'Seleccionar todos',
    },
    demand: {
      selectAll: 'Seleccionar todos',
    },
    toggleActiveSecondLevel: {
      activatedMsg:
        'Has agregado {{count}} nuevo $t(global.commune, lowercase) activo a la $t(global.region)',
      activatedMsg_plural:
        'Has agregado {{count}} nuevos $t(global.commune_plural, lowercase) activos a la $t(global.region)',
      deactivatedAllMsg:
        'Has desactivado todos los $t(global.commune_plural, lowercase) de la $t(global.region)',
    },
    enableSecondLevel: {
      title: 'Activar otros $t(global.commune, {"count": 0 })',
      select: {
        all: 'Activar todos $t($t(global.communeWArticle, {"count": 0 }), lowercase) de $t($t(global.regionWArticle), lowercase)',
      },
    },
    components: {
      CommunesFormItemCard: {
        communesError: 'Selecciona al menos un $t(global.commune, lowercase)',
      },
    },
  },
};
