export enum RolesEnum {
  ADMIN = 'admin',
  STAFF = 'staff',
  STAFF_ONBOARDING = 'staff_onboarding',
  STAFF_LEGAL = 'staff_legal',
  STAFF_FINANCE = 'staff_finance',
  STAFF_MARKETING = 'staff_marketing',
  ENTERPRISE = 'enterprise',
  ENTERPRISE_ADMIN = 'enterprise_admin',
}
