import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import server from '@services/api';
import i18n from 'i18next';

import {
  fetchJobsRoute,
  deleteJobRoute,
  updateStatusJobRoute,
  getJobApplicationsRoute,
  getJobDetailsRoute,
  updateJobRoute,
  getJobApplicationsDataRoute,
  getJobOverlappedJobbersRoute,
  updateRecordHoursRoute,
  applicationUpdateDeleteCommentRoute,
  applicationCreateCommentRoute,
  multipleJobApplicationsRoute,
  rosterEmailRoute,
  getExtraHoursRoute,
  updateAuthorizedOvertimeRoute,
  bulkCancelJobsRoute,
  bulkDeleteJobsRoute,
  updateCarouselStarRoute,
  updateTaskEntrySchemaRoute,
  getJobsWithSkillRoute,
  fetchStatusInsuredRoute,
  updateInsuredRoute,
  massiveInsuredActionRoute,
  updateJobPaymentSchemaVariablesRoute,
  acceptJobRoute,
} from '@features/tasks/ts/routes';
import {
  ApplicationsDataPayload,
  ApplicationsPayload,
  OverlappedJobbersPayload,
  ApplicationStatusUpdate,
  GetJobPayload,
  Jobs,
  RecordHoursUpdate,
  ApplicationPayload,
  CreateApplicationComment,
  UpdateApplicationComment,
  FinishedApplicationsBody,
  RosterEmail,
} from './ts/jobs.interfaces';
import { RequestStatusEnum } from '@utils/enum';
import { sendLog } from '@utils/SentryError';
import { InsuredStatusEnum, JobApplicationStatusEnum, JobStatusEnum } from './ts/jobs.enum';
import { createUpdateApplicationRoute } from './ts/utils';
import { spreadsheetAssignRoute } from '../payments/ts/routes';

export const tasksAdapter = createEntityAdapter();

export const initialState: any = tasksAdapter.getInitialState({
  tasks: {
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  loading: RequestStatusEnum.IDLE,
  currentRequestId: undefined,
  error: null,
  jobFormError: null,
  alertMessages: { type: undefined, text: '', extraLines: [] },
  alertMessagesForHistory: { type: undefined, text: '', extraLines: [] },
  alertMessagesForHistoryHeaders: { type: undefined, text: '', extraLines: [] },
  alertMessagesForComments: { type: undefined, text: '', extraLines: [] },
  stores: {
    docs: [],
  },
  jobDetails: { loading: false, error: false },
  jobEditDetails: { loading: false },
  historyJobberDetails: { loading: false },
  applications: { loading: false },
  applicationsDetails: { loading: false },
  overlappedJobbers: {
    loading: false,
    overlapped: [],
    warningOverlapped: [],
    blocked: [],
    disconnected: [],
    jobbersWithoutContract: [],
  },
  overlappedJobbersForList: { loading: false, overlapped: [] },
  historyApplicationDetails: { loading: false },
  jobberParallelApplications: { loading: false },
  updateApplicationStatus: RequestStatusEnum.IDLE,
  updateApplicationType: '',
  bulkAssignStatus: RequestStatusEnum.IDLE,
  updateRecordHoursStatus: RequestStatusEnum.IDLE,
  assigned: { loading: false },
  createApplicationComment: { loading: false },
  rosterEmail: {
    loadingEmails: false,
    suggestedEmails: [],
    submitStatus: RequestStatusEnum.IDLE,
    message: '',
  },
  extraHours: {
    loading: RequestStatusEnum.IDLE,
    refresh: false,
    docs: [],
    previous: null,
    next: null,
    totalDocs: null,
  },
  updateOvertime: RequestStatusEnum.IDLE,
  listFilter: {},
  bulkCancelStatus: RequestStatusEnum.IDLE,
  bulkDeleteStatus: RequestStatusEnum.IDLE,
  bulkCreateSubmit: {
    status: RequestStatusEnum.IDLE,
    current: null,
    total: null,
    failed: [],
    success: [],
    successPrevious: [],
  },
  updateEntrySchemaStatus: RequestStatusEnum.IDLE,
  tasksWithSkills: {
    data: {},
    loading: RequestStatusEnum.IDLE,
  },
  insuredStatus: {
    executed: undefined,
    loading: RequestStatusEnum.IDLE,
  },
  editInsuredSubmit: RequestStatusEnum.IDLE,
  massiveInsuredAction: RequestStatusEnum.IDLE,
  updateJobPaymentSchemaVariables: {
    loading: RequestStatusEnum.IDLE,
    error: null,
  },
});

export const getJobs = createAsyncThunk(
  'tasks/getJobs',
  async (params: any, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: Jobs = await server({
      method: 'get',
      url: fetchJobsRoute(),
      params,
    });

    return response.data;
  }
);

// TODO: crear interfaz
export const createJob = createAsyncThunk(
  'tasks/createJobs',
  async (payload: any, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId, loading } = getState().tasks;
      if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
        return;
      }
      const response = await server.post('/assignment/job-orders', payload);

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateJob = createAsyncThunk(
  'tasks/updateJob',
  async (job: any, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId, loading } = getState().tasks;
      if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
        return;
      }
      const response = await server.put(updateJobRoute(job._id), job);

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateTaskStatus = createAsyncThunk(
  'tasks/updateTaskStatus',
  async (task: any, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: Jobs = await server({
      method: 'put',
      url: updateStatusJobRoute(task._id),
      data: { status: task.status },
    });

    return response;
  }
);

export const deleteTask = createAsyncThunk(
  'tasks/deleteTask',
  async (id: string, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: Jobs = await server({
      method: 'delete',
      url: deleteJobRoute(id),
    });

    return response;
  }
);

export const acceptJob = createAsyncThunk(
  'tasks/acceptJob',
  async (id: string, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: Jobs = await server({
      method: 'put',
      url: acceptJobRoute(id),
    });

    return response;
  }
);


export const getJobDetails = createAsyncThunk(
  'tasks/getJobDetails',
  async (payload: GetJobPayload, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.get(getJobDetailsRoute(payload.id));

    return response?.data;
  }
);

export const getJobDetailsByCode = createAsyncThunk(
  'tasks/getJobDetailsByCode',
  async (payload: any, { getState, requestId, rejectWithValue }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.get(fetchJobsRoute(), {
      params: { wildcard: payload.wildcard, populate: payload?.populate },
    });

    if (response?.data?.data?.totalDocs !== 1)
      return rejectWithValue({
        status: 400,
      });

    return response;
  }
);

export const getJobOverlappedJobbers = createAsyncThunk(
  'tasks/getJobOverlappedJobbers',
  async (payload: OverlappedJobbersPayload, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.get(getJobOverlappedJobbersRoute(), { params: { ...payload } });

    return response?.data;
  }
);

export const getJobApplicationsData = createAsyncThunk(
  'tasks/getJobApplicationsData',
  async (payload: ApplicationsDataPayload, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.get(getJobApplicationsDataRoute(), {
      params: { ...payload },
    });

    return response?.data;
  }
);

export const getJobApplications = createAsyncThunk(
  'tasks/getApplications',
  async (payload: ApplicationsPayload, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response = await server.get(getJobApplicationsRoute(), { params: { ...payload } });
    return response?.data;
  }
);

export const getJobApplication = createAsyncThunk(
  'tasks/getApplication',
  async (payload: ApplicationPayload) => {
    const response = await server.get(getJobApplicationsRoute(), {
      params: { jobber: [payload.jobber], ...payload },
    });
    return response?.data;
  }
);

export const updateJobApplication = createAsyncThunk(
  'tasks/updateJobApplication',
  async (body: ApplicationStatusUpdate, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId, loading } = getState().tasks;
      if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
        return;
      }
      const response: Jobs = await server.put(createUpdateApplicationRoute(body), body);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createFinishedApplications = createAsyncThunk(
  'tasks/createFinishedApplications',
  async (body: FinishedApplicationsBody, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId, loading } = getState().tasks;
      if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
        return;
      }
      const response: Jobs = await server.post(multipleJobApplicationsRoute(), body);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateRecordHours = createAsyncThunk(
  'tasks/updateRecordHours',
  async (body: RecordHoursUpdate, { getState, requestId }: any) => {
    const { currentRequestId, loading } = getState().tasks;
    if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
      return;
    }
    const response: Jobs = await server.put(
      updateRecordHoursRoute(body.applicationId, body.recordId),
      {
        presentAt: body.presentAt,
        finishAt: body.finishAt,
        authorizedOvertime: body.authorizedOvertime,
      }
    );

    return response?.data;
  }
);

export const createApplicationComment = createAsyncThunk(
  'tasks/createApplicationComment',
  async (body: CreateApplicationComment, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId, loading } = getState().tasks;
      if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
        return;
      }
      const response = await server.post(applicationCreateCommentRoute(body.applicationId), {
        type: body.type,
        message: body.message,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateApplicationComment = createAsyncThunk(
  'tasks/updateApplicationComment',
  async (body: UpdateApplicationComment, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId, loading } = getState().tasks;
      if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
        return;
      }
      const response = await server.put(applicationUpdateDeleteCommentRoute(body.commentId), {
        type: body.type,
        message: body.message,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteApplicationComment = createAsyncThunk(
  'tasks/deleteApplicationComment',
  async (commentId: string, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId, loading } = getState().tasks;
      if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
        return;
      }
      const response = await server.delete(applicationUpdateDeleteCommentRoute(commentId));

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const submitRosterEmail = createAsyncThunk(
  'tasks/submitRosterEmail',
  async (payload: RosterEmail, { getState, rejectWithValue, requestId }: any) => {
    try {
      const { currentRequestId, loading } = getState().tasks;
      if (loading !== RequestStatusEnum.PENDING || requestId !== currentRequestId) {
        return;
      }
      const response: Jobs = await server.get(rosterEmailRoute(), { params: payload });

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getExtraHours = createAsyncThunk(
  'payments/getExtraHours',
  async (params: any, { getState }: any) => {
    const { extraHours } = getState().tasks;
    if (extraHours?.loading !== RequestStatusEnum.PENDING) {
      return;
    }
    const response: Jobs = await server({
      method: 'get',
      url: getExtraHoursRoute(),
      params,
    });
    return response.data;
  }
);

export const updateAuthorizedOvertime = createAsyncThunk(
  'payments/updateAuthorizedOvertime',
  async (payload: any, { getState, rejectWithValue }: any) => {
    try {
      const { updateOvertime } = getState().tasks;
      if (updateOvertime !== RequestStatusEnum.PENDING) {
        return;
      }
      const response: Jobs = await server({
        method: 'put',
        url: updateAuthorizedOvertimeRoute(),
        data: payload,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const bulkCancelJob = createAsyncThunk(
  'payments/bulkCancelJob',
  async (payload: any, { getState, rejectWithValue }: any) => {
    try {
      const { bulkCancelStatus } = getState().tasks;
      if (bulkCancelStatus !== RequestStatusEnum.PENDING) {
        return;
      }
      const response = await server({
        method: 'put',
        url: bulkCancelJobsRoute(),
        data: payload,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);
export const bulkDeleteJob = createAsyncThunk(
  'payments/bulkDeleteJob',
  async (payload: any, { getState, rejectWithValue }: any) => {
    try {
      const { bulkDeleteStatus } = getState().tasks;
      if (bulkDeleteStatus !== RequestStatusEnum.PENDING) {
        return;
      }
      const response = await server({
        method: 'delete',
        url: bulkDeleteJobsRoute(),
        params: payload,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const bulkCreateJobs = createAsyncThunk(
  'tasks/bulkCreateJobs',
  async (payload: any, { rejectWithValue }: any) => {
    try {
      const response = await server.post('/assignment/job-orders', payload);

      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const bulkUpdateJobs = createAsyncThunk(
  'tasks/bulkUpdateJobs',
  async (payload: any, { rejectWithValue }: any) => {
    try {
      const response = await server.put(updateJobRoute(payload.rowId), { ...payload });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateCarouselStar = createAsyncThunk(
  'tasks/updateCarouselStar',
  async (payload: { taskId: string; carrouselStar: boolean }, { rejectWithValue }: any) => {
    try {
      const response = await server.put(updateCarouselStarRoute(payload.taskId), { ...payload });
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateTaskEntrySchema = createAsyncThunk(
  'payments/updateTaskEntrySchema',
  async (payload: any, { rejectWithValue }: any) => {
    try {
      const response: Jobs = await server({
        method: 'put',
        url: updateTaskEntrySchemaRoute(payload.taskId),
        data: payload,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const getJobsWithSkill = createAsyncThunk(
  'tasks/getJobsWithSkill',
  async (payload: string, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'get',
        url: getJobsWithSkillRoute(payload),
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const loadMassiveAssign = createAsyncThunk(
  'tasks/loadMassiveAssign',
  async (payload: { key: string; timezone: string }, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'post',
        url: spreadsheetAssignRoute(),
        data: payload,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const fetchStatusInsured = createAsyncThunk(
  'tasks/fetchStatusInsured',
  async (params: { _id: string }, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'get',
        url: fetchStatusInsuredRoute(),
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const updateInsured = createAsyncThunk(
  'tasks/updateInsured',
  async (payload: { _id: string; payInsured: boolean }, { rejectWithValue }: any) => {
    try {
      const response = await server({
        method: 'put',
        url: updateInsuredRoute(payload._id),
        data: payload,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const massiveInsuredAction = createAsyncThunk(
  'tasks/massiveInsuredAction',
  async (
    payload: { status: InsuredStatusEnum; applications: string[] },
    { rejectWithValue }: any
  ) => {
    try {
      const response = await server({
        method: 'put',
        url: massiveInsuredActionRoute(),
        data: payload,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

export const updateJobPaymentSchemaVariables = createAsyncThunk(
  'tasks/updateJobPaymentSchemaVariables',
  async (
    payload: { _id: string; variables: { code: string; value: number; max?: number }[] },
    { rejectWithValue }: any
  ) => {
    try {
      const response = await server({
        method: 'put',
        url: updateJobPaymentSchemaVariablesRoute(payload._id),
        data: {
          variables: payload.variables,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response, null);
    }
  }
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    clearAlertMsg: (state) => {
      state.alertMessages = { type: '', text: '', extraLines: [] };
    },
    clearAlertMsgForHistory: (state) => {
      state.alertMessagesForHistory = { type: '', text: '', extraLines: [] };
      state.alertMessagesForHistoryHeaders = { type: '', text: '', extraLines: [] };
    },
    clearAlertMsgForComments: (state) => {
      state.alertMessagesForComments = { type: '', text: '', extraLines: [] };
    },
    clearRosterAlertMsg: (state) => {
      state.rosterEmail.message = '';
    },
    clearNewJobError: (state) => {
      state.jobFormError = null;
    },
    clearState: (state, b: { payload: { key: string; subkey?: string; base?: any } }) => {
      const { key, subkey, base } = b.payload ?? {};
      const originalState = base ?? RequestStatusEnum.IDLE;
      if (subkey && state[key]?.[subkey]) state[key][subkey] = originalState;
      else if (key && state[key]) state[key] = originalState;
    },
    clearBulkCreate: (state, b: { payload: any }) => {
      let newSuccessPrevious = b.payload?.clearLists ? [] : state.bulkCreateSubmit.successPrevious;
      if (!b.payload?.clearLists && state.bulkCreateSubmit.success?.length > 0)
        newSuccessPrevious = [
          ...state.bulkCreateSubmit.success,
          ...state.bulkCreateSubmit.successPrevious,
        ];
      state.bulkCreateSubmit = {
        successPrevious: newSuccessPrevious,
        failed: b.payload?.clearLists ? [] : state.bulkCreateSubmit.failed,
        success: [],
        status: RequestStatusEnum.IDLE,
        current: null,
        total: null,
      };
    },
  },
  extraReducers: {
    [String(getJobs.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.listFilter = {};
      }
    },
    [String(getJobs.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.tasks = action.payload.data;
        state.currentRequestId = undefined;
        if (!arg.dontSaveArgs) {
          state.listFilter = arg;
        }
      }
    },
    [String(getJobs.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
        state.listFilter = {};
      }
    },

    [String(createJob.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(createJob.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.alertMessages = {
          type: 'success',
          text: i18n.t('tasks.jobs.drawer.new.message'),
          extraLines: [],
        };
        state.jobFormError = null;
        state.currentRequestId = undefined;
      }
    },
    [String(createJob.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
        const status = action?.payload?.status;
        if (status === 409)
          state.jobFormError = i18n.t('tasks.jobs.drawer.new.overlappedJobsError');
        else state.jobFormError = null;
        sendLog('Store - Tasks', action?.meta?.arg, 'Fatal', action.error, 'BO-TASKS_SLICE-293');
      }
    },

    [String(updateJob.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updateJob.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.alertMessages = {
          type: 'success',
          text: i18n.t('tasks.jobs.drawer.edit.message'),
          extraLines: [],
        };
        state.jobFormError = null;
        state.currentRequestId = undefined;
      }
    },
    [String(updateJob.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
        const status = action.payload.status;
        if (status === 406)
          state.jobFormError = i18n.t('tasks.jobs.drawer.edit.negativeQuotasError');
      }
    },

    [String(deleteTask.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(deleteTask.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.alertMessages = {
          type: 'success',
          text: i18n.t('tasks.jobs.modalActions.deleteTask.message'),
          extraLines: [],
        };
        state.currentRequestId = undefined;
      }
    },
    [String(deleteTask.rejected)]: (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(acceptJob.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(acceptJob.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(acceptJob.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(updateTaskStatus.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updateTaskStatus.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        const { status } = action.meta?.arg || {
          status: JobStatusEnum.CANCELLED,
        };
        state.loading = RequestStatusEnum.IDLE;
        state.alertMessages = {
          type: 'success',
          text: i18n.t(
            `tasks.jobs.modalActions.${
              status === JobStatusEnum.CANCELLED ? 'cancelTask' : 'publishTask'
            }.message`
          ),
          extraLines: [],
        };
        state.currentRequestId = undefined;
      }
    },
    [String(updateTaskStatus.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(getJobDetails.pending)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'jobEditDetails' : 'jobDetails';
      state[field] = { loading: true, error: false };
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getJobDetails.fulfilled)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'jobEditDetails' : 'jobDetails';
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state[field] = {
          loading: false,
          ...action.payload?.data,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(getJobDetails.rejected)]: (state, action) => {
      const field = action.meta.arg?.forEditing ? 'jobEditDetails' : 'jobDetails';
      state[field] = { loading: false, error: true };
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },

    [String(getJobDetailsByCode.pending)]: (state, action) => {
      state.jobDetails = { loading: true, error: false };
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getJobDetailsByCode.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;

      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        const auxTasks = action.payload?.data?.data?.docs?.[0];
        state.tasks.docs.push(auxTasks);
        state.loading = RequestStatusEnum.IDLE;
        state.jobDetails = { loading: false, error: false, ...auxTasks };
        state.currentRequestId = undefined;
      }
    },
    [String(getJobDetailsByCode.rejected)]: (state, action) => {
      state.jobDetails = { loading: false, error: true };
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },

    [String(getJobOverlappedJobbers.pending)]: (state, action) => {
      const field = action.meta.arg?.forList ? 'overlappedJobbersForList' : 'overlappedJobbers';

      state[field].loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getJobOverlappedJobbers.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        const field = action.meta.arg?.forList ? 'overlappedJobbersForList' : 'overlappedJobbers';
        state.loading = RequestStatusEnum.IDLE;
        state[field] = {
          loading: false,
          blocked: [],
          disconnected: [],
          ...action.payload?.data,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(getJobOverlappedJobbers.rejected)]: (state, action) => {
      const field = action.meta.arg?.forList ? 'overlappedJobbersForList' : 'overlappedJobbers';
      state[field].loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },

    [String(getJobApplicationsData.pending)]: (state, action) => {
      const field = action.meta.arg?.forHistory ? 'historyJobberDetails' : 'applications';
      state[field] = { loading: true };
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getJobApplicationsData.fulfilled)]: (state, action) => {
      const field = action.meta.arg?.forHistory ? 'historyJobberDetails' : 'applications';
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state[field] = {
          loading: false,
          ...action.payload,
        };
        state.currentRequestId = undefined;
      }
    },
    [String(getJobApplicationsData.rejected)]: (state, action) => {
      state.applications.loading = false;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },

    [String(getJobApplications.pending)]: (state, action) => {
      const field = action.meta.arg?.parallelJobs ? 'jobberParallelJobs' : 'assigned';
      state[field] = { loading: true };
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(getJobApplications.fulfilled)]: (state, action) => {
      const field = action.meta.arg?.parallelJobs ? 'jobberParallelJobs' : 'assigned';
      state.loading = RequestStatusEnum.IDLE;
      state[field] = {
        loading: false,
        ...action.payload?.data,
      };
      state.currentRequestId = undefined;
    },
    [String(getJobApplications.rejected)]: (state, action) => {
      const field = action.meta.arg?.parallelJobs ? 'jobberParallelJobs' : 'assigned';
      state[field].loading = false;
      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
    },

    [String(getJobApplication.pending)]: (state) => {
      state.historyApplicationDetails = { loading: true };
    },
    [String(getJobApplication.fulfilled)]: (state, action) => {
      state.historyApplicationDetails = {
        loading: false,
        ...action.payload?.data,
      };
    },
    [String(getJobApplication.rejected)]: (state) => {
      state.historyApplicationDetails.loading = false;
    },

    [String(updateJobApplication.pending)]: (state, action) => {
      state.updateApplicationStatus = RequestStatusEnum.PENDING;
      state.updateApplicationType = action.meta.arg?.applicationType;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updateJobApplication.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading !== RequestStatusEnum.PENDING || state.currentRequestId !== requestId)
        return;

      const {
        assigned,
        overlapped,
        alreadyAssigned,
        blocked,
        present,
        notPresent,
        absent,
        notAbsent,
        finish,
        notFinish,
        disconnected,
        jobbersWithoutContract,
      } = action.payload?.data || {
        assigned: [],
        overlapped: [],
        alreadyAssigned: [],
        blocked: [],
        present: [],
        notPresent: [],
        absent: [],
        notAbsent: [],
        finish: [],
        notFinish: [],
        disconnected: [],
        jobbersWithoutContract: [],
      };

      const completed = [
        ...(assigned || []),
        ...(present || []),
        ...(absent || []),
        ...(finish || []),
      ];

      const updateFailed = [...(notPresent || []), ...(notAbsent || []), ...(notFinish || [])];
      const { jobbers, status, forHistory } = action.meta?.arg || {
        jobbers: [],
        status: JobApplicationStatusEnum.ASSIGNED,
        forHistory: false,
      };

      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.updateApplicationStatus = RequestStatusEnum.SUCCESS;
      const field = forHistory ? 'alertMessagesForHistory' : 'alertMessages';

      if (
        state.updateApplicationType === 'bulk_assign' &&
        (overlapped?.length > 0 ||
          alreadyAssigned?.length > 0 ||
          blocked?.length > 0 ||
          disconnected?.length > 0 ||
          jobbersWithoutContract?.length > 0)
      ) {
        state.overlappedJobbers = {
          loading: false,
          overlappedComplete: [...overlapped, ...alreadyAssigned],
          overlapped: [
            ...overlapped?.map((obj) => obj._id),
            ...alreadyAssigned?.map((obj) => obj._id),
          ],
          blockedComplete: blocked,
          blocked: blocked?.map((obj) => obj._id),
          disconnectedComplete: disconnected,
          disconnected: disconnected?.map((obj) => obj._id),
          jobbersWithoutContract: jobbersWithoutContract.map((obj) => obj._id),
          jobbersWithoutContractComplete: jobbersWithoutContract,
        };
      }

      if (
        overlapped?.length > 0 ||
        blocked?.length > 0 ||
        updateFailed?.length > 0 ||
        disconnected?.length > 0 ||
        jobbersWithoutContract?.length > 0
      ) {
        const extraLines = [];
        if (overlapped?.length > 0) {
          extraLines.push(
            i18n.t(`tasks.jobDetails.modal.${status}.alertMessage`, {
              count: overlapped?.length,
              jobbers: overlapped
                .map((obj, index) => {
                  let extra = '';
                  if (index < overlapped?.length - 2) extra = ', ';
                  else if (index < overlapped?.length - 1) extra = ' y ';
                  return `${obj.personalData.names} ${obj.personalData.surnames}${extra}`;
                })
                .join(''),
            })
          );
        }

        if (blocked?.length > 0) {
          extraLines.push(
            i18n.t(`tasks.jobDetails.modal.assigned.manual.blockedMessage`, {
              count: blocked?.length,
              jobbers: blocked
                .map((obj, index) => {
                  let extra = '';
                  if (index < blocked?.length - 2) extra = ', ';
                  else if (index < blocked?.length - 1) extra = ' y ';
                  return `${obj.personalData.names} ${obj.personalData.surnames}${extra}`;
                })
                .join(''),
              jobberName: blocked[0].personalData.names,
            })
          );
        }
        if (updateFailed?.length > 0) {
          extraLines.push(
            i18n.t(`tasks.jobDetails.modal.${status}.bulk.failedMessage`, {
              count: updateFailed?.length,
              jobbers: updateFailed
                .map((obj, index) => {
                  let extra = '';
                  if (index < updateFailed?.length - 2) extra = ', ';
                  else if (index < updateFailed?.length - 1) extra = ' y ';
                  return `${obj.personalData.names} ${obj.personalData.surnames}${extra}`;
                })
                .join(''),
            })
          );
        }

        if (disconnected?.length > 0) {
          extraLines.push(
            i18n.t(`tasks.jobDetails.modal.assigned.disconnectedMessage`, {
              count: disconnected?.length,
              jobbers: disconnected
                .map((obj, index) => {
                  let extra = '';
                  if (index < disconnected?.length - 2) extra = ', ';
                  else if (index < disconnected?.length - 1) extra = ' y ';
                  return `${obj.personalData.names} ${obj.personalData.surnames}${extra}`;
                })
                .join(''),
            })
          );
        }
        if (jobbersWithoutContract?.length > 0) {
          extraLines.push(
            i18n.t(`tasks.jobDetails.modal.assigned.withOutContractMessage`, {
              count: jobbersWithoutContract?.length,
              jobbers: jobbersWithoutContract
                .map((obj, index) => {
                  let extra = '';
                  if (index < jobbersWithoutContract?.length - 2) extra = ', ';
                  else if (index < jobbersWithoutContract?.length - 1) extra = ' y ';
                  return `${obj.personalData.names} ${obj.personalData.surnames}${extra}`;
                })
                .join(''),
            })
          );
        }
        state[field] = {
          type:
            state.updateApplicationType === 'application' && disconnected?.length === 1
              ? 'warningAlert'
              : 'warning',
          text:
            completed?.length > 0
              ? i18n.t(`tasks.jobDetails.modal.${status}.successMessage`, {
                  count: completed?.length,
                })
              : '',
          extraLines,
        };
      } else {
        state[field] = {
          type: 'success',
          text: i18n.t(`tasks.jobDetails.modal.${status}.successMessage`, {
            count: completed?.length || jobbers?.length || 1,
          }),
          extraLines: [],
        };
      }
    },
    [String(updateJobApplication.rejected)]: (state, action) => {
      state.updateApplicationStatus = RequestStatusEnum.ERROR;
      state.updateApplicationType = '';
      const { status } = action.meta?.arg;
      const statusCode = action?.payload?.status;

      state.alertMessagesForHistory = {
        type: 'error',
        text: i18n.t(
          `tasks.jobDetails.modal.${status}.errors.code.${
            statusCode === 409 ? statusCode : 'default'
          }`
        ),
        extraLines: [],
      };

      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
    },

    [String(createFinishedApplications.pending)]: (state, action) => {
      state.updateApplicationStatus = RequestStatusEnum.PENDING;
      state.updateApplicationType = 'new_finished';
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(createFinishedApplications.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading !== RequestStatusEnum.PENDING || state.currentRequestId !== requestId)
        return;

      const {
        jobbersCreatedOrUpdated,
        overlappedJobbers,
        finishedJobbers,
        jobbersWithoutContract,
      } = action.payload?.data || {
        jobbersCreatedOrUpdated: [],
        overlappedJobbers: [],
        finishedJobbers: [],
        jobbersWithoutContract: [],
      };
      const { jobbers } = action.meta?.arg || {
        jobbers: [],
      };
      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.updateApplicationStatus = RequestStatusEnum.SUCCESS;

      if (
        overlappedJobbers?.length > 0 ||
        finishedJobbers?.length > 0 ||
        jobbersWithoutContract?.length > 0
      ) {
        state.overlappedJobbers = {
          loading: false,
          overlapped: [...overlappedJobbers, ...finishedJobbers],
          jobbersWithoutContract,
          blocked: [],
        };
      } else {
        state.alertMessages = {
          type: 'success',
          text: i18n.t(`tasks.jobDetails.modal.assigned.successMessage`, {
            count: jobbersCreatedOrUpdated?.length || jobbers?.length || 1,
          }),
          extraLines: [],
        };
      }
    },
    [String(createFinishedApplications.rejected)]: (state) => {
      state.updateApplicationStatus = RequestStatusEnum.ERROR;
      state.updateApplicationType = '';

      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
    },
    [String(updateRecordHours.pending)]: (state, action) => {
      state.updateRecordHoursStatus = RequestStatusEnum.PENDING;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updateRecordHours.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading !== RequestStatusEnum.PENDING || state.currentRequestId !== requestId)
        return;

      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.updateRecordHoursStatus = RequestStatusEnum.SUCCESS;
    },
    [String(updateRecordHours.rejected)]: (state, action) => {
      state.updateRecordHoursStatus = RequestStatusEnum.ERROR;
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(createApplicationComment.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(createApplicationComment.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading !== RequestStatusEnum.PENDING || state.currentRequestId !== requestId)
        return;

      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.alertMessagesForComments = {
        type: 'success',
        text: i18n.t('tasks.jobDetails.history.comments.new.message'),
        extraLines: [],
      };
    },
    [String(createApplicationComment.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(updateApplicationComment.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updateApplicationComment.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading !== RequestStatusEnum.PENDING || state.currentRequestId !== requestId)
        return;

      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.alertMessagesForComments = {
        type: 'success',
        text: i18n.t('tasks.jobDetails.history.comments.update.message'),
        extraLines: [],
      };
    },
    [String(updateApplicationComment.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(deleteApplicationComment.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(deleteApplicationComment.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading !== RequestStatusEnum.PENDING || state.currentRequestId !== requestId)
        return;

      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.alertMessagesForComments = {
        type: 'success',
        text: i18n.t('tasks.jobDetails.history.comments.delete.message'),
        extraLines: [],
      };
    },
    [String(deleteApplicationComment.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(submitRosterEmail.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
        state.rosterEmail.submitStatus = RequestStatusEnum.PENDING;
      }
    },
    [String(submitRosterEmail.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading !== RequestStatusEnum.PENDING || state.currentRequestId !== requestId)
        return;

      state.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
      state.rosterEmail.submitStatus = RequestStatusEnum.IDLE;
      state.rosterEmail.message = i18n.t('tasks.jobDetails.modal.sendRoster.successMessage', {
        emails: action.meta.arg?.emails.join(', '),
        count: action.meta.arg?.emails?.length,
      });
    },
    [String(submitRosterEmail.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
        state.rosterEmail.submitStatus = RequestStatusEnum.IDLE;
      }
    },

    [String(getExtraHours.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.extraHours.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(getExtraHours.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.extraHours = { loading: RequestStatusEnum.IDLE, ...action.payload?.data };
      state.currentRequestId = undefined;
    },
    [String(getExtraHours.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.extraHours.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(updateAuthorizedOvertime.pending)]: (state, action) => {
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
        state.updateOvertime = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(updateAuthorizedOvertime.fulfilled)]: (state, action) => {
      const { requestId, arg } = action.meta;
      const field = action.meta.arg?.forHistory
        ? 'alertMessagesForHistoryHeaders'
        : 'alertMessages';
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state[field] = {
          type: 'success',
          text: i18n.t(
            `tasks.jobs.dashboard.tabs.extraHours.${action.meta.arg?.forHistory ? `history.` : ''}${
              arg.value
            }.successToast`
          ),
          extraLines: [],
        };
        state.updateOvertime = RequestStatusEnum.SUCCESS;
        state.currentRequestId = undefined;
      }
    },
    [String(updateAuthorizedOvertime.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === RequestStatusEnum.PENDING && state.currentRequestId === requestId) {
        state.loading = RequestStatusEnum.IDLE;
        state.updateOvertime = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },
    [String(bulkCancelJob.pending)]: (state, action) => {
      if (state.bulkCancelStatus === RequestStatusEnum.IDLE) {
        state.bulkCancelStatus = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(bulkCancelJob.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.bulkCancelStatus === RequestStatusEnum.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.bulkCancelStatus = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(bulkCancelJob.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.bulkCancelStatus === RequestStatusEnum.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.bulkCancelStatus = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(bulkDeleteJob.pending)]: (state, action) => {
      if (state.bulkDeleteStatus === RequestStatusEnum.IDLE) {
        state.bulkDeleteStatus = RequestStatusEnum.PENDING;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [String(bulkDeleteJob.fulfilled)]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.bulkDeleteStatus === RequestStatusEnum.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.bulkDeleteStatus = RequestStatusEnum.IDLE;
        state.currentRequestId = undefined;
      }
    },
    [String(bulkDeleteJob.rejected)]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.bulkDeleteStatus === RequestStatusEnum.PENDING &&
        state.currentRequestId === requestId
      ) {
        state.bulkDeleteStatus = RequestStatusEnum.IDLE;
        state.error = action.error;
        state.currentRequestId = undefined;
      }
    },

    [String(bulkCreateJobs.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.bulkCreateSubmit.status = RequestStatusEnum.PENDING;
      if (!state.bulkCreateSubmit.total) {
        state.bulkCreateSubmit.current = 1;
        state.bulkCreateSubmit.total = action.meta.arg?.total;
        state.bulkCreateSubmit.failed = [];
      } else state.bulkCreateSubmit.current = state.bulkCreateSubmit.current + 1;
      state.currentRequestId = action.meta.requestId;
    },
    [String(bulkCreateJobs.fulfilled)]: (state, action) => {
      if (state.bulkCreateSubmit.current === state.bulkCreateSubmit.total) {
        state.loading = RequestStatusEnum.IDLE;
        state.bulkCreateSubmit.status = RequestStatusEnum.SUCCESS;
        state.alertMessages = {
          type: 'success',
          text: i18n.t('tasks.jobs.drawer.new.message'),
          extraLines: [],
        };
      }
      state.bulkCreateSubmit.success = [
        ...state.bulkCreateSubmit.success,
        {
          _id: action.meta.arg?.rowId,
          code: action.payload?.data?.data?.jobs?.[0]?.code,
        },
      ];
      state.currentRequestId = undefined;
    },
    [String(bulkCreateJobs.rejected)]: (state, action) => {
      if (state.bulkCreateSubmit.current === state.bulkCreateSubmit.total) {
        state.loading = RequestStatusEnum.IDLE;
        state.bulkCreateSubmit.status = RequestStatusEnum.SUCCESS;
        state.error = action.error;
      }
      state.bulkCreateSubmit.failed = [
        ...state.bulkCreateSubmit.failed,
        {
          _id: action.meta.arg?.rowId,
          code: action.payload.status,
        },
      ];
      state.currentRequestId = undefined;
    },

    [String(bulkUpdateJobs.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.bulkCreateSubmit.status = RequestStatusEnum.PENDING;
      if (!state.bulkCreateSubmit.total) {
        state.bulkCreateSubmit.current = 1;
        state.bulkCreateSubmit.total = action.meta.arg?.total;
        state.bulkCreateSubmit.failed = [];
      } else state.bulkCreateSubmit.current = state.bulkCreateSubmit.current + 1;
      state.currentRequestId = action.meta.requestId;
    },
    [String(bulkUpdateJobs.fulfilled)]: (state, action) => {
      if (state.bulkCreateSubmit.current === state.bulkCreateSubmit.total) {
        state.loading = RequestStatusEnum.IDLE;
        state.bulkCreateSubmit.status = RequestStatusEnum.SUCCESS;
        state.alertMessages = {
          type: 'success',
          text: i18n.t('tasks.jobs.drawer.new.message'),
          extraLines: [],
        };
      }
      state.bulkCreateSubmit.success = [
        ...state.bulkCreateSubmit.success,
        {
          _id: action.meta.arg?.rowId,
          code: action.payload?.data?.data?.jobs?.[0]?.code,
        },
      ];
      state.currentRequestId = undefined;
    },
    [String(bulkUpdateJobs.rejected)]: (state, action) => {
      if (state.bulkCreateSubmit.current === state.bulkCreateSubmit.total) {
        state.loading = RequestStatusEnum.IDLE;
        state.bulkCreateSubmit.status = RequestStatusEnum.SUCCESS;
        state.error = action.error;
      }
      state.bulkCreateSubmit.failed = [
        ...state.bulkCreateSubmit.failed,
        {
          _id: action.meta.arg?.rowId,
          code: action.payload.status,
        },
      ];
      state.currentRequestId = undefined;
    },

    // UPDATE CARROUSEL STAR

    [String(updateCarouselStar.pending)]: (state) => {
      if (state.loading === RequestStatusEnum.IDLE) state.loading = RequestStatusEnum.PENDING;
    },
    [String(updateCarouselStar.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
    },
    [String(updateCarouselStar.rejected)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
    },

    [String(updateTaskEntrySchema.pending)]: (state) => {
      if (state.updateEntrySchemaStatus === RequestStatusEnum.IDLE)
        state.updateEntrySchemaStatus = RequestStatusEnum.PENDING;
    },
    [String(updateTaskEntrySchema.fulfilled)]: (state) => {
      state.updateEntrySchemaStatus = RequestStatusEnum.IDLE;
    },
    [String(updateTaskEntrySchema.rejected)]: (state) => {
      state.updateEntrySchemaStatus = RequestStatusEnum.IDLE;
    },

    [String(getJobsWithSkill.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
      state.tasksWithSkills.loading = RequestStatusEnum.PENDING;
    },
    [String(getJobsWithSkill.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.tasksWithSkills.data = action.payload?.data?.data;
      state.tasksWithSkills.loading = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
    },
    [String(getJobsWithSkill.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.tasksWithSkills.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(loadMassiveAssign.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(loadMassiveAssign.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.alertMessages = {
        type: 'success',
        text: i18n.t('tasks.jobs.dashboard.massiveStatus.success'),
        extraLines: [],
      };
      state.currentRequestId = undefined;
    },
    [String(loadMassiveAssign.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(fetchStatusInsured.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.insuredStatus.loading = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(fetchStatusInsured.fulfilled)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.insuredStatus = {
        executed: action.payload?.data?.data,
        loading: RequestStatusEnum.IDLE,
      };
      state.currentRequestId = undefined;
    },
    [String(fetchStatusInsured.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.insuredStatus.loading = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },

    [String(updateInsured.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.editInsuredSubmit = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(updateInsured.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.editInsuredSubmit = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
    },
    [String(updateInsured.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.editInsuredSubmit = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },
    [String(massiveInsuredAction.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.massiveInsuredAction = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(massiveInsuredAction.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.massiveInsuredAction = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
    },
    [String(massiveInsuredAction.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.massiveInsuredAction = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },
    [String(updateJobPaymentSchemaVariables.pending)]: (state, action) => {
      state.loading = RequestStatusEnum.PENDING;
      state.updateJobPaymentSchemaVariables = RequestStatusEnum.PENDING;
      state.currentRequestId = action.meta.requestId;
    },
    [String(updateJobPaymentSchemaVariables.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.updateJobPaymentSchemaVariables = RequestStatusEnum.IDLE;
      state.currentRequestId = undefined;
    },
    [String(updateJobPaymentSchemaVariables.rejected)]: (state, action) => {
      state.loading = RequestStatusEnum.IDLE;
      state.updateJobPaymentSchemaVariables = RequestStatusEnum.IDLE;
      state.error = action.error;
      state.currentRequestId = undefined;
    },
  },
});

export const {
  clearAlertMsg,
  clearAlertMsgForHistory,
  clearAlertMsgForComments,
  clearRosterAlertMsg,
  clearNewJobError,
  clearState,
  clearBulkCreate,
} = tasksSlice.actions;
export default tasksSlice.reducer;
